import React from 'react'
import { useSelector } from 'react-redux'

const { REACT_APP_DEPLOY_PHASE } = process.env

const AccessEnvironment = () => {
  const style = {
    position: 'fixed',
    height: 16,
    padding: '0px 8px',
    borderRadius: 16,
    fontSize: 11,
    lineHeight: '16px',
    margin: 1,
    border: '1px solid rgb(55, 195, 51)',
    color: 'rgb(55, 195, 51)',
    zIndex: 99999,
    fontWeight: 500,
    left: 0,
    top: 0,
  }
  const { currentAdmin } = useSelector(state => state.dspAccount)
  if (currentAdmin) {
    return (
      <p className="user_access" style={style}>
        통합어드민
      </p>
    )
  } else if (REACT_APP_DEPLOY_PHASE === 'dev') {
    return (
      <p className="user_access" style={style}>
        Develop
      </p>
    )
  } else if (REACT_APP_DEPLOY_PHASE === 'sandbox') {
    return (
      <p className="user_access" style={style}>
        Sandbox
      </p>
    )
  } else if (REACT_APP_DEPLOY_PHASE === 'cbt') {
    return (
      <p className="user_access" style={style}>
        CBT
      </p>
    )
  } else {
    return null
  }
}

export default AccessEnvironment
