import React from 'react'
import PropTypes from 'prop-types'

const NoDataTable = ({ colSpan, children }) => (
  <tr>
    <td colSpan={colSpan} className="nodata_info">
      <div className="inner_tbl">{children}</div>
    </td>
  </tr>
)

NoDataTable.propTypes = {
  colSpan: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default NoDataTable
