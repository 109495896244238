import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAdAccount, putAdAccountOff, putAdAccountOn } from 'api/accountAPI'
import { original } from 'immer'

export const AD_ACCOUNT_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  UNAUTHORIZED: 'unauthorized',
  FAIL: 'fail',
}

const persistAdAccount = adAccount => {
  sessionStorage.setItem('adAccount', JSON.stringify(adAccount))
}

const initialState = {
  current: undefined,
  status: AD_ACCOUNT_STATUS.IDLE,
  adAccounts: [],
}

export const fetchAdAccount = createAsyncThunk(
  'adAccount/fetchAdAccount',
  async (adAccountId, { rejectWithValue }) => {
    try {
      const { data } = await getAdAccount(adAccountId)
      return data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchAdAccountWithoutStatus = createAsyncThunk(
  'adAccount/fetchAdAccountWithoutStatus',
  async adAccountId => {
    const { data } = await getAdAccount(adAccountId)
    return data
  }
)

export const modifyAdAccountOnOff = createAsyncThunk(
  'adAccount/modifyAdAccountOnOff',
  async ({ adAccountId, state }) => {
    const { data } =
      state === 'OFF'
        ? await putAdAccountOff(adAccountId)
        : await putAdAccountOn(adAccountId)
    return data
  }
)

const adAccountSlice = createSlice({
  name: 'adAccount',
  initialState,

  extraReducers: builder => {
    builder.addCase(fetchAdAccount.pending, state => {
      state.status = AD_ACCOUNT_STATUS.PENDING
    })

    builder.addCase(fetchAdAccount.fulfilled, (state, { payload }) => {
      const account = payload
      persistAdAccount(account)
      state.current = account
      state.status = AD_ACCOUNT_STATUS.SUCCESS
      document.dispatchEvent(
        new CustomEvent('accountChange', { detail: account })
      )
    })

    builder.addCase(fetchAdAccount.rejected, (state, { payload }) => {
      const errorCode = payload?.errorCode
      if (errorCode === 9004 || errorCode === 6009 || errorCode === 6003) {
        state.status = AD_ACCOUNT_STATUS.UNAUTHORIZED
      } else {
        state.status = AD_ACCOUNT_STATUS.FAIL
      }
    })

    builder.addCase(
      fetchAdAccountWithoutStatus.fulfilled,
      (state, { payload }) => {
        const account = payload
        persistAdAccount(account)
        state.current = account
        document.dispatchEvent(
          new CustomEvent('accountChange', { detail: account })
        )
      }
    )

    builder.addCase(modifyAdAccountOnOff.fulfilled, (state, { payload }) => {
      const origin = original(state)
      const account = {
        ...origin.current,
        userConfig: payload.userConfig,
        status: payload.status,
        version: payload.version,
        lastModifiedDate: payload.lastModifiedDate,
      }
      persistAdAccount(account)
      document.dispatchEvent(
        new CustomEvent('accountChange', { detail: account })
      )
    })
  },
})
export default adAccountSlice.reducer
