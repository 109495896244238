import React from 'react'
import Modal from '../../../components/dialog/Modal'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import {
  getTraderLabel,
  cashChargeMethodItems,
} from '../../../features/settings/cash/CashCommons'
import { PropTypes } from 'prop-types'

const ModalDeferredRePaymentDetail = ({ data }) => (
  <Modal
    id="modalDeferredRePaymentDetail"
    className="paydetail_layer"
    title="결제 상세 내역"
    okText="확인"
    showCancel={false}>
    <p className="subtit_layer">{data.settleDt} 결제 상세 내역</p>
    <div className="itemtbl_wrap">
      <div className="itemtbl_head">
        <table className="tbl_itemchoice">
          <caption className="hide_caption">결제상세내역 표 제목</caption>
          <colgroup>
            <col style={{ width: 159 }} />
            <col style={{ width: 115 }} />
            <col style={{ width: 180 }} />
            <col style={{ width: 122 }} />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <div className="inner_cell">결제일시</div>
              </th>
              <th scope="col">
                <div className="inner_cell">거래유형</div>
              </th>
              <th scope="col">
                <div className="inner_cell">거래수단</div>
              </th>
              <th scope="col">
                <div className="inner_cell">결제 금액</div>
              </th>
              <th scope="col">
                <div className="inner_cell">거래자</div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="itemtbl_body">
        <table className="tbl_itemchoice">
          <caption className="hide_caption">결제상세내역 표 내용</caption>
          <colgroup>
            <col style={{ width: 159 }} />
            <col style={{ width: 115 }} />
            <col style={{ width: 180 }} />
            <col style={{ width: 122 }} />
            <col />
          </colgroup>
          <tbody>
            {data.data.map((items, index) => {
              const {
                dt,
                chargeMethod,
                chargeMethodDetail,
                repaymentAmount,
                memberEmail,
                memberStatus,
                memberType,
              } = items

              const traderName = getTraderLabel(
                memberType,
                memberStatus,
                memberEmail
              )
              const chargeMethodType = cashChargeMethodItems.find(
                v => v.status === chargeMethod
              )

              return (
                <tr key={`${dt}_${index}`}>
                  <td>
                    <div className="inner_cell">
                      {moment(dt).format('YYYY.MM.DD HH:mm')}
                    </div>
                  </td>
                  <td>
                    <div className="inner_cell">
                      {chargeMethodType?.label || ''}
                    </div>
                  </td>
                  <td>
                    <div className="inner_cell">{chargeMethodDetail}</div>
                  </td>
                  <td className="align_r">
                    <div className="inner_cell">
                      <NumberFormat
                        value={repaymentAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        defaultValue={0}
                      />
                      원
                    </div>
                  </td>
                  <td>
                    <div className="inner_cell">{traderName}</div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  </Modal>
)

export default ModalDeferredRePaymentDetail

ModalDeferredRePaymentDetail.propTypes = {
  data: PropTypes.object.isRequired,
}
