import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const OtpLogin = () => {
  const { current: dspAccount, currentAdmin: admin } = useSelector(
    state => state.dspAccount
  )
  const { current: adAccount } = useSelector(state => state.adAccount)
  return admin && dspAccount && adAccount ? (
    <Redirect to={`/${adAccount.id}/dashboard`} />
  ) : (
    <Fragment />
  )
}
export default OtpLogin
