import React, { forwardRef } from 'react'
import * as PropTypes from 'prop-types'
import { noop } from '../utils'

const NoneAnchor = forwardRef(
  ({ className = '', onClick = noop, children, ...rest }, ref) => {
    const { href, ...attr } = rest
    const handleClick = event => {
      event.preventDefault()
      onClick(event)
    }
    return (
      <a
        ref={ref}
        href="#none"
        className={className}
        onClick={handleClick}
        {...attr}>
        {children}
      </a>
    )
  }
)
NoneAnchor.displayName = 'NoneAnchor'
NoneAnchor.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}
export default NoneAnchor
