import axios from 'axios'
import { kakaoLogin } from 'features/login/kakaoAccount'
import { getPathAdAccountId } from '../../utils'

const { REACT_APP_CRUX_API_HOST } = process.env

export const SERVICE_TYPE = 'KEYWORDAD'
const cruxAxios = axios.create({
  baseURL: REACT_APP_CRUX_API_HOST,
  withCredentials: true,
})
cruxAxios.interceptors.request.use(
  config => {
    config.headers.set('serviceType', SERVICE_TYPE)
    const accessToken = sessionStorage.getItem('accessToken')
    if (accessToken) {
      config.headers.set('accessToken', accessToken)
    }
    const loginToken = sessionStorage.getItem('loginToken')
    if (loginToken) {
      config.headers.set('loginToken', loginToken)
    }
    const adAccountId = getPathAdAccountId()
    if (config.headers.get('adAccountId') === undefined && adAccountId) {
      config.headers.set('adAccountId', adAccountId)
    }
    return config
  },
  error => Promise.reject(error)
)
cruxAxios.interceptors.response.use(
  response => {
    const { headers } = response
    const accessToken = headers.get('accessToken')
    if (accessToken) {
      sessionStorage.setItem('accessToken', accessToken)
    }
    const loginToken = headers.get('loginToken')
    if (loginToken) {
      sessionStorage.setItem('loginToken', loginToken)
    }
    return response
  },
  error => {
    const { response } = error
    const { data } = response
    if (data) {
      if (data?.errorCode === 1002 && data?.component === 'CRUX_ACCOUNT') {
        sessionStorage.clear()
        kakaoLogin()
        return
      }
      if (data?.errorCode) {
        error.code = data.errorCode
      }
      if (data?.message) {
        error.message = data.message
      }
    }
    return Promise.reject(error)
  }
)

export default cruxAxios
