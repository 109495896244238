import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { hideToast } from 'features/dialog/dialogSlice'
import NoneAnchor from 'components/NoneAnchor'
import Fade from 'components/animation/Fade'
import classNames from 'classnames'

const Toast = () => {
  const dispatch = useDispatch()
  const { type, text, isOpen } = useSelector(state => state.dialog.toast)
  const [opaque, setOpaque] = useState(false)
  const content = Array.isArray(text) ? text : text.split('\n')
  useEffect(() => {
    if (isOpen) {
      setOpaque(true)
    }
  }, [isOpen])

  const hide = () => {
    dispatch(hideToast())
  }

  return (
    opaque &&
    createPortal(
      <Fade
        className="tooltip_layer"
        on={isOpen}
        onFadeOut={() => setOpaque(false)}
        style={{ position: 'fixed', right: 50, bottom: 50, zIndex: 1200 }}>
        <div
          className={classNames('inner_tooltip_layer', {
            inner_tooltip_layer1: type === 'success',
            inner_tooltip_layer2: type === 'fail',
          })}>
          <strong className="tit_tooltip">
            <span
              className={classNames('ico_comm', {
                ico_good: type === 'success',
                ico_bad: type !== 'success',
              })}>
              {type === 'success' ? '완료' : '미완료'}
            </span>
          </strong>
          <div className="desc_tooltip">
            <p className="inner_desc">
              {content &&
                content.map((line, index) => (
                  <React.Fragment key={`toast-line${index}`}>
                    {line}
                    {index < content.length - 1 && <br />}
                  </React.Fragment>
                ))}
            </p>
          </div>
          <NoneAnchor onClick={hide} className="btn_close">
            <span className="ico_comm ico_close">닫기</span>
          </NoneAnchor>
        </div>
      </Fade>,
      document.body
    )
  )
}

export default Toast
