import React from 'react'
import PropTypes from 'prop-types'

const Button = ({
  type = 'button',
  disabled = false,
  className = 'btn_gb',
  children = '확인',
  ...rest
}) => (
  <button
    type={type}
    className={className + (disabled ? ' in_active' : '')}
    {...rest}>
    <span className="inner_g">{children}</span>
  </button>
)

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button
