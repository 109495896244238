import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import Icon from '../Icon'

const ToolboxNaviLink = ({ to, children, icon }) => {
  const { pathname } = useLocation()
  return (
    <li className={pathname === to ? 'on' : ''}>
      <Link to={to} className="link_sub">
        <Icon name={icon} />
        <span className="txt_sub">{children}</span>
      </Link>
    </li>
  )
}

ToolboxNaviLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default ToolboxNaviLink
