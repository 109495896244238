import { createSlice } from '@reduxjs/toolkit'

const initialToast = {
  isOpen: false,
  type: 'success',
  text: '',
}

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    toast: initialToast,
    modals: [],
  },
  reducers: {
    showToast: (state, { payload }) => {
      state.toast = {
        isOpen: true,
        type: payload.type,
        text: payload.text,
      }
    },
    hideToast: state => {
      state.toast.isOpen = false
    },
    showModal: (state, { payload }) => {
      state.modals.push(payload)
    },
    hideModal: (state, { payload }) => {
      if (payload) {
        const index = state.modals.indexOf(payload)
        if (index !== -1) state.modals.splice(index, 1)
      } else {
        state.modals = []
      }
    },
  },
})
const { actions, reducer } = dialogSlice
export const { showToast, hideToast, showModal, hideModal } = actions
export default reducer
