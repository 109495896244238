import React from 'react'
import LoadingTable from '../../../components/table/LoadingTable'
import { useDispatch } from 'react-redux'
import { showModal } from 'features/dialog/dialogSlice'
import {
  putCancelTransferMaster,
  putRejectTransferMaster,
} from '../../../api/accountAPI'
import {
  fetchMemberHistory,
  fetchPendingMasterByMaster,
  fetchPendingMasterByMember,
} from 'features/settings/adaccount/memberSlice'
import { fail, success } from 'utils/toastUtils'
import PropTypes from 'prop-types'

const MemberPendingMaster = ({
  adAccount,
  pendingMaster,
  pendingMasterLoading,
  pendingMasterMember,
  pendingMasterMemberLoading,
}) => {
  const dispatch = useDispatch()
  const { master, id } = adAccount
  const handleModalTransferMasterByMember = () => {
    dispatch(showModal('modalTransferMasterByMember'))
  }

  const handleRejectTransferMasterByMember = async () => {
    try {
      await putRejectTransferMaster(pendingMasterMember.content[0].id)
      await dispatch(fetchPendingMasterByMember(id))
      success('마스터 권한 이전 요청을 거부하였습니다.')
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  const handleCancelTransferMasterByMember = async () => {
    try {
      await putCancelTransferMaster(pendingMaster.content[0].id)
      await dispatch(fetchPendingMasterByMaster(id))
      await dispatch(
        fetchMemberHistory({
          adAccountId: id,
          page: 0,
        })
      )
      success('마스터 권한 이전 요청을 취소하였습니다.')
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  return (
    <>
      {master &&
      pendingMaster.content.length > 0 &&
      pendingMaster.content[0] ? (
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">마스터 권한 이전 요청 받은 멤버</strong>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">권한 요청 안내 표</caption>
              <colgroup>
                <col width="339px" />
                <col width="340px" />
                <col width="300px" />
                <col width="196px" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">이름</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">카카오ID</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">상태</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">요청취소</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingMaster.content.length > 0 &&
                pendingMaster.content[0] &&
                !pendingMasterLoading ? (
                  <tr>
                    <td>
                      <div className="inner_tbl">
                        {pendingMaster.content[0].dspAccount.name}
                      </div>
                    </td>
                    <td className="td_state">
                      <div className="inner_tbl">
                        {pendingMaster.content[0].dspAccount.kakaoEmail}
                      </div>
                    </td>
                    <td>
                      <div className="inner_tbl">
                        <span className="link_txt">마스터 요청 중</span>
                      </div>
                    </td>
                    <td className="align_c">
                      <div className="inner_tbl">
                        <div className="wrap_btn">
                          <button
                            type="button"
                            className="btn_gs"
                            onClick={handleCancelTransferMasterByMember}>
                            <span className="inner_g">요청취소</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  pendingMaster.content[0] &&
                  pendingMaster.content.length > 0 &&
                  pendingMasterLoading && <LoadingTable colSpan={4} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : !master &&
        pendingMasterMember.content.length > 0 &&
        pendingMasterMember.content[0] ? (
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">내가 받은 마스터권한 요청</strong>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">권한 요청 안내 표</caption>
              <colgroup>
                <col width="339px" />
                <col width="340px" />
                <col width="300px" />
                <col width="196px" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">이름</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">카카오ID</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">상태</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">수락/거부</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingMasterMember.content[0] &&
                pendingMasterMember.content.length > 0 &&
                !pendingMasterMemberLoading ? (
                  <tr>
                    <td>
                      <div className="inner_tbl">
                        {pendingMasterMember.content[0].adAccount.masterName}
                      </div>
                    </td>
                    <td className="td_state">
                      <div className="inner_tbl">
                        {pendingMasterMember.content[0].adAccount.masterEmail}
                      </div>
                    </td>
                    <td>
                      <div className="inner_tbl">
                        <span className="link_txt">마스터 요청 중</span>
                      </div>
                    </td>
                    <td className="align_c">
                      <div className="inner_tbl">
                        <div className="wrap_btn">
                          <button
                            type="button"
                            className="btn_gs"
                            onClick={handleModalTransferMasterByMember}>
                            <span className="inner_g">수락</span>
                          </button>
                          <button
                            type="button"
                            className="btn_gs"
                            onClick={handleRejectTransferMasterByMember}>
                            <span className="inner_g">거부</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  pendingMasterMember.content[0] &&
                  pendingMasterMember.content.length > 0 &&
                  pendingMasterMemberLoading && <LoadingTable colSpan={4} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

MemberPendingMaster.propTypes = {
  adAccount: PropTypes.object.isRequired,
  pendingMaster: PropTypes.object.isRequired,
  pendingMasterLoading: PropTypes.bool.isRequired,
  pendingMasterMember: PropTypes.object.isRequired,
  pendingMasterMemberLoading: PropTypes.bool.isRequired,
}

export default MemberPendingMaster
