import Select from 'components/form/Select'
import { SELECTBOX_DURATION } from './CashConstants'
import React, { useEffect, useState } from 'react'
import Modal from 'components/dialog/Modal'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { fetchDeferredPaymentChangeLogList } from 'features/settings/cash/cashSlice'
import Pagination from 'components/pagination/Pagination'
import PropTypes from 'prop-types'

const ModalDeferredPaymentChangeLog = ({ adAccount }) => {
  const dispatch = useDispatch()
  const { isLoading, pagable } = useSelector(
    state => state.cash.deferredPaymentChangeLogList
  )
  const [deferredPaymentChangeLogPage, setDeferredPaymentChangeLogPage] =
    useState(0)
  const [searchOptions, setSearchOptions] = useState({
    selectedDuration: 1,
    accountId: adAccount.id,
    to: moment(moment()).format('YYYYMMDD'),
    from: moment(moment().subtract(1, 'month')).format('YYYYMMDD'),
  })
  const handleDeferredPaymentChangeLogPageChange = number => {
    setDeferredPaymentChangeLogPage(number)
  }
  const handleSearchOptionsDuration = value => {
    setSearchOptions({
      ...searchOptions,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYYMMDD'),
    })
  }
  const reset = () => {
    setSearchOptions({
      selectedDuration: 1,
      accountId: adAccount.id,
      to: moment(moment()).format('YYYYMMDD'),
      from: moment(moment().subtract(1, 'month')).format('YYYYMMDD'),
    })
    setDeferredPaymentChangeLogPage(0)
  }
  useEffect(() => {
    dispatch(
      fetchDeferredPaymentChangeLogList({
        adAccountId: adAccount.id,
        from: searchOptions.from,
        to: searchOptions.to,
        page: deferredPaymentChangeLogPage,
      })
    )
  }, [dispatch, adAccount.id, searchOptions, deferredPaymentChangeLogPage])

  return (
    <>
      <Modal
        id="modalDeferredPaymentChangeLog"
        className="postpay_layer"
        title="후불한도 변경이력"
        okText="확인"
        showCancel={false}
        ok={reset}
        close={reset}>
        <div>
          <div className="layer_subtit">
            <p className="subtit_layer">변경이력 상세내역</p>
            <div className="f_right">
              <Select
                options={SELECTBOX_DURATION}
                onChange={handleSearchOptionsDuration}
                selected={searchOptions.selectedDuration}
                active={pagable && pagable.totalElements > 0}
              />
            </div>
          </div>
          <div className="itemtbl_wrap">
            <div className="itemtbl_head">
              <table className="tbl_itemchoice">
                <caption className="hide_caption">
                  변경이력 상세내역 표 제목
                </caption>
                <colgroup>
                  <col style={{ width: 159 }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="inner_cell">변경일시</div>
                    </th>
                    <th scope="col">
                      <div className="inner_cell">내용</div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="itemtbl_body">
              {isLoading ? (
                <div className="thumb_loading">
                  <span className="area_loading">
                    <span className="group_loading">
                      <span className="ico_loading ani_loading"></span>
                      <span className="ico_loading ani_loading2"></span>
                      <span className="ico_loading ani_loading3"></span>
                      <span className="screen_out">로딩중</span>
                    </span>
                  </span>
                </div>
              ) : !(pagable && pagable.content.length > 0) ? (
                <div className="nodata_wrap">
                  <p className="inner_nodata">후불한도 변경 이력이 없습니다.</p>
                </div>
              ) : (
                <table className="tbl_itemchoice">
                  <caption className="hide_caption">
                    변경이력 상세내역 표 내용
                  </caption>
                  <colgroup>
                    <col style={{ width: 159 }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {pagable.content.map(data => {
                      const { id, lastModifiedDate, text } = data
                      return (
                        <tr key={`${id}`}>
                          <td>
                            <div className="inner_cell">
                              {moment(lastModifiedDate).format(
                                'YYYY.MM.DD HH:mm'
                              )}
                            </div>
                          </td>
                          <td className="td_postpay">
                            <div className="inner_cell">{text}</div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          {pagable.totalElements > 0 && (
            <Pagination
              onChange={handleDeferredPaymentChangeLogPageChange}
              totalPages={pagable.totalPages}
              number={pagable.number}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
ModalDeferredPaymentChangeLog.propTypes = {
  adAccount: PropTypes.object,
}
export default ModalDeferredPaymentChangeLog
