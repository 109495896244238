import React from 'react'
import Modal from '../../../components/dialog/Modal'
import { hideModal } from 'features/dialog/dialogSlice'
import { useDispatch } from 'react-redux'
import { putApproveTransferMaster } from 'api/accountAPI'
import { fetchAdAccountWithoutStatus } from 'features/adAccount/adAccountSlice'
import { BANK_NAME } from 'features/settings/cash/CashConstants'
import {
  fetchMemberHistory,
  fetchMemberList,
  fetchPendingMasterByMaster,
  fetchPendingMasterByMember,
  fetchPendingMemberList,
} from 'features/settings/adaccount/memberSlice'
import { fail, success } from 'utils/toastUtils'
import PropTypes from 'prop-types'

const ModalApproveTransferMasterByMember = ({
  adAccount,
  adAccountType,
  pendingMasterMember,
  refundAccountLoading,
  refundAccount,
}) => {
  const dispatch = useDispatch()
  const handleApproveTransferMasterByMember = async () => {
    try {
      await putApproveTransferMaster(pendingMasterMember.content[0].id)
      await dispatch(fetchAdAccountWithoutStatus(adAccount.id))
      await dispatch(
        fetchMemberList({
          adAccountId: adAccount.id,
          page: 0,
        })
      )
      await dispatch(
        fetchPendingMemberList({
          adAccountId: adAccount.id,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
      await dispatch(
        fetchMemberHistory({
          adAccountId: adAccount.id,
          page: 0,
        })
      )
      await dispatch(fetchPendingMasterByMaster(adAccount.id))
      await dispatch(fetchPendingMasterByMember(adAccount.id))
      success('마스터 권한 이전 요청을 수락하였습니다.')
      dispatch(hideModal())
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const masterName =
    pendingMasterMember.content.length > 0 &&
    pendingMasterMember.content[0].adAccount.masterName
  const dspName =
    pendingMasterMember.content.length > 0 &&
    pendingMasterMember.content[0].dspAccount.name
  return (
    <>
      <Modal
        id="modalTransferMasterByMember"
        keepModal={true}
        ok={async () => {
          await handleApproveTransferMasterByMember()
        }}
        className="masterauth_layer"
        innerClassName="inner_basic_layer2"
        title="마스터 권한 이전 요청"
        okText="확인">
        <strong className="subtit_layer">
          마스터 권한을 수락하시겠습니까?
        </strong>
        <ul className="list_guide">
          <li>
            ‘{masterName}’님이 ‘{dspName}’ 님에게 마스터 권한 일체의 이전을
            요청하였습니다.
          </li>
          <li>
            수락시 본 광고계정의 소유권을 비롯하여 약관에 따른 마스터의 모든
            권한과 책임 일체가 ‘{dspName}’ 님에게 이전됩니다.
          </li>
          {(adAccountType === 'BUSINESS' ||
            (adAccountType === 'AGENCY' &&
              !refundAccountLoading &&
              refundAccount)) && (
            <li>
              ‘{masterName}’님이 ‘
              {!refundAccountLoading && refundAccount ? (
                <>
                  환불계좌 (예금주명 : {refundAccount.ownerName}, 은행명 :{' '}
                  {BANK_NAME[refundAccount.bankCode]})
                  {adAccount.taxBillEmail && <>, </>}
                </>
              ) : (
                ''
              )}
              {adAccount.taxBillEmail && (
                <>
                  세금계산서 수신 이메일주소(
                  {adAccount.taxBillEmail.replace(
                    new RegExp(
                      `.(?=.{0,${
                        Math.ceil(
                          adAccount.taxBillEmail.split('@')[0].length / 2
                        ) - 1
                      }}@)`,
                      'g'
                    ),
                    '*'
                  )}
                  )
                </>
              )}
              ’ 정보를 ‘{dspName}’ 님에게 제공했습니다.
            </li>
          )}
        </ul>
      </Modal>
    </>
  )
}
ModalApproveTransferMasterByMember.propTypes = {
  adAccount: PropTypes.object.isRequired,
  adAccountType: PropTypes.string.isRequired,
  pendingMasterMember: PropTypes.object.isRequired,
  refundAccountLoading: PropTypes.bool.isRequired,
  refundAccount: PropTypes.object,
}
export default ModalApproveTransferMasterByMember
