import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import NoneAnchor from 'components/NoneAnchor'
import classNames from 'classnames'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import PropTypes from 'prop-types'

const MenuLink = ({ className, name, link, handleClose }) => (
  <a
    className={className}
    href={link.href}
    target={link?.target}
    onClick={handleClose}>
    {name}
    {link?.target === '_blank' && (
      <span className="ico_gnb ico_outlink">페이지이동</span>
    )}
  </a>
)
MenuLink.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
  }),
  handleClose: PropTypes.func,
}

const Menu = ({ id, name, link, submenu }) => {
  const [isOpen, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
  }

  const toggle = event => {
    event.preventDefault()
    setOpen(!isOpen)
  }

  const wrapperRef = useOutsideClick(close)

  return (
    <li
      key={id}
      className={classNames({
        open: isOpen,
        on: id === 'ad',
      })}
      ref={wrapperRef}>
      {link ? (
        <MenuLink className="link_gnb" link={link} name={name} />
      ) : (
        <>
          <NoneAnchor className="link_gnb" onClick={toggle}>
            {name}
            <span className="ico_gnb">더보기</span>
          </NoneAnchor>
          <div className="sub_gnb">
            <ul className="list_gnbsub">
              {submenu.map(
                ({
                  id: childrenId,
                  name: childrenName,
                  link: childrenLink,
                }) => (
                  <li key={childrenId}>
                    <MenuLink
                      className={classNames('link_sub', {
                        on: childrenId === 'keyword',
                      })}
                      link={childrenLink}
                      name={childrenName}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
        </>
      )}
    </li>
  )
}
Menu.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
  }),
  grouping: PropTypes.bool,
  submenu: PropTypes.array,
}

const ServiceMenu = () => {
  const { menu } = useSelector(state => state.gnb)
  return (
    <div id="kakaoGnb" className="gnb_comm">
      <h2 className="screen_out">비즈니스 메뉴</h2>
      <ul className="list_gnb">
        {menu.map(({ id, name, link, grouping, children }) => (
          <Menu
            key={id}
            id={id}
            name={name}
            link={link}
            grouping={grouping}
            submenu={children}
          />
        ))}
      </ul>
    </div>
  )
}
export default ServiceMenu
