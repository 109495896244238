import cruxAxios, { SERVICE_TYPE } from './config/cruxAxios'

/**
 * @file CRUX GATEWAY 빌링 API
 * @see http://billing-api-sandbox.moment.devel.kakao.com:8080/swagger-ui.html
 * 기존 계정 API 앞에 다음 /crux-billing/ path를 추가한다.
 */
const { get, post, put } = cruxAxios

export const getWallet = adAccountId =>
  get(`/crux-billing/advertiser/v3/wallet/${adAccountId}`)

export const getWithdrawAccount = adAccountId =>
  get(`/crux-billing/advertiser/v3/wallet/${adAccountId}/withdrawAccount`)

export const getCashStatList = ({ adAccountId, from, to, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/cashStat?size=7&page=${page}&from=${from}&to=${to}&adPlatformType=${SERVICE_TYPE}`
  )

export const getCashStatListCsv = ({ adAccountId, from, to }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/cashStat/exportCsv?from=${from}&to=${to}&adPlatformType=${SERVICE_TYPE}`,
    {
      params: {
        adAccountId,
        from,
        to,
      },
      responseType: 'blob',
    }
  )

export const getCashList = ({ adAccountId, from, to, type, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/cash/list?size=5&page=${page}&from=${from}&to=${to}&type=${type}&adPlatformType=${SERVICE_TYPE}`
  )

export const getCashTotalList = ({ adAccountId, from, to, type }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/cash/totalList?from=${from}&to=${to}&type=${type}&adPlatformType=${SERVICE_TYPE}`
  )

export const getFreeCashList = ({ adAccountId, from, to, status, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/freeCash?size=5&page=${page}&from=${from}&to=${to}&status=${status}&adPlatformType=${SERVICE_TYPE}`
  )

export const getWithdrawList = ({ adAccountId, from, to, statuses, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/withdraw?size=5&page=${page}&from=${from}&to=${to}&statuses=${statuses}&adPlatformType=${SERVICE_TYPE}`
  )

export const getBondList = ({ adAccountId, repaymentStatus, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/bond?size=5&page=${page}&repaymentStatus=${repaymentStatus}&adPlatformType=${SERVICE_TYPE}`
  )

export const postWithdrawAccount = (adAccountId, params) =>
  post(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/withdrawAccount`,
    params
  )

export const putWithdrawAccount = (adAccountId, params) =>
  put(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/withdrawAccount`,
    params
  )

export const getTaxList = ({ adAccountId, from, to, issueStatuses, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/taxbill?size=5&page=${page}&issueStartDt=${from}&issueEndDt=${to}&issueStatuses=${issueStatuses}&adPlatformType=${SERVICE_TYPE}`
  )

export const postPaymentOrder = (adAccountId, params) =>
  post(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/charge/paymentOrder`,
    params
  )

export const postWithdraw = (adAccountId, params) =>
  post(`/crux-billing/advertiser/v3/wallet/${adAccountId}/withdraw`, params)

export const postUseFreeCash = (adAccountId, freeCashId, dspAccountId) =>
  post(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/charge/freeCash/${freeCashId}/use?adPlatformType=${SERVICE_TYPE}&memberId=${dspAccountId}&memberType=DSP_ACCOUNT`
  )

export const getRePaymentDetail = (adAccountId, settleDt) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/repayment?settleDt=${settleDt}`
  )

export const getBondRepay = adAccountId =>
  get(`/crux-billing/advertiser/v3/wallet/${adAccountId}/bond/repay`)

export const postBondRepay = (adAccountId, params) =>
  post(`/crux-billing/advertiser/v3/wallet/${adAccountId}/bond/repay`, params)

export const getPlan = adAccountId =>
  get(`/crux-billing/advertiser/v3/wallet/${adAccountId}/plan`)

export const deletePlan = (adAccountId, txId) =>
  cruxAxios.delete(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/plan?txId=${txId}`
  )

export const deleteWithdrawAccount = (adAccountId, params) =>
  cruxAxios.delete(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/withdrawAccount`,
    params
  )

export const getPaymentInfo = kakaoBillingTxId =>
  get(`/crux-billing/advertiser/v3/charge/${kakaoBillingTxId}/paymentInfo`)

export const getTaxbillInfo = (adAccountId, taxbillErpId) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/taxbill/${taxbillErpId}`
  )

export const getMyAutoPayCardPayHistory = ({
  adAccountId,
  from,
  to,
  status,
  page,
}) =>
  get('/crux-billing/advertiser/v3/my/autopay/card/autopayCardPayHistory', {
    params: {
      adPlatformType: SERVICE_TYPE,
      size: 10,
      walletId: adAccountId,
      page,
      from,
      to,
      status,
    },
  })

export const getMyAutopayCard = () =>
  get(
    `/crux-billing/advertiser/v3/my/autopay/card?adPlatformType=${SERVICE_TYPE}`
  )

export const postMyAutopayCard = ({ isPopUpRedirectUrl, redirectUrl }) =>
  post(
    `/crux-billing/advertiser/v3/my/autopay/card?adPlatformType=${SERVICE_TYPE}&isPopUpRedirectUrl=${isPopUpRedirectUrl}&redirectUrl=${redirectUrl}`
  )

export const getAutopayCard = ({ adAccountId }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card?adPlatformType=${SERVICE_TYPE}`
  )

export const postAutopayCard = (adAccountId, param) =>
  post(`/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card`, param)

export const deleteAutopayCard = (adAccountId, param) =>
  cruxAxios.delete(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card`,
    param
  )

export const getKakaoPayCards = ({ adAccountId }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card/getKakaoPayCards?adPlatformType=${SERVICE_TYPE}`
  )

export const putPriorityOrderToZero = (adAccountId, param) =>
  put(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card/setPriorityOrderToZero`,
    param
  )

export const getPayHistory = ({ adAccountId, from, to, status, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card/payHistory?size=10&page=${page}&from=${from}&to=${to}&status=${status}&adPlatformType=${SERVICE_TYPE}&walletId=${adAccountId}`
  )

export const getChangeHistory = ({ adAccountId, page }) =>
  get(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card/changeHistory?size=10&page=${page}&adPlatformType=${SERVICE_TYPE}&walletId=${adAccountId}`
  )

export const putAutopayDailySpendingBudget = (adAccountId, amount) =>
  put(
    `/crux-billing/advertiser/v3/wallet/${adAccountId}/autopay/card/setAutopayDailySpendingBudget?amount=${amount}&adPlatformType=${SERVICE_TYPE}`
  )

export const deleteMyAutopayCard = param =>
  cruxAxios.delete(
    `/crux-billing/advertiser/v3/my/autopay/card?adPlatformType=${SERVICE_TYPE}`,
    param
  )
