import { createSlice } from '@reduxjs/toolkit'

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    classNames: [],
    hideGnb: false,
    disabledLogoLink: false,
  },
  reducers: {
    addLayoutClass: (state, { payload }) => {
      const classNameSet = new Set(state.classNames)
      const className = payload
      if (Array.isArray(className)) {
        for (const item of className) {
          classNameSet.add(item)
        }
      } else {
        classNameSet.add(className)
      }
      state.classNames = [...classNameSet]
    },
    removeLayoutClass: (state, { payload }) => {
      const classNameSet = new Set(state.classNames)
      const className = payload
      if (Array.isArray(className)) {
        for (const item of className) {
          classNameSet.delete(item)
        }
      } else {
        classNameSet.delete(className)
      }
      state.classNames = [...classNameSet]
    },
    showGnb: state => {
      state.hideGnb = false
    },
    hideGnb: state => {
      state.hideGnb = true
    },
    disableLogoLink: state => {
      state.disabledLogoLink = true
    },
    enableLogoLink: state => {
      state.disabledLogoLink = false
    },
  },
})

const { actions, reducer } = layoutSlice

export const {
  addLayoutClass,
  removeLayoutClass,
  showGnb,
  hideGnb,
  disableLogoLink,
  enableLogoLink,
} = actions

export default reducer
