import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import useAdAccountRouteMatch from 'features/layout/useAdAccountRouteMatch'
import useGlobalRouteMatch from 'features/layout/useGlobalRouteMatch'
import useLogoLinkDisabled from 'features/layout/useLogoLinkDisabled'
import useGnbHide from 'features/layout/useGnbHide'
import NoneAnchor from 'components/NoneAnchor'

const NotFound = () => {
  const accountRouteMatch = useAdAccountRouteMatch()
  const globalRouteMatch = useGlobalRouteMatch()
  useLogoLinkDisabled(accountRouteMatch === null && globalRouteMatch === false)
  useGnbHide(accountRouteMatch === null && globalRouteMatch === false)
  const history = useHistory()
  return accountRouteMatch || globalRouteMatch ? (
    <></>
  ) : (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        알림 본문
      </h2>
      <div className="error_wrap">
        <strong className="tit_error">
          요청하신 페이지를 찾을 수 없습니다.
        </strong>
        <p className="txt_error">
          페이지 주소가 잘못 입력되었거나, 변경되어 페이지를 찾을 수 없습니다.
          <br />
          주소를 다시 확인해주시기 바랍니다.
        </p>
        <div className="page_btn">
          <div className="inner_btn">
            <NoneAnchor
              className="btn_gb"
              onClick={() => {
                history.goBack()
              }}>
              <span className="inner_g">이전</span>
            </NoneAnchor>
            <Link to="/adaccount/all" className="btn_gb gb_bl">
              <span className="inner_g">Kakao 키워드 광고홈으로 이동</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
