export const noop = () => {}
export const isNullOrUndefined = value => value === null || value === undefined
export const isEmptyObject = value =>
  typeof value === 'object' && Object.keys(value).length === 0
export const isEmpty = val => {
  if (val instanceof Map || val instanceof Set) {
    return val.size === 0
  }
  if (!isNullOrUndefined(val) && !isNullOrUndefined(val.length)) {
    return val.length === 0
  }
  if (typeof val === 'object') {
    return isEmptyObject(val)
  }
  throw new TypeError('value must be object, array-like, map or set.')
}
// css transition 동작을 위해, DOM 속성을 읽어서 브라우저 렌더링 쓰레드와 JS 엔진을 강제로 동기화
export const forceStyleSync = el => noop(el.offsetLeft)
export const mapValues = mapper => target =>
  Object.entries(target).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: mapper(value, key, target),
    }),
    {}
  )
export const fillProps = from => mapValues((value, key) => from[key])
export const filterEntries = filter => target =>
  Object.entries(target)
    .filter(([key, value], index, entries) =>
      filter(key, value, index, entries)
    )
    .reduce(
      (result, [key, value]) => ({
        ...result,
        [key]: value,
      }),
      {}
    )
export const getPathAdAccountId = () => {
  const splits = window.location.pathname.split('/')
  return splits.length > 1 && !isNaN(Number(splits[1]))
    ? Number(splits[1])
    : undefined
}
