import PropTypes from 'prop-types'
import React from 'react'
import { noop } from 'utils'

const Icon = ({ className = 'ico_comm', name, onClick = noop, children }) => (
  <span className={`${className} ico_${name}`} onClick={onClick}>
    {children}
  </span>
)
Icon.displayName = 'Icon'

Icon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default Icon
