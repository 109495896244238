import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNoticeBoardList } from 'features/board/boardSlice'
import moment from 'moment'
import LoadingTable from 'components/table/LoadingTable'
import NoDataTable from 'components/table/NoDataTable'
import cx from 'classnames'
import { NOTICE_TYPE_TEXT } from 'features/board/BoardConstants'
import Pagination from 'components/pagination/Pagination'
import Select from 'components/form/Select'
import NoneAnchor from 'components/NoneAnchor'

const NoticeBoardList = () => {
  const h = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { isLoading: listLoading, pagable: list } = useSelector(
    state => state.board.list
  )
  const [page, setPage] = useState(parseInt(params.get('page'), 10) || 0)
  const handlePageChange = number => {
    setPage(number)
  }
  const [noticeTypes, setNoticeTypes] = useState(
    params.get('noticeTypes') || ''
  )
  const handleNoticeTypesChange = noticeType => {
    setNoticeTypes(noticeType)
    setPage(0)
    setSearchKeywordDisp('')
    setSearchKeyword('')
  }
  const [searchType, setSearchType] = useState('TITLE')
  const handleSearchTypeChange = value => {
    setSearchType(value)
  }
  const [searchKeywordDisp, setSearchKeywordDisp] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')
  const noticeSearchTextWrapper = React.createRef()
  const handleSearchKeywordChange = event => {
    const { target, keyCode } = event
    const { value } = target
    setSearchKeywordDisp(value)
    if (keyCode === 13) {
      setSearchKeyword(value)
      setPage(0)
    }
  }
  const handleSearchKeywordButton = () => {
    if (searchKeywordDisp.length > 0) {
      noticeSearchTextWrapper.current.className = 'box_inptxt search_inp2 on'
    }
    setSearchKeyword(searchKeywordDisp)
    setPage(0)
  }
  const SELECTBOX_SEARCHTYPE = [
    { text: '제목', value: 'TITLE' },
    { text: '제목+내용', value: 'TITLE_BODY' },
  ]
  useEffect(
    () => {
      ;(async () => {
        await dispatch(
          fetchNoticeBoardList({ noticeTypes, searchType, searchKeyword, page })
        )
        h.push(
          `?noticeTypes=${noticeTypes}&searchType=${searchType}&searchKeyword=${searchKeyword}&page=${page}`
        )
      })()
    }, // eslint-disable-next-line
    [h, dispatch, noticeTypes, searchKeyword, page]
  )

  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        공지사항 본문
      </h2>
      <div className="notice_wrap">
        {' '}
        {/* 2021-01-21 클래스명 정정 */}
        <div className="dsp_tit">
          <div className="tit_dsp">
            <h3 className="tit_subject">공지사항</h3>
          </div>
        </div>
        <strong className="screen_out">공지사항 메뉴 탭</strong>
        <ul className="tab_g">
          <li className={noticeTypes === '' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('')}>
              전체
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'NOTICE_EVENT' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('NOTICE_EVENT')}>
              공지/이벤트
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'POLICY_REVIEW' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('POLICY_REVIEW')}>
              정책/심사
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'SYSTEM' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('SYSTEM')}>
              시스템
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'PRODUCT' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('PRODUCT')}>
              상품
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'PAYMENT_BILL' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('PAYMENT_BILL')}>
              결제/계산서
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'OPERATION' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('OPERATION')}>
              운영
            </NoneAnchor>
          </li>
          <li className={noticeTypes === 'ETC' ? 'on' : ''}>
            <NoneAnchor
              className="link_tab"
              onClick={() => handleNoticeTypesChange('ETC')}>
              기타
            </NoneAnchor>
          </li>
        </ul>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <div className="f_left">
              <div className="search_box">
                <Select
                  options={SELECTBOX_SEARCHTYPE}
                  onChange={handleSearchTypeChange}
                  selected={searchType}
                />
                <span
                  className="box_inptxt search_inp2"
                  ref={noticeSearchTextWrapper}>
                  <span className="inner_inp">
                    <input
                      type="text"
                      id="inpTxt1"
                      className="inp_txt"
                      onFocus={() => {
                        noticeSearchTextWrapper.current.className =
                          'box_inptxt search_inp2 on'
                      }}
                      onBlur={() => {
                        noticeSearchTextWrapper.current.className =
                          'box_inptxt search_inp2'
                      }}
                      value={searchKeywordDisp}
                      onChange={handleSearchKeywordChange}
                      onKeyUp={handleSearchKeywordChange}
                      placeholder="검색어를 입력하세요"
                    />
                  </span>
                  <button
                    className="btn_search"
                    onClick={handleSearchKeywordButton}>
                    <span className="ico_comm ico_search">검색</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">공지사항 표</caption>
              <colgroup>
                <col width="90px" />
                <col width="97px" />
                <col />
                <col width="100px" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">번호</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">구분</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">제목</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">작성일</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listLoading ? (
                  <LoadingTable colSpan={4} />
                ) : !(list && list.content.length > 0) ? (
                  <NoDataTable colSpan={4}>
                    {searchKeyword === '' ? '공지사항이' : '검색결과가'}{' '}
                    없습니다.
                  </NoDataTable>
                ) : (
                  list.content.map((item, index) => (
                    <tr
                      key={`noticeBoardList|${index}`}
                      className={cx({ tr_depthitem: item.importance })}>
                      <td>
                        <div className="inner_tbl">
                          {item.importance ? '중요공지' : item.id}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {NOTICE_TYPE_TEXT[item.noticeType]}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {moment().diff(item.createdDate, 'days') < 6 ? (
                            <span className="new_adname">
                              <Link
                                to={`/noticeboard/${item.id}`}
                                className="link_txt">
                                {item.title}
                              </Link>
                              <span className="ico_new ico_comm">NEW</span>
                            </span>
                          ) : (
                            <Link
                              to={`/noticeboard/${item.id}`}
                              className="link_txt">
                              {item.title}
                            </Link>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {moment(item.createdDate).format('YYYY-MM-DD')}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {list.totalElements > 0 && (
            <Pagination
              onChange={handlePageChange}
              totalPages={list.totalPages}
              number={list.number}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default NoticeBoardList
