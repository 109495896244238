import React from 'react'
import { kakaoLogout } from 'features/login/kakaoAccount'
import useLogoLinkDisabled from 'features/layout/useLogoLinkDisabled'
import useGnbHide from 'features/layout/useGnbHide'
import NoneAnchor from 'components/NoneAnchor'

const Withdrawal = () => {
  useLogoLinkDisabled()
  useGnbHide()
  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        알림 본문
      </h2>
      <div className="error_wrap">
        <strong className="tit_error">
          카카오광고 회원 탈퇴가 완료되었습니다.
        </strong>
        <div className="page_btn">
          <div className="inner_btn">
            <NoneAnchor
              onClick={() => {
                kakaoLogout()
              }}
              className="btn_gb gb_bl">
              <span className="inner_g">확인</span>
            </NoneAnchor>
          </div>
        </div>
      </div>
    </>
  )
}

export default Withdrawal
