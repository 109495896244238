import cruxAxios, { SERVICE_TYPE } from './config/cruxAxios'

/**
 * @file CRUX GATEWAY API
 * @see https://crux-gateway-sandbox.devel.kakao.com/swagger-ui.html
 */
const { get, post } = cruxAxios

export const login = isAgreement =>
  post('/gateway/dsp/login', undefined, {
    params: {
      serviceType: SERVICE_TYPE,
      ...(!isAgreement && { isAgreement: 'false' }),
    },
  })
export const getIntegrationInfo = () =>
  get(`/crux-user/bizAccounts/integrationInfo`)
export const optLogin = ({ otp, accountId, adAccountId, serviceType }) =>
  get('/moment-gateway/login/otp', {
    params: {
      otp,
      accountId,
      serviceType,
      adAccountId,
    },
  })
