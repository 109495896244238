import React, { useMemo } from 'react'
import { LOGIN_STATUS } from '../login/LoginStatus'
import ToolboxNaviLink from '../../components/header/ToolboxNaviLink'
import Icon from '../../components/Icon'
import NoneAnchor from '../../components/NoneAnchor'
import { kakaoLogout } from '../login/kakaoAccount'
import ToolboxLayer from '../../components/header/ToolboxLayer'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const PROFILE_BACKGROUND_COLORS = [
  '#DF5C54',
  '#EA8569',
  '#97BC4B',
  '#51A598',
  '#51A3B1',
  '#5D9CD1',
  '#4E679D',
  '#7061A6',
  '#8A5DB2',
  '#C25D97',
]
const MyMenu = () => {
  const {
    loginStatus,
    current: { kakaoEmail, name, kakaoAccountId },
  } = useSelector(state => state.dspAccount)
  const background = useMemo(
    () => PROFILE_BACKGROUND_COLORS[kakaoAccountId % 10],
    [kakaoAccountId]
  )
  const initialCharacterOfName = useMemo(() => name.charAt(0), [name])
  const isAlphaNumeric = useMemo(
    () => /^[a-z0-9]+$/i.test(initialCharacterOfName),
    [initialCharacterOfName]
  )
  return (
    <ToolboxLayer
      className="menu_my"
      title={
        <>
          <span className="thumb_account" style={{ background }}>
            <span
              className={classNames('txt_account', {
                account_type2: isAlphaNumeric,
              })}>
              {initialCharacterOfName}
            </span>
          </span>
          <span className="desc_account">{kakaoEmail}</span>
          <span className="ico_gnb ico_fold" />
        </>
      }>
      <div className="wrap_account">
        <span className="thumb_account" style={{ background }}>
          <span
            className={classNames('txt_account', {
              account_type2: isAlphaNumeric,
            })}>
            {initialCharacterOfName}
          </span>
        </span>
        <strong className="tit_account">{name}</strong>
        <span className="desc_account">{kakaoEmail}</span>
      </div>
      <ul className="list_service">
        {loginStatus === LOGIN_STATUS.SUCCESS && (
          <>
            <ToolboxNaviLink to="/myinfo" icon="myinfo">
              내 정보
            </ToolboxNaviLink>
            <ToolboxNaviLink to="/adaccount/all" icon="myaccount">
              내 광고계정
            </ToolboxNaviLink>
          </>
        )}
        <li>
          <a
            href="https://kakaobusiness.gitbook.io/main/ad/searchad/keywordad"
            target="_blank"
            rel="noopener noreferrer"
            className="link_sub">
            <Icon name="guide" />
            <span className="txt_sub">광고 이용가이드</span>
          </a>
        </li>
      </ul>
      <ul className="list_service">
        <li>
          <NoneAnchor className="link_sub" onClick={kakaoLogout}>
            <Icon name="logout" />
            <span className="txt_sub">로그아웃</span>
          </NoneAnchor>
        </li>
      </ul>
    </ToolboxLayer>
  )
}
export default MyMenu
