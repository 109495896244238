import { createContext } from 'react'
import { noop } from 'utils'

export const CalendarContext = createContext({
  min: null,
  max: null,
  close: noop,
  onChange: noop,
  reset: noop,
})
export const DatePickerContext = createContext({
  value: null,
  setValue: noop,
  month: null,
  setMonth: noop,
})
export const DateRangePickerContext = createContext({
  start: null,
  setStart: noop,
  end: null,
  setEnd: noop,
  leftMonth: null,
  setLeftMonth: noop,
  rightMonth: null,
  setRightMonth: noop,
  preset: null,
  setPreset: noop,
  presetExcludes: [],
  movedRef: false,
  durationRef: null,
  presetTooltip: null,
})
