import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'custom-event-polyfill'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './app/store'
import App from 'App'
import { BrowserRouter } from 'react-router-dom'
import { renderIE } from 'indexIE'
import { ErrorBoundary } from '@sentry/react'
import { sentryInit } from './utils/sentryUtils'

if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
  renderIE()
} else {
  if (navigator.userAgent.toLowerCase().includes('mac')) {
    document.documentElement.classList.add('os_mac')
  }
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  )
}

const { REACT_APP_MATRIX_DSN } = process.env
if (REACT_APP_MATRIX_DSN) {
  sentryInit(REACT_APP_MATRIX_DSN)
}
