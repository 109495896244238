import classNames from 'classnames'
import moment from 'moment'
import ToolboxLayer from 'components/header/ToolboxLayer'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Notice = () => {
  const { gnbList, gnbNew } = useSelector(state => state.board)
  return (
    <ToolboxLayer
      title={
        <>공지사항{gnbNew && <span className="ico_gnb ico_new">new</span>}</>
      }
      isNotice={true}>
      {gnbList && gnbList.content.length === 0 && (
        <p className="txt_nodata">등록된 공지사항이 없습니다.</p>
      )}
      {gnbList && gnbList.content.length > 0 && (
        <>
          <div className="fake_scroll">
            <ul className="list_noti">
              {gnbList &&
                gnbList.content.map(item => (
                  <li
                    key={`notice_gnb_${item.id}`}
                    className={classNames({
                      noti_on: item.importance,
                      new_on: item.news,
                    })}>
                    <Link to={`/noticeboard/${item.id}`} className="link_noti">
                      <span className="desc_noti">
                        {item.importance && (
                          <span className="txt_noti">[중요공지]</span>
                        )}
                        {item.title}
                        {moment().diff(item.createdDate, 'days') < 6 && (
                          <span className="ico_gnb">NEW</span>
                        )}
                      </span>
                      <span className="txt_date">
                        {moment(item.createdDate).format('YYYY-MM-DD')}
                      </span>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
          <Link to={`/noticeboard`} className="link_allview">
            전체보기
          </Link>
        </>
      )}
    </ToolboxLayer>
  )
}
export default Notice
