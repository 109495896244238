import { useLocation } from 'react-router-dom'

const useGlobalRouteMatch = () => {
  const { pathname } = useLocation()
  return (
    pathname.startsWith('/error/') ||
    pathname.startsWith('/withdrawal') ||
    pathname.startsWith('/otp')
  )
}

export default useGlobalRouteMatch
