import React from 'react'
import Modal from '../../../components/dialog/Modal'
import {
  coerceAtLeast,
  convertToCommaSeparatedString,
  getTraderLabel,
} from 'features/settings/cash/CashCommons'
import moment from 'moment'
import PropTypes from 'prop-types'

const ModalWithdrawDetail = ({ requestAmount, amount, details }) => {
  const _details = details && details[0]
  const requestAmountLabel =
    requestAmount > 0
      ? `${convertToCommaSeparatedString(requestAmount)}원`
      : '-'
  const amountLabel =
    amount > 0 ? `${convertToCommaSeparatedString(amount)}원` : 0
  const amountDetailLabel =
    _details && _details.status === 'SUCCESS'
      ? `${convertToCommaSeparatedString(
          coerceAtLeast(_details && _details.amount, 0)
        )}원`
      : _details && `${_details.statusDesc}(${_details.failReason})`
  const trader = getTraderLabel(
    _details && _details.chargeMemberType,
    _details && _details.chargeMemberStatus,
    _details && _details.chargeKakaoEmail
  )

  return (
    <Modal
      id="modalWithdrawDetail"
      className="refund_layer"
      innerClassName="inner_basic_layer2"
      title="환불 내역"
      okText="확인"
      showCancel={false}>
      <table className="tbl_layer">
        <caption className="hide_caption">환불 요청 금액, 환불 금액 표</caption>
        <colgroup>
          <col style={{ width: 161 }} />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">환불 요청 금액</th>
            <td className="td_refundcash">
              <div className="inner_cell">{requestAmountLabel}</div>
            </td>
          </tr>
          <tr>
            <th scope="row">환불 금액</th>
            <td className="td_refundcash">
              <div className="inner_cell">{amountLabel}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="subtit_layer">환불 상세 내역</p>
      <table className="tbl_layer">
        <caption className="hide_caption">환불 상세 내역 표</caption>
        <colgroup>
          <col style={{ width: 161 }} />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">충전일시</th>
            <td>
              <div className="inner_cell">
                {moment(_details && _details.dt).format('YYYY.MM.DD HH:mm')}
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">충전 내역</th>
            <td>
              <div className="inner_cell">
                {_details && _details.chargeMethod}
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">환불 방법</th>
            <td>
              <div className="inner_cell">
                {_details && _details.withdrawMethod}
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">환불 금액</th>
            <td className="td_warn">
              <div className="inner_cell">{amountDetailLabel}</div>
            </td>
          </tr>
          <tr>
            <th scope="row">거래자</th>
            <td>
              <div className="inner_cell">{trader}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  )
}

ModalWithdrawDetail.propTypes = {
  requestAmount: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  details: PropTypes.array.isRequired,
}

export default ModalWithdrawDetail
