import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMenu } from 'api/businessAPI'

export const fetchGnbMenu = createAsyncThunk('gnb/fetchGnbMenu', async () => {
  const { data } = await getMenu()
  return data
})
const gnbSlice = createSlice({
  name: 'gnb',

  initialState: {
    isLoading: false,
    home: undefined,
    menu: [],
  },

  extraReducers: builder => {
    builder.addCase(fetchGnbMenu.pending, state => {
      state.isLoading = true
    })

    builder.addCase(
      fetchGnbMenu.fulfilled,
      (state, { payload: { home, serviceMenu } }) => {
        state.home = home
        state.menu = serviceMenu
        state.isLoading = false
      }
    )

    builder.addCase(fetchGnbMenu.rejected, state => {
      state.isLoading = false
    })
  },
})
const { reducer } = gnbSlice
export default reducer
