import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import useLogoLinkDisabled from 'features/layout/useLogoLinkDisabled'
import useGnbHide from 'features/layout/useGnbHide'
import NoneAnchor from 'components/NoneAnchor'

const Unauthorized = () => {
  useLogoLinkDisabled()
  useGnbHide()
  const history = useHistory()
  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        알림 본문
      </h2>
      <div className="error_wrap">
        <strong className="tit_error">비정상적인 접근입니다.</strong>
        <p className="txt_error">
          올바르지 않은 경로의 접근입니다.
          <br />
          확인 후 다시 시도 해 주세요.
        </p>
        <div className="page_btn">
          <div className="inner_btn">
            <NoneAnchor
              className="btn_gb"
              onClick={() => {
                history.goBack()
              }}>
              <span className="inner_g">이전</span>
            </NoneAnchor>
            <Link to="/adaccount/all" className="btn_gb gb_bl">
              <span className="inner_g">Kakao 키워드 광고홈으로 이동</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Unauthorized
