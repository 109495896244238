import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../utils/hooks/useOutsideClick'
import classNames from 'classnames'
import NoneAnchor from 'components/NoneAnchor'

const ToolboxLayer = ({ children, className, title, isNotice = false }) => {
  const [isOpen, setOpen] = useState(false)

  const close = () => setOpen(false)

  const toggle = () => setOpen(!isOpen)

  const wrapperRef = useOutsideClick(close)

  return (
    <li className={classNames(className, { on: isOpen })} ref={wrapperRef}>
      <NoneAnchor className="link_gnb" onClick={toggle}>
        {title}
      </NoneAnchor>
      {isNotice ? (
        <div className="layer_service">
          <div className="layer_head">
            <strong className="tit_service">공지사항</strong>
          </div>
          <div className="layer_body" onClick={close}>
            {children}
          </div>
        </div>
      ) : (
        <div className="layer_mymenu" onClick={close}>
          {children}
        </div>
      )}
    </li>
  )
}

ToolboxLayer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.node,
  isNotice: PropTypes.bool,
}

export default ToolboxLayer
