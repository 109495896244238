import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  downloadCashStatListCsv,
  fetchBondList,
  fetchCashList,
  fetchCashStatList,
  fetchFreeCashList,
  fetchWallet,
  fetchWithdrawAccount,
  fetchWithdrawList,
} from 'features/settings/cash/cashSlice'
import NumberFormat from 'react-number-format'
import Tooltip from 'components/tooltip/Tooltip'
import Select from 'components/form/Select'
import {
  BANK_NAME,
  BOND_CRITERIA,
  BOND_STATUS,
  CASH_TYPE,
  FREE_CASH_STATUS,
  SELECTBOX_BOND_STATUS,
  SELECTBOX_CASH_TYPE,
  SELECTBOX_DURATION,
  SELECTBOX_FREECASH_STATUS,
  SELECTBOX_WITHDRAW_STATUS,
  WITHDRAW_STATUS,
} from 'features/settings/cash/CashConstants'
import moment from 'moment'
import LoadingTable from 'components/table/LoadingTable'
import NoDataTable from 'components/table/NoDataTable'
import Pagination from 'components/pagination/Pagination'
import { useHistory, useLocation } from 'react-router-dom'
import cx from 'classnames'
import {
  checkNotEmpty,
  getChargeHistoryInfoLabel,
  getFreeCashHistoryInfoValue,
  getOwnerLabel,
  getWithdrawHistoryInfoValue,
  handleDownloadChargeHistory,
} from './CashCommons'
import Modal from 'components/dialog/Modal'
import { showModal } from 'features/dialog/dialogSlice'
import ModalRegisterWithdrawAccount from './ModalRegisterWithdrawAccount'
import ModalModifyWithdrawAccount from './ModalModifyWithdrawAccount'
import ModalDeferredPaymentChangeLog from './ModalDeferredPaymentChangeLog'
import ModalDeferredRePaymentDetail from './ModalDeferredRePaymentDetail'
import ModalRequestRePayment from './ModalRequestRePayment'
import ModalCharge from './ModalCharge'
import ModalWithdraw from './ModalWithdraw'
import Confirm from 'components/dialog/Confirm'
import Alert from 'components/dialog/Alert'
import {
  getBondRepay,
  getCashTotalList,
  getPlan,
  getRePaymentDetail,
  postUseFreeCash,
} from 'api/billingAPI'
import ModalVirtualAccountHistory from './ModalVirtualAccountHistory'
import ModalWithdrawDetail from './ModalWithdrawDetail'
import NoneAnchor from 'components/NoneAnchor'
import { fail } from 'utils/toastUtils'

const CashIndex = () => {
  const h = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const dspAccount = useSelector(state => state.dspAccount.current)
  const adAccount = useSelector(state => state.adAccount.current)
  const { id, name, master } = adAccount
  const { info: wallet } = useSelector(state => state.cash.wallet)
  const today = moment(moment()).format('YYYY-MM-DD')
  const [fromClicked, setFromClicked] = useState(null)
  const {
    refundAccount: { isLoading: refundAccountLoading, info: refundAccount },
    cashStatList: { isLoading: cashStatListLoading, pagable: cashStatList },
    cashList: { isLoading: cashListLoading, pagable: cashList },
    freeCashList: { isLoading: freeCashListLoading, pagable: freeCashList },
    withdrawList: { isLoading: withdrawListLoading, pagable: withdrawList },
    bondList: { isLoading: bondListLoading, pagable: bondList },
  } = useSelector(state => state.cash)

  const [cashStatListPage, setCashStatListPage] = useState(
    parseInt(params.get('cashStatListPage'), 10) || 0
  )
  const [searchOptionsCashStat, setSearchOptionsCashStat] = useState({
    selectedDuration: 1,
    accountId: id,
    to: today,
    from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
  })
  const handleCashStatListPageChange = number => {
    h.push(`?cashStatListPage=${number}`)
    setCashStatListPage(number)
  }

  const [cashListPage, setCashListPage] = useState(
    parseInt(params.get('cashListPage'), 10) || 0
  )
  const [searchOptionsCash, setSearchOptionsCash] = useState({
    selectedDuration: 1,
    type: '',
    accountId: id,
    to: today,
    from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
  })
  const handleCashListPageChange = number => {
    h.push(`?cashListPage=${number}`)
    setCashListPage(number)
  }

  const [freeCashListPage, setFreeCashListPage] = useState(
    parseInt(params.get('freeCashListPage'), 10) || 0
  )
  const [searchOptionsFreeCash, setSearchOptionsFreeCash] = useState({
    selectedDuration: 1,
    status: '',
    accountId: id,
    to: today,
    from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
  })
  const handleFreeCashListPageChange = number => {
    h.push(`?freeCashListPage=${number}`)
    setFreeCashListPage(number)
  }

  const [withdrawListPage, setWithdrawListPage] = useState(
    parseInt(params.get('withdrawListPage'), 10) || 0
  )
  const [searchOptionsWithdraw, setSearchOptionsWithdraw] = useState({
    selectedDuration: 1,
    statuses: '',
    accountId: id,
    to: today,
    from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
  })
  const handleWithdrawListPageChange = number => {
    h.push(`?withdrawListPage=${number}`)
    setWithdrawListPage(number)
  }

  const [bondListPage, setBondListPage] = useState(
    parseInt(params.get('bondListPage'), 10) || 0
  )
  const [searchOptionsBond, setSearchOptionsBond] = useState({
    repaymentStatus: '',
    accountId: id,
  })
  const handleBondListPageChange = number => {
    h.push(`?bondListPage=${number}`)
    setBondListPage(number)
  }

  const [useFreeCashId, setUseFreeCashId] = useState(null)
  const showUseFreeCashModal = freeCashId => {
    setUseFreeCashId(freeCashId)
    dispatch(showModal('confirmUseFreeCash'))
  }

  const handleConfirmUseFreeCash = async () => {
    try {
      await postUseFreeCash(adAccount.id, useFreeCashId, dspAccount.id)
      dispatch(fetchWallet(id))
      dispatch(
        fetchFreeCashList({
          selectedDuration: 1,
          status: '',
          adAccountId: id,
          from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
          to: today,
          page: 0,
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  const renderCashUseDayRow = v => {
    const { usedDt, status, id: freeCashId } = v
    const usedDtLabel = usedDt ? moment(usedDt).format('YYYY.MM.DD') : '-'

    if (status === 'READY') {
      return (
        <td className="align_c">
          {/* 버튼이 있는 td에만 align_c 클래스 부여 */}
          <div className="inner_tbl">
            <div className="wrap_btn">
              <button
                className="btn_gs"
                onClick={() => {
                  showUseFreeCashModal(freeCashId)
                }}>
                <span className="inner_g">사용하기</span>
              </button>
            </div>
          </div>
        </td>
      )
    } else {
      return (
        <td>
          <div className="inner_tbl">{usedDtLabel}</div>
        </td>
      )
    }
  }
  const handleDownloadCashStatList = async () => {
    const { error, payload } = await dispatch(
      downloadCashStatListCsv({
        adAccountId: id,
        from: searchOptionsCashStat.from,
        to: searchOptionsCashStat.to,
      })
    )
    if (error === undefined) {
      const filename = `일별캐시현황${searchOptionsCash.from}~${searchOptionsCash.to}.csv`
      if (window?.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(payload, filename)
      } else {
        const url = URL.createObjectURL(payload)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        link.click()
      }
    }
  }
  const handleDownloadCashList = async () => {
    try {
      const { data } = await getCashTotalList({
        adAccountId: id,
        from: searchOptionsCash.from,
        to: searchOptionsCash.to,
        type: searchOptionsCash.type,
      })
      handleDownloadChargeHistory(
        data,
        `충전내역_${searchOptionsCash.from}_${searchOptionsCash.to}.csv`
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const [bondRepayLoaded, setBondRepayLoaded] = useState(false)
  const [bondRepay, setBondRepay] = useState(null)
  const handleDeferredRePayment = async () => {
    try {
      const { data } = await getBondRepay(id)
      const unAvailableRepayment =
        !data.bondRepays ||
        data.bondRepays.every(v => v.remainedBondAmount === 0)
      if (unAvailableRepayment) {
        dispatch(showModal('alertUnAvailableRePayment'))
      } else {
        const billion = 1000000000
        // 최대 결제 가능 금액
        const maxPayableAmount =
          data.bondRepays[0].remainedBondAmount -
            data.bondRepays[0].planRepayAmount <=
          billion
            ? data.bondRepays[0].remainedBondAmount -
              data.bondRepays[0].planRepayAmount
            : billion
        // 결제 금액
        const paymentAmount =
          data.bondRepays[0].dueDtRemainedBondAmount <= billion
            ? data.bondRepays[0].dueDtRemainedBondAmount
            : billion
        // 전체 미결제 금액이 1000원 미만인 경우 결제 금액 고려
        const finalPaymentAmount =
          data.bondRepays[0].remainedBondAmount < 1000
            ? data.bondRepays[0].remainedBondAmount -
              data.bondRepays[0].planRepayAmount
            : paymentAmount
        setBondRepay({
          nextDueDt: data.nextDueDt,
          remainedBondAmount: data.bondRepays[0].remainedBondAmount,
          planRepayAmount: data.bondRepays[0].planRepayAmount,
          dueDtRemainedBondAmount: data.bondRepays[0].dueDtRemainedBondAmount,
          maxPayableAmount,
          paymentAmount: finalPaymentAmount,
        })
        setBondRepayLoaded(true)
        dispatch(showModal('modalRequestRePayment'))
      }
    } catch (error) {
      const code = error?.response?.data?.errorCode
      const message = error?.response?.data?.message
      if (code === 11006) {
        dispatch(showModal('alertUnAvailableRePayment'))
      } else {
        fail(message)
      }
    }
  }

  const [plans, setPlans] = useState(null)
  const handleDeferredVirtualAccountHistory = async () => {
    try {
      const { data } = await getPlan(id)
      const noPlans =
        !data.repaymentPlanVirtualAccounts ||
        data.repaymentPlanVirtualAccounts.length === 0
      if (noPlans) {
        dispatch(showModal('alertEmptyVirtualAccountHistory'))
      } else {
        setPlans(data)
        dispatch(showModal('modalVirtualAccountHistory'))
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  const handleSearchOptionsCashStatDuration = value => {
    setSearchOptionsCashStat({
      ...searchOptionsCashStat,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYY-MM-DD'),
    })
  }
  const handleSearchOptionsCashDuration = value => {
    setSearchOptionsCash({
      ...searchOptionsCash,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYY-MM-DD'),
    })
  }
  const handleSearchOptionsFreeCashDuration = value => {
    setSearchOptionsFreeCash({
      ...searchOptionsFreeCash,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYY-MM-DD'),
    })
  }
  const handleSearchOptionsWithdrawDuration = value => {
    setSearchOptionsWithdraw({
      ...searchOptionsWithdraw,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYY-MM-DD'),
    })
  }

  const handleSearchOptionsCashType = value => {
    setSearchOptionsCash({
      ...searchOptionsCash,
      type: value,
    })
  }
  const handleSearchOptionsFreeCashType = value => {
    setSearchOptionsFreeCash({
      ...searchOptionsFreeCash,
      status: value,
    })
  }
  const handleSearchOptionsWithdrawStatuses = value => {
    setSearchOptionsWithdraw({
      ...searchOptionsWithdraw,
      statuses: value,
    })
  }
  const handleSearchOptionsBondRepaymentStatus = value => {
    setSearchOptionsBond({
      ...searchOptionsBond,
      repaymentStatus: value,
    })
  }

  const showModalCharge = () => {
    dispatch(showModal('modalCharge'))
  }
  const showModalWithdraw = () => {
    dispatch(showModal('modalWithdraw'))
  }
  const showModalRegisterWithdrawAccount = () => {
    dispatch(showModal('modalRegisterWithdrawAccount'))
  }
  const showModalModifyWithdrawAccount = () => {
    dispatch(showModal('modalModifyWithdrawAccount'))
  }

  const [layerDeferredRePaymentDetail, setLayerDeferredRePaymentDetail] =
    useState({
      settleDt: moment().format('YYYY년 MM월'),
      data: [],
    })
  const showModalDeferredRePaymentDetail = async settleDt => {
    try {
      const { data } = await getRePaymentDetail(
        adAccount.id,
        moment(settleDt).format('YYYYMM')
      )
      setLayerDeferredRePaymentDetail({
        settleDt: moment(settleDt).format('YYYY년 MM월'),
        data,
      })
      dispatch(showModal('modalDeferredRePaymentDetail'))
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const [withdrawDetail, setWithdrawDetail] = useState({
    requestAmount: 0,
    amount: 0,
    details: null,
    rejectReason: '',
  })

  useEffect(() => {
    Promise.all([dispatch(fetchWallet(id)), dispatch(fetchWithdrawAccount(id))])
    dispatch(fetchWallet(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(
      fetchCashStatList({
        adAccountId: id,
        from: searchOptionsCashStat.from,
        to: searchOptionsCashStat.to,
        page: cashStatListPage,
      })
    )
  }, [dispatch, id, searchOptionsCashStat, cashStatListPage])

  useEffect(() => {
    dispatch(
      fetchCashList({
        adAccountId: id,
        from: searchOptionsCash.from,
        to: searchOptionsCash.to,
        type: searchOptionsCash.type,
        page: cashListPage,
      })
    )
  }, [dispatch, id, searchOptionsCash, cashListPage])

  useEffect(() => {
    dispatch(
      fetchFreeCashList({
        adAccountId: id,
        from: searchOptionsFreeCash.from,
        to: searchOptionsFreeCash.to,
        status: searchOptionsFreeCash.status,
        page: freeCashListPage,
      })
    )
  }, [dispatch, id, searchOptionsFreeCash, freeCashListPage])

  useEffect(() => {
    dispatch(
      fetchWithdrawList({
        adAccountId: id,
        from: searchOptionsWithdraw.from,
        to: searchOptionsWithdraw.to,
        statuses: searchOptionsWithdraw.statuses,
        page: withdrawListPage,
      })
    )
  }, [dispatch, id, searchOptionsWithdraw, withdrawListPage])

  useEffect(() => {
    dispatch(
      fetchBondList({
        adAccountId: id,
        repaymentStatus: searchOptionsBond.repaymentStatus,
        page: bondListPage,
      })
    )
  }, [dispatch, id, searchOptionsBond, bondListPage])
  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        광고캐시 관리 본문
      </h2>
      <div className="settingmanage_wrap">
        <div className="dsp_tit">
          <div className="tit_dsp">
            <h3 className="tit_subject">광고캐시 관리</h3>
            <strong className="sub_title">{name}</strong>
            <span className="sub_code">{id}</span>
          </div>
        </div>
        <div className="multibox_top">
          {wallet && wallet.deferredPayment ? (
            <div className="ad_managebox managebox_cash">
              <div className="managebox_tit">
                <strong className="tit_box">
                  캐시 현황 <span className="sub_title">(후불)</span>
                </strong>
                <div className="f_right">
                  <div className="single_wrap">
                    <button
                      className="btn_gm gm_line"
                      onClick={() => {
                        dispatch(showModal('modalDeferredPaymentChangeLog'))
                      }}>
                      <span className="inner_g">후불한도 변경이력</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="managebox_detail detail_helf">
                <div className="listcashreport_wrap">
                  <dl className="list_cashreport">
                    <dt>총 잔액</dt>
                    <dd className="txt_balance">
                      <NumberFormat
                        value={wallet && wallet.balance}
                        displayType={'text'}
                        thousandSeparator={true}
                        defaultValue={0}
                      />
                      원
                    </dd>
                  </dl>
                  <div className="set_listcashreport">
                    <dl className="list_cashreport">
                      <dt>유상캐시</dt>
                      <dd>
                        <NumberFormat
                          value={wallet && wallet.cash}
                          displayType={'text'}
                          thousandSeparator={true}
                          defaultValue={0}
                        />
                        원
                      </dd>
                    </dl>
                    <dl className="list_cashreport">
                      <dt>무상캐시</dt>
                      <dd>
                        <NumberFormat
                          value={wallet && wallet.freeCash}
                          displayType={'text'}
                          thousandSeparator={true}
                          defaultValue={0}
                        />
                        원
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="listcashreport_wrap">
                  <dl className="list_cashreport">
                    <dt>
                      후불한도
                      <Tooltip>
                        광고계정의 후불한도 값으로, 광고계정 잔액이 0원 밑으로
                        떨어져도
                        <br />
                        후불한도 만큼 광고집행할 수 있습니다.
                      </Tooltip>
                    </dt>
                    <dd>
                      <NumberFormat
                        value={wallet && wallet.creditLimit}
                        displayType={'text'}
                        thousandSeparator={true}
                        defaultValue={0}
                      />
                      원
                    </dd>
                  </dl>
                  <dl className="list_cashreport">
                    <dt>
                      가용후불한도
                      <Tooltip>
                        조회시점 기준으로 광고집행에 활용할 수 있는 남은
                        후불한도입니다.
                        <br />
                        가용후불한도가 부족하면 광고가 중단됩니다.
                      </Tooltip>
                    </dt>
                    <dd>
                      <NumberFormat
                        value={wallet && wallet.remainedCreditLimit}
                        displayType={'text'}
                        thousandSeparator={true}
                        defaultValue={0}
                      />
                      원
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          ) : (
            <div className="ad_managebox managebox_cash">
              <div className="managebox_tit">
                <strong className="tit_box">캐시 현황</strong>
                <div className="f_right">
                  <div className="single_wrap">
                    <button className="btn_gm gm_bl" onClick={showModalCharge}>
                      <span className="inner_g">충전</span>
                    </button>
                  </div>
                  {
                    <div className="single_wrap">
                      {refundAccountLoading ? (
                        <></>
                      ) : refundAccount ? (
                        <button
                          className="btn_gm gm_line"
                          onClick={showModalWithdraw}>
                          <span className="inner_g">환불</span>
                        </button>
                      ) : master ? (
                        <button
                          className="btn_gm gm_line"
                          onClick={() => {
                            setFromClicked('WITHDRAW_BUTTON')
                            showModalRegisterWithdrawAccount()
                          }}>
                          <span className="inner_g">환불</span>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                </div>
              </div>
              <div className="managebox_detail">
                <div className="listcashreport_wrap">
                  <dl className="list_cashreport">
                    <dt>총 잔액</dt>
                    <dd className="txt_balance">
                      <NumberFormat
                        value={wallet && wallet.balance}
                        displayType={'text'}
                        thousandSeparator={true}
                        defaultValue={0}
                      />
                      원
                    </dd>
                  </dl>
                  <div className="set_listcashreport">
                    <dl className="list_cashreport">
                      <dt>유상캐시</dt>
                      <dd>
                        <NumberFormat
                          value={wallet && wallet.cash}
                          displayType={'text'}
                          thousandSeparator={true}
                          defaultValue={0}
                        />
                        원
                      </dd>
                    </dl>
                    <dl className="list_cashreport">
                      <dt>무상캐시</dt>
                      <dd>
                        <NumberFormat
                          value={wallet && wallet.freeCash}
                          displayType={'text'}
                          thousandSeparator={true}
                          defaultValue={0}
                        />
                        원
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="ad_managebox managebox_refund">
            <div className="managebox_tit">
              <strong className="tit_box">환불계좌 정보</strong>
              {master && (
                <div className="f_right">
                  <div className="single_wrap">
                    {refundAccount ? (
                      <NoneAnchor
                        className="btn_gm gm_line"
                        onClick={showModalModifyWithdrawAccount}>
                        <span className="inner_g">수정</span>
                      </NoneAnchor>
                    ) : (
                      <NoneAnchor
                        className="btn_gm gm_line"
                        onClick={showModalRegisterWithdrawAccount}>
                        <span className="inner_g">등록</span>
                      </NoneAnchor>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="managebox_detail">
              {refundAccountLoading ? (
                <></>
              ) : refundAccount ? (
                <table className="define_adinfo">
                  <caption className="hide_caption">환불 정보 표</caption>
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">은행</th>
                      <td>
                        {BANK_NAME[refundAccount && refundAccount.bankCode]}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">계좌번호</th>
                      <td>{refundAccount && refundAccount.accountNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">예금주</th>
                      <td>{refundAccount && refundAccount.ownerName}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p className="nodata_info">
                  등록된 환불계좌가 없습니다.
                  <br />
                  {master ? (
                    <>
                      <NoneAnchor
                        className="link_txt"
                        onClick={showModalRegisterWithdrawAccount}>
                        등록
                      </NoneAnchor>
                      을 클릭하여 환불 계좌를 등록하세요.
                    </>
                  ) : (
                    <>
                      환불 계좌는 마스터만 등록 가능하며
                      <br />
                      환불 계좌 등록 이후에 환불 요청이 가능합니다.
                    </>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">
              캐시 일별 사용 현황
              <Tooltip style={{ margin: '9px -2px 0 1px' }}>
                캐시 일별 사용 현황은 상단의 ‘캐시 현황’ 정보와 상이할 수 있으며
                <br />
                당일 잔액은 다음날 제공됩니다.
              </Tooltip>
            </strong>
            <div className="f_right">
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_DURATION}
                  onChange={handleSearchOptionsCashStatDuration}
                  selected={searchOptionsCashStat.selectedDuration}
                />
              </div>
              <div className="single_wrap">
                <button
                  className={cx('btn_gm gm_line', {
                    in_active:
                      cashStatList && cashStatList.content.length === 0,
                  })}
                  onClick={handleDownloadCashStatList}>
                  <span className="inner_g icon_g">
                    <span className="ico_comm ico_download">다운로드</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2 thead_type2">
              {/* 2021-04-12 테이블 head 2줄 타입 */}
              <caption className="hide_caption">캐시 일별 사용 현황 표</caption>
              <colgroup>
                <col style={{ width: 186 }} />
                {/* 2021-04-12 수정 */}
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" rowSpan={2}>
                    <div className="inner_tbl">거래일시</div>
                  </th>
                  <th scope="col" colSpan={2} className="align_c">
                    <div className="inner_tbl">충전</div>
                  </th>
                  <th scope="col" colSpan={2} className="align_c">
                    <div className="inner_tbl">캐시사용</div>
                  </th>
                  <th scope="col" rowSpan={2} className="align_c">
                    <div className="inner_tbl">환불</div>
                  </th>
                  <th scope="col" rowSpan={2} className="align_c">
                    <div className="inner_tbl">환급</div>
                  </th>
                  <th scope="col" colSpan={2} className="align_c">
                    <div className="inner_tbl">총 잔액</div>
                  </th>
                </tr>
                <tr>
                  <th scope="col" className="align_c th_multi">
                    <div className="inner_tbl">유상캐시</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">무상캐시</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">유상캐시</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">무상캐시</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">유상캐시</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_tbl">무상캐시</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {cashStatListLoading ? (
                  <LoadingTable colSpan={9} />
                ) : !(cashStatList && cashStatList.content.length > 0) ? (
                  <NoDataTable colSpan={9}>
                    캐시 일별 사용 현황이 없습니다.
                  </NoDataTable>
                ) : (
                  cashStatList.content.map((item, index) => (
                    <tr key={`cashStatList_${item.journalDt}_${index}`}>
                      <td>
                        <div className="inner_tbl">
                          {item.journalDt.replace(/-/g, '.')}
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.chargeCash + item.chargeAdminCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={
                              item.chargeFreeCash + item.chargeSystemFreeCash
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.spendCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.spendFreeCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.withdrawCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.refundCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.closingCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.closingFreeCash}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {cashStatList.totalElements > 0 && (
            <Pagination
              onChange={handleCashStatListPageChange}
              totalPages={cashStatList.totalPages}
              number={cashStatList.number}
            />
          )}
        </div>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">충전 내역</strong>
            <div className="f_right">
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_DURATION}
                  onChange={handleSearchOptionsCashDuration}
                  selected={searchOptionsCash.selectedDuration}
                />
              </div>
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_CASH_TYPE}
                  onChange={handleSearchOptionsCashType}
                  selected={searchOptionsCash.type}
                />
              </div>
              <div className="single_wrap">
                {/* eslint-disable-next-line camelcase */}
                <button
                  className={cx('btn_gm gm_line', {
                    in_active: cashList && cashList.content.length === 0,
                  })}
                  onClick={handleDownloadCashList}>
                  <span className="inner_g icon_g">
                    <span className="ico_comm ico_download">다운로드</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">충전 내역 정보 표</caption>
              <colgroup>
                <col style={{ width: 189 }} />
                <col style={{ width: 130 }} />
                <col />
                <col />
                <col />
                <col style={{ width: 284 }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">거래일시</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">구분</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">거래유형</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">거래수단</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">금액</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">거래자</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {cashListLoading ? (
                  <LoadingTable colSpan={6} />
                ) : !(cashList && cashList.content.length > 0) ? (
                  <NoDataTable colSpan={6}>
                    {searchOptionsCash.type === 'REFUND'
                      ? '환급'
                      : searchOptionsCash.type === 'WITHDRAW'
                        ? '환불'
                        : searchOptionsCash.type === 'REPAYMENT'
                          ? '후불 결제'
                          : '충전'}{' '}
                    내역이 없습니다.
                  </NoDataTable>
                ) : (
                  cashList.content.map((item, index) => {
                    const { memberType } = item
                    const {
                      date,
                      type,
                      method,
                      methodDetail,
                      amount,
                      trader,
                      memberEmail,
                    } = getChargeHistoryInfoLabel(item)

                    const owner =
                      memberType === 'DSP_ACCOUNT'
                        ? getOwnerLabel(memberEmail, dspAccount.kakaoEmail)
                        : ''
                    const typeText = CASH_TYPE[item.type]
                    const span =
                      type === 'WITHDRAW' ? (
                        <span className="fc_warn">{amount}원</span>
                      ) : (
                        <>{amount}원</>
                      )
                    return (
                      <tr key={`${date}_${index}`}>
                        <td>
                          <div className="inner_tbl">
                            {moment(date).format('YYYY.MM.DD HH:mm')}
                          </div>
                        </td>
                        <td>
                          <div className="inner_tbl">{typeText}</div>
                        </td>
                        <td>
                          <div className="inner_tbl">{method}</div>
                        </td>
                        <td>
                          <div className="inner_tbl">{methodDetail}</div>
                        </td>
                        <td className="align_r">
                          <div className="inner_tbl">{span}</div>
                        </td>
                        <td>
                          <div className="inner_tbl">{`${trader} ${owner}`}</div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
          {cashList.totalElements > 0 && (
            <Pagination
              onChange={handleCashListPageChange}
              totalPages={cashList.totalPages}
              number={cashList.number}
            />
          )}
        </div>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">무상캐시 사용 현황</strong>
            <div className="f_right">
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_DURATION}
                  onChange={handleSearchOptionsFreeCashDuration}
                  selected={searchOptionsFreeCash.selectedDuration}
                />
              </div>
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_FREECASH_STATUS}
                  onChange={handleSearchOptionsFreeCashType}
                  selected={searchOptionsFreeCash.status}
                />
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">
                무상캐시 사용 현황 정보 표
              </caption>
              <colgroup>
                <col style={{ width: 169 }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: 253 }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">발행일시</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">충전사유</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">충전금액</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">상태</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">만료일</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">사용일</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">거래자</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {freeCashListLoading ? (
                  <LoadingTable colSpan={7} />
                ) : !(freeCashList && freeCashList.content.length > 0) ? (
                  <NoDataTable colSpan={7}>
                    무상캐시{' '}
                    {searchOptionsFreeCash.status === 'READY'
                      ? '사용전'
                      : searchOptionsFreeCash.status === 'USING'
                        ? '사용중'
                        : searchOptionsFreeCash.status === 'USED'
                          ? '사용완료'
                          : searchOptionsFreeCash.status === 'EXPIRED'
                            ? '만료'
                            : searchOptionsFreeCash.status === 'CANCEL'
                              ? '회수'
                              : '사용'}{' '}
                    현황이 없습니다.
                  </NoDataTable>
                ) : (
                  freeCashList.content.map((v, i) => {
                    const {
                      description,
                      type,
                      createDt,
                      endDt,
                      member,
                      amount,
                    } = getFreeCashHistoryInfoValue(v)
                    const owner = getOwnerLabel(member, dspAccount.kakaoEmail)

                    return (
                      <tr key={`${createDt}|${endDt}|${i}`}>
                        <td>
                          <div className="inner_tbl">
                            {moment(createDt).format('YYYY.MM.DD HH:mm')}
                          </div>
                        </td>
                        <td>
                          <div className="inner_tbl">{description}</div>
                        </td>
                        <td className="align_r">
                          <div className="inner_tbl">
                            <NumberFormat
                              value={amount}
                              displayType={'text'}
                              thousandSeparator={true}
                              defaultValue={0}
                            />
                            원
                          </div>
                        </td>
                        <td>
                          <div className="inner_tbl">
                            {FREE_CASH_STATUS[type]}
                          </div>
                        </td>
                        <td>
                          <div className="inner_tbl">
                            {moment(endDt).format('YYYY.MM.DD')}
                          </div>
                        </td>
                        {renderCashUseDayRow(v)}
                        <td>
                          <div className="inner_tbl">{`${member} ${owner}`}</div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
          {freeCashList.totalElements > 0 && (
            <Pagination
              onChange={handleFreeCashListPageChange}
              totalPages={freeCashList.totalPages}
              number={freeCashList.number}
            />
          )}
        </div>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">환불 처리 현황</strong>
            <div className="f_right">
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_DURATION}
                  onChange={handleSearchOptionsWithdrawDuration}
                  selected={searchOptionsWithdraw.selectedDuration}
                />
              </div>
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_WITHDRAW_STATUS}
                  onChange={handleSearchOptionsWithdrawStatuses}
                  selected={searchOptionsWithdraw.statuses}
                />
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">환불 처리 현황 정보 표</caption>
              <colgroup>
                <col style={{ width: 169 }} />
                <col />
                <col />
                <col style={{ width: 270 }} />
                <col />
                <col />
                <col style={{ width: 133 }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">요청일시</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">환불 요청 금액</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">환불 금액</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">요청자</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">상태</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">처리일</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">환불 내역</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {withdrawListLoading ? (
                  <LoadingTable colSpan={7} />
                ) : !(withdrawList && withdrawList.content.length > 0) ? (
                  <NoDataTable colSpan={7}>
                    환불{' '}
                    {searchOptionsWithdraw.statuses === 'STANDBY,RESERVED'
                      ? '처리중'
                      : searchOptionsWithdraw.statuses === 'REJECTED'
                        ? '처리불가'
                        : searchOptionsWithdraw.statuses === 'APPROVED'
                          ? '처리완료'
                          : '처리'}{' '}
                    현황이 없습니다.
                  </NoDataTable>
                ) : (
                  withdrawList.content.map((v, i) => {
                    const {
                      requestDt,
                      amount,
                      requestAmount,
                      resultDt,
                      trader,
                      memberEmail,
                      status,
                      rejectReason,
                    } = getWithdrawHistoryInfoValue(v)
                    const handleOpenHistoryPopup = item => () => {
                      setWithdrawDetail({
                        ...withdrawDetail,
                        requestAmount: item.requestAmount,
                        amount: item.amount,
                        details: item.details,
                      })
                      dispatch(showModal('modalWithdrawDetail'))
                    }
                    const handleRejectPopup = reason => () => {
                      setWithdrawDetail({
                        ...withdrawDetail,
                        rejectReason: reason,
                      })
                      dispatch(showModal('alertWithdrawRejectDetail'))
                    }

                    const statusLabel = WITHDRAW_STATUS[status]
                    const owner = getOwnerLabel(
                      memberEmail,
                      dspAccount.kakaoEmail
                    )
                    const isApproved = status === 'APPROVED'
                    const isRejected = status === 'REJECTED'
                    const showHistory = isApproved || isRejected
                    const popupOpen = isApproved
                      ? handleOpenHistoryPopup(v)
                      : handleRejectPopup(rejectReason)
                    const span = isRejected ? (
                      <span className="fc_warn">{statusLabel}</span>
                    ) : (
                      statusLabel
                    )
                    const requestAmountLabel = checkNotEmpty(requestAmount) ? (
                      requestAmount > 0 ? (
                        <>
                          <NumberFormat
                            value={requestAmount}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </>
                      ) : (
                        '-'
                      )
                    ) : (
                      '전액환불'
                    )

                    return (
                      <tr key={`${requestDt}|${resultDt}|${i}`}>
                        <td>
                          <div className="inner_tbl">
                            {moment(requestDt).format('YYYY.MM.DD HH:mm')}
                          </div>
                        </td>
                        <td className="align_r">
                          <div className="inner_tbl">
                            <span className="fc_warn">
                              {requestAmountLabel}
                            </span>
                          </div>
                        </td>
                        <td className="align_r">
                          <div className="inner_tbl">
                            <span className="fc_warn">{amount}</span>
                          </div>
                        </td>
                        <td>
                          <div className="inner_tbl">{`${trader} ${owner}`}</div>
                        </td>
                        <td>
                          <div className="inner_tbl">{span}</div>
                        </td>
                        <td>
                          <div className="inner_tbl">
                            {checkNotEmpty(resultDt)
                              ? moment(resultDt).format('YYYY.MM.DD')
                              : '-'}
                          </div>
                        </td>
                        <td className="align_c">
                          <div className="inner_tbl">
                            {showHistory && (
                              <div className="wrap_btn">
                                <NoneAnchor
                                  className="btn_gs"
                                  onClick={popupOpen}>
                                  <span className="inner_g">자세히보기</span>
                                </NoneAnchor>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
          {withdrawList.totalElements > 0 && (
            <Pagination
              onChange={handleWithdrawListPageChange}
              totalPages={withdrawList.totalPages}
              number={withdrawList.number}
            />
          )}
        </div>
        {wallet && wallet.deferredPayment && (
          <div className="ad_managebox">
            <div className="managebox_tit">
              <strong className="tit_box">후불 결제 현황</strong>
              <div className="f_left">
                <div className="single_wrap">
                  <button
                    type="button"
                    className="btn_gm gm_bl"
                    onClick={handleDeferredRePayment}>
                    <span className="inner_g">결제</span>
                  </button>
                  <button
                    type="button"
                    className="btn_gm gm_line"
                    onClick={handleDeferredVirtualAccountHistory}>
                    <span className="inner_g">가상계좌 발급 이력</span>
                  </button>
                </div>
              </div>
              <div className="f_right">
                <Select
                  options={SELECTBOX_BOND_STATUS}
                  onChange={handleSearchOptionsBondRepaymentStatus}
                  selected={searchOptionsBond.repaymentStatus}
                />
              </div>
            </div>
            <div className="tblg2_wrap">
              <table className="tbl_g2">
                <caption className="hide_caption">
                  후불 결제 현황 정보 표
                </caption>
                <colgroup>
                  <col style={{ width: 149 }} />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col style={{ width: 138 }} />
                  <col style={{ width: 136 }} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="inner_tbl">
                        결제기한일
                        <Tooltip>
                          결제기한일은 정산월별로 결제기준일 기간에 해당하는
                          월의 마지막 일자로 자동 설정됩니다. (예- 결제기준일이
                          30일인 경우, 정산월 익월 말일)
                        </Tooltip>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">
                        결제기준일
                        <Tooltip>
                          정산월별 결제 기준 기간입니다. 결제 기준일에 따라
                          결제기한일이 설정됩니다.
                        </Tooltip>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">
                        정산월
                        <Tooltip>광고 비용이 발생한 월을 의미합니다.</Tooltip>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">
                        정산금액
                        <Tooltip>
                          정산 금액은 정산월 기준 다음달 초에 생성됩니다.
                        </Tooltip>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">
                        결제금액
                        <Tooltip>
                          정산 금액 중 이미 납부한 금액을 의미합니다.
                        </Tooltip>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">
                        미결제금액
                        <Tooltip>
                          정산 금액 중 아직 납부되지 않은 금액을 의미합니다.
                        </Tooltip>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">결제내역</div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">상태</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bondListLoading ? (
                    <LoadingTable colSpan={8} />
                  ) : !(bondList && bondList.content.length > 0) ? (
                    <NoDataTable colSpan={8}>
                      후불{' '}
                      {searchOptionsBond.repaymentStatus === 'READY'
                        ? '미결제'
                        : searchOptionsBond.repaymentStatus === 'PARTIAL'
                          ? '일부결제'
                          : searchOptionsBond.repaymentStatus === 'DONE'
                            ? '결제완료'
                            : searchOptionsBond.repaymentStatus === 'OVERDUE'
                              ? '연체'
                              : '결제'}{' '}
                      현황이 없습니다.
                    </NoDataTable>
                  ) : (
                    bondList.content.map((tableItems, index) => {
                      const {
                        bondAmount,
                        dueDt,
                        remainedBondAmount,
                        repaymentAmount,
                        repaymentPeriod,
                        repaymentStatus,
                        settleDt,
                      } = tableItems

                      const showHistory = repaymentAmount > 0
                      const statusText = BOND_STATUS[repaymentStatus]
                      const repaymentDate = BOND_CRITERIA[repaymentPeriod]
                      return (
                        <tr key={`${dueDt}_${settleDt}_${index}`}>
                          <td>
                            <div className="inner_tbl">
                              {dueDt.replace(/-/g, '.')}
                            </div>
                          </td>
                          <td>
                            <div className="inner_tbl">{repaymentDate}일</div>
                          </td>
                          <td>
                            <div className="inner_tbl">
                              {settleDt.replace('-', '.')}
                            </div>
                          </td>
                          <td className="align_r">
                            <div className="inner_tbl">
                              <NumberFormat
                                value={bondAmount}
                                displayType={'text'}
                                thousandSeparator={true}
                                defaultValue={0}
                              />
                              원
                            </div>
                          </td>
                          <td className="align_r">
                            <div className="inner_tbl">
                              <NumberFormat
                                value={repaymentAmount}
                                displayType={'text'}
                                thousandSeparator={true}
                                defaultValue={0}
                              />
                              원
                            </div>
                          </td>
                          <td className="align_r">
                            <div className="inner_tbl">
                              {remainedBondAmount > 0 ? (
                                <span className="fc_warn">
                                  <NumberFormat
                                    value={remainedBondAmount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    defaultValue={0}
                                  />
                                  원
                                </span>
                              ) : (
                                <>
                                  <NumberFormat
                                    value={remainedBondAmount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    defaultValue={0}
                                  />
                                  원
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="inner_tbl">
                              {showHistory ? (
                                <div className="wrap_btn">
                                  <NoneAnchor
                                    className="btn_gs"
                                    onClick={() =>
                                      showModalDeferredRePaymentDetail(settleDt)
                                    }>
                                    <span className="inner_g">자세히보기</span>
                                  </NoneAnchor>
                                </div>
                              ) : (
                                '-'
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="inner_tbl">
                              {repaymentStatus === 'OVERDUE' ? (
                                <span className="fc_warn">{statusText}</span>
                              ) : (
                                statusText
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>
            </div>
            {bondList.totalElements > 0 && (
              <Pagination
                onChange={handleBondListPageChange}
                totalPages={bondList.totalPages}
                number={bondList.number}
              />
            )}
          </div>
        )}
      </div>
      <ModalCharge
        dspAccount={dspAccount}
        adAccount={adAccount}
        wallet={wallet}
      />
      <ModalWithdraw
        dspAccount={dspAccount}
        adAccount={adAccount}
        searchOptionsWithdraw={searchOptionsWithdraw}
      />
      <Modal
        id="modalWithdrawGuide"
        className="refund_layer"
        title="환불 방법"
        okText="확인">
        <div className="layer_body">
          <p className="subtit_layer">신용카드</p>
          <table className="tbl_layer">
            <caption className="hide_caption">신용카드 표</caption>
            <colgroup>
              <col style={{ width: 161 }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">환불시점</th>
                <td>
                  <div className="inner_cell">
                    취소 완료 후, 3-5일 내 카드사 승인취소 (영업일 기준)
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">환불방법</th>
                <td>
                  <div className="inner_cell">신용카드 승인취소</div>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="subtit_layer">계좌이체</p>
          <table className="tbl_layer">
            <caption className="hide_caption">계좌이체 표</caption>
            <colgroup>
              <col style={{ width: 161 }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">종류</th>
                <td>
                  <div className="inner_cell">실시간 계좌이체, 무통장 입금</div>
                </td>
              </tr>
              <tr>
                <th scope="row">환불시점</th>
                <td>
                  <div className="inner_cell">
                    취소 완료 후, 등록하신 환불계좌로 1-2일 내 환불금액 입금
                    <br />
                    (영업일 기준)
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">환불방법</th>
                <td>
                  <div className="inner_cell">
                    계좌입금 (환불계좌 등록 필수)
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="desc_guide">
            환불 요청을 한 사용자의 최종 결제 수단으로 환불 되며, 충전하신
            캐시는 언제든지 환불 받을 수 있습니다.
          </p>
        </div>
      </Modal>
      <ModalRegisterWithdrawAccount
        dspAccount={dspAccount}
        adAccount={adAccount}
        fromClicked={fromClicked}
      />
      {!refundAccountLoading && (
        <ModalModifyWithdrawAccount
          dspAccount={dspAccount}
          adAccount={adAccount}
          refundAccount={refundAccount}
          withdrawList={withdrawList}
        />
      )}
      {wallet && wallet.deferredPayment && (
        <>
          <ModalDeferredPaymentChangeLog adAccount={adAccount} />
          <ModalDeferredRePaymentDetail data={layerDeferredRePaymentDetail} />
          {bondRepayLoaded && (
            <ModalRequestRePayment
              dspAccount={dspAccount}
              adAccount={adAccount}
              bondRepay={bondRepay}
            />
          )}
          <ModalVirtualAccountHistory adAccount={adAccount} plans={plans} />
        </>
      )}
      <ModalWithdrawDetail
        requestAmount={withdrawDetail.requestAmount}
        amount={withdrawDetail.amount}
        details={withdrawDetail.details}
      />
      <Confirm
        id="confirmUseFreeCash"
        title="무상캐시 사용하기"
        ok={() => {
          handleConfirmUseFreeCash()
        }}>
        무상캐시를 사용하시겠습니까?
      </Confirm>
      <Alert id="alertUnAvailableRePayment" title="결제 불가">
        미결제 금액이 없습니다.
      </Alert>
      <Alert id="alertEmptyVirtualAccountHistory" title="가상계좌 미발급">
        가상계좌 발급 이력이 없습니다.
      </Alert>
      <Alert id="alertWithdrawRejectDetail" title="반려사유">
        {withdrawDetail && withdrawDetail.rejectReason}
      </Alert>
    </>
  )
}

export default CashIndex
