export const bizNumRegex = /^\d{3}-\d{2}-\d{5}$/
export const cut = (str, size) => {
  if (str && str.length > size) {
    return `${str.slice(0, size)}...`
  }
  return str
}
export const whitespaceOnlyRegex = /^\s+$/
export const isNullOrWhitespace = input => !input || !input.trim()
export const isNumeric = str => /^\d+$/.test(str)
