import { Link } from 'react-router-dom'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

export const CardBusinessRight = ({
  hasBizRight,
  bizRightDate,
  bizRightAgencyName,
  transferBizRight,
}) => (
  <div className="card_summary card_agency">
    <strong className="tit_card">영업권</strong>
    <em className="txt_info">
      {hasBizRight ? bizRightAgencyName : '직접 운영'}
    </em>
    <div className="state_agency">
      {hasBizRight && (
        <p className="desc_info">
          {bizRightDate && moment(bizRightDate).format('YYYY.MM.DD HH:mm')}에
          영업권 설정됨
        </p>
      )}
      {!transferBizRight && (
        <div className="desc_state">
          <span className="ico_comm ico_fail">안내</span>영업권 요청 거부 설정됨
        </div>
      )}
    </div>
    <Link to={`?tab=businessright`} className="btn_gs">
      {/* 비활성화시 in_active 클래스 추가 */}
      <span className="inner_g">더보기</span>
    </Link>
  </div>
)
CardBusinessRight.propTypes = {
  hasBizRight: PropTypes.bool.isRequired,
  bizRightDate: PropTypes.string,
  bizRightAgencyName: PropTypes.string,
  transferBizRight: PropTypes.bool,
}
