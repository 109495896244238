import React from 'react'

const Loading = () => (
  <span className="area_loading">
    <span className="group_loading">
      <span className="ico_loading ani_loading" />
      <span className="ico_loading ani_loading2" />
      <span className="ico_loading ani_loading3" />
      <span className="screen_out">로딩중</span>
    </span>
  </span>
)

export default Loading
