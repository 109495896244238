import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { disableLogoLink, enableLogoLink } from 'features/layout/layoutSlice'

const useLogoLinkDisabled = (flag = true) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (flag) {
      dispatch(disableLogoLink())
      return () => {
        dispatch(enableLogoLink())
      }
    }
  })
}
export default useLogoLinkDisabled
