import { createPortal } from 'react-dom'
import React, { useCallback, useRef, useState } from 'react'
import { hideModal } from './dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Button from 'components/form/Button'
import NoneAnchor from 'components/NoneAnchor'
import { noop } from 'utils'

export const DialogPortal = ({ close = noop, children, id }) => {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const { modals } = useSelector(state => state.dialog)
  const index = modals.indexOf(id)
  const handleRefClick = event => {
    if (ref.current === event.target) {
      event.stopPropagation()
      if (modals.length > 0) {
        dispatch(hideModal(modals[modals.length - 1]))
      }
      close()
    }
  }
  return createPortal(
    <>
      <div className="dimmed_layer" style={{ zIndex: 1100 + index }} />
      <div
        ref={ref}
        className="layer_wrap"
        onClick={handleRefClick}
        style={{ zIndex: 1100 + index }}>
        {children}
      </div>
    </>,
    document.body
  )
}

const Dialog = ({
  type,
  id,
  title,
  children,
  ok = noop,
  cancel = noop,
  close = noop,
  className,
  innerClassName,
  showOk = true,
  disabledOk = false,
  okText = '확인',
  showCancel = true,
  cancelText = '취소',
  keepModal = false,
  marginLeft = -200,
}) => {
  const dispatch = useDispatch()
  const { modals } = useSelector(state => state.dialog)
  const [height, setHeight] = useState(0)
  const ref = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const handleOk = () => {
    if (!keepModal) {
      dispatch(hideModal(id))
    }
    ok()
  }

  const handleCancel = () => {
    dispatch(hideModal(id))
    cancel()
  }

  const handleClose = () => {
    dispatch(hideModal(id))
    close()
  }

  return (
    modals.includes(id) && (
      <DialogPortal close={close} id={id}>
        {type === 'modal' ? (
          <div
            ref={ref}
            className={`basic_layer${className ? ` ${className}` : ''}`}>
            <div
              className={`inner_basic_layer${
                innerClassName ? ` ${innerClassName}` : ''
              }`}>
              <div className="layer_head">
                <strong className="tit_layer">{title}</strong>
              </div>
              <div className="layer_body">{children}</div>
              <div className="layer_foot">
                <div className="btn_group">
                  {showCancel && (
                    <NoneAnchor onClick={handleCancel} className="btn_gm">
                      <span className="inner_g">{cancelText}</span>
                    </NoneAnchor>
                  )}
                  {showOk && (
                    <Button
                      onClick={handleOk}
                      className="btn_gm gm_bl"
                      disabled={disabledOk}>
                      {okText}
                    </Button>
                  )}
                </div>
                <NoneAnchor onClick={handleClose} className="btn_close">
                  <span className="ico_comm ico_close">닫기</span>
                </NoneAnchor>
              </div>
            </div>
          </div>
        ) : (
          <div
            ref={ref}
            className={`basic_layer2${className ? ` ${className}` : ''}`}
            style={{
              marginTop: -height / 2,
              marginLeft: `${marginLeft || -200}px`,
            }}>
            <div className="inner_basic_layer">
              <strong className="tit_layer">{title}</strong>
              <p className="txt_layer">{children}</p>
              <div className="btn_group">
                {type === 'confirm' && (
                  <NoneAnchor className="btn_gm" onClick={handleCancel}>
                    <span className="inner_g">{cancelText}</span>
                  </NoneAnchor>
                )}
                <NoneAnchor className="btn_gm gm_bl" onClick={handleOk}>
                  <span className="inner_g">{okText}</span>
                </NoneAnchor>
              </div>
              <NoneAnchor className="btn_close" onClick={handleClose}>
                <span className="ico_comm ico_close">닫기</span>
              </NoneAnchor>
            </div>
          </div>
        )}
      </DialogPortal>
    )
  )
}

Dialog.propTypes = {
  type: PropTypes.oneOf(['modal', 'alert', 'confirm']).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  ok: PropTypes.func,
  showOk: PropTypes.bool,
  disabledOk: PropTypes.bool,
  okText: PropTypes.string,
  cancel: PropTypes.func,
  showCancel: PropTypes.bool,
  cancelText: PropTypes.string,
  close: PropTypes.func,
  keepModal: PropTypes.bool,
  marginLeft: PropTypes.number,
}

export default Dialog
