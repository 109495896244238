import {
  init,
  makeFetchTransport,
  makeMultiplexedTransport,
  moduleMetadataIntegration,
} from '@sentry/react'

const EXTRA_KEY = 'ROUTE_TO'
const transport = makeMultiplexedTransport(makeFetchTransport, args => {
  const event = args.getEvent()
  if (
    event &&
    event.extra &&
    EXTRA_KEY in event.extra &&
    Array.isArray(event.extra[EXTRA_KEY])
  ) {
    return event.extra[EXTRA_KEY]
  }
  return []
})
const beforeSend = event => {
  if (event?.exception?.values?.[0]?.stacktrace?.frames) {
    const { frames } = event.exception.values[0].stacktrace
    const routeTo = frames
      .filter(frame => frame.module_metadata && frame.module_metadata.dsn)
      .map(v => v.module_metadata)
      .slice(-1)
    if (routeTo.length) {
      event.extra = {
        ...event.extra,
        [EXTRA_KEY]: routeTo,
      }
    }
  }
  return event
}

export const sentryInit = dsn => {
  init({
    dsn,
    integrations: [moduleMetadataIntegration()],
    transport,
    beforeSend,
    normalizeDepth: 10,
  })
}
