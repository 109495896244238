import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import NoneAnchor from '../../../components/NoneAnchor'
import Loading from '../../../components/loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { CARD_ACCOUNT_SIZE, setPage } from './autoPayCardAccountSlice'
import useOutsideClick from '../../../utils/hooks/useOutsideClick'
import classNames from 'classnames'

const OnOffIcon = ({ userConfig, outOfBalance, status = [] }) =>
  userConfig === 'ON' &&
  outOfBalance !== 'Y' &&
  status.includes('STOPPED') === false ? (
    <span className="ico_on">운영중</span>
  ) : (
    <span className="ico_off">운영중지</span>
  )
OnOffIcon.propTypes = {
  userConfig: PropTypes.string.isRequired,
  outOfBalance: PropTypes.string.isRequired,
  status: PropTypes.arrayOf(PropTypes.string),
}

const AdAccountLayer = ({ walletId, onClose, onSelect }) => {
  const dispatch = useDispatch()
  const { search, adAccounts, isLoading, page, totalElements } = useSelector(
    state => state.autoPayCardAccount
  )
  const lastPage = useMemo(
    () => Math.max(Math.ceil(totalElements / CARD_ACCOUNT_SIZE) - 1, 0),
    [totalElements]
  )
  const ref = useOutsideClick(onClose)
  const selectedIndex = adAccounts.findIndex(({ id }) => id === walletId)
  const scrollRef = useCallback(
    node => {
      if (node) {
        node.scrollTop = selectedIndex === -1 ? 0 : selectedIndex * 44
      }
    },
    [selectedIndex]
  )

  return (
    <div ref={ref} className="layer_account" style={{ display: 'block' }}>
      <div ref={scrollRef} className="fake_scroll">
        <ul className="list_account">
          {isLoading ? (
            <div className="txt_nodata">
              <Loading />
            </div>
          ) : search !== '' && adAccounts.length === 0 ? (
            <p className="txt_nodata">검색 결과가 없습니다.</p>
          ) : (
            adAccounts.map(
              ({ id, name, master, userConfig, outOfBalance, status }) => (
                <li key={id} className={classNames({ on: id === walletId })}>
                  <NoneAnchor
                    className="link_option"
                    onClick={() => {
                      onSelect({
                        id,
                        name,
                      })
                    }}>
                    {master && (
                      <div className="opt_state">
                        <span className="ico_badge ico_master">마스터</span>
                      </div>
                    )}
                    <span className="txt_option">
                      <OnOffIcon
                        userConfig={userConfig}
                        outOfBalance={outOfBalance}
                        status={status}
                      />
                      {name}
                      <span className="txt_num">{id}</span>
                    </span>
                  </NoneAnchor>
                </li>
              )
            )
          )}
        </ul>
      </div>
      <div className="opt_tool">
        <div className="paging_wrap2">
          <span className="inner_paging">
            <strong className="num_current">
              <span className="screen_out">현재 광고계정 리스트</span>
              {page + 1}
            </strong>
            / <span className="screen_out">총 광고계정 리스트 수</span>
            {lastPage + 1}
          </span>
          {page === 0 || isLoading ? (
            <span className="num_paging num_prev">
              <span className="ico_comm ico_prev">이전</span>
            </span>
          ) : (
            <NoneAnchor
              className="num_paging num_prev"
              onClick={() => dispatch(setPage(page - 1))}>
              <span className="ico_comm ico_prev">이전</span>
            </NoneAnchor>
          )}
          {page === lastPage || isLoading ? (
            <span className="num_paging num_next">
              <span className="ico_comm ico_next">다음</span>
            </span>
          ) : (
            <NoneAnchor
              className="num_paging num_next"
              onClick={() => dispatch(setPage(page + 1))}>
              <span className="ico_comm ico_next">다음</span>
            </NoneAnchor>
          )}
        </div>
      </div>
    </div>
  )
}
AdAccountLayer.propTypes = {
  walletId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}
export default AdAccountLayer
