import PropTypes from 'prop-types'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

export const isNullOrUndefined = value => value === null || value === undefined

const PositionTooltip = ({
  on,
  content,
  tag: TagName = 'span',
  display = 'inline-block',
  style = {},
  offsetX = 0,
  offsetY = 0,
  position = 'top alignLeft',
  containerId = 'mArticle',
  className,
  children,
}) => {
  const getContainerElement = useCallback(
    () => document.getElementById(containerId) || document.body,
    [containerId]
  )
  const areaRef = useRef()
  const tooltipRef = useRef()
  const [isVisible, setIsVisible] = useState(false)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const [positionY, setPositionY] = useState(0)
  const [positionX, setPositionX] = useState(0)

  const [maxTop, setMaxTop] = useState(Number.MAX_SAFE_INTEGER)
  const [maxLeft, setMaxLeft] = useState(Number.MAX_SAFE_INTEGER)

  useLayoutEffect(() => {
    if (!isVisible && !on) return

    const {
      top: containerTop,
      left: containerLeft,
      width: containerWidth,
      height: containerHeight,
    } = getContainerElement().getBoundingClientRect()

    const {
      top: areaTop,
      left: areaLeft,
      height: areaHeight,
      width: areaWidth,
    } = areaRef.current.getBoundingClientRect()

    const { height: tooltipHeight, width: tooltipWidth } =
      tooltipRef.current.getBoundingClientRect()

    let newPositionY = 0
    let newPositionX = 0

    position
      .split(' ')
      .filter(positionKeyword => {
        // center 만 가로세로양쪽에 먼저 적용
        if (positionKeyword === 'center') {
          newPositionX = (areaWidth - tooltipWidth) / 2
          newPositionY = (areaHeight - tooltipHeight) / 2
          return false
        }
        return true
      })
      .forEach(positionKeyword => {
        // 나머지로 오버라이드
        switch (positionKeyword) {
          case 'top':
            newPositionY = -tooltipHeight
            break
          case 'bottom':
            newPositionY = areaHeight
            break
          case 'left':
            newPositionX = -tooltipWidth
            break
          case 'right':
            newPositionX = areaWidth
            break
          case 'alignTop':
            newPositionY = 0
            break
          case 'alignBottom':
            newPositionY = areaHeight - tooltipHeight
            break
          case 'alignLeft':
            newPositionX = 0
            break
          case 'alignRight':
            newPositionX = areaWidth - tooltipWidth
            break
          default:
        }
      })

    setTop(areaTop - containerTop)
    setLeft(areaLeft - containerLeft)
    setPositionY(newPositionY)
    setPositionX(newPositionX)
    setMaxTop(containerHeight - tooltipHeight)
    setMaxLeft(containerWidth - tooltipWidth)
  }, [
    isVisible,
    getContainerElement,
    position,
    top,
    left,
    positionY,
    positionX,
    maxTop,
    maxLeft,
    on,
  ])

  return (
    <>
      <TagName
        ref={areaRef}
        style={{ display, ...style }}
        onMouseEnter={() => {
          setIsVisible(true)
        }}
        onMouseLeave={() => setIsVisible(false)}>
        {children}
        {createPortal(
          <div
            ref={tooltipRef}
            className={className || 'tooltip_basic ly_tooltip ly_tooltip_type2'}
            style={{
              whiteSpace: 'nowrap',
              position: 'absolute',
              top: Math.min(Math.max(0, top + offsetY + positionY), maxTop),
              left: Math.min(Math.max(0, left + offsetX + positionX), maxLeft),
              display: (isNullOrUndefined(on) ? isVisible : on)
                ? 'block'
                : 'none',
            }}>
            {content}
          </div>,
          getContainerElement()
        )}
      </TagName>
    </>
  )
}

export default PositionTooltip

PositionTooltip.propTypes = {
  on: PropTypes.bool,
  children: PropTypes.node,
  tag: PropTypes.string,
  containerId: PropTypes.string,
  content: PropTypes.node,
  display: PropTypes.string,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.oneOf([
    'center',
    'left',
    'right',
    'alignLeft',
    'alignRight',
    'top',
    'bottom',
    'alignTop',
    'alignBottom',

    'center top',
    'center bottom',
    'center alignTop',
    'center alignBottom',
    'left center',
    'left top',
    'left bottom',
    'left alignTop',
    'left alignBottom',
    'right center',
    'right top',
    'right bottom',
    'right alignTop',
    'right alignBottom',
    'alignLeft center',
    'alignLeft top',
    'alignLeft bottom',
    'alignLeft alignTop',
    'alignLeft alignBottom',
    'alignRight center',
    'alignRight top',
    'alignRight bottom',
    'alignRight alignTop',
    'alignRight alignBottom',

    'top center',
    'bottom center',
    'alignTop center',
    'alignBottom center',
    'center left',
    'top left',
    'bottom left',
    'alignTop left',
    'alignBottom left',
    'center right',
    'top right',
    'bottom right',
    'alignTop right',
    'alignBottom right',
    'center alignLeft',
    'top alignLeft',
    'bottom alignLeft',
    'alignTop alignLeft',
    'alignBottom alignLeft',
    'center alignRight',
    'top alignRight',
    'bottom alignRight',
    'alignTop alignRight',
    'alignBottom alignRight',
  ]),
}
