import React from 'react'
import PropTypes from 'prop-types'
import NoneAnchor from 'components/NoneAnchor'

const Pagination = ({ number, totalPages, limit = 5, onChange }) => {
  const firstNumber = 0
  const lastNumber = Math.max(totalPages - 1, 0)
  const page =
    number > lastNumber
      ? lastNumber
      : number < firstNumber
        ? firstNumber
        : number
  const isFirst = page === firstNumber
  const isLast = page === lastNumber
  const start = Math.floor(page / limit) * limit
  const end = Math.min(start + limit - 1, lastNumber)
  const prevNumber = Math.max(start - limit, firstNumber)
  const hasPrev = prevNumber !== start
  const nextNumber = Math.min(end + 1, lastNumber)
  const hasNext = nextNumber !== end
  const range = [...Array(end - start + 1).keys()].map(value => value + start)
  return (
    <div className="paging_wrap">
      <div className="inner_paging">
        {isFirst ? (
          <span className="num_paging">
            <span className="ico_comm ico_prevs">맨처음</span>
          </span>
        ) : (
          <NoneAnchor
            onClick={() => onChange(firstNumber)}
            className="num_paging">
            <span className="ico_comm ico_prevs">맨처음</span>
          </NoneAnchor>
        )}
        {hasPrev ? (
          <NoneAnchor
            href="#none"
            onClick={() => onChange(prevNumber)}
            className="num_paging">
            <span className="ico_comm ico_prev">이전</span>
          </NoneAnchor>
        ) : (
          <span className="num_paging">
            <span className="ico_comm ico_prev">이전</span>
          </span>
        )}
        {range.map(pageNumber =>
          pageNumber === page ? (
            <em key={pageNumber} className="num_paging">
              <span className="screen_out">현재페이지</span>
              {pageNumber + 1}
            </em>
          ) : (
            <NoneAnchor
              key={pageNumber}
              onClick={() => onChange(pageNumber)}
              className="num_paging">
              {pageNumber + 1}
            </NoneAnchor>
          )
        )}
        {hasNext ? (
          <NoneAnchor
            href="#none"
            onClick={() => onChange(nextNumber)}
            className="num_paging">
            <span className="ico_comm ico_next">다음</span>
          </NoneAnchor>
        ) : (
          <span className="num_paging">
            <span className="ico_comm ico_next">다음</span>
          </span>
        )}
        {isLast ? (
          <span className="num_paging">
            <span className="ico_comm ico_nexts">맨끝</span>
          </span>
        ) : (
          <NoneAnchor
            href="#none"
            onClick={() => onChange(lastNumber)}
            className="num_paging">
            <span className="ico_comm ico_nexts">맨끝</span>
          </NoneAnchor>
        )}
      </div>
    </div>
  )
}
Pagination.propTypes = {
  number: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.number,
}
export default Pagination
