import React from 'react'
import useLogoLinkDisabled from 'features/layout/useLogoLinkDisabled'
import useGnbHide from 'features/layout/useGnbHide'

const InternalServerError = () => {
  useLogoLinkDisabled()
  useGnbHide()
  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        알림 본문
      </h2>
      <div className="error_wrap">
        <strong className="tit_error">페이지 오류 안내</strong>
        <p className="txt_error">
          일시적인 오류로 인해 서비스 연결이 되지 않습니다.
          <br />
          다시 시도해 주세요.
        </p>
        <div className="page_btn">
          <div className="inner_btn">
            <a href="/adaccount/all" className="btn_gb gb_bl">
              <span className="inner_g">Kakao 키워드 광고홈으로 이동</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default InternalServerError
