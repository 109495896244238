import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useOutsideClick from '../../utils/hooks/useOutsideClick'
import NoneAnchor from '../NoneAnchor'

const Options = ({ options, selected, onClick }) =>
  options.map(option => (
    <li
      key={option.value || 'default'}
      className={option.value === selected ? 'on' : ''}>
      <NoneAnchor
        className="link_option"
        onClick={event => onClick(event, option)}>
        {option.text || option.value}
      </NoneAnchor>
    </li>
  ))

const Select = ({
  options,
  selected = null,
  onChange,
  active = true,
  addClassNames,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [value, setValue] = useState(
    () => options.find(option => option.value === selected) || options[0]
  )

  const close = () => {
    setOpen(false)
  }

  const handleSelectClick = event => {
    event.preventDefault()
    setOpen(!isOpen)
  }

  const handleOptionClick = (event, option) => {
    event.preventDefault()
    if (value.value !== option.value) {
      setValue(option)
      if (onChange) {
        onChange(option.value)
      }
    }
    close()
  }

  const wrapperRef = useOutsideClick(close)

  return (
    <div
      ref={wrapperRef}
      className={`${addClassNames} opt_select${isOpen ? ' opt_open' : ''}${
        active ? '' : ' in_active'
      }`}>
      <div className="screen_out">선택상자</div>
      <span className="screen_out">선택내용 : </span>
      <NoneAnchor className="link_selected" onClick={handleSelectClick}>
        {value && (value.text || value.value)}
      </NoneAnchor>
      <span className="ico_arr" />
      <div className="screen_out">선택옵션</div>
      <div className="opt_list">
        <ul className="list_opt">
          <Options
            options={options}
            selected={selected}
            onClick={handleOptionClick}
          />
        </ul>
      </div>
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.any,
      text: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  active: PropTypes.bool,
  addClassNames: PropTypes.string,
}

export default Select
