import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DoubleMonthLayer from 'components/calendar/DoubleMonthLayer'
import Icon from 'components/Icon'
import moment from 'moment'
import {
  CalendarContext,
  DateRangePickerContext,
} from 'components/calendar/CalendarContext'
import {
  CALENDAR_PRESETS,
  fmt,
  getDisplayText,
  getDuration,
  getVisibleMonths,
} from 'components/calendar/calendarUtil'
import NoneAnchor from 'components/NoneAnchor'
import useOutsideClick from '../../utils/hooks/useOutsideClick'

const DateRangePicker = ({
  type = 'basic',
  start: startString,
  end: endString,
  preset: presetValue = CALENDAR_PRESETS.CUSTOM,
  min: minString,
  max: maxString,
  onChange,
  presetExcludes = [],
  presetTooltip,
}) => {
  const [active, setActive] = useState(false)
  const [start, setStart] = useState(moment(startString))
  const [end, setEnd] = useState(moment(endString))
  const { left, right } = getVisibleMonths(moment(endString))
  const [leftMonth, setLeftMonth] = useState(left)
  const [rightMonth, setRightMonth] = useState(right)
  const min = minString ? moment(minString) : null
  const max = maxString ? moment(maxString) : null
  const movedRef = useRef(false)
  const durationRef = useRef(
    getDuration(presetValue, moment(startString), moment(endString))
  )
  const [title, setTitle] = useState(
    getDisplayText(
      presetValue,
      startString,
      endString,
      moment(minString),
      moment(maxString),
      durationRef.current
    )
  )
  const [preset, setPreset] = useState(presetValue)
  useEffect(() => {
    setPreset(presetValue)
    setStart(moment(startString))
    setEnd(moment(endString))
    if (movedRef.current === false) {
      durationRef.current = getDuration(
        presetValue,
        moment(startString),
        moment(endString)
      )
    }
    setTitle(
      getDisplayText(
        presetValue,
        startString,
        endString,
        moment(minString),
        moment(maxString),
        durationRef.current
      )
    )
    const visibleMonths = getVisibleMonths(moment(endString))
    setLeftMonth(visibleMonths.left)
    setRightMonth(visibleMonths.right)
  }, [startString, endString, presetValue, minString, maxString])
  const reset = () => {
    setPreset(presetValue)
    setStart(moment(startString))
    setEnd(moment(endString))
  }
  const close = () => {
    setActive(false)
  }
  const toggle = () => {
    setActive(state => !state)
  }
  const handlePrev = () => {
    const { unit } = presetValue
    let prevStart
    let prevEnd
    if (unit === 'weeks' || unit === 'months') {
      prevStart = min
        ? moment.max(
            start.clone().subtract(durationRef.current, unit).startOf(unit),
            min.clone()
          )
        : start.clone().subtract(durationRef.current, unit).startOf(unit)
      prevEnd = min
        ? moment.max(
            start.clone().subtract(durationRef.current, unit).endOf(unit),
            min.clone()
          )
        : start.clone().subtract(durationRef.current, unit).endOf(unit)
    } else {
      prevEnd = min
        ? moment.max(
            end.clone().subtract(durationRef.current, unit),
            min.clone()
          )
        : end.clone().subtract(durationRef.current, unit)
      prevStart = min
        ? moment.max(
            prevEnd.clone().subtract(durationRef.current - 1, unit),
            min.clone()
          )
        : prevEnd.clone().subtract(durationRef.current - 1, unit)
    }
    movedRef.current = true
    onChange(prevStart.format(fmt), prevEnd.format(fmt), presetValue)
  }
  const handleNext = () => {
    const { unit } = presetValue
    let nextStart
    let nextEnd
    if (unit === 'weeks' || unit === 'months') {
      nextStart = max
        ? moment.min(
            start.clone().add(durationRef.current, unit).startOf(unit),
            max.clone()
          )
        : start.clone().add(durationRef.current, unit).startOf(unit)
      nextEnd = max
        ? moment.min(
            start.clone().add(durationRef.current, unit).endOf(unit),
            max.clone()
          )
        : start.clone().add(durationRef.current, unit).endOf(unit)
    } else {
      nextStart = max
        ? moment.min(start.clone().add(durationRef.current, unit), max.clone())
        : start.clone().add(durationRef.current, unit)
      nextEnd = max
        ? moment.min(
            nextStart.clone().add(durationRef.current - 1, unit),
            max.clone()
          )
        : nextStart.clone().add(durationRef.current - 1, unit)
    }
    movedRef.current = true
    onChange(nextStart.format(fmt), nextEnd.format(fmt), presetValue)
  }
  const handleCancel = () => {
    close()
    reset()
  }
  const ref = useOutsideClick(handleCancel)
  return (
    <CalendarContext.Provider
      value={{
        min,
        max,
        close,
        reset,
        onChange,
      }}>
      <DateRangePickerContext.Provider
        value={{
          start,
          setStart,
          end,
          setEnd,
          leftMonth,
          setLeftMonth,
          rightMonth,
          setRightMonth,
          preset,
          setPreset,
          presetExcludes,
          movedRef,
          durationRef,
          presetTooltip,
        }}>
        <div
          ref={ref}
          className={classNames('box_calendar', {
            on: active,
          })}>
          {type === 'basic' && <span className="txt_period">{title}</span>}
          <div
            className={classNames('btn_gm gm_calendar freeset_calendar', {
              open: active,
            })}>
            <NoneAnchor className="link_calendar" onClick={toggle}>
              {type === 'basic' ? `${startString} ~ ${endString}` : title}
              <Icon name="calendar" />
            </NoneAnchor>
            {active && <DoubleMonthLayer />}
          </div>
          {type === 'basic' && (
            <div className="paging_wrap">
              <div className="inner_paging">
                {active || (min && min.isSame(moment(startString), 'days')) ? (
                  <span className="num_paging in_active">
                    <span className="ico_comm ico_prev">이전</span>
                  </span>
                ) : (
                  <NoneAnchor className="num_paging" onClick={handlePrev}>
                    <span className="ico_comm ico_prev">이전</span>
                  </NoneAnchor>
                )}
                {active || (max && max.isSame(moment(endString), 'days')) ? (
                  <span className="num_paging in_active">
                    <span className="ico_comm ico_next">다음</span>
                  </span>
                ) : (
                  <NoneAnchor className="num_paging" onClick={handleNext}>
                    <span className="ico_comm ico_next">다음</span>
                  </NoneAnchor>
                )}
              </div>
            </div>
          )}
        </div>
      </DateRangePickerContext.Provider>
    </CalendarContext.Provider>
  )
}

DateRangePicker.propTypes = {
  type: PropTypes.oneOf(['basic', 'simple']),
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  preset: PropTypes.shape({
    text: PropTypes.string,
    duration: PropTypes.number,
    unit: PropTypes.string,
  }).isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func,
  presetExcludes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      duration: PropTypes.number,
      unit: PropTypes.string,
    })
  ),
  presetTooltip: PropTypes.shape({
    preset: PropTypes.shape({
      text: PropTypes.string,
      duration: PropTypes.number,
      unit: PropTypes.string,
    }),
    text: PropTypes.string,
  }),
}

export default DateRangePicker
