import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const useOutsideClick = onOutsideClick => {
  const wrapperRef = useRef(null)

  const handleOutsideClick = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (onOutsideClick) {
        onOutsideClick(event)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  return wrapperRef
}

useOutsideClick.propType = {
  handleOutsideClick: PropTypes.func,
}

export default useOutsideClick
