import React, { useState } from 'react'
import Modal from '../../../components/dialog/Modal'
import { getPaymentInfo, postPaymentOrder } from 'api/billingAPI'
import { hideModal } from 'features/dialog/dialogSlice'
import { useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import { convertToCommaSeparatedString } from 'features/settings/cash/CashCommons'
import { fetchCashList, fetchWallet } from 'features/settings/cash/cashSlice'
import { fetchAdAccountWithoutStatus } from 'features/adAccount/adAccountSlice'
import moment from 'moment'
import PropTypes from 'prop-types'
import { fail, success } from 'utils/toastUtils'

const ModalCharge = ({ dspAccount, adAccount, wallet }) => {
  const dispatch = useDispatch()

  const handleChangeRadio = e => {
    setErrorChargeAmount('')
    const { value } = e.target
    setChargeAmount(value)
    if (value === '0') {
      setChargeType('CUSTOM')
    } else {
      setChargeType('SELECT')
    }
  }
  const resetChargeAmount = () => {
    setChargeType('SELECT')
    setChargeAmount('10000')
    setChargeCustomAmount('0')
    setErrorChargeAmount('')
  }
  const [chargeType, setChargeType] = useState('SELECT')
  const [chargeAmount, setChargeAmount] = useState('10000')
  const [chargeCustomAmount, setChargeCustomAmount] = useState('0')
  const [errorChargeAmount, setErrorChargeAmount] = useState('')
  const handlePaymentOrder = async () => {
    setErrorChargeAmount('')
    if (
      chargeType === 'CUSTOM' &&
      (chargeCustomAmount - 0 < 1000 || chargeCustomAmount - 0 > 1000000000)
    ) {
      setErrorChargeAmount(
        '충전은 1,000원 이상 10억원 이하의 숫자로만 입력 가능합니다.'
      )
      return false
    }
    try {
      const { data } = await postPaymentOrder(adAccount.id, {
        adPlatformType: 'KEYWORDAD',
        dspId: 'KEYWORDAD',
        memberType: 'DSP_ACCOUNT',
        memberId: dspAccount.id,
        businessType: adAccount.adAccountType,
        amount: chargeType !== 'CUSTOM' ? chargeAmount : chargeCustomAmount,
        redirectUrl: [
          window.location.protocol,
          '//',
          window.location.host,
          '/popup.html',
        ].join(''),
        popUpRedirectUrl: true,
      })
      if (data.kakaoBillingTxId) {
        window.open(
          data.paymentUrl,
          'charge',
          'width=520,height=701,top=0,left=0,menubar=no,status=no,toolbar=no'
        )
        window.anonymousCallback = async () => {
          try {
            const payInfo = await getPaymentInfo(data.kakaoBillingTxId)
            if (payInfo.data.chargeStatus === 'SUCCESS') {
              success(
                `${adAccount.name}계정에 ${convertToCommaSeparatedString(
                  payInfo.data.amount
                )}원 충전되었습니다.`
              )
              dispatch(fetchWallet(adAccount.id))
              dispatch(fetchAdAccountWithoutStatus(adAccount.id))
              dispatch(
                fetchCashList({
                  adAccountId: adAccount.id,
                  from: moment(moment().subtract(1, 'month')).format(
                    'YYYY-MM-DD'
                  ),
                  to: moment(moment()).format('YYYY-MM-DD'),
                  type: '',
                  page: 0,
                })
              )
            }
            dispatch(hideModal('modalCharge'))
            resetChargeAmount()
          } catch (error) {
            const message = error?.response?.data?.message
            fail(message)
            dispatch(hideModal('modalCharge'))
            resetChargeAmount()
          }
        }
      }
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal('modalCharge'))
      resetChargeAmount()
      fail(message)
    }
  }
  return (
    <>
      <Modal
        id="modalCharge"
        className="charge_layer"
        title="충전"
        okText="확인"
        keepModal={true}
        disabledOk={
          chargeType === 'CUSTOM' &&
          (chargeCustomAmount.length === 0 || chargeCustomAmount - 0 < 1)
        }
        ok={handlePaymentOrder}
        cancel={resetChargeAmount}
        close={resetChargeAmount}>
        <table className="tbl_layer">
          <caption className="hide_caption">충전금액 선택 표</caption>
          <colgroup>
            <col style={{ width: 161 }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">캐시 잔액</th>
              <td>
                <div className="inner_cell">
                  <span className="cash_balance">
                    <NumberFormat
                      value={
                        (wallet && wallet.cash) + (wallet && wallet.freeCash)
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      defaultValue={0}
                    />
                    원
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">충전금액</th>
              <td>
                <div className="inner_cell">
                  <ul className="list_selinp">
                    <li>
                      <span className="box_radioinp">
                        <input
                          type="radio"
                          name="chargeCash"
                          id="chargeCash1"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={
                            chargeType === 'SELECT' && chargeAmount === '10000'
                          }
                          value={10000}
                        />
                        <label htmlFor="chargeCash1" className="lab_radio">
                          <span className="img_comm ico_radio" />
                          10,000원
                        </label>
                      </span>
                    </li>
                    <li>
                      <span className="box_radioinp">
                        <input
                          type="radio"
                          name="chargeCash"
                          id="chargeCash2"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={
                            chargeType === 'SELECT' && chargeAmount === '50000'
                          }
                          value={50000}
                        />
                        <label htmlFor="chargeCash2" className="lab_radio">
                          <span className="img_comm ico_radio" />
                          50,000원
                        </label>
                      </span>
                    </li>
                    <li>
                      <span className="box_radioinp">
                        <input
                          type="radio"
                          name="chargeCash"
                          id="chargeCash3"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={
                            chargeType === 'SELECT' && chargeAmount === '100000'
                          }
                          value={100000}
                        />
                        <label htmlFor="chargeCash3" className="lab_radio">
                          <span className="img_comm ico_radio" />
                          100,000원
                        </label>
                      </span>
                    </li>
                    <li>
                      <span className="box_radioinp">
                        <input
                          type="radio"
                          name="chargeCash"
                          id="chargeCash4"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={
                            chargeType === 'SELECT' && chargeAmount === '200000'
                          }
                          value={200000}
                        />
                        <label htmlFor="chargeCash4" className="lab_radio">
                          <span className="img_comm ico_radio" />
                          200,000원
                        </label>
                      </span>
                    </li>
                    <li className="item_cash">
                      <label className="radio_inp">
                        <input
                          type="radio"
                          name="chargeCash"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={chargeType === 'CUSTOM'}
                          value={'0'}
                        />
                        <span className="img_comm ico_radio" />
                      </label>
                      <span
                        className={cx('box_inptxt inp_num', {
                          in_active: chargeType !== 'CUSTOM',
                          in_error: errorChargeAmount,
                        })}>
                        <span className="num_byte">원</span>
                        <span className="inner_inp">
                          <label htmlFor="inpTxt6" className="screen_out">
                            충전 금액 입력
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            className="inp_txt"
                            allowNegative={false}
                            decimalSeparator={false}
                            disabled={chargeType !== 'CUSTOM'}
                            inputMode="numeric"
                            defaultValue={0}
                            onValueChange={val => {
                              setChargeCustomAmount(val.value)
                            }}
                          />
                        </span>
                      </span>
                      {errorChargeAmount && (
                        <p className="txt_error">{errorChargeAmount}</p>
                      )}
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <em className="screen_out">주의사항</em>
        <ul className="list_append">
          <li>
            타인의 광고비를 대신 결제하는 경우, 금전적 피해가 발생할 수 있으니
            주의하시기 바랍니다.
          </li>
          <li>고객센터: 1599-5533</li>
        </ul>
      </Modal>
    </>
  )
}
ModalCharge.propTypes = {
  dspAccount: PropTypes.object,
  adAccount: PropTypes.object,
  wallet: PropTypes.object,
}
export default ModalCharge
