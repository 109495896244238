import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getBondList,
  getCashList,
  getCashStatList,
  getCashStatListCsv,
  getFreeCashList,
  getWallet,
  getWithdrawAccount,
  getWithdrawList,
} from 'api/billingAPI'
import { getDeferredPaymentChangeLogList } from 'api/accountAPI'

const name = 'cash'
export const fetchWallet = createAsyncThunk(
  `${name}/fetchWallet`,
  async (adAccountId, { rejectWithValue }) => {
    try {
      const { data } = await getWallet(adAccountId)
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchWithdrawAccount = createAsyncThunk(
  `${name}/fetchWithdrawAccount`,
  async (adAccountId, { rejectWithValue }) => {
    try {
      const { data } = await getWithdrawAccount(adAccountId)
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchCashStatList = createAsyncThunk(
  `${name}/fetchCashStatList`,
  async ({ adAccountId, from, to, page }, { rejectWithValue }) => {
    try {
      const { data } = await getCashStatList({
        adAccountId,
        from,
        to,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const downloadCashStatListCsv = createAsyncThunk(
  'cashReceipt/downloadCashStatListCsv',
  async ({ adAccountId, from, to }, { rejectWithValue }) => {
    try {
      const { data } = await getCashStatListCsv({
        adAccountId,
        from,
        to,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status } = response
      const { errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchCashList = createAsyncThunk(
  `${name}/fetchCashList`,
  async ({ adAccountId, from, to, type, page }, { rejectWithValue }) => {
    try {
      const { data } = await getCashList({
        adAccountId,
        from,
        to,
        type,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchFreeCashList = createAsyncThunk(
  `${name}/fetchFreeCashList`,
  async ({ adAccountId, from, to, status, page }, { rejectWithValue }) => {
    try {
      const { data } = await getFreeCashList({
        adAccountId,
        from,
        to,
        status,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { s, errorCode, message } = response.data
      return rejectWithValue({
        s,
        errorCode,
        message,
      })
    }
  }
)

export const fetchWithdrawList = createAsyncThunk(
  `${name}/fetchWithdrawList`,
  async ({ adAccountId, from, to, statuses, page }, { rejectWithValue }) => {
    try {
      const { data } = await getWithdrawList({
        adAccountId,
        from,
        to,
        statuses,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchBondList = createAsyncThunk(
  `${name}/fetchBondList`,
  async ({ adAccountId, repaymentStatus, page }, { rejectWithValue }) => {
    try {
      const { data } = await getBondList({
        adAccountId,
        repaymentStatus,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchDeferredPaymentChangeLogList = createAsyncThunk(
  `${name}/fetchDeferredPaymentChangeLogList`,
  async ({ adAccountId, from, to, page }, { rejectWithValue }) => {
    try {
      const { data } = await getDeferredPaymentChangeLogList({
        adAccountId,
        from,
        to,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

const cashSlice = createSlice({
  name,

  initialState: {
    wallet: {
      info: null,
      isLoading: false,
    },
    refundAccount: {
      info: null,
      isLoading: false,
    },
    cashStatList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    cashList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    freeCashList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    withdrawList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    bondList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    deferredPaymentChangeLogList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
  },

  reducers: {
    setDisabled: (state, { payload }) => {
      state.disabled = payload
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchWallet.pending, state => {
      state.wallet.isLoading = true
    })

    builder.addCase(fetchWallet.fulfilled, (state, { payload }) => {
      state.wallet.isLoading = false
      state.wallet.info = payload
      state.wallet.info.balance = Number(
        (payload.cash + payload.freeCash).toFixed(3)
      )
    })

    builder.addCase(fetchWallet.rejected, state => {
      state.wallet.isLoading = false
    })

    builder.addCase(fetchWithdrawAccount.pending, state => {
      state.refundAccount.isLoading = true
    })

    builder.addCase(fetchWithdrawAccount.fulfilled, (state, { payload }) => {
      state.refundAccount.isLoading = false
      state.refundAccount.info = payload
    })

    builder.addCase(fetchWithdrawAccount.rejected, (state, err) => {
      state.refundAccount.isLoading = false
      if (
        err.payload &&
        err.payload.status === 404 &&
        err.payload &&
        err.payload.errorCode === 4001
      ) {
        state.refundAccount.info = null
      }
    })

    builder.addCase(fetchCashStatList.pending, state => {
      state.cashStatList.isLoading = true
    })

    builder.addCase(fetchCashStatList.fulfilled, (state, { payload }) => {
      state.cashStatList.isLoading = false
      state.cashStatList.pagable = payload
    })

    builder.addCase(fetchCashStatList.rejected, state => {
      state.cashStatList.isLoading = false
    })

    builder.addCase(fetchCashList.pending, state => {
      state.cashList.isLoading = true
    })

    builder.addCase(fetchCashList.fulfilled, (state, { payload }) => {
      state.cashList.isLoading = false
      state.cashList.pagable = payload
    })

    builder.addCase(fetchCashList.rejected, state => {
      state.cashList.isLoading = false
    })

    builder.addCase(fetchFreeCashList.pending, state => {
      state.freeCashList.isLoading = true
    })

    builder.addCase(fetchFreeCashList.fulfilled, (state, { payload }) => {
      state.freeCashList.isLoading = false
      state.freeCashList.pagable = payload
    })

    builder.addCase(fetchFreeCashList.rejected, state => {
      state.freeCashList.isLoading = false
    })

    builder.addCase(fetchWithdrawList.pending, state => {
      state.withdrawList.isLoading = true
    })

    builder.addCase(fetchWithdrawList.fulfilled, (state, { payload }) => {
      state.withdrawList.isLoading = false
      state.withdrawList.pagable = payload
    })

    builder.addCase(fetchWithdrawList.rejected, state => {
      state.withdrawList.isLoading = false
    })

    builder.addCase(fetchBondList.pending, state => {
      state.bondList.isLoading = true
    })

    builder.addCase(fetchBondList.fulfilled, (state, { payload }) => {
      state.bondList.isLoading = false
      state.bondList.pagable = payload
    })

    builder.addCase(fetchBondList.rejected, state => {
      state.bondList.isLoading = false
    })

    builder.addCase(fetchDeferredPaymentChangeLogList.pending, state => {
      state.deferredPaymentChangeLogList.isLoading = true
    })

    builder.addCase(
      fetchDeferredPaymentChangeLogList.fulfilled,
      (state, { payload }) => {
        state.deferredPaymentChangeLogList.isLoading = false
        state.deferredPaymentChangeLogList.pagable = payload
      }
    )

    builder.addCase(fetchDeferredPaymentChangeLogList.rejected, state => {
      state.deferredPaymentChangeLogList.isLoading = false
    })
  },
})
const { reducer } = cashSlice
export default reducer
