import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getBizRightCompleted,
  getBizRightRequested,
  putBizRightTransferable,
} from 'api/accountAPI'

const name = 'businessRight'
export const fetchBizRightCompleted = createAsyncThunk(
  `${name}/fetchBizRightCompleted`,
  async (adaccountId, { rejectWithValue }) => {
    try {
      const { data } = await getBizRightCompleted(adaccountId)
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)
export const fetchBizRightRequested = createAsyncThunk(
  `${name}/fetchBizRightRequested`,
  async (adaccountId, { rejectWithValue }) => {
    try {
      const { data } = await getBizRightRequested(adaccountId)
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const changeBizRightTransferable = createAsyncThunk(
  `${name}/changeBizRightTransferable`,
  async ({ adAccountId, transferable }) => {
    const { data } = await putBizRightTransferable(adAccountId, transferable)
    return data
  }
)

const businessRightSlice = createSlice({
  name,

  initialState: {
    dashboard: {
      isLoading: false,
      businessRight: null,
    },
    completed: null,
    completedLoading: false,
    requested: null,
    requestedLoading: false,
    transferBizRight: null,
  },

  reducers: {
    setTransferBizRight: (state, { payload }) => {
      state.completed = payload
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchBizRightCompleted.pending, state => {
      state.completedLoading = true
      state.dashboard.isLoading = true
    })

    builder.addCase(fetchBizRightCompleted.fulfilled, (state, { payload }) => {
      state.completedLoading = false
      state.dashboard.isLoading = false
      state.completed = payload
      state.dashboard.businessRight = payload
    })

    builder.addCase(fetchBizRightCompleted.rejected, (state, err) => {
      state.completedLoading = false
      state.dashboard.isLoading = false
      if (
        err.payload &&
        err.payload.status === 404 &&
        err.payload &&
        err.payload.errorCode === 7001
      ) {
        state.completed = null
        state.dashboard.businessRight = null
      }
    })

    builder.addCase(fetchBizRightRequested.pending, state => {
      state.requestedLoading = true
    })

    builder.addCase(fetchBizRightRequested.fulfilled, (state, { payload }) => {
      state.requestedLoading = false
      state.requested = payload
    })

    builder.addCase(fetchBizRightRequested.rejected, (state, err) => {
      state.requestedLoading = false
      if (
        err.payload &&
        err.payload.status === 404 &&
        err.payload &&
        err.payload.errorCode === 7001
      ) {
        state.requested = null
      }
    })
  },
})
const { actions, reducer } = businessRightSlice
export const { setTransferBizRight } = actions
export default reducer
