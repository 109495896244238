import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNoticeBoardView } from 'features/board/boardSlice'
import { NOTICE_TYPE_TEXT } from 'features/board/BoardConstants'
import moment from 'moment'
import useScrollTop from '../layout/useScrollTop'

const NoticeBoardView = () => {
  useScrollTop()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { view } = useSelector(state => state.board)
  useEffect(() => {
    ;(async () => {
      await dispatch(fetchNoticeBoardView(id))
    })()
  }, [dispatch, id])

  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        공지사항 본문
      </h2>
      <div className="noticedetail_wrap">
        <div className="dsp_tit">
          <div className="tit_dsp">
            <h3 className="tit_subject">공지사항</h3>
            <div className="f_right">
              <div className="single_wrap">
                <Link to={`/noticeboard`} className="btn_gm gm_line">
                  <span className="inner_g">목록</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="ad_managebox">
          {view ? (
            <>
              <div className="managebox_tit">
                <h4 className="tit_box">{view.title}</h4>
                <div className="tit_cate">
                  <span className="txt_info">
                    {NOTICE_TYPE_TEXT[view.noticeType]}
                  </span>
                  <span className="txt_info">
                    {moment(view.lastModifiedDate).format('YYYY-MM-DD')}
                  </span>
                </div>
              </div>
              <div
                className="notice_view"
                dangerouslySetInnerHTML={{ __html: view && view.body }}
              />
            </>
          ) : (
            <>
              <div className="managebox_tit">
                <h4 className="tit_box">해당 공지사항이 존재하지 않습니다.</h4>
              </div>
              <div className="notice_view">
                해당 공지사항이 존재하지 않습니다.
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default NoticeBoardView
