import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAdAccounts,
  reset,
  setPage,
  setSearch,
} from './autoPayCardAccountSlice'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '../../../components/Icon'
import AdAccountLayer from './AdAccountLayer'

const AdAccountFinder = ({ walletId, onSelect, onSubmit }) => {
  const dispatch = useDispatch()
  const { search, isLoading, page } = useSelector(
    state => state.autoPayCardAccount
  )
  const [input, setInput] = useState(search)
  const [focused, setFocused] = useState(false)
  const [layerVisible, setLayerVisible] = useState(false)
  const formRef = useRef(undefined)
  const handleSelect = ({ id, name }) => {
    setInput(name)
    onSelect(id)
    setLayerVisible(false)
  }
  const handleClose = event => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setLayerVisible(false)
    }
  }
  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      if (!isLoading) {
        if (input === '') {
          onSelect(undefined)
        }
        if (input !== search) {
          dispatch(setSearch(input))
        } else {
          dispatch(fetchAdAccounts())
        }
        onSubmit()
      }
    },
    [dispatch, input, isLoading, onSelect, onSubmit, search]
  )

  useEffect(() => {
    dispatch(fetchAdAccounts())
  }, [dispatch, search, page])
  useEffect(() => () => dispatch(reset()), [dispatch])

  return (
    <div className="search_account2">
      <div
        className={classNames('box_inptxt search_inp', {
          on: focused,
          in_active: isLoading,
        })}>
        <span className="inner_inp">
          <form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
            <button type="submit" className="btn_search">
              <Icon name="search">검색</Icon>
            </button>
            <input
              type="text"
              className="inp_txt"
              placeholder="광고계정 이름을 입력하세요."
              onFocus={() => {
                if (page > 0) dispatch(setPage(0))
                setFocused(true)
                setLayerVisible(true)
              }}
              onBlur={() => setFocused(false)}
              disabled={isLoading}
              value={input}
              onChange={({ target: { value } }) => setInput(value)}
            />
            {input !== '' && (
              <button
                type="button"
                className="btn_del"
                onClick={() => setInput('')}
                style={{ ...(input !== '' && { display: 'block' }) }}>
                <Icon name="del" />
              </button>
            )}
          </form>
        </span>
      </div>
      {layerVisible && (
        <AdAccountLayer
          walletId={walletId}
          onSelect={handleSelect}
          onClose={handleClose}
        />
      )}
    </div>
  )
}
AdAccountFinder.propTypes = {
  walletId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
export default AdAccountFinder
