import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MicroAppPage, CruxEventBus } from '@kakao/mfa-container'

const CruxAdAccount = () => {
  const history = useHistory()
  const microAppManifestUrl = process.env.REACT_APP_CRUX_ACCOUNT_MICRO_HOST
  const loginToken = sessionStorage.getItem('loginToken')
  const accessToken = sessionStorage.getItem('accessToken')

  useEffect(() => {
    const unSubscribe = CruxEventBus.getInstance().subscribe(
      'bizAdaccountApp::list::clickAdAccount',
      ({ data }) => {
        history.push(`/${data}/dashboard`)
      }
    )

    const dashboardUnSubscribe = CruxEventBus.getInstance().subscribe(
      'bizAdaccountApp::adAccountCreationComplete::clickGotoDashboard',
      ({ data }) => {
        history.push(`/${data}/dashboard`)
      }
    )
    const pixelLinkageUnSubscribe = CruxEventBus.getInstance().subscribe(
      'bizAdaccountApp::adAccountCreationComplete::clickLinkagePixel',
      ({ data }) => {
        history.push(`/${data}/tracker`)
      }
    )
    const reviewUnSubscribe = CruxEventBus.getInstance().subscribe(
      'bizAdaccountApp::adAccountCreationComplete::clickReviewRegist',
      ({ data }) => {
        history.push(`/${data}/settings/review`)
      }
    )
    const createAdUnSubscribe = CruxEventBus.getInstance().subscribe(
      'bizAdaccountApp::adAccountCreationComplete::clickCreateAd',
      ({ data }) => {
        history.push(`/${data}/ads/campaigns/new?from=campaign`)
      }
    )
    const clickListUnSubscribe = CruxEventBus.getInstance().subscribe(
      'bizAdaccountApp::create::clickList',
      () => {
        window.location.href = `${window.location.origin}/adaccount/all`
      }
    )
    /**
     * 광고 계정 화면은 배경색이 흰색이라 변경이 필요함.
     * 기존 css 속성에 따라 html, body의 스타일을 변경하는 것은 불가피함.
     * 다시, 스타일을 초기화 하는것은 empty로 넣는것이 일반적임.
     */
    const htmlElement = document.documentElement.style
    const bodyElement = document.body.style
    htmlElement.backgroundColor = '#FFFFFF'
    bodyElement.backgroundColor = '#FFFFFF'
    return () => {
      htmlElement.backgroundColor = ''
      bodyElement.backgroundColor = ''
      unSubscribe()
      dashboardUnSubscribe()
      pixelLinkageUnSubscribe()
      reviewUnSubscribe()
      createAdUnSubscribe()
      clickListUnSubscribe()
    }
  })
  return (
    <MicroAppPage
      microAppName="bizAdaccountApp"
      microAppManifestUrl={microAppManifestUrl}
      manifestName="asset-manifest.json"
      basePath="/adaccount/all"
      adPlatformType="KEYWORDAD"
      loginToken={loginToken}
      accessToken={accessToken}
    />
  )
}
export default CruxAdAccount
