import React from 'react'
import PropTypes from 'prop-types'
import PositionTooltip from 'components/tooltip/PositionTooltip'
import NoneAnchor from 'components/NoneAnchor'

/*
 * 도움말 버튼에 노출되는 툴팁
 */
const HelpTooltip = ({ children, position = 'top alignLeft' }) =>
  !children ? (
    ''
  ) : (
    <PositionTooltip content={children} position={position}>
      <NoneAnchor className="link_g link_help">
        <span className="ico_comm ico_help">도움말</span>
      </NoneAnchor>
    </PositionTooltip>
  )

HelpTooltip.propTypes = {
  position: PositionTooltip.propTypes.position,
  children: PropTypes.node,
}

export default HelpTooltip
