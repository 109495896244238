import React, { useEffect, useState } from 'react'
import NoneAnchor from 'components/NoneAnchor'

const { REACT_APP_POLICY_HOST, REACT_APP_BUSINESS_HOST } = process.env
const scrollTop = event => {
  event.preventDefault()
  window.scrollTo({ top: 0 })
}

const AppFooter = () => {
  const [scrollVisible, setScrollVisible] = useState(false)
  useEffect(() => {
    const onScroll = () => setScrollVisible(window.scrollY > 0)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <footer id="kakaoFoot" className="kakao_foot">
      <div className="inner_footer">
        <h2 className="screen_out">서비스 이용정보</h2>
        <a
          href="https://www.kakaocorp.com/"
          className="link_copyright"
          target="_blank"
          rel="noopener noreferrer">
          © <strong>Kakao Corp.</strong>
        </a>
        <strong className="screen_out">광고 약관 및 정책</strong>
        <ul className="list_policy">
          <li>
            <a
              href={`${REACT_APP_POLICY_HOST}/SERVICE/`}
              target="_blank"
              rel="noopener noreferrer"
              className="link_policy">
              카카오비즈니스 이용약관
            </a>
          </li>
          <li>
            <a
              href={`${REACT_APP_POLICY_HOST}/OPERATIONPOLICY/`}
              target="_blank"
              rel="noopener noreferrer"
              className="link_policy">
              광고 운영정책
            </a>
          </li>
          <li>
            <a
              href={`${REACT_APP_BUSINESS_HOST}/policy/privacy/`}
              target="_blank"
              rel="noopener noreferrer"
              className="link_policy">
              <em className="fw_bold">개인정보처리방침</em>
            </a>
          </li>
        </ul>
      </div>
      {scrollVisible && (
        <NoneAnchor className="btn_top" onClick={scrollTop}>
          <span className="ico_comm ico_top">Top</span>
        </NoneAnchor>
      )}
    </footer>
  )
}
export default AppFooter
