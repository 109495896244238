import React, { useEffect, useState } from 'react'
import Modal from 'components/dialog/Modal'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { fetchDelegationHistory } from 'features/settings/adaccount/taxSlice'
import Pagination from 'components/pagination/Pagination'
import PropTypes from 'prop-types'

const ModalDelegationHistory = ({ adAccount }) => {
  const dispatch = useDispatch()
  const { isLoading, pagable } = useSelector(
    state => state.tax.delegationHistory
  )
  const [delegationHistoryPage, setDelegationHistoryPage] = useState(0)
  const handleDelegationHistoryPagePageChange = number => {
    setDelegationHistoryPage(number)
  }
  const reset = () => {
    setDelegationHistoryPage(0)
  }
  useEffect(() => {
    dispatch(
      fetchDelegationHistory({
        adAccountId: adAccount.id,
        page: delegationHistoryPage,
      })
    )
  }, [dispatch, adAccount.id, delegationHistoryPage])
  return (
    <Modal
      id="modalDelegationHistory"
      className="entrusthistory_layer"
      title="위임발행 이력"
      showOk={false}
      showCancel={false}
      close={() => {
        reset()
      }}>
      <p className="subtit_layer">전체 {pagable.totalElements}</p>
      <div className="itemtbl_wrap">
        <div className="itemtbl_head">
          <table className="tbl_itemchoice">
            <caption className="hide_caption">위임발행 이력 표 제목</caption>
            <colgroup>
              <col style={{ width: 195 }} />
              <col style={{ width: 193 }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="inner_cell">발행일시</div>
                </th>
                <th scope="col">
                  <div className="inner_cell">내용</div>
                </th>
                <th scope="col">
                  <div className="inner_cell">사용자</div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="itemtbl_body">
          {isLoading ? (
            <div className="thumb_loading">
              <span className="area_loading">
                <span className="group_loading">
                  <span className="ico_loading ani_loading"></span>
                  <span className="ico_loading ani_loading2"></span>
                  <span className="ico_loading ani_loading3"></span>
                  <span className="screen_out">로딩중</span>
                </span>
              </span>
            </div>
          ) : !(pagable && pagable.content.length > 0) ? (
            <div className="nodata_wrap">
              <p className="inner_nodata">위임발행 이력이 없습니다.</p>
            </div>
          ) : (
            <table className="tbl_itemchoice">
              <caption className="hide_caption">위임발행 이력 표 내용</caption>
              <colgroup>
                <col style={{ width: 195 }} />
                <col style={{ width: 193 }} />
                <col />
              </colgroup>
              <tbody>
                {pagable.content.map(data => {
                  const {
                    id,
                    lastModifiedDate,
                    delegationStatus,
                    systemInfo,
                    transactor,
                  } = data
                  const delegationStatusLabel =
                    delegationStatus === 'REQUESTED'
                      ? '위임발행 신청'
                      : delegationStatus === 'COMPLETED'
                        ? '위임발행 완료'
                        : delegationStatus === 'REJECTED'
                          ? '위임발행 거절'
                          : delegationStatus === 'RELEASED'
                            ? '위임발행 해제'
                            : delegationStatus === 'FAILED'
                              ? '위임발행 실패'
                              : ''
                  const userLabel =
                    systemInfo === null
                      ? transactor.marketer
                        ? transactor.marketer.agencyAccountStatus === 'DORMANCY'
                          ? '휴면마케터'
                          : transactor.marketer.agencyAccountStatus ===
                              'WITHDRAWAL'
                            ? '탈퇴마케터'
                            : `${transactor.marketer.name}(${transactor.marketer.kakaoEmail})`
                        : transactor.status === 'DORMANCY'
                          ? '휴면회원'
                          : transactor.status === 'WITHDRAWAL'
                            ? '탈퇴회원'
                            : `${transactor.name}(${transactor.email})`
                      : systemInfo
                  return (
                    <tr key={`${id}`}>
                      <td>
                        <div className="inner_cell">
                          {moment(lastModifiedDate).format('YYYY.MM.DD HH:mm')}
                        </div>
                      </td>
                      <td>
                        <div className="inner_cell">
                          {delegationStatusLabel}
                        </div>
                      </td>
                      <td>
                        <div className="inner_cell">{userLabel}</div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
        {pagable.totalElements > 0 && (
          <Pagination
            onChange={handleDelegationHistoryPagePageChange}
            totalPages={pagable.totalPages}
            number={pagable.number}
          />
        )}
      </div>
    </Modal>
  )
}
ModalDelegationHistory.propTypes = {
  adAccount: PropTypes.object,
}
export default ModalDelegationHistory
