import React, { useState } from 'react'
import Modal from 'components/dialog/Modal'
import { postWithdraw } from 'api/billingAPI'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import { fetchWithdrawList } from 'features/settings/cash/cashSlice'
import Alert from 'components/dialog/Alert'
import NoneAnchor from '../../../components/NoneAnchor'
import PropTypes from 'prop-types'

const ModalWithdraw = ({ dspAccount, adAccount, searchOptionsWithdraw }) => {
  const dispatch = useDispatch()
  const { info: wallet } = useSelector(state => state.cash.wallet)
  const [allWithdraw, setAllWithdraw] = useState(true)
  const [withdrawCustomAmount, setWithdrawCustomAmount] = useState('0')
  const [errorMessage, setErrorMessage] = useState('')
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false)
  const handleChangeRadio = e => {
    const { value } = e.target
    setAllWithdraw(value === 'true')
  }
  const resetWithdraw = () => {
    setAllWithdraw(true)
    setWithdrawCustomAmount('0')
    setErrorMessage('')
  }
  const handleWithdraw = async () => {
    try {
      if (!allWithdraw && wallet.cash < withdrawCustomAmount - 0) {
        setErrorMessage('요청하신 금액이 유상캐시 잔액보다 많습니다.')
      } else {
        setDisabledConfirmButton(true)
        await postWithdraw(adAccount?.id, {
          adPlatformType: 'KEYWORDAD',
          dspId: 'KEYWORDAD',
          memberType: 'DSP_ACCOUNT',
          memberId: dspAccount?.id,
          allWithdraw,
          amount: withdrawCustomAmount,
          forceWithdraw: false,
          reason: null,
        })
        setDisabledConfirmButton(false)
        dispatch(showModal('completedWithdraw'))
        resetWithdraw()
        await dispatch(
          fetchWithdrawList({
            adAccountId: adAccount?.id,
            from: searchOptionsWithdraw?.from,
            to: searchOptionsWithdraw?.to,
            statuses: '',
            page: 0,
          })
        )
      }
    } catch (error) {
      const message = error?.response?.data?.message
      setErrorMessage(message)
      setDisabledConfirmButton(false)
    }
  }
  return (
    <>
      <Modal
        id="modalWithdraw"
        className="refund_layer"
        title="환불 요청"
        okText="확인"
        keepModal={true}
        disabledOk={
          (!allWithdraw && withdrawCustomAmount.length === 0) ||
          disabledConfirmButton
        }
        ok={handleWithdraw}
        cancel={resetWithdraw}
        close={resetWithdraw}>
        <p className="subtit_layer">
          환불 요청 한 사용자의 최종 결제하신 수단으로 환불됩니다.{' '}
          <NoneAnchor
            className="link_viewmore"
            onClick={() => {
              window.scrollTo({ top: 0 })
              dispatch(showModal('modalWithdrawGuide'))
            }}>
            자세히보기
          </NoneAnchor>
        </p>
        <table className="tbl_layer">
          <caption className="hide_caption">환불 요청 표</caption>
          <colgroup>
            <col style={{ width: 161 }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">환불 신청액</th>
              <td>
                <div className="inner_cell">
                  <ul className="list_selinp">
                    <li>
                      <span className="box_radioinp">
                        <input
                          type="radio"
                          name="refundCash"
                          id="refundCash1"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={allWithdraw}
                          value={true}
                        />
                        <label htmlFor="refundCash1" className="lab_radio">
                          <span className="img_comm ico_radio" />
                          전액 환불
                        </label>
                      </span>
                    </li>
                    <li className="item_cash">
                      <span className="box_radioinp">
                        <input
                          type="radio"
                          name="refundCash"
                          id="refundCash2"
                          className="inp_radio"
                          onChange={handleChangeRadio}
                          checked={!allWithdraw}
                          value={false}
                        />
                        <label htmlFor="refundCash2" className="lab_radio">
                          <span className="img_comm ico_radio" />
                          부분 환불
                        </label>
                      </span>
                      <span
                        className={cx('box_inptxt inp_num', {
                          in_active: allWithdraw,
                        })}>
                        <span className="num_byte">원</span>
                        <span className="inner_inp">
                          <label htmlFor="refundCash3" className="screen_out">
                            환불 금액 입력
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            className="inp_txt"
                            allowNegative={false}
                            decimalSeparator={false}
                            disabled={allWithdraw}
                            inputMode="numeric"
                            defaultValue={0}
                            onValueChange={val => {
                              setWithdrawCustomAmount(val.value)
                            }}
                          />
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {errorMessage && (
          <div className="box_norefund">
            <span className="ico_comm ico_fail">안내</span>
            <strong className="tit_norefund">환불 요청 불가</strong>
            <p className="desc_norefund">{errorMessage}</p>
          </div>
        )}
      </Modal>
      <Alert
        id="completedWithdraw"
        title="환불 요청이 완료되었습니다."
        ok={() => {
          dispatch(hideModal('modalWithdraw'))
        }}
        close={() => {
          dispatch(hideModal('modalWithdraw'))
        }}>
        환불 요청을 한 사용자의 최종 결제 수단으로 환불될 예정이며 요청 완료 후,
        3~5일 내 처리됩니다.
      </Alert>
    </>
  )
}

ModalWithdraw.propTypes = {
  dspAccount: PropTypes.object.isRequired,
  adAccount: PropTypes.object.isRequired,
  searchOptionsWithdraw: PropTypes.object.isRequired,
}

export default ModalWithdraw
