export const BANK_NAME = {
  '02': '산업',
  '03': '기업',
  '04': '국민',
  '07': '수협중앙',
  11: '농협중앙',
  12: '농협(단위조합)',
  20: '우리',
  23: 'SC제일',
  27: '씨티',
  31: '대구',
  32: '부산',
  34: '광주',
  35: '제주',
  37: '전북',
  39: '경남',
  45: '새마을',
  48: '신협',
  71: '우체국',
  81: 'KEB 하나',
  88: '신한',
  89: '케이뱅크',
  90: '카카오뱅크',
  92: '토스뱅크',
  54: 'HSBC',
  209: '유안타증권',
  240: '삼성증권',
}

export const SELECTBOX_BANKNAME = [
  {
    text: '산업',
    value: '02',
  },
  {
    text: '기업',
    value: '03',
  },
  {
    text: '국민',
    value: '04',
  },
  {
    text: '수협중앙',
    value: '07',
  },
  {
    text: '농협중앙',
    value: '11',
  },
  {
    text: '농협(단위조합)',
    value: '12',
  },
  {
    text: '우리',
    value: '20',
  },
  {
    text: 'SC제일',
    value: '23',
  },
  {
    text: '씨티',
    value: '27',
  },
  {
    text: '대구',
    value: '31',
  },
  {
    text: '부산',
    value: '32',
  },
  {
    text: '광주',
    value: '34',
  },
  {
    text: '제주',
    value: '35',
  },
  {
    text: '전북',
    value: '37',
  },
  {
    text: '경남',
    value: '39',
  },
  {
    text: '새마을',
    value: '45',
  },
  {
    text: '신협',
    value: '48',
  },
  {
    text: '우체국',
    value: '71',
  },
  {
    text: 'KEB 하나',
    value: '81',
  },
  {
    text: '신한',
    value: '88',
  },
  {
    text: '케이뱅크',
    value: '89',
  },
  {
    text: '카카오뱅크',
    value: '90',
  },
  {
    text: 'HSBC',
    value: '54',
  },
  {
    text: '유안타증권',
    value: '209',
  },
  {
    text: '삼성증권',
    value: '240',
  },
  {
    text: '토스뱅크',
    value: '92',
  },
]

export const CASH_TYPE = {
  ALL: '전체',
  CHARGE: '충전',
  REFUND: '환급',
  WITHDRAW: '환불',
  REPAYMENT: '후불 결제',
}

export const SELECTBOX_DURATION = [
  {
    text: '최근 1개월',
    value: 1,
  },
  {
    text: '최근 3개월',
    value: 3,
  },
  {
    text: '최근 6개월',
    value: 6,
  },
  {
    text: '최근 12개월',
    value: 12,
  },
]

export const SELECTBOX_CASH_TYPE = [
  {
    text: '전체',
    value: '',
  },
  {
    text: '충전',
    value: 'CHARGE',
  },
  {
    text: '환급',
    value: 'REFUND',
  },
  {
    text: '환불',
    value: 'WITHDRAW',
  },
  {
    text: '후불 결제',
    value: 'REPAYMENT',
  },
]

export const SELECTBOX_FREECASH_STATUS = [
  {
    text: '전체',
    value: '',
  },
  {
    text: '사용전',
    value: 'READY',
  },
  {
    text: '사용중',
    value: 'USING',
  },
  {
    text: '사용완료',
    value: 'USED',
  },
  {
    text: '만료',
    value: 'EXPIRED',
  },
  {
    text: '회수',
    value: 'CANCEL',
  },
]

export const FREE_CASH_STATUS = {
  READY: '사용전',
  USING: '사용중',
  USED: '사용완료',
  EXPIRED: '만료',
  CANCEL: '회수',
}

export const SELECTBOX_WITHDRAW_STATUS = [
  {
    text: '전체',
    value: '',
  },
  {
    text: '처리중',
    value: 'STANDBY,RESERVED',
  },
  {
    text: '처리불가',
    value: 'REJECTED',
  },
  {
    text: '처리완료',
    value: 'APPROVED',
  },
]

export const WITHDRAW_STATUS = {
  STANDBY: '처리중',
  RESERVED: '처리중',
  REJECTED: '처리불가',
  APPROVED: '처리완료',
  ASK_ADMIN: '관리자 문의',
}

export const SELECTBOX_BOND_STATUS = [
  {
    text: '전체',
    value: '',
  },
  {
    text: '미결제',
    value: 'READY',
  },
  {
    text: '일부결제',
    value: 'PARTIAL',
  },
  {
    text: '결제완료',
    value: 'DONE',
  },
  {
    text: '연체',
    value: 'OVERDUE',
  },
]

export const BOND_STATUS = {
  READY: '미결제',
  PARTIAL: '일부 결제',
  DONE: '결제 완료',
  OVERDUE: '연체',
}

export const BOND_CRITERIA = {
  DAYS_30: 30,
  DAYS_60: 60,
  DAYS_90: 90,
  DAYS_120: 120,
}

export const SELECTBOX_TAX_ISSUE_STATUSES = [
  {
    text: '전체',
    value: 'SUCCESS,FAIL',
  },
  {
    text: '발행 완료',
    value: 'SUCCESS',
  },
  {
    text: '발행 실패',
    value: 'FAIL',
  },
]

export const SELECTBOX_PAY_HISTORY_STATUS = [
  {
    text: '전체 결제 상태',
    value: 'SUCCESS,FAIL',
  },
  {
    text: '결제 성공',
    value: 'SUCCESS',
  },
  {
    text: '결제 실패',
    value: 'FAIL',
  },
]
