import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { noop } from 'utils'

const TextInput = forwardRef(
  (
    {
      type = 'text',
      id,
      name,
      value,
      className = 'box_inptxt',
      pattern,
      required = false,
      disabled = false,
      readOnly = false,
      placeholder,
      minLength,
      maxLength,
      onChange = noop,
      onValueChange = noop,
      onFocus = noop,
      onBlur = noop,
      onInput = noop,
      onInvalid = noop,
      validityMessage,
      children,
      error,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleFocus = event => {
      setFocused(true)
      onFocus(event)
    }

    const handleBlur = event => {
      setFocused(false)
      onBlur(event)
    }

    const handleInput = event => {
      if (event.target.validity.valid === true) {
        setErrorMessage('')
      }
      onInput(event)
    }

    const handleInvalid = event => {
      event.preventDefault()
      onInvalid(event)
      const { target } = event
      const { validity, validationMessage } = target
      showError(validity, validationMessage)
    }

    const handleChange = event => {
      onChange(event)
      onValueChange(event.target.value)
    }

    const showError = (validityState, validationMessage) => {
      if (validityState.customError === true) {
        setErrorMessage(
          (validityMessage && validityMessage.customError) || validationMessage
        )
      } else if (validityState.valueMissing === true) {
        setErrorMessage(
          (validityMessage && validityMessage.valueMissing) || validationMessage
        )
      } else if (validityState.patternMismatch === true) {
        setErrorMessage(
          (validityMessage && validityMessage.patternMismatch) ||
            validationMessage
        )
      } else if (validityState.typeMismatch === true) {
        setErrorMessage(
          (validityMessage && validityMessage.typeMismatch) || validationMessage
        )
      } else if (validityState.tooLong === true) {
        setErrorMessage(
          (validityMessage && validityMessage.tooLong) || validationMessage
        )
      } else if (validityState.tooShort === true) {
        setErrorMessage(
          (validityMessage && validityMessage.tooShort) || validationMessage
        )
      } else if (validityState.rangeOverflow === true) {
        setErrorMessage(
          (validityMessage && validityMessage.rangeOverflow) ||
            validationMessage
        )
      } else if (validityState.rangeUnderflow === true) {
        setErrorMessage(
          (validityMessage && validityMessage.rangeUnderflow) ||
            validationMessage
        )
      } else if (validityState.stepMismatch === true) {
        setErrorMessage(
          (validityMessage && validityMessage.stepMismatch) || validationMessage
        )
      }
    }

    return (
      <>
        <span
          className={classNames(className, {
            on: focused,
            in_active: disabled || readOnly,
            in_error: errorMessage || error,
          })}>
          {children}
          <span className="inner_inp">
            <input
              type={type}
              className="inp_txt"
              id={id}
              name={name}
              value={value}
              pattern={pattern}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              minLength={minLength}
              maxLength={maxLength}
              onChange={handleChange}
              onInput={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onInvalid={handleInvalid}
              ref={ref}
            />
          </span>
        </span>
        {errorMessage && <p className="txt_error">{errorMessage}</p>}
      </>
    )
  }
)
TextInput.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'search',
    'email',
    'url',
    'tel',
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  onInvalid: PropTypes.func,
  children: PropTypes.node,
  validityMessage: PropTypes.shape({
    badInput: PropTypes.string,
    customError: PropTypes.string,
    patternMismatch: PropTypes.string,
    rangeOverflow: PropTypes.string,
    rangeUnderflow: PropTypes.string,
    stepMismatch: PropTypes.string,
    tooLong: PropTypes.string,
    tooShort: PropTypes.string,
    typeMismatch: PropTypes.string,
    valueMissing: PropTypes.string,
  }),
  error: PropTypes.any,
}

TextInput.displayName = 'TextInput'

export default TextInput
