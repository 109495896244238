import React, { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from '@reduxjs/toolkit'
import classNames from 'classnames'
import { noop } from 'utils'

const Checkbox = forwardRef(
  (
    {
      id,
      name,
      className = 'box_checkinp',
      children,
      checked,
      required = false,
      disabled = false,
      onChange = noop,
      onCheckedChange = noop,
      ...rest
    },
    ref
  ) => {
    const idRef = useRef(id || nanoid())
    const handleChange = event => {
      onChange(event)
      onCheckedChange(event.target.checked)
    }
    return (
      <span
        className={classNames(className, {
          on: checked,
          in_active: disabled,
        })}>
        <input
          type="checkbox"
          className="inp_check"
          ref={ref}
          id={idRef.current}
          name={name}
          checked={checked}
          disabled={disabled}
          required={required}
          onChange={handleChange}
          {...rest}
        />
        <label htmlFor={idRef.current} className="lab_check">
          <span className="img_comm ico_check" />
          {children}
        </label>
      </span>
    )
  }
)

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
}

Checkbox.displayName = 'Checkbox'

export default Checkbox
