import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getMemberHistory,
  getMemberList,
  getPendingMasterByMaster,
  getPendingMasterByMember,
  getPendingMemberList,
  putMemberRequestable,
} from 'api/accountAPI'

const name = 'member'
export const fetchMemberList = createAsyncThunk(
  `${name}/fetchMemberList`,
  async ({ adAccountId, page }, { rejectWithValue }) => {
    try {
      const { data } = await getMemberList(adAccountId, page)
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchPendingMemberList = createAsyncThunk(
  `${name}/fetchPendingMemberList`,
  async ({ adAccountId, page, memberStatuses }, { rejectWithValue }) => {
    try {
      const { data } = await getPendingMemberList(
        adAccountId,
        page,
        memberStatuses
      )
      return data
    } catch (error) {
      const { response } = error
      const { status, errorCode, message } = response.data
      return rejectWithValue({
        status,
        errorCode,
        message,
      })
    }
  }
)

export const fetchPendingMasterByMaster = createAsyncThunk(
  `${name}/fetchPendingMasterByMaster`,
  async adAccountId => {
    const { data } = await getPendingMasterByMaster(adAccountId)
    return data
  }
)

export const fetchPendingMasterByMember = createAsyncThunk(
  `${name}/fetchPendingMasterByMember`,
  async adAccountId => {
    const { data } = await getPendingMasterByMember(adAccountId)
    return data
  }
)

export const fetchMemberHistory = createAsyncThunk(
  `${name}/fetchMemberHistory`,
  async ({ adAccountId, page }) => {
    const { data } = await getMemberHistory(adAccountId, page)
    return data
  }
)

export const changeMemberRequestable = createAsyncThunk(
  `${name}/changeMemberRequestable`,
  async ({ adAccountId, isRequestable }) => {
    const { data } = await putMemberRequestable(adAccountId, isRequestable)
    return data
  }
)

const memberSlice = createSlice({
  name,

  initialState: {
    members: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    memberRequestable: null,
    pendingMaster: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    pendingMasterMember: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    pendingMemberList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    history: {
      pagable: {
        content: [],
        number: 0,
        totalElements: 0,
        totalPages: 1,
      },
      isLoading: false,
    },
  },

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchMemberList.pending, state => {
      state.members.isLoading = true
    })

    builder.addCase(fetchMemberList.fulfilled, (state, { payload }) => {
      state.members.isLoading = false
      state.members.pagable = payload
    })

    builder.addCase(fetchMemberList.rejected, state => {
      state.members.isLoading = false
    })

    builder.addCase(fetchPendingMasterByMaster.pending, state => {
      state.pendingMaster.isLoading = true
    })

    builder.addCase(
      fetchPendingMasterByMaster.fulfilled,
      (state, { payload }) => {
        state.pendingMaster.isLoading = false
        state.pendingMaster.pagable = payload
      }
    )

    builder.addCase(fetchPendingMasterByMaster.rejected, state => {
      state.pendingMaster.isLoading = false
    })

    builder.addCase(fetchPendingMasterByMember.pending, state => {
      state.pendingMasterMember.isLoading = true
    })

    builder.addCase(
      fetchPendingMasterByMember.fulfilled,
      (state, { payload }) => {
        state.pendingMasterMember.isLoading = false
        state.pendingMasterMember.pagable = payload
      }
    )

    builder.addCase(fetchPendingMasterByMember.rejected, state => {
      state.pendingMasterMember.isLoading = false
    })

    builder.addCase(fetchPendingMemberList.pending, state => {
      state.pendingMemberList.isLoading = true
    })

    builder.addCase(fetchPendingMemberList.fulfilled, (state, { payload }) => {
      state.pendingMemberList.isLoading = false
      state.pendingMemberList.pagable = payload
    })

    builder.addCase(fetchPendingMemberList.rejected, state => {
      state.pendingMemberList.isLoading = false
    })

    builder.addCase(fetchMemberHistory.pending, state => {
      state.history.isLoading = true
    })

    builder.addCase(fetchMemberHistory.fulfilled, (state, { payload }) => {
      state.history.isLoading = false
      state.history.pagable = payload
    })

    builder.addCase(fetchMemberHistory.rejected, state => {
      state.history.isLoading = false
    })
  },
})
const { reducer } = memberSlice
export default reducer
