import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import 'moment/locale/ko'
import Moment from 'react-moment'
import {
  CalendarContext,
  DatePickerContext,
  DateRangePickerContext,
} from 'components/calendar/CalendarContext'
import { CALENDAR_PRESETS, getDays } from 'components/calendar/calendarUtil'
import NoneAnchor from 'components/NoneAnchor'

const Cell = ({ day, handleClick }) => {
  const { min, max } = useContext(CalendarContext)
  const { value } = useContext(DatePickerContext)
  const { start, end } = useContext(DateRangePickerContext)
  return (
    <td
      className={classNames({
        today: day && moment().isSame(day, 'days'),
        fst: day && day.date() === 1,
        lst:
          day &&
          (day.day() === 6 || day.isSame(day.clone().endOf('months'), 'days')),
        selected:
          day &&
          start &&
          end &&
          day.isBetween(start, end, 'days', '[]') &&
          !start.isSame(end, 'days'),
        selected_start:
          day &&
          start &&
          day.isSame(start, 'days') &&
          !start.isSame(end, 'days'),
        selected_end:
          day && end && day.isSame(end, 'days') && !start.isSame(end, 'days'),
        selected_only:
          (value && day && day.isSame(value, 'days')) ||
          (start &&
            end &&
            day &&
            day.isSame(start, 'days') &&
            day.isSame(end, 'days')),
        in_active:
          (day && min && day.isBefore(min, 'days')) ||
          (day && max && day.isAfter(max, 'days')),
      })}>
      {day &&
        ((min && day.isBefore(min, 'days')) ||
        (max && day.isAfter(max, 'days')) ? (
          <span className="link_day">
            <span className="inner_link_day">{day.date()}</span>
          </span>
        ) : (
          <NoneAnchor
            className="link_day"
            onClick={() => {
              handleClick(day)
            }}>
            <span className="inner_link_day">{day.date()}</span>
          </NoneAnchor>
        ))}
    </td>
  )
}
Cell.propTypes = {
  day: PropTypes.instanceOf(moment),
  handleClick: PropTypes.func,
}
const Calendar = ({ type, month }) => {
  const { min, max } = useContext(CalendarContext)
  const { value, setValue } = useContext(DatePickerContext)
  const { start, end, setStart, setEnd, setPreset, durationRef } = useContext(
    DateRangePickerContext
  )
  const days = getDays(month)
  const handleClick = day => {
    if (type === 'range') {
      if (
        start === null ||
        (start && end && day.isSame(start, 'days')) ||
        (start && day.isBefore(start, 'days')) ||
        (end && day.isAfter(start, 'days'))
      ) {
        setStart(day)
        setEnd(null)
        setPreset(CALENDAR_PRESETS.CUSTOM)
      } else if (start && day.isSameOrAfter(start, 'days')) {
        setEnd(day)
        setPreset(CALENDAR_PRESETS.CUSTOM)
        durationRef.current = day.diff(start, 'days') + 1
      }
    } else {
      value && value.isSame(day, 'days') ? setValue(null) : setValue(day)
    }
  }
  return (
    <div
      className={classNames('area_calendar', {
        in_active:
          (min && month.isBefore(min.clone().startOf('month'), 'days')) ||
          (max && month.isAfter(max.clone().endOf('month'), 'days')),
      })}>
      <em className="tit_date">
        <Moment format="YYYY년 MM월">{month}</Moment>
      </em>
      <table className="tbl_calendar">
        <caption className="hide_caption">날짜 선택 달력 표</caption>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">일</th>
            <th scope="col">월</th>
            <th scope="col">화</th>
            <th scope="col">수</th>
            <th scope="col">목</th>
            <th scope="col">금</th>
            <th scope="col">토</th>
          </tr>
        </thead>
        <tbody>
          {days.map((week, weekIndex) => (
            <tr key={`week-${weekIndex}`}>
              {week.map((day, dayIndex) => (
                <Cell
                  key={`day-${weekIndex}-${dayIndex}`}
                  day={day}
                  handleClick={handleClick}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
Calendar.propTypes = {
  type: PropTypes.oneOf(['single', 'range']),
  month: PropTypes.instanceOf(moment),
}
export default Calendar
