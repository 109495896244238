import React from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useAdAccountLogin from 'features/adAccount/useAdAccountLogin'
import Unauthorized from 'components/error/Unauthorized'
import MicroFrontend from 'components/MicroFrontend'
import { AD_ACCOUNT_STATUS } from 'features/adAccount/adAccountSlice'

const { REACT_APP_FOKA_MICRO_HOST } = process.env

const KeywordAd = () => {
  useAdAccountLogin()
  const { accountId } = useParams()
  const { current, status } = useSelector(state => state.adAccount)
  const history = useHistory()
  if (
    current?.id === Number(accountId) &&
    status === AD_ACCOUNT_STATUS.SUCCESS
  ) {
    return (
      <MicroFrontend
        history={history}
        host={REACT_APP_FOKA_MICRO_HOST}
        name="keywordad"
      />
    )
  } else if (status === AD_ACCOUNT_STATUS.FAIL) {
    return <Redirect to="/error/500" />
  } else if (status === AD_ACCOUNT_STATUS.UNAUTHORIZED) {
    return <Unauthorized />
  } else {
    return <></>
  }
}

export default KeywordAd
