import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { forceStyleSync, mapValues, noop } from 'utils'
import usePrevious from 'utils/hooks/usePrevious'

const QUARTER_SECOND = 250
const MILLI = 1000

const RENDER_PHASE = {
  OFF: 'OFF',
  MEASURE: 'MEASURE',
  READY_TO_EXPAND: 'READY_TO_EXPAND',
  EXPANDING: 'EXPANDING',
  ON: 'ON',
  READY_TO_COLLAPSE: 'READY_TO_COLLAPSE',
  COLLAPSING: 'COLLAPSING',
}

const Transition = React.memo(
  ({
    on,
    cssProperties,
    transitionIn = QUARTER_SECOND,
    transitionOut = QUARTER_SECOND,
    measure = noop,
    onTransitionIn = noop,
    onTransitionOut = noop,
    preserveElement,
    inline,
    style,
    className,
    children,
  }) => {
    const Tag = inline ? 'span' : 'div'

    const onStyle = useMemo(
      () => mapValues(([, onValue]) => onValue)(cssProperties),
      [cssProperties]
    )
    const offStyle = useMemo(
      () => mapValues(([offValue]) => offValue)(cssProperties),
      [cssProperties]
    )

    const [shouldBeHidden, setShouldBeHidden] = useState(true)
    const [lastMeasuredStyle, setLastMeasuredStyle] = useState({})
    const [phaseStyle, setPhaseStyle] = useState(on ? onStyle : offStyle)
    const [expanded, setExpanded] = useState(false)
    const [collpsed, setCollpsed] = useState(true)

    const [phase, setPhase] = useState(RENDER_PHASE.OFF)
    const [lastAnimationFrameRequest, setLastAnimationFrameRequest] =
      useState(null)
    const containerRef = useRef()

    useEffect(
      () => () => {
        if (lastAnimationFrameRequest !== null)
          cancelAnimationFrame(lastAnimationFrameRequest)
      },
      [lastAnimationFrameRequest]
    )

    const getTransitionStyle = useCallback(
      milliseconds =>
        Object.keys(cssProperties)
          .map(
            propertyName =>
              `${propertyName} ${milliseconds / MILLI}s ease-in-out`
          )
          .join(', '),
      [cssProperties]
    )

    const prevTransitionOut = usePrevious(transitionOut)

    useLayoutEffect(() => {
      switch (phase) {
        case RENDER_PHASE.OFF:
          if (on) {
            setShouldBeHidden(false)
            setPhase(RENDER_PHASE.MEASURE)
            setPhaseStyle({
              ...onStyle,
              position: 'absolute',
              visibility: 'hidden',
            })
          }
          break

        case RENDER_PHASE.MEASURE:
          setLastMeasuredStyle(measure(containerRef.current))
          setPhase(RENDER_PHASE.READY_TO_EXPAND)
          setPhaseStyle(offStyle)
          break

        case RENDER_PHASE.READY_TO_EXPAND:
          setPhase(RENDER_PHASE.EXPANDING)
          setCollpsed(false)
          forceStyleSync(containerRef.current)
          setPhaseStyle({
            ...onStyle,
            ...lastMeasuredStyle,
            transition: getTransitionStyle(transitionIn),
          })
          break

        case RENDER_PHASE.EXPANDING:
          if (!on) {
            // EXPANDING 도중에 off 되면 ON Phase로 바로 이행해서 COLLAPSE 시작
            setPhase(RENDER_PHASE.ON)
          }
          if (expanded) {
            setPhase(RENDER_PHASE.ON)
            setPhaseStyle(onStyle)
            onTransitionIn()
          }
          break

        case RENDER_PHASE.ON: {
          if (!on) {
            setLastMeasuredStyle(measure(containerRef.current))
            setPhase(RENDER_PHASE.READY_TO_COLLAPSE)
            setPhaseStyle({
              ...onStyle,
              ...lastMeasuredStyle,
            })
          }
          break
        }
        case RENDER_PHASE.READY_TO_COLLAPSE:
          setPhase(RENDER_PHASE.COLLAPSING)
          setExpanded(false)
          forceStyleSync(containerRef.current)
          setPhaseStyle({
            ...offStyle,
            transition: getTransitionStyle(transitionOut),
          })
          break

        case RENDER_PHASE.COLLAPSING:
          if (on) {
            // EXPANDING 도중에 off 되면 READY_TO_EXPAND Phase로 바로 이행해서 EXPANDING 시작
            setPhase(RENDER_PHASE.READY_TO_EXPAND)
          } else if (collpsed) {
            setShouldBeHidden(true)
            setPhase(RENDER_PHASE.OFF)
            setPhaseStyle(offStyle)
            onTransitionOut()
          } else if (transitionOut !== prevTransitionOut) {
            // COLLAPSING 중 transitionOut 변경
            setPhaseStyle({
              ...onStyle,
              transition: '9999s',
            })

            setLastAnimationFrameRequest(
              requestAnimationFrame(() => {
                forceStyleSync(containerRef.current)
                setPhaseStyle({
                  ...offStyle,
                  transition: getTransitionStyle(transitionOut),
                })
              })
            )
          }
          break
        default:
      }
    }, [
      on,
      phase,
      lastAnimationFrameRequest,
      setLastAnimationFrameRequest,
      measure,
      onStyle,
      offStyle,
      collpsed,
      expanded,
      lastMeasuredStyle,
      onTransitionIn,
      onTransitionOut,
      getTransitionStyle,
      transitionIn,
      transitionOut,
      prevTransitionOut,
    ])
    return (
      (preserveElement || !shouldBeHidden) && (
        <Tag
          className={className}
          ref={containerRef}
          onTransitionEnd={() => {
            if (phase === RENDER_PHASE.EXPANDING) return setExpanded(true)
            if (phase === RENDER_PHASE.COLLAPSING) return setCollpsed(true)
          }}
          style={{
            ...phaseStyle,
            ...style,
          }}>
          {children}
        </Tag>
      )
    )
  }
)

export default Transition
Transition.displayName = 'Transition'
Transition.propTypes = {
  cssProperties: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  transitionIn: PropTypes.number,
  transitionOut: PropTypes.number,
  inline: PropTypes.bool,
  on: PropTypes.bool,
  onTransitionIn: PropTypes.func,
  onTransitionOut: PropTypes.func,
  preserveElement: PropTypes.bool,
  measure: PropTypes.func,
  style: PropTypes.object,
}
