import React, { useEffect, useState } from 'react'
import LoadingTable from '../../../components/table/LoadingTable'
import { useDispatch } from 'react-redux'
import {
  fetchMemberHistory,
  fetchMemberList,
  fetchPendingMemberList,
} from 'features/settings/adaccount/memberSlice'
import { useHistory, useLocation } from 'react-router-dom'
import Select from '../../../components/form/Select'
import NoDataTable from '../../../components/table/NoDataTable'
import Pagination from '../../../components/pagination/Pagination'
import {
  putAdAccountMemberInviteCancel,
  putAdAccountMemberJoinApprove,
  putAdAccountMemberJoinReject,
} from '../../../api/accountAPI'
import { fail, success } from 'utils/toastUtils'
import PropTypes from 'prop-types'

const SELECTBOX_PENDING_MEMBERSTATUSES = [
  {
    text: '모두',
    value: 'PENDING_REQUEST,PENDING_INVITE',
  },
  {
    text: '요청중',
    value: 'PENDING_REQUEST',
  },
  {
    text: '초대중',
    value: 'PENDING_INVITE',
  },
]

const MemberJoinInviteStatus = ({
  adAccount,
  pendingMemberList,
  pendingMemberListLoading,
}) => {
  const h = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const { id } = adAccount
  const [pendingMemberPage, setPendingMemberPage] = useState(
    parseInt(params.get('pendingMemberPage'), 10) || 0
  )
  const handlePendingMemberPageChange = number => {
    h.push(`?tab=member&pendingMemberPage=${number}`)
    setPendingMemberPage(number)
  }
  const [memberStatuses, setMemberStatuses] = useState(
    'PENDING_REQUEST,PENDING_INVITE'
  )
  const handlePedingMemberStatuses = value => {
    setMemberStatuses(value)
    handlePendingMemberPageChange(0)
  }
  const pendingMemberStatues = member => (
    <>
      <td>
        <div className="inner_tbl">
          <span className="link_txt">
            {member.status === 'PENDING_REQUEST'
              ? '요청중'
              : member.status === 'PENDING_INVITE'
                ? '초대중'
                : ''}
          </span>
        </div>
      </td>
      <td className="align_c">
        <div className="inner_tbl">
          <div className="wrap_btn">
            {member.status === 'PENDING_REQUEST' ? (
              <>
                <button
                  type="button"
                  className="btn_gs"
                  onClick={() => handleJoinReject(member.id)}>
                  <span className="inner_g">거부</span>
                </button>
                <button
                  type="button"
                  className="btn_gs"
                  onClick={() => handleJoinApprove(member.id)}>
                  <span className="inner_g">수락</span>
                </button>
              </>
            ) : member.status === 'PENDING_INVITE' ? (
              <button
                type="button"
                className="btn_gs btn_cancel"
                onClick={() => handleInviteCancel(member.id)}>
                <span className="inner_g">초대취소</span>
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </td>
    </>
  )

  const handleInviteCancel = async memberId => {
    try {
      await putAdAccountMemberInviteCancel(memberId)
      success('멤버 초대를 취소하였습니다.')
      await dispatch(
        fetchPendingMemberList({
          adAccountId: id,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  const handleJoinApprove = async memberId => {
    try {
      await putAdAccountMemberJoinApprove(memberId)
      success('멤버 요청을 수락하였습니다.')
      await dispatch(
        fetchMemberList({
          adAccountId: id,
          page: 0,
        })
      )
      await dispatch(
        fetchPendingMemberList({
          adAccountId: id,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
      await dispatch(
        fetchMemberHistory({
          adAccountId: id,
          page: 0,
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  const handleJoinReject = async memberId => {
    try {
      await putAdAccountMemberJoinReject(memberId)
      success('멤버 요청을 거부하였습니다.')
      await dispatch(
        fetchPendingMemberList({
          adAccountId: id,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  useEffect(() => {
    ;(async () => {
      await dispatch(
        fetchPendingMemberList({
          adAccountId: id,
          page: pendingMemberPage,
          memberStatuses,
        })
      )
    })()
  }, [dispatch, id, pendingMemberPage, memberStatuses])
  return (
    <div className="ad_managebox managebox_member">
      <div className="managebox_tit">
        <strong className="tit_box">요청/초대중 멤버</strong>
        <div className="f_right">
          <Select
            options={SELECTBOX_PENDING_MEMBERSTATUSES}
            onChange={handlePedingMemberStatuses}
            selected={memberStatuses}
          />
        </div>
      </div>
      <div className="tblg2_wrap">
        <table className="tbl_g2">
          <caption className="hide_caption">멤버 상태 안내 표</caption>
          <colgroup>
            <col width="339px" />
            <col width="340px" />
            <col width="300px" />
            <col width="196px" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">
                <div className="inner_tbl">이름</div>
              </th>
              <th scope="col">
                <div className="inner_tbl">카카오ID</div>
              </th>
              <th scope="col">
                <div className="inner_tbl">상태</div>
              </th>
              <th scope="col" className="align_c">
                <div className="inner_tbl">수락/거부/초대취소</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {pendingMemberListLoading ? (
              <LoadingTable colSpan={4} />
            ) : !(pendingMemberList && pendingMemberList.content.length > 0) ? (
              <NoDataTable colSpan={4}>
                {memberStatuses === 'PENDING_REQUEST,PENDING_INVITE'
                  ? '요청/초대'
                  : memberStatuses === 'PENDING_REQUEST'
                    ? '요청'
                    : memberStatuses === 'PENDING_INVITE'
                      ? '초대'
                      : ''}{' '}
                중인 멤버가 없습니다.
              </NoDataTable>
            ) : (
              pendingMemberList.content.map((item, index) => (
                <tr key={`pendingMemberList|${index}`}>
                  <td>
                    <div className="inner_tbl">{item.dspAccount.name}</div>
                  </td>
                  <td className="td_state">
                    <div className="inner_tbl">
                      {item.dspAccount.kakaoEmail}
                    </div>
                  </td>
                  {pendingMemberStatues(item)}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {pendingMemberList.totalElements > 0 && (
        <Pagination
          onChange={handlePendingMemberPageChange}
          totalPages={pendingMemberList.totalPages}
          number={pendingMemberList.number}
        />
      )}
    </div>
  )
}
MemberJoinInviteStatus.propTypes = {
  adAccount: PropTypes.object.isRequired,
  pendingMemberList: PropTypes.object.isRequired,
  pendingMemberListLoading: PropTypes.bool.isRequired,
}
export default MemberJoinInviteStatus
