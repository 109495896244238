import React, { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

const MicroFrontend = ({ name, host, history, adAccountId }) => {
  const scriptId = `micro-frontend-script-${name}`

  const render = useCallback(() => {
    try {
      window[name].render({
        containerId: `${name}-container`,
        history,
        adAccountId,
      })
    } catch (error) {
      console.error(error)
    }
  }, [history, name, adAccountId])

  const unmount = useCallback(() => {
    try {
      window[name].unmount(`${name}-container`)
    } catch (error) {
      console.error(error)
    }
  }, [name])

  const fetchManifest = useCallback(async () => {
    const response = await fetch(`${host}/asset-manifest.json`, {
      cache: 'no-store',
    })
    const manifest = await response.json()
    const { files } = manifest
    if (files['main.css']) {
      const stylesheetId = `micro-frontend-stylesheet-${name}`
      if (!document.getElementById(stylesheetId)) {
        const stylesheet = document.createElement('link')
        stylesheet.rel = 'stylesheet'
        stylesheet.type = 'text/css'
        stylesheet.id = stylesheetId
        stylesheet.href = `${host}${files['main.css']}`
        document.head.appendChild(stylesheet)
      }
    }
    const script = document.createElement('script')
    script.id = scriptId
    script.src = `${host}${files['main.js']}`
    script.onload = render
    document.head.appendChild(script)
  }, [host, name, render, scriptId])

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      render()
    } else {
      fetchManifest().catch(error => console.error(error))
    }
    return () => {
      unmount()
    }
  }, [host, fetchManifest, render, scriptId, unmount])

  return <div id={`${name}-container`} />
}

MicroFrontend.propTypes = {
  history: PropTypes.object.isRequired,
  host: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  adAccountId: PropTypes.number,
}

export default MicroFrontend
