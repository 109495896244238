import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchTaxList } from 'features/settings/adaccount/taxSlice'
import moment from 'moment'
import Select from '../../../components/form/Select'
import {
  SELECTBOX_DURATION,
  SELECTBOX_TAX_ISSUE_STATUSES,
} from 'features/settings/cash/CashConstants'
import LoadingTable from '../../../components/table/LoadingTable'
import NoDataTable from '../../../components/table/NoDataTable'
import NumberFormat from 'react-number-format'
import {
  checkEmpty,
  convertToCommaSeparatedString,
} from 'features/settings/cash/CashCommons'
import Pagination from '../../../components/pagination/Pagination'
import { fetchAdAccountWithoutStatus } from 'features/adAccount/adAccountSlice'
import { fetchBizRightCompleted } from 'features/settings/adaccount/businessRightSlice'
import Tooltip from '../../../components/tooltip/Tooltip'
import TextInput from '../../../components/form/TextInput'
import {
  putChangeTaxbillEmail,
  putModifyDelegationStatus,
} from 'api/accountAPI'
import { getTaxbillInfo } from 'api/billingAPI'
import { showModal } from 'features/dialog/dialogSlice'
import Alert from '../../../components/dialog/Alert'
import Confirm from '../../../components/dialog/Confirm'
import ModalDelegationHistory from './ModalDelegationHistory'
import PropTypes from 'prop-types'
import NoneAnchor from 'components/NoneAnchor'
import { fail, success } from 'utils/toastUtils'

export const invoicePopupReplaceColumnString = v => {
  if (checkEmpty(v)) return '&nbsp;'
  return v
}

const renderDomesticInvoiceFailReasonColumn = (v, onFail) => {
  if (checkEmpty(v) || v.size <= 0) return null

  const { status, failReason } = v
  return status.includes('발행실패') ? (
    <NoneAnchor className="fc_warn" onClick={onFail(failReason)}>
      {status}
    </NoneAnchor>
  ) : (
    status
  )
}
const renderDomesticInvoiceTotalAmountColumn = (v, onSuccess) => {
  if (checkEmpty(v) || v.size <= 0) return null

  const { status, totalAmount, id, recipientType } = v
  return status.includes('발행실패') || recipientType === 'DELEGATION' ? (
    <>
      <NumberFormat
        value={totalAmount}
        displayType={'text'}
        thousandSeparator={true}
        defaultValue={0}
      />
      원
    </>
  ) : (
    <NoneAnchor className="link_txt" onClick={onSuccess(id)}>
      <NumberFormat
        value={totalAmount}
        displayType={'text'}
        thousandSeparator={true}
        defaultValue={0}
      />
      원
    </NoneAnchor>
  )
}

const Tax = ({ adAccountType }) => {
  const h = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const adAccount = useSelector(state => state.adAccount.current)
  const { id, master, taxBillEmail, delegationStatus } = useSelector(
    state => state.adAccount.current
  )
  const today = moment(moment()).format('YYYY-MM-DD')
  const { completed } = useSelector(state => state.businessRight)
  const { isLoading: taxListLoading, pagable: taxList } = useSelector(
    state => state.tax.taxList
  )
  const [taxListPage, setTaxListPage] = useState(
    parseInt(params.get('taxListPage'), 10) || 0
  )
  const [searchOptionsTaxList, setSearchOptionsTaxList] = useState({
    selectedDuration: 1,
    issueStatuses: 'SUCCESS,FAIL',
    accountId: id,
    to: today,
    from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
  })
  const [failReason, setFailReason] = useState('')
  const handleFailStatusClick = reason => () => {
    setFailReason(reason)
    dispatch(showModal('alertFailReason'))
  }
  const handleInvoiceAmountClick = taxBillId => async () => {
    try {
      const { data } = await getTaxbillInfo(id, taxBillId)
      const {
        approvalNumber,
        supplierBusinessNumber,
        supplierSubBusinessNumber,
        supplierCompanyName,
        supplierRepresentationName,
        supplierBusinessAddress,
        supplierBusinessItem,
        supplierBusinessCategory,
        supplierBillingMember,
        supplierBillingPhone,
        supplierBillingEmail,
        contractorBusinessNumber,
        contractorSubBusinessNumber,
        contractorCompanyName,
        contractorRepresentationName,
        contractorBusinessAddress,
        contractorBusinessItem,
        contractorBusinessCategory,
        contractorBillingMember,
        contractorBillingPhone,
        contractorBillingEmail,
        contractorBillingMember2,
        contractorBillingPhone2,
        contractorBillingEmail2,
        reportingDt,
        supplyAmount,
        taxAmount,
        modificationReason,
        originalApprovalNumber,
        itemDt,
        itemName,
        itemStandard,
        itemQuantity,
        itemSupplyAmount,
        itemTaxAmount,
        itemNote,
        issueForm,
        note,
      } = data
      const itemDate = moment(itemDt).format('MM-DD').split('-')
      const popup = window.open(
        '',
        '_blank',
        'width=695, height=850, menubar=no, status=no, toolbar=no'
      )
      popup.document.write(
        `<!DOCTYPE html>
          <html lang='ko'>
        <head>
          <meta charSet='utf-8' />
          <title>kakao moment</title>
          <style>
            body,div,dl,dt,dd,ul,ol,li,h1,h2,p,th,td{margin:0;padding:0}
            body{min-width:initial}
            body,th,td{font-size:14px;line-height:1.5;color:#555}
            dl,ul,ol,li{list-style:none}
            .kakao_popup{min-height:100%;background-color:#fff}
            .kakao_popup .page_btn{padding:50px 0 60px;margin:0}
            #kakaoLogo, #kakaoServiceLogo{background:url(https://t1.daumcdn.net/biz/ui/keywordad/logo_keyword.png) 0 0 no-repeat;background-size:135px 18px}
            .popup_dsp_tax #kakaoLogo, .popup_dsp_tax #kakaoServiceLogo{background:url(https://t1.daumcdn.net/biz/ui/keywordad/logo_keyword.png) 0 0 no-repeat;background-size:135px 18px}
            #kakaoLogo{float:left;width:144px;height:18px;background-position:0 0;text-decoration:none}
            .ir_pm{display:block;overflow:hidden;font-size:0;line-height:0;text-indent:-9999px}
            .ir_wa{display:block;overflow:hidden;position:relative;z-index:-1;width:100%;height:100%}
            .popup_dsp_tax{position:relative;width:695px}
            .popup_dsp_tax .popup_head{height:64px;background-color:#262626}
            .popup_dsp_tax .popup_head .tit_dsptax{float:left;width:219px;padding:23px 0 0 20px;font-weight:normal;font-size:14px}
            .popup_dsp_tax .tit_tax{display:block;padding:28px 0 0 31px;font-size:20px;line-height:24px;color:#111}
            .popup_dsp_tax .tbl_tax{border-collapse:collapse;border-spacing:0}
            .popup_dsp_tax .emph_g{font-weight:600;color:#111}
            .popup_dsp_tax th, .popup_dsp_tax td{font-size:12px}
            .popup_dsp_tax .td_left{text-align:left}
            .popup_dsp_tax .td_right{text-align:right}
            .popup_dsp_tax .td_center{text-align:center}
            .popup_dsp_tax .head_invoice{overflow:hidden;margin:15px 18px 0;border:2px solid #40454f;border-bottom:0;background-color:#f7faf9}
            .popup_dsp_tax .head_invoice .tit_invoice{float:left;width:327px;height:48px;font-size:18px;line-height:48px;text-align:center;color:#111}
            .popup_dsp_tax .head_invoice .txt_assi{font-size:12px;letter-spacing:-1px;vertical-align:middle}
            .popup_dsp_tax .head_invoice .info_serial{overflow:hidden;text-align:center;color:#111}
            .popup_dsp_tax .head_invoice .tit_serial{float:left;width:84px;border-top:1px solid #e4e8ec;line-height:23px;color:#6e7478;border-left:1px solid #e4e8ec;border-right:1px solid #e4e8ec}
            .popup_dsp_tax .head_invoice .desc_serial{overflow:hidden;height:23px;line-height:23px;border-top:1px solid #e4e8ec}
            .info_invoice{overflow:hidden;margin:0 18px;background-color:#f7faf9}
            .info_invoice .invoice_name{float:left}
            .info_invoice .txt_assi{float:right;margin-right:3px;font-size:11px;line-height:22px;color:#6e7478}
            .area_supply{overflow:hidden}
            .area_supply th{font-weight:600;border-bottom:1px solid #e4e8ec;text-align:center}
            .area_supply td{overflow:hidden;height:34px;padding:0 5px;border-left:1px solid #ea5555;border-bottom:1px solid #e4e8ec;color:#111}
            .invoice_supplier{float:left;width:327px;border:2px solid #ea5555;border-right:0}
            .invoice_supplier .tit_object{border-left:0}
            .invoice_supplier th{border-left:1px solid #ea5555;color:#ea5555;background-color:#fff3f3}
            .invoice_supplier tr:last-child td, .invoice_demander tr:last-child td{border-bottom:0}
            .invoice_demander{float:left;width:328px;border:2px solid #5396da;border-left:0}
            .invoice_demander th{border-left:1px solid #5396da;color:#3c88d5;background-color:#eff9fd}
            .invoice_demander td{border-left:1px solid #5396da}
            .info_sheet{margin:0 18px 15px;border:2px solid #40454f;border-top:0}
            .info_sheet .cover_sheet{border-top:2px solid #40454f}
            .tbl_sheet{width:100%;background-color:#fff;table-layout:fixed}
            .tbl_sheet thead th{font-weight:bold;border-bottom:1px solid #8e9296;color:#6e7478;background-color:#fbfbfb}
            .tbl_sheet tbody th{color:#6e7478;background-color:#fbfbfb}
            .tbl_sheet tbody tr:last-child th, .tbl_sheet tr:last-child td{border-bottom:0}
            .tbl_sheet th{line-height:23px;word-break:break-all}
            .tbl_sheet td{padding:2px 3px 1px;word-break:break-all;color:#111}
            .tbl_sheet th, .tbl_sheet td{border-right:1px solid #e4e8ec;border-bottom:1px solid #e4e8ec;text-align:center}
            .info_sheet thead th:last-child, .info_sheet tbody th:last-child, .info_sheet tbody td:last-child{border-right:0}
            .person_sheet{margin:0 18px;border:2px solid #40454f}
            .person_sheet tbody th{font-weight:normal;color:#333}
            .person_sheet th:last-child, .person_sheet td:last-child{border-right:0}
            .tbl_money tbody th{border-left:1px solid #e4e8ec;border-right:0}
            .tbl_money tbody th:last-child{border-right:0}
            .tbl_money tbody td{border-left:1px solid #e4e8ec;border-right:0;text-align:right}
            .tbl_money tbody th:first-child, .tbl_money tbody td:first-child{border-left:0}
            .list_infomation{padding:20px 26px 0 32px}
            .list_infomation li{position:relative;margin-top:11px;padding-left:12px;font-size:13px;line-height:15px;color:#666;letter-spacing:0}
            .list_infomation li:before{position:absolute;left:0;top:7px;width:3px;height:3px;background-color:#666;content:''}

            .hide_caption{overflow:hidden;width:1px;font-size:1px;line-height:0;text-indent:-9999px}
            .screen_out{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px;}
          </style>
        </head>
        <body>
          <div class='kakao_popup'>
            <div class='popup_dsp_tax'>
              <div class='popup_head'>
                <h1 class='tit_dsptax'>
                  <a href='#' target='_blank' id='kakaoLogo'><span class='ir_pm'>kakao 키워드광고</span></a>
                </h1>
              </div>
              <div class='popup_body'>
                <h2 class='tit_tax'>전자세금계산서</h2>
                <div class='head_invoice'>
                  <strong class='tit_invoice'>전자세금계산서&gt; <span class='txt_assi'>(공급받는자 보관용)</span></strong>
                  <dl class='info_serial'>
                    <dt class='tit_serial'>일련번호</dt>
                    <dd class='desc_serial' />
                    <dt class='tit_serial'>승인번호</dt>
                    <dd class='desc_serial'>${approvalNumber}</dd>
                  </dl>
                </div>
                <div class='info_invoice'>
                  <div class='area_supply invoice_supplier'>
                    <table class='tbl_tax'>
                      <caption class='hide_caption'>공급자 정보</caption>
                      <tbody>
                        <tr>
                          <th id='invoiceSupply' rowspan='6' class='tit_object' style='width:19px'>공급자</th>
                          <th headers='invoiceSupply' scope='row' style='width:64px'>등록번호</th>
                          <td style='width:236px'>
                            <em class='emph_g'>${supplierBusinessNumber}</em>
                          </td>
                        </tr>
                        <tr>
                          <th headers='invoiceSupply' scope='row'>상호</th>
                          <td>
                            <em class='emph_g'>${supplierCompanyName}</em>
                          </td>
                        </tr>
                        <tr>
                          <th headers='invoiceSupply' scope='row'>대표자</th>
                          <td>
                            <span class='invoice_name'>${supplierRepresentationName}</span>
                            <span class='txt_assi'>(종사업장등록번호: ${supplierSubBusinessNumber})</span>
                          </td>
                        </tr>
                        <tr>
                          <th headers='invoiceSupply' scope='row'>
                            사업장<br>주소
                          </th>
                          <td>${supplierBusinessAddress}</td>
                        </tr>
                        <tr>
                          <th headers='invoiceSupply' scope='row'>업태</th>
                          <td>${supplierBusinessCategory}</td>
                        </tr>
                        <tr>
                          <th headers='invoiceSupply' scope='row'>종목</th>
                          <td>${supplierBusinessItem}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class='area_supply invoice_demander'>
                    <table class='tbl_tax'>
                      <caption class='hide_caption'>공급자 받는자 정보</caption>
                      <tbody>
                        <tr>
                          <th id='invoiceDemand' rowspan='6' style='width:19px'>공급받는자</th>
                          <th headers='invoiceDemand' scope='row' style='width:64px'>등록번호</th>
                          <td style='width:236px'>
                            <em class='emph_g'>${contractorBusinessNumber}</em>
                          </td>
                        </tr>
                        <tr>
                          <th headers='invoiceDemand' scope='row'>상호</th>
                          <td>
                            <em class='emph_g'>${contractorCompanyName}</em>
                          </td>
                        </tr>
                        <tr>
                          <th headers='invoiceDemand' scope='row'>대표자</th>
                          <td>
                            <span class='invoice_name'>${contractorRepresentationName}</span>
                            <span class='txt_assi'>(종사업장등록번호: ${contractorSubBusinessNumber})</span>
                          </td>
                        </tr>
                        <tr>
                          <th headers='invoiceDemand' scope='row'>
                            사업장<br>주소
                          </th>
                          <td>${contractorBusinessAddress}</td>
                        </tr>
                        <tr>
                          <th headers='invoiceDemand' scope='row'>업태</th>
                          <td>${contractorBusinessCategory}</td>
                        </tr>
                        <tr>
                          <th headers='invoiceDemand' scope='row'>종목</th>
                          <td>${contractorBusinessItem}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class='info_sheet'>
                  <table class='tbl_tax tbl_sheet'>
                    <caption class='hide_caption'>세금계산서 금액정보</caption>
                    <thead>
                      <tr>
                        <th scope='col' style='width:77px'>작성일자</th>
                        <th scope='col' style='width:94px'>공급가액</th>
                        <th scope='col' style='width:94px'>세액</th>
                        <th scope='col' style='width:166px'>수정사유</th>
                        <th scope='col' style='width:187px'>당초승인번호</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>${moment(reportingDt).format('YYYY/MM/DD')}</td>
                        <td class='td_right'>${convertToCommaSeparatedString(
                          supplyAmount
                        )}</td>
                        <td class='td_right'>${convertToCommaSeparatedString(
                          taxAmount
                        )}</td>
                        <td>${invoicePopupReplaceColumnString(
                          modificationReason
                        )}</td>
                        <td>${invoicePopupReplaceColumnString(
                          originalApprovalNumber
                        )}</td>
                      </tr>
                      <tr>
                        <th scope='row'>비고</th>
                        <td colspan='4'>${invoicePopupReplaceColumnString(
                          note
                        )}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class='cover_sheet'>
                    <table class='tbl_tax tbl_sheet'>
                      <caption class='hide_caption'>세금계산서 상세내역</caption>
                      <thead>
                        <tr>
                          <th scope='col' style='width:18px'>월</th>
                          <th scope='col' style='width:18px'>일</th>
                          <th scope='col' style='width:204px'>품목</th>
                          <th scope='col' style='width:47px'>규격</th>
                          <th scope='col' style='width:47px'>수량</th>
                          <th scope='col' style='width:47px'>단가</th>
                          <th scope='col' style='width:88px'>공급가액</th>
                          <th scope='col' style='width:83px'>세액</th>
                          <th scope='col' style='width:48px'>비고</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>${itemDate[0]}</td>
                          <td>${itemDate[1]}</td>
                          <td class='td_left'>${itemName}</td>
                          <td>${invoicePopupReplaceColumnString(
                            itemStandard
                          )}</td>
                          <td>${invoicePopupReplaceColumnString(
                            itemQuantity
                          )}</td>
                          <td>&nbsp;</td>
                          <td class='td_right'>${convertToCommaSeparatedString(
                            itemSupplyAmount
                          )}</td>
                          <td class='td_right'>${convertToCommaSeparatedString(
                            itemTaxAmount
                          )}</td>
                          <td>${invoicePopupReplaceColumnString(itemNote)}</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td class='td_left'>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td class='td_right'>&nbsp;</td>
                          <td class='td_right'>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td class='td_left'>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td class='td_right'>&nbsp;</td>
                          <td class='td_right'>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td class='td_left'>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td class='td_right'>&nbsp;</td>
                          <td class='td_right'>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class='cover_sheet'>
                    <table class='tbl_tax tbl_sheet tbl_money'>
                      <caption class='hide_caption'>세금계산서 합계내역</caption>
                      <tbody>
                        <tr>
                          <th scope='col' style='width:102px'>합계금액</th>
                          <th scope='col' style='width:81px'>현금</th>
                          <th scope='col' style='width:82px'>수표</th>
                          <th scope='col' style='width:82px'>어음</th>
                          <th scope='col' style='width:82px'>외상미수금</th>
                          <td rowspan='2' class='td_center' style='width:193px'>
                            이 금액을 (<em class='emph_g'>${
                              issueForm === 'R' ? '영수' : '청구'
                            }</em>) 함
                          </td>
                        </tr>
                        <tr>
                          <td>${convertToCommaSeparatedString(
                            supplyAmount + taxAmount
                          )}</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class='person_sheet'>
                  <table class='tbl_tax tbl_sheet'>
                    <caption class='hide_caption'>세금계산서 관련인 정보</caption>
                    <thead>
                      <tr>
                        <th scope='col' style='width:62px'>구분</th>
                        <th scope='col' style='width:191px'>공급자</th>
                        <th scope='col' style='width:191px'>공급받는자</th>
                        <th scope='col' style='width:191px'>공급받는자2</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope='row'>담당자</th>
                        <td>${supplierBillingMember}</td>
                        <td>${contractorBillingMember}</td>
                        <td>${invoicePopupReplaceColumnString(
                          contractorBillingMember2
                        )}</td>
                      </tr>
                      <tr>
                        <th scope='row'>연락처</th>
                        <td>${invoicePopupReplaceColumnString(
                          supplierBillingPhone
                        )}</td>
                        <td>${invoicePopupReplaceColumnString(
                          contractorBillingPhone
                        )}</td>
                        <td>${invoicePopupReplaceColumnString(
                          contractorBillingPhone2
                        )}</td>
                      </tr>
                      <tr>
                        <th scope='row'>e-mail</th>
                        <td>${invoicePopupReplaceColumnString(
                          supplierBillingEmail
                        )}</td>
                        <td>${invoicePopupReplaceColumnString(
                          contractorBillingEmail
                        )}</td>
                        <td>${invoicePopupReplaceColumnString(
                          contractorBillingEmail2
                        )}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <strong class='screen_out'>유의사항</strong>
                <ul class='list_infomation'>
                  <li>본 세금계산서는 부가가치세법에 의하여 발행된 세금계산서이며, 전자서명 법에 의거한 전자인증서명으로 인감날인이 없어도 법적 효력을 갖습니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </body>
      </html>`
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleTaxListPageChange = number => {
    h.push(`?tab=tax&taxListPage=${number}`)
    setTaxListPage(number)
  }
  const handleSearchOptionsTaxListDuration = value => {
    setSearchOptionsTaxList({
      ...searchOptionsTaxList,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYY-MM-DD'),
    })
  }
  const handleSearchOptionsTaxIssueStatuses = value => {
    setSearchOptionsTaxList({
      ...searchOptionsTaxList,
      issueStatuses: value,
    })
  }
  const [viewModifyTaxbillEmail, setViewModifyTaxbillEmail] = useState(false)
  const [newTaxbillEmail, setNewTaxbillEmail] = useState(taxBillEmail)
  const handleChange = event => {
    setNewTaxbillEmail(event.target.value)
  }
  const handleChangeTaxbillEmail = async () => {
    try {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newTaxbillEmail)) {
        const resultAction = await putChangeTaxbillEmail(id, {
          email: newTaxbillEmail,
        })
        await dispatch(fetchAdAccountWithoutStatus(id))
        sessionStorage.setItem('adAccount', JSON.stringify(resultAction.data))
        setViewModifyTaxbillEmail(false)
        success('세금계산서 발행 이메일이 수정되었습니다.')
      } else {
        dispatch(showModal('alertInvalidTaxbillEmail'))
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleConfirmModifyDelegationStatus = async status => {
    try {
      await putModifyDelegationStatus(completed.id, status)
      dispatch(fetchAdAccountWithoutStatus(id))
      dispatch(fetchBizRightCompleted(id))
      success(
        `세금계산서 위임발행 설정을 ${
          status === 'COMPLETED'
            ? '수락'
            : status === 'REJECTED'
              ? '거부'
              : status === 'RELEASED'
                ? '해제'
                : ''
        }했습니다.`
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  useEffect(() => {
    ;(async () => {
      await dispatch(fetchBizRightCompleted(id))
    })()
  }, [dispatch, id, delegationStatus])
  useEffect(() => {
    dispatch(
      fetchTaxList({
        adAccountId: id,
        issueStatuses: searchOptionsTaxList.issueStatuses,
        to: searchOptionsTaxList.to,
        from: searchOptionsTaxList.from,
        page: taxListPage,
      })
    )
  }, [dispatch, id, searchOptionsTaxList, taxListPage])
  return (
    <>
      <div>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">정보</strong>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">
                세금계산서 정보 안내 표
              </caption>
              <colgroup>
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">이메일</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">
                      위임발행
                      <Tooltip>
                        설정시 영업권이 설정된 대행사로 세금계산서를 위임발행
                        합니다.
                      </Tooltip>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="inner_tbl">
                      {adAccountType === 'BUSINESS' ? (
                        completed &&
                        completed.id &&
                        delegationStatus === 'COMPLETED' ? (
                          <div className="inner_tbl">
                            세금계산서 위임발행이 설정되어 대행사로 세금계산서가
                            발행됩니다.
                          </div>
                        ) : master ? (
                          viewModifyTaxbillEmail ? (
                            <>
                              <TextInput
                                className="box_inptxt inp_modify on_inp"
                                placeholder="세금계산서 발행 메일주소를 입력하세요."
                                name="name"
                                value={newTaxbillEmail}
                                onChange={handleChange}
                              />
                              <div className="wrap_btn after_item">
                                <button
                                  type="button"
                                  className="btn_gm gm_bl"
                                  onClick={handleChangeTaxbillEmail}>
                                  <span className="inner_g">수정 완료</span>
                                </button>
                                <button
                                  type="button"
                                  className="btn_gm gm_line"
                                  onClick={() => {
                                    setNewTaxbillEmail(taxBillEmail)
                                    setViewModifyTaxbillEmail(false)
                                  }}>
                                  <span className="inner_g">취소</span>
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {taxBillEmail}
                              <div className="wrap_btn after_item">
                                <button
                                  type="button"
                                  className="btn_gm gm_line"
                                  onClick={() => {
                                    setViewModifyTaxbillEmail(true)
                                  }}>
                                  <span className="inner_g">수정</span>
                                </button>
                              </div>
                            </>
                          )
                        ) : (
                          taxBillEmail
                        )
                      ) : adAccountType === 'AGENCY' ? (
                        <div className="inner_tbl">
                          세금계산서가 대행사 사업자번호로 통합발행됩니다.
                          <br />
                          <a
                            href="https://agency.ad.kakao.com"
                            className="link_txt"
                            target="_blank"
                            rel="noopener noreferrer">
                            카카오광고 에이전시
                          </a>
                          에서 확인하여 주시길 바랍니다.
                        </div>
                      ) : adAccountType === 'INDIVIDUAL' ? (
                        <div className="inner_tbl">
                          {completed &&
                          completed.id &&
                          delegationStatus === 'COMPLETED'
                            ? '세금계산서 위임발행이 설정되어 대행사로 세금계산서가 발행됩니다.'
                            : '세금계산서는 사업자등록번호로 가입한 사업자 계정에서만 발행 가능합니다.'}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="inner_tbl">
                      {completed && completed.id ? (
                        delegationStatus === 'REQUESTED' ? (
                          master ? (
                            <div className="wrap_btn">
                              <button
                                className="btn_gs"
                                onClick={() => {
                                  handleConfirmModifyDelegationStatus(
                                    'REJECTED'
                                  )
                                }}>
                                <span className="inner_g">거부</span>
                              </button>
                              <button
                                className="btn_gs"
                                onClick={() => {
                                  dispatch(showModal('confirmDelegationTax'))
                                }}>
                                <span className="inner_g">수락</span>
                              </button>
                            </div>
                          ) : (
                            '신청중'
                          )
                        ) : delegationStatus === 'COMPLETED' ? (
                          <>
                            <p className="desc_entrust">
                              &apos;
                              {completed &&
                                completed.marketer.agencyCompany.name}
                              &apos;으로 위임발행 설정
                              <br />(
                              {moment(
                                completed &&
                                  completed.delegationStatusModifiedDate
                              ).format('YYYY.MM.DD HH:mm')}{' '}
                              업데이트,{' '}
                              <NoneAnchor
                                onClick={() =>
                                  dispatch(showModal('modalDelegationHistory'))
                                }
                                className="link_txt">
                                히스토리
                              </NoneAnchor>
                              )
                            </p>
                            {master && (
                              <div className="wrap_btn after_item">
                                <button
                                  type="button"
                                  className="btn_gs"
                                  onClick={() =>
                                    dispatch(
                                      showModal(
                                        'confirmReleaseTaxbillDelegation'
                                      )
                                    )
                                  }>
                                  <span className="inner_g">해제</span>
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <>미설정</>
                        )
                      ) : (
                        <>미설정</>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {adAccountType === 'BUSINESS' && (
          <>
            <div className="ad_managebox">
              <div className="managebox_tit">
                <strong className="tit_box">발행 내역</strong>
                <div className="f_right">
                  <div className="single_wrap">
                    <Select
                      options={SELECTBOX_DURATION}
                      onChange={handleSearchOptionsTaxListDuration}
                      selected={searchOptionsTaxList.selectedDuration}
                    />
                  </div>
                  <div className="single_wrap">
                    <Select
                      options={SELECTBOX_TAX_ISSUE_STATUSES}
                      onChange={handleSearchOptionsTaxIssueStatuses}
                      selected={searchOptionsTaxList.issueStatuses}
                    />
                  </div>
                </div>
              </div>
              <div className="tblg2_wrap">
                <table className="tbl_g2">
                  <caption className="hide_caption">발행내역 안내 표</caption>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="inner_tbl">이메일</div>
                      </th>
                      <th scope="col">
                        <div className="inner_tbl">사업자등록번호</div>
                      </th>
                      <th scope="col">
                        <div className="inner_tbl">공급가액</div>
                      </th>
                      <th scope="col">
                        <div className="inner_tbl">부가세액</div>
                      </th>
                      <th scope="col">
                        <div className="inner_tbl">총금액</div>
                      </th>
                      <th scope="col">
                        <div className="inner_tbl">상태</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxListLoading ? (
                      <LoadingTable colSpan={6} />
                    ) : !(taxList && taxList.content.length > 0) ? (
                      <NoDataTable colSpan={6}>
                        발행{' '}
                        {searchOptionsTaxList.issueStatuses === 'SUCCESS'
                          ? '완료'
                          : searchOptionsTaxList.issueStatuses === 'FAIL'
                            ? '실패'
                            : ''}{' '}
                        내역이 없습니다.
                      </NoDataTable>
                    ) : (
                      taxList.content.map((item, index) => {
                        const {
                          issueDttm,
                          businessNumber,
                          supplyAmount,
                          taxAmount,
                          recipientType,
                          recipientName,
                        } = item
                        const renderFailReason =
                          renderDomesticInvoiceFailReasonColumn(
                            item,
                            handleFailStatusClick
                          )
                        const renderTotalAmount =
                          renderDomesticInvoiceTotalAmountColumn(
                            item,
                            handleInvoiceAmountClick
                          )
                        return (
                          <tr key={`taxList|${index}`}>
                            <td>
                              <div className="inner_tbl">
                                {moment(issueDttm).format('YYYY.MM.DD HH:mm')}
                              </div>
                            </td>
                            <td>
                              <div className="inner_tbl">
                                {recipientType === 'DELEGATION'
                                  ? `${recipientName} 위임발행`
                                  : businessNumber}
                              </div>
                            </td>
                            <td className="align_r">
                              <div className="inner_tbl">
                                <NumberFormat
                                  value={supplyAmount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  defaultValue={0}
                                />
                                원
                              </div>
                            </td>
                            <td className="align_r">
                              <div className="inner_tbl">
                                <NumberFormat
                                  value={taxAmount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  defaultValue={0}
                                />
                                원
                              </div>
                            </td>
                            <td className="align_r">
                              <div className="inner_tbl">
                                {renderTotalAmount}
                              </div>
                            </td>
                            <td>
                              <div className="inner_tbl">
                                {renderFailReason}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </table>
              </div>
              {taxList.totalElements > 0 && (
                <Pagination
                  onChange={handleTaxListPageChange}
                  totalPages={taxList.totalPages}
                  number={taxList.number}
                />
              )}
            </div>
            <div className="desc_append">
              세금계산서 분리 발행 : 계정당 월별로 1장이 발행됩니다. <br />
              단, 사업자등록번호를 변경하신 경우에는 월 중 2장 이상으로 분리
              발행될 수 있습니다.
            </div>
          </>
        )}
      </div>
      <Alert id="alertInvalidTaxbillEmail" title="이메일 수정">
        올바른 이메일 형식을 입력하세요.
      </Alert>
      <Confirm
        id="confirmDelegationTax"
        className="entrustset_layer"
        okText="수락"
        cancelText="거부"
        marginLeft={-428.5}
        ok={async () => {
          await handleConfirmModifyDelegationStatus('COMPLETED')
        }}>
        <strong className="tit_layer">
          {completed && completed.marketer.agencyCompany.name}(대행사)으로
          세금계산서 위임발행을 설정 하시겠습니까?
        </strong>
        <p className="txt_layer">
          세금계산서 위임발행 설정 후에는 세금계산서가{' '}
          {completed && completed.marketer.agencyCompany.name}(대행사)으로
          발송됩니다.
        </p>
        <div className="box_entrustset">
          <em className="tit_list">주의사항</em>
          <ul className="list_guide">
            <li>
              광고용역거래에서 광고대행사가 대행 역할(주선·중개)만을 수행하는
              경우에는 부가가치세법 시행령 제69조에 따라 해당 용역거래와 관련된
              세금계산서의 [공급자] 는 카카오가 되고 [공급받는 자] 는 광고주가
              되어야 합니다.
            </li>
            <li>
              광고용역거래에서 광고대행사가 대행 역할(주선·중개)만을 수행하는
              경우가 아닌, 광고주로부터 포괄적 위임을 받아 자기 계산과 책임하에
              광고용역을 광고주에게 제공하는 것임을 광고대행사 및 광고주가
              확인해 주는 경우에 한하여 [공급받는 자] 를 광고대행사로 세금계산서
              위임발행을 하고 있습니다.
            </li>
            <li>
              세금계산서 위임발행은 대행사와 광고주 간의 문자 승인의 절차를
              거쳐서 진행하므로, 세금계산서 위임발행으로 인한 문제의 귀책사유는
              이를 신청한 대행사와 승인한 광고주에 있음을 알려드리며, 승인 전
              해당 대행사의 계약관계를 재고하여, 승인해 주시기 바랍니다.
            </li>
            <li>
              위임발행에 대한 판단 착오시, 「부가가치세법」 제60조[가산세]에
              따라, 공급가액의 3%의 가산세를 부담합니다.
              <br />이 때의 가산세는, 약관 상 &apos;회원의 의무&apos;를 위반한
              것으로 보아, 손해를 배상하여야 합니다.
            </li>
          </ul>
        </div>
      </Confirm>
      <Confirm
        id="confirmReleaseTaxbillDelegation"
        title="위임발행 설정 해제"
        ok={() => {
          handleConfirmModifyDelegationStatus('RELEASED')
        }}>
        위임발행 설정을 해제하시겠습니까?
      </Confirm>
      <Alert id="alertFailReason" title="실패사유">
        {failReason}
      </Alert>
      <ModalDelegationHistory adAccount={adAccount} />
    </>
  )
}
Tax.propTypes = {
  adAccountType: PropTypes.string,
}
export default Tax
