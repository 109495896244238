import React, { useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export const CardBusinessRegistrationNumber = ({ taxBill = false }) => {
  const { ownerCompany, advertiser } = useSelector(
    state => state.adAccount.current
  )
  const company = taxBill ? ownerCompany : advertiser || ownerCompany
  const disabled = useMemo(
    () => (taxBill && company ? !company.active : false),
    [taxBill, company]
  )
  return (
    <div
      className={classNames('card_summary card_number', {
        in_active: disabled,
      })}>
      <strong className="tit_card">
        {taxBill ? '세금계산서 발행용 사업자등록번호' : '광고주 사업자등록번호'}
        {disabled && <span className="ico_comm ico_clear">해제</span>}
      </strong>
      <em className="txt_info">
        {company && company.businessRegistrationNumber
          ? company.businessRegistrationNumber
          : '사업자등록번호 없음'}
      </em>
      {company && (
        <div className="define_adinfo">
          <dl>
            <dt>사업자</dt>
            <dd>
              <span className="txt_limit">{company.name}</span>
            </dd>
          </dl>
          <dl>
            <dt>대표자</dt>
            <dd>{company.representationName || '-'}</dd>
          </dl>
          <dl>
            <dt>업태/종목</dt>
            <dd>
              <div className="limit_info">
                <span className="txt_limit">
                  {company.businessCategory || '-'}
                </span>
                <span className="txt_limit">
                  /{company.businessItem || '-'}
                </span>
              </div>
            </dd>
          </dl>
        </div>
      )}
      {taxBill && (
        <Link to={`?tab=tax`} className="btn_gs">
          <span className="inner_g">더보기</span>
        </Link>
      )}
    </div>
  )
}
CardBusinessRegistrationNumber.propTypes = {
  taxBill: PropTypes.bool,
}
