import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useAdAccountLogin from 'features/adAccount/useAdAccountLogin'
import { AD_ACCOUNT_STATUS } from 'features/adAccount/adAccountSlice'
import AdAccountIndex from 'features/settings/adaccount/AdAccountIndex'
import CashIndex from 'features/settings/cash/CashIndex'
import PaymentCardIndex from 'features/settings/paymentcard/PaymentCardIndex'

const SettingsLayout = () => {
  useAdAccountLogin()
  const { current: adAccount, status } = useSelector(state => state.adAccount)
  if (adAccount && status === AD_ACCOUNT_STATUS.SUCCESS) {
    return (
      <Switch>
        <Route
          exact
          path="/:accountId(\d+)/settings/adaccount"
          component={AdAccountIndex}
        />
        <Route
          exact
          path="/:accountId(\d+)/settings/cash"
          component={CashIndex}
        />
        <Route
          exact
          path="/:accountId(\d+)/settings/paymentcard"
          component={PaymentCardIndex}
        />
      </Switch>
    )
  } else if (status === AD_ACCOUNT_STATUS.FAIL) {
    return <Redirect to="/error/500" />
  } else {
    return <></>
  }
}

export default SettingsLayout
