import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchAdAccount } from 'features/adAccount/adAccountSlice'
import { useParams } from 'react-router-dom'

const useAdAccountLogin = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()
  useEffect(() => {
    if (accountId) {
      dispatch(fetchAdAccount(accountId))
    }
  }, [dispatch, accountId])
}
export default useAdAccountLogin
