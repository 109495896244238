import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMemberList } from 'features/settings/adaccount/memberSlice'
import { fetchBizRightCompleted } from 'features/settings/adaccount/businessRightSlice'
import {
  fetchWallet,
  fetchWithdrawAccount,
} from 'features/settings/cash/cashSlice'
import useScrollTop from 'features/layout/useScrollTop'
import { CardBusinessRegistrationNumber } from 'features/settings/adaccount/CardBusinessRegistrationNumber'
import { CardMember } from 'features/settings/adaccount/CardMember'
import { CardBusinessRight } from 'features/settings/adaccount/CardBusinessRight'
import { CardCash } from 'features/settings/adaccount/CardCash'
import { CardCardPayment } from 'features/settings/adaccount/CardCardPayment'
import { fetchAutopayCard } from 'features/settings/paymentcard/paymentCardSlice'

const AdAccount = () => {
  useScrollTop()
  const dispatch = useDispatch()
  const dspAccount = useSelector(state => state.dspAccount.current)
  const adAccount = useSelector(state => state.adAccount.current)
  const { isLoading: membersLoading, pagable: memberList } = useSelector(
    state => state.member.members
  )
  const {
    wallet: { isLoading: walletLoading, info: wallet },
    refundAccount: { isLoading: refundAccountLoading, info: refundAccount },
  } = useSelector(state => state.cash)
  const { isLoading: autoPayCardLoading, pagable: autoPayCard } = useSelector(
    state => state.card.autopayCard
  )
  const adAccountId = adAccount.id

  useEffect(() => {
    Promise.all([
      dispatch(fetchMemberList({ adAccountId, page: 0 })),
      dispatch(fetchWallet(adAccountId)),
      dispatch(fetchWithdrawAccount(adAccountId)),
      dispatch(fetchBizRightCompleted(adAccountId)),
      dispatch(fetchAutopayCard({ adAccountId })),
    ])
  }, [dispatch, adAccountId])

  return (
    <>
      <h4 className="screen_out">광고계정 현황</h4>
      <div className="account_present">
        {/* 광고주 사업자등록번호 */}
        <CardBusinessRegistrationNumber />
        {/* 멤버 */}
        <CardMember
          isLoading={membersLoading}
          dspId={dspAccount.id}
          memberList={memberList}
        />
        {/* 캐시 잔액 */}
        <CardCash
          walletLoading={walletLoading}
          refundAccountLoading={refundAccountLoading}
          wallet={wallet}
          refundAccount={refundAccount}
          adAccountId={adAccountId}
        />
        {/* 세금계산서 발행용 사업자등록번호 */}
        <CardBusinessRegistrationNumber taxBill />
        {/* 영업권 */}
        <CardBusinessRight
          hasBizRight={adAccount.hasBizRight}
          bizRightDate={adAccount.bizRightLastModifiedDate}
          bizRightAgencyName={adAccount.bizRightAgencyName}
          transferBizRight={adAccount.transferBizRight}
        />
        {/* 카드 결제 예정액 */}
        <CardCardPayment
          autopayCardLoading={autoPayCardLoading}
          autopayCard={autoPayCard}
          wallet={wallet}
          dspAccount={dspAccount}
          adAccount={adAccount}
        />
      </div>
    </>
  )
}
export default AdAccount
