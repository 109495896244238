import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { boardList, boardView } from 'api/boardAPI'
import moment from 'moment'

export const fetchGnbNoticeList = createAsyncThunk(
  'board/fetchGnbNoticeList',
  async () => {
    const { data } = await boardList({
      noticeTypes:
        'NOTICE_EVENT,POLICY_REVIEW,SYSTEM,PRODUCT,PAYMENT_BILL,OPERATION,ETC',
      searchType: '',
      searchKeyword: '',
      page: 0,
    })
    return data
  }
)

export const fetchNoticeBoardList = createAsyncThunk(
  'board/fetchNoticeBoardList',
  async ({ noticeTypes, searchType, searchKeyword, page }) => {
    const { data } = await boardList({
      noticeTypes,
      searchType,
      searchKeyword,
      page,
    })
    return data
  }
)

export const fetchNoticeBoardView = createAsyncThunk(
  'board/fetchNoticeBoardView',
  async id => {
    const { data } = await boardView(id)
    return data
  }
)

const boardSlice = createSlice({
  name: 'board',

  initialState: {
    gnbList: null,
    gnbNew: false,
    list: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    view: null,
  },

  extraReducers: builder => {
    builder.addCase(fetchGnbNoticeList.pending, state => {
      state.gnbList = null
    })

    builder.addCase(fetchGnbNoticeList.fulfilled, (state, { payload }) => {
      state.gnbList = payload
      if (payload && payload.content.length > 0) {
        state.gnbNew = payload.content.some(
          item => moment().diff(item.createdDate, 'days') < 6
        )
      }
    })

    builder.addCase(fetchGnbNoticeList.rejected, state => {
      state.gnbList = null
    })

    builder.addCase(fetchNoticeBoardList.pending, state => {
      state.list.isLoading = true
    })

    builder.addCase(fetchNoticeBoardList.fulfilled, (state, { payload }) => {
      state.list.isLoading = false
      state.list.pagable = payload
    })

    builder.addCase(fetchNoticeBoardList.rejected, state => {
      state.list.isLoading = false
    })

    builder.addCase(fetchNoticeBoardView.pending, state => {
      state.view = null
    })

    builder.addCase(fetchNoticeBoardView.fulfilled, (state, { payload }) => {
      state.view = payload
    })

    builder.addCase(fetchNoticeBoardView.rejected, state => {
      state.view = null
    })
  },
})

const { reducer } = boardSlice
export default reducer
