import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Tooltip from '../../../components/tooltip/Tooltip'
import {
  fetchAutopayCardPayHistory,
  fetchMyAutopayCard,
} from 'features/settings/paymentcard/paymentCardSlice'
import moment from 'moment'
import {
  SELECTBOX_DURATION,
  SELECTBOX_PAY_HISTORY_STATUS,
} from 'features/settings/cash/CashConstants'
import Select from 'components/form/Select'
import LoadingTable from 'components/table/LoadingTable'
import NoDataTable from 'components/table/NoDataTable'
import Loading from 'components/loading/Loading'
import NumberFormat from 'react-number-format'
import Pagination from 'components/pagination/Pagination'
import cx from 'classnames'
import { showModal } from 'features/dialog/dialogSlice'
import { deleteMyAutopayCard, postMyAutopayCard } from 'api/billingAPI'
import { putKakaoPayAgreement } from 'api/accountAPI'
import Modal from 'components/dialog/Modal'
import Confirm from 'components/dialog/Confirm'
import NoneAnchor from 'components/NoneAnchor'
import UsingPaymentCardListLayer from './UsingPaymentCardListLayer'
import AdAccountFinder from './AdAccountFinder'
import { fail } from 'utils/toastUtils'

const AutoPayCard = () => {
  const h = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const today = moment(moment()).format('YYYY-MM-DD')
  const dspAccount = useSelector(state => state.dspAccount.current)
  const { isLoading: myAutopayCardLoading, pagable: myAutopayCard } =
    useSelector(state => state.card.myAutopayCard)
  const {
    isLoading: autopayCardPayHistoryLoading,
    pagable: autopayCardPayHistory,
  } = useSelector(state => state.card.autopayCardPayHistory)
  const [autopayCardPayHistoryPage, setAutopayCardPayHistoryPage] = useState(
    parseInt(params.get('autopayCardPayHistoryPage'), 10) || 0
  )
  const [searchOptionsPayHistory, setSearchOptionsPayHistory] = useState({
    selectedDuration: 1,
    status: 'SUCCESS,FAIL',
    adAccountId: undefined,
    to: today,
    from: moment(moment().subtract(1, 'month')).format('YYYY-MM-DD'),
  })
  const handleAutopayCardPayHistoryPageChange = number => {
    h.push(`?tab=autopaycard&autopayCardPayHistoryPage=${number}`)
    setAutopayCardPayHistoryPage(number)
  }
  const handleSearchOptionsDuration = value => {
    setSearchOptionsPayHistory({
      ...searchOptionsPayHistory,
      selectedDuration: value,
      from: moment(moment().subtract(value, 'month')).format('YYYY-MM-DD'),
    })
  }
  const handleSearchOptionsAdAccountId = value => {
    setSearchOptionsPayHistory({
      ...searchOptionsPayHistory,
      adAccountId: value,
    })
  }
  const handleSearchOptionsStatus = value => {
    setSearchOptionsPayHistory({
      ...searchOptionsPayHistory,
      status: value,
    })
  }

  const getMaskingCardNumber = numberString => {
    if (numberString && numberString.length >= 6) {
      return `${numberString.substring(0, 4)} ${numberString.substring(
        4,
        6
      )}** **** ****`
    }
    return numberString
  }

  const [checkedAddAutopayCard, setCheckedAddAutopayCard] = useState(false)
  const handleAddMyAutopayCard = async () => {
    if (myAutopayCard.length > 0) {
      await addMyAutoPayCard(false)
    } else {
      setCheckedAddAutopayCard(false)
      dispatch(showModal('confirmAddMyAutopayCard'))
    }
  }
  const addMyAutoPayCard = async init => {
    if (init) await putKakaoPayAgreement(dspAccount.id)
    const { data } = await postMyAutopayCard({
      isPopUpRedirectUrl: false,
      redirectUrl: window.location.href,
    })
    const { paymentUrl } = data
    window.open(
      paymentUrl,
      '_blank',
      'width=520,height=701,top=0,left=0,menubar=no,status=no,toolbar=no'
    )
  }

  const [deleteCardByOwnerCard, setDeleteCardByOwnerCard] = useState('')
  const [deleteCardByOwnerCardKey, setDeleteCardByOwnerCardKey] = useState('')
  const handleDeleteSpareCardByOwner = (card, cardKey) => {
    setDeleteCardByOwnerCard(card)
    setDeleteCardByOwnerCardKey(cardKey)
    dispatch(showModal('confirmDeleteCardByOwner'))
  }
  const deleteAutopayCardMethodOwner = async () => {
    try {
      await deleteMyAutopayCard({
        data: {
          autopayCardKey: deleteCardByOwnerCardKey,
        },
      })
      await dispatch(fetchMyAutopayCard())
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const refreshAutopayCardPayHistory = () =>
    dispatch(
      fetchAutopayCardPayHistory({
        adAccountId: searchOptionsPayHistory.adAccountId,
        from: searchOptionsPayHistory.from,
        to: searchOptionsPayHistory.to,
        status: searchOptionsPayHistory.status,
        page: autopayCardPayHistoryPage,
      })
    )

  useEffect(() => {
    dispatch(fetchMyAutopayCard())
  }, [dispatch])
  useEffect(() => {
    dispatch(
      fetchAutopayCardPayHistory({
        adAccountId: searchOptionsPayHistory.adAccountId,
        from: searchOptionsPayHistory.from,
        to: searchOptionsPayHistory.to,
        status: searchOptionsPayHistory.status,
        page: autopayCardPayHistoryPage,
      })
    )
  }, [dispatch, searchOptionsPayHistory, autopayCardPayHistoryPage])

  return (
    <>
      <div className="ad_managebox">
        <div className="managebox_tit">
          <strong className="tit_box">
            자동 결제카드 <em className="fc_emph">{myAutopayCard.length}</em>
            <Tooltip>
              로그인한 계정과 동일한 카카오계정을 기준으로, 신용카드를 추가하여
              사용할 수 있습니다.
            </Tooltip>
          </strong>
          {myAutopayCard && myAutopayCard.length < 10 && (
            <div className="f_left">
              <NoneAnchor
                className="btn_gm gm_bl"
                onClick={handleAddMyAutopayCard}>
                <span className="inner_g">
                  <span className="img_comm ico_add" />
                  카드추가
                </span>
              </NoneAnchor>
            </div>
          )}
        </div>
        {myAutopayCardLoading ? (
          <div className="myinfo_cardinfo nodata_info">
            <Loading />
          </div>
        ) : !(myAutopayCard && myAutopayCard.length > 0) ? (
          <div className="myinfo_cardinfo nodata_info">
            <p className="txt_nodata">
              사용 가능한 자동결제 카드가 없습니다.
              <br />
              카드를 추가하여 사용하세요.
            </p>
          </div>
        ) : (
          <div className="myinfo_cardinfo">
            {myAutopayCard.map(
              ({
                autopayCardKey,
                autopayStatus,
                autopayCardInfos,
                cardCorpName,
                cardCorpDisplayName,
                cardBin,
              }) => (
                <div
                  key={autopayCardKey}
                  className={cx(
                    `card_pay_s card_basic card_${cardCorpName.toLowerCase()}`,
                    {
                      on_card: autopayStatus === 'OK',
                      off_card: autopayStatus === 'HOLD',
                    }
                  )}>
                  <div className="inner_card">
                    <em className="use_card">
                      {autopayCardInfos.length > 0 ? '사용중' : '미사용'}
                    </em>
                    {cardCorpName.toLowerCase() === 'kb' ||
                    cardCorpName.toLowerCase() === 'nh' ||
                    cardCorpName.toLowerCase() === 'shinhan' ||
                    cardCorpName.toLowerCase() === 'hana' ||
                    cardCorpName.toLowerCase() === 'bc' ||
                    cardCorpName.toLowerCase() === 'lotte' ||
                    cardCorpName.toLowerCase() === 'samsung' ||
                    cardCorpName.toLowerCase() === 'citi' ||
                    cardCorpName.toLowerCase() === 'hyundai' ||
                    cardCorpName.toLowerCase() === 'kakaobank' ||
                    cardCorpName.toLowerCase() === 'kakaopay' ||
                    cardCorpName.toLowerCase() === 'paymoney' ? (
                      <strong className="card_logo">
                        <span className="ico_cardlogo">
                          {cardCorpDisplayName}
                        </span>
                      </strong>
                    ) : (
                      <strong className="tit_card">
                        {cardCorpDisplayName}
                      </strong>
                    )}
                    <dl className="detail_cardnum">
                      <dt>카드번호</dt>
                      <dd>{getMaskingCardNumber(cardBin)}</dd>
                    </dl>
                    {autopayCardInfos.length === 0 && (
                      <NoneAnchor
                        className="btn_del"
                        onClick={() => {
                          const card = `${cardCorpDisplayName} ${cardBin.substring(
                            0,
                            4
                          )}`
                          handleDeleteSpareCardByOwner(card, autopayCardKey)
                        }}>
                        <span className="ico_comm ico_del">삭제</span>
                      </NoneAnchor>
                    )}
                    {autopayCardInfos.length > 0 && (
                      <UsingPaymentCardListLayer
                        autopayCardInfos={autopayCardInfos}
                      />
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div className="ad_managebox">
        <div className="managebox_tit">
          <strong className="tit_box">거래 내역</strong>
          <div className="f_right">
            <div className="single_wrap">
              <Select
                options={SELECTBOX_DURATION}
                onChange={handleSearchOptionsDuration}
                selected={searchOptionsPayHistory.selectedDuration}
              />
            </div>
            <div className="single_wrap">
              <Select
                options={SELECTBOX_PAY_HISTORY_STATUS}
                onChange={handleSearchOptionsStatus}
                selected={searchOptionsPayHistory.status}
              />
            </div>
            <div className="single_wrap">
              <AdAccountFinder
                walletId={searchOptionsPayHistory.adAccountId}
                onSelect={handleSearchOptionsAdAccountId}
                onSubmit={refreshAutopayCardPayHistory}
              />
            </div>
          </div>
        </div>
        <div className="tblg2_wrap">
          <table className="tbl_g2">
            <caption className="hide_caption">결제카드 거래내역 표</caption>
            <colgroup>
              <col width="199px" />
              <col width="423px" />
              <col width="185px" />
              <col width="184px" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="inner_tbl">거래일시</div>
                </th>
                <th scope="col">
                  <div className="inner_tbl">광고계정</div>
                </th>
                <th scope="col">
                  <div className="inner_tbl">카드 정보</div>
                </th>
                <th scope="col">
                  <div className="inner_tbl">결제 금액</div>
                </th>
                <th scope="col">
                  <div className="inner_tbl">결제 상태</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {autopayCardPayHistoryLoading ? (
                <LoadingTable colSpan={5} />
              ) : !(
                  autopayCardPayHistory &&
                  autopayCardPayHistory.content.length > 0
                ) ? (
                <NoDataTable colSpan={5}>결제 내역이 없습니다.</NoDataTable>
              ) : (
                autopayCardPayHistory.content.map(
                  (
                    {
                      approvedDt,
                      adAccountName,
                      cardName,
                      cardNumber,
                      amount,
                      status,
                      errorMessage,
                    },
                    index
                  ) => (
                    <tr key={index}>
                      <td>
                        <div className="inner_tbl">
                          {moment(approvedDt).format('YYYY.MM.DD HH:mm')}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          <span className="limit_line">{adAccountName}</span>
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {cardName}({cardNumber})
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={amount}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {status === 'SUCCESS' ? (
                            '결제 성공'
                          ) : (
                            <span className="fc_warn">
                              결제 실패
                              <Tooltip
                                className="ico_comm ico_fail"
                                iconStyle={{
                                  width: '14px',
                                  height: '14px',
                                  marginTop: '4px',
                                }}>
                                {errorMessage}
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
        {autopayCardPayHistory.totalElements > 0 && (
          <Pagination
            onChange={handleAutopayCardPayHistoryPageChange}
            totalPages={autopayCardPayHistory.totalPages}
            number={autopayCardPayHistory.number}
          />
        )}
      </div>
      <Modal
        id="confirmAddMyAutopayCard"
        className="cardinfo_layer"
        innerClassName="inner_basic_layer1"
        title="자동결제 카드 등록"
        okText="확인"
        disabledOk={!checkedAddAutopayCard}
        ok={() => {
          addMyAutoPayCard(true)
        }}>
        <strong className="subtit_layer">
          자동결제를 위한 카드를 불러오거나, 새로 등록하기 위해 아래 동의가
          필요합니다.
        </strong>
        <div className="agree_master">
          <div className="tit_agreemaster">
            <span className="box_checkinp">
              <input
                type="checkbox"
                name="inpCheckSet2"
                id="checkSet1"
                className="inp_check"
                checked={checkedAddAutopayCard}
                onChange={ev => {
                  setCheckedAddAutopayCard(ev.target.checked)
                }}
              />
              <label htmlFor="checkSet1" className="lab_check">
                <span className="img_comm ico_check" />
                결제조건을 확인하고 결제 진행에 동의합니다.
              </label>
            </span>
          </div>
          <ul className="list_guide">
            <li>결제 예정금액이 50만 원에 도달하면 결제됩니다.</li>
            <li>
              결제 예정금액이 50만 원에 도달하지 않은 경우 매일 자정 00시 00분에
              결제됩니다.
            </li>
            <li>
              결제 예정금액이 있는 경우 카드를 삭제하려고 시도할 때
              결제예정금액을 결제해야 삭제가 가능합니다.
            </li>
          </ul>
        </div>
      </Modal>
      <Confirm
        id="confirmDeleteCardByOwner"
        title={`카드 삭제`}
        okText="확인"
        ok={deleteAutopayCardMethodOwner}>
        <p className="txt_layer">
          {deleteCardByOwnerCard}을(를) 삭제하시겠습니까?
        </p>
      </Confirm>
    </>
  )
}

export default AutoPayCard
