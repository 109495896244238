import PropTypes from 'prop-types'
import React from 'react'
import { noop } from 'utils'
import Transition from 'components/animation/Transition'

const Fade = ({
  inline,
  style,
  on,
  duration,
  fadeIn,
  fadeOut,
  preserveElement,
  onFadeIn = noop,
  onFadeOut = noop,
  className,
  children,
}) => (
  <Transition
    cssProperties={{ opacity: [0, 1] }}
    inline={inline}
    style={style}
    on={on}
    transitionIn={duration || fadeIn}
    transitionOut={duration || fadeOut}
    preserveElement={preserveElement}
    onTransitionIn={onFadeIn}
    onTransitionOut={onFadeOut}
    className={className}>
    {children}
  </Transition>
)

export default Fade

Fade.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  duration: PropTypes.number,
  fadeIn: PropTypes.number,
  fadeOut: PropTypes.number,
  inline: PropTypes.bool,
  on: PropTypes.bool,
  onFadeIn: PropTypes.func,
  onFadeOut: PropTypes.func,
  preserveElement: PropTypes.bool,
  style: PropTypes.object,
}
