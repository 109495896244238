import { configureStore } from '@reduxjs/toolkit'
import { createReduxEnhancer } from '@sentry/react'
import layout from '../features/layout/layoutSlice'
import gnb from '../features/gnb/gnbSlice'
import dspAccount from '../features/login/dspAccountSlice'
import adAccount from '../features/adAccount/adAccountSlice'
import member from '../features/settings/adaccount/memberSlice'
import businessRight from '../features/settings/adaccount/businessRightSlice'
import tax from '../features/settings/adaccount/taxSlice'
import cash from '../features/settings/cash/cashSlice'
import dialog from '../features/dialog/dialogSlice'
import board from '../features/board/boardSlice'
import card from '../features/settings/paymentcard/paymentCardSlice'
import autoPayCardAccount from '../features/myInfo/autoPayCard/autoPayCardAccountSlice'

const reducer = {
  layout,
  gnb,
  dspAccount,
  adAccount,
  member,
  businessRight,
  tax,
  cash,
  dialog,
  board,
  card,
  autoPayCardAccount,
}

const store = configureStore({
  reducer,
  enhancers: defaultEnhancers => [...defaultEnhancers, createReduxEnhancer()],
})

export default store
