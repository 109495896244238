import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchAdAccounts } from '../../../api/accountAPI'

export const CARD_ACCOUNT_SIZE = 200
export const fetchAdAccounts = createAsyncThunk(
  'autoPayCardAccount/fetchAdAccounts',
  async (noArgs, { getState }) => {
    const { page, search } = getState().autoPayCardAccount
    const { data } = await searchAdAccounts(search, page)
    return data
  }
)

const initialState = {
  search: '',
  isLoading: false,
  adAccounts: [],
  page: 0,
  totalElements: 0,
}
const autoPayCardAccountSlice = createSlice({
  name: 'autoPayCardAccount',
  initialState,
  reducers: {
    reset: () => initialState,
    setSearch: (state, { payload: search }) => {
      state.search = search
      state.page = 0
    },
    setPage: (state, { payload: page }) => {
      state.page = page
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAdAccounts.pending, state => {
      state.isLoading = true
    })
    builder.addCase(
      fetchAdAccounts.fulfilled,
      (state, { payload: { totalElements, content } }) => {
        state.totalElements = totalElements
        state.adAccounts = content.map(({ adAccount }) => adAccount)
        state.isLoading = false
      }
    )
    builder.addCase(fetchAdAccounts.rejected, state => {
      state.isLoading = false
    })
  },
})
export const { reset, setSearch, setPage } = autoPayCardAccountSlice.actions
export default autoPayCardAccountSlice.reducer
