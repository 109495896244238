import axios from 'axios'
import { SERVICE_TYPE } from './config/cruxAxios'

const { REACT_APP_CRUX_API_HOST } = process.env
const boardAxios = axios.create({
  baseURL: REACT_APP_CRUX_API_HOST,
})
export const boardList = ({ noticeTypes, searchType, searchKeyword, page }) =>
  boardAxios.get('/crux-board/notice/external/noticeBoards', {
    params: {
      noticeTypes,
      searchType,
      searchKeyword,
      page,
      size: 15,
      serviceType: 'MOMENT',
      adPlatformTypes: SERVICE_TYPE,
    },
  })
export const boardView = id =>
  boardAxios.get(`/crux-board/notice/external/noticeBoards/${id}`, {
    params: {
      serviceType: 'MOMENT',
      adPlatformType: SERVICE_TYPE,
    },
  })
