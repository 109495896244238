import React from 'react'
import { useSelector } from 'react-redux'

const { REACT_APP_BUSINESS_HOST, REACT_APP_CRUX_BIZ_AUTH_HOST } = process.env
const Contact = () => {
  const integrationInfo = useSelector(state => state.dspAccount.integrationInfo)

  return (
    <>
      {integrationInfo && integrationInfo.redirectStatus === 'FALSE' ? (
        <div className="ad_managebox">
          <div className="box_bizcombine">
            <strong className="tit_bizcombine">
              개인정보는 카카오 비즈니스에서 조회 및 수정할 수 있습니다.
            </strong>
            <div className="page_btn">
              <div className="inner_btn">
                <a
                  href={`${REACT_APP_BUSINESS_HOST}/my-info/`}
                  className="btn_gb gb_bl"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="inner_g">카카오 비즈니스로 이동</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ad_managebox">
          <div className="box_bizcombine">
            <strong className="tit_bizcombine">
              개인정보는 카카오 비즈니스 통합 서비스 약관 동의한 이후 조회 및
              수정할 수 있습니다.
            </strong>
            <ul className="list_bizcombine">
              <li>
                카카오의 다양한 비즈니스 서비스를 자유롭게 이용할 수 있도록
                카카오 비즈니스 회원 통합을 시작합니다.
              </li>
              <li>
                카카오 비즈니스 통합 서비스 약관 동의 유예기간 동안에는 약관
                동의 없이 서비스 이용이 가능하며,
                <br />
                동의를 원하지 않을 경우 유예기간 동안 서비스 중단 및 회원 탈퇴를
                할 수 있습니다.
              </li>
              <li>
                유예기간이 종료된 이후에도 통합 서비스 약관에 동의하지 않은
                사용자는 서비스 이용이 제한될 수 있습니다.
                <br />
                카카오 비즈니스 회원 통합에 대한 문의가 있으신 경우,{' '}
                <a
                  href="https://cs.kakao.com"
                  className="link_txt"
                  target="_blank"
                  rel="noopener noreferrer">
                  카카오 고객센터
                </a>
                로 연락주세요.
              </li>
            </ul>
            <div className="page_btn">
              <div className="inner_btn">
                <a
                  href={`${REACT_APP_CRUX_BIZ_AUTH_HOST}/account/join/new-service?serviceType=KEYWORDAD&after=${window.location.href}`}
                  className="btn_gb gb_bl">
                  <span className="inner_g">
                    카카오 비즈니스 회원 통합 시작
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Contact
