import moment from 'moment'
import 'moment/locale/ko'

moment.updateLocale('ko', {
  week: {
    dow: 1,
  },
})

export const fmt = moment.HTML5_FMT.DATE

const emptyWeek = () => Array(7).fill(null)

export const getDays = month => {
  const start = month.clone().startOf('month')
  const end = month.clone().endOf('months')
  const current = start.clone()
  const result = []
  let week = emptyWeek()
  while (current.isSameOrBefore(end)) {
    week[current.day()] = current.clone()
    if (current.day() === 6 || current.isSame(end, 'days')) {
      result.push(week)
      week = emptyWeek()
    }
    current.add(1, 'days')
  }
  return result
}

export const CALENDAR_PRESETS = {
  TODAY: {
    text: '오늘',
    duration: 1,
    unit: 'days',
    protocol: 'today',
  },
  YESTER_DAY: {
    text: '어제',
    duration: 1,
    unit: 'days',
    protocol: 'yesterday',
  },
  THIS_WEEK: {
    text: '이번 주',
    duration: 1,
    unit: 'weeks',
    protocol: 'thisWeek',
  },
  LAST_WEEK: {
    text: '지난 주',
    duration: 1,
    unit: 'weeks',
    protocol: 'lastWeek',
  },
  LAST_7_DAYS: {
    text: '최근 7일',
    duration: 7,
    unit: 'days',
    protocol: 'last7Days',
  },
  LAST_14_DAYS: {
    text: '최근 14일',
    duration: 14,
    unit: 'days',
    protocol: 'last14Days',
  },
  LAST_30_DAYS: {
    text: '최근 30일',
    duration: 30,
    unit: 'days',
    protocol: 'last30Days',
  },
  THIS_MONTH: {
    text: '이번 달',
    duration: 1,
    unit: 'months',
    protocol: 'thisMonth',
  },
  LAST_MONTH: {
    text: '지난 달',
    duration: 1,
    unit: 'months',
    protocol: 'lastMonth',
  },
  ALL: {
    text: '전체',
    duration: null,
    unit: null,
    protocol: 'all',
  },
  CUSTOM: {
    text: '맞춤 설정',
    duration: null,
    unit: 'days',
    protocol: null,
  },
}

export const getVisibleMonths = end => ({
  left: end.clone().startOf('month').subtract(1, 'months'),
  right: end.clone().startOf('month'),
})

export const getPresetDays = (min, max) => ({
  today: moment(),
  yesterday: moment().subtract(1, 'days'),
  thisWeekStart: min
    ? moment.max(moment().startOf('weeks'), min.clone())
    : moment().startOf('weeks'),
  thisWeekEnd: max
    ? moment.min(moment().endOf('weeks'), max.clone())
    : moment().endOf('weeks'),
  lastWeekStart:
    min &&
    min.isBetween(
      moment().subtract(1, 'weeks').startOf('weeks'),
      moment().subtract(1, 'weeks').endOf('weeks'),
      'days',
      '[]'
    )
      ? min
      : moment().subtract(1, 'weeks').startOf('weeks'),
  lastWeekEnd: moment().subtract(1, 'weeks').endOf('weeks'),
  thisMonthStart: min
    ? moment.max(moment().startOf('months'), min.clone())
    : moment().startOf('months'),
  thisMonthEnd: max
    ? moment.min(moment().endOf('months'), max.clone())
    : moment().endOf('months'),
  lastMonthStart:
    min &&
    min.isBetween(
      moment().subtract(1, 'months').startOf('months'),
      moment().subtract(1, 'months').endOf('months'),
      'days',
      '[]'
    )
      ? min
      : moment().subtract(1, 'months').startOf('months'),
  lastMonthEnd: moment().subtract(1, 'months').endOf('months'),
  last7daysStart: min
    ? moment.max(moment().subtract(6, 'days'), min.clone())
    : moment().subtract(6, 'days'),
  last14daysStart: min
    ? moment.max(moment().subtract(13, 'days'), min.clone())
    : moment().subtract(13, 'days'),
  last30daysStart: min
    ? moment.max(moment().subtract(29, 'days'), min.clone())
    : moment().subtract(29, 'days'),
})

export const getPresetRange = (preset, min, max) => {
  const days = getPresetDays(min, max)
  if (preset === CALENDAR_PRESETS.TODAY) {
    return {
      start: days.today,
      end: days.today,
    }
  } else if (preset === CALENDAR_PRESETS.YESTER_DAY) {
    return {
      start: days.yesterday,
      end: days.yesterday,
    }
  } else if (preset === CALENDAR_PRESETS.THIS_WEEK) {
    return {
      start: days.thisWeekStart,
      end: days.thisWeekEnd,
    }
  } else if (preset === CALENDAR_PRESETS.LAST_WEEK) {
    return {
      start: days.lastWeekStart,
      end: days.lastWeekEnd,
    }
  } else if (preset === CALENDAR_PRESETS.LAST_7_DAYS) {
    return {
      start: days.last7daysStart,
      end: days.today,
    }
  } else if (preset === CALENDAR_PRESETS.LAST_14_DAYS) {
    return {
      start: days.last14daysStart,
      end: days.today,
    }
  } else if (preset === CALENDAR_PRESETS.LAST_30_DAYS) {
    return {
      start: days.last30daysStart,
      end: days.today,
    }
  } else if (preset === CALENDAR_PRESETS.THIS_MONTH) {
    return {
      start: days.thisMonthStart,
      end: days.thisMonthEnd,
    }
  } else if (preset === CALENDAR_PRESETS.LAST_MONTH) {
    return {
      start: days.lastMonthStart,
      end: days.lastMonthEnd,
    }
  } else if (preset === CALENDAR_PRESETS.ALL) {
    return {
      start: min,
      end: max,
    }
  } else if (preset === CALENDAR_PRESETS.CUSTOM) {
    return {
      start: days.today,
      end: days.today,
    }
  }
}

export const getDuration = (preset, start, end) =>
  preset.duration || end.diff(start, 'days') + 1

export const getDisplayText = (
  presetValue,
  startString,
  endString,
  min,
  max,
  duration
) => {
  const { start: presetStart, end: presetEnd } = getPresetRange(
    presetValue,
    min,
    max
  )
  const displayStart = moment(startString)
  const displayEnd = moment(endString)
  const days = getPresetDays(min, max)
  if (
    presetValue !== CALENDAR_PRESETS.CUSTOM &&
    presetStart.isSame(displayStart, 'days') &&
    presetEnd.isSame(displayEnd, 'days')
  ) {
    return presetValue.text
  } else if (
    presetValue === CALENDAR_PRESETS.TODAY ||
    presetValue === CALENDAR_PRESETS.YESTER_DAY
  ) {
    if (
      displayStart.isSame(days.today, 'days') &&
      displayEnd.isSame(days.today, 'days')
    ) {
      return CALENDAR_PRESETS.TODAY.text
    } else if (
      displayStart.isSame(days.yesterday, 'days') &&
      displayEnd.isSame(days.yesterday, 'days')
    ) {
      return CALENDAR_PRESETS.YESTER_DAY.text
    } else {
      return `${moment().diff(displayStart, 'days')}일 전`
    }
  } else if (
    presetValue === CALENDAR_PRESETS.THIS_WEEK ||
    presetValue === CALENDAR_PRESETS.LAST_WEEK
  ) {
    if (
      displayStart.isSame(days.thisWeekStart, 'days') &&
      displayEnd.isSame(days.thisWeekEnd, 'days')
    ) {
      return CALENDAR_PRESETS.THIS_WEEK.text
    } else if (
      displayStart.isSame(days.lastWeekStart, 'days') &&
      displayEnd.isSame(days.lastWeekEnd, 'days')
    ) {
      return CALENDAR_PRESETS.LAST_WEEK.text
    } else {
      return `${moment().diff(displayStart, 'weeks')}주 전`
    }
  } else if (
    presetValue === CALENDAR_PRESETS.THIS_MONTH ||
    presetValue === CALENDAR_PRESETS.LAST_MONTH
  ) {
    if (
      displayStart.isSame(days.thisMonthStart, 'days') &&
      displayEnd.isSame(days.thisMonthEnd, 'days')
    ) {
      return CALENDAR_PRESETS.THIS_MONTH.text
    } else if (
      displayStart.isSame(days.lastMonthStart, 'days') &&
      displayEnd.isSame(days.lastMonthEnd, 'days')
    ) {
      return CALENDAR_PRESETS.LAST_MONTH.text
    } else {
      return `${moment().diff(displayStart, 'months')}개월 전`
    }
  } else {
    return `${duration}일 단위`
  }
}
