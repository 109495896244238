import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../features/dialog/Dialog'

const Confirm = ({ children, ...rest }) => (
  <Dialog type="confirm" {...rest}>
    {children}
  </Dialog>
)

Confirm.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  ok: PropTypes.func,
  cancel: PropTypes.func,
  close: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  marginLeft: PropTypes.number,
}

export default Confirm
