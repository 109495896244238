// moment의 aCashCommons.js에서 가져와서 수정해서 사용함.

export const cashChargeMethodItems = [
  {
    id: 0,
    status: 'CREDIT_CARD',
    label: '신용카드 일반결제',
  },
  {
    id: 1,
    status: 'VIRTUAL_ACCOUNT',
    label: '무통장입금',
  }, // 해외광고주일 경우에는 "(주)카카오"임 (난제 수정할것.)
  {
    id: 2,
    status: 'KAKAOPAY_CARD_AUTO',
    label: '신용카드 자동결제',
  },
  {
    id: 3,
    status: 'ADMIN_CASH',
    label: '유상캐시 관리자 충전',
  },
  {
    id: 4,
    status: 'ADMIN_FREECASH',
    label: '무상캐시 사용',
    member: '',
  },
  {
    id: 5,
    status: 'VAT_CASH_REFUND',
    label: '유상캐시 환급',
  },
  {
    id: 6,
    status: 'CPMS',
    label: '메시지 미발송분',
  },
  {
    id: 7,
    status: 'ADMIN_ADJUST_FREECASH',
    label: '무상캐시 금액보정',
  },
  {
    id: 8,
    status: 'CPT',
    label: '계약해지 환급금',
  },
  {
    id: 9,
    status: 'KAKAOPAY_CARD',
    label: '카카오페이카드',
  },
  {
    id: 10,
    status: 'ADV_RET_CASH',
    label: '유상캐시 광고주 환원',
  },
  {
    id: 11,
    status: 'ADV_RET_FREE_CASH',
    label: '무상캐시 광고주 환원',
  },
]

export const withdrawMethodItems = [
  {
    id: 0,
    status: 'CREDIT_CARD',
    label: '신용카드 승인취소',
  },
  {
    id: 1,
    status: 'VIRTUAL_ACCOUNT',
    label: '계좌환불',
  },
]

export const getOwnerLabel = (memberEmail, kakaoEmail) => {
  if (checkEmpty(memberEmail) || checkEmpty(kakaoEmail)) return ''
  return memberEmail === kakaoEmail ? '(나)' : ''
}

export function fileDownloadBlob(data, filename, mime) {
  const encodedData = encodeUTF16(data)
  const blob = new Blob([encodedData], {
    type: mime || 'application/octet-stream',
  })

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE
    window.navigator.msSaveBlob(blob, filename)
  } else {
    // Chrome
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)
    tempLink.setAttribute('target', '_blank')
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }
}

function encodeUTF16(str) {
  const buf = new ArrayBuffer(str.length * 2)
  const bufArray = new Uint16Array(buf)
  for (let i = 0, len = str.length; i < len; i++) {
    bufArray[i] = str.charCodeAt(i)
  }
  return bufArray
}

export const handleDownloadChargeHistory = (data, fileName) => {
  if (data) {
    const prefix = '\uFEFF'
    const t1 = [
      '거래일시',
      '구분',
      '거래유형',
      '거래수단',
      '금액',
      '거래자',
    ].join('\t')
    const header = prefix.concat(t1, '\n')
    const result = data.reduce((prev, v) => {
      const { date, type, method, methodDetail, amount, trader } =
        getChargeHistoryInfoLabel(v)

      return prev.concat(
        [date, type, method, methodDetail, amount, trader]
          .join('\t')
          .concat('\n')
      )
    }, header)
    fileDownloadBlob(result, fileName)
  }
}
export const getChargeHistoryInfoLabel = v => {
  if (checkEmpty(v)) return null

  const {
    dt,
    type,
    chargeMethod,
    withdrawMethod,
    methodDetail,
    amount,
    memberEmail,
    memberStatus,
    memberType,
  } = v

  const methodItems = chargeMethod ? cashChargeMethodItems : withdrawMethodItems
  const methodItem = methodItems
    ? methodItems.find(
        ({ status }) => status === chargeMethod || status === withdrawMethod
      )
    : null

  return {
    memberEmail: DspAccountHelper.Status.toString(memberStatus, memberEmail),
    trader: getTraderLabel(memberType, memberStatus, memberEmail),
    type,
    date: dt,
    methodDetail: checkNotEmpty(methodDetail) ? methodDetail : '-',
    method: methodItem?.label || '-',
    amount: convertToCommaSeparatedString(amount),
  }
}

const DspAccountHelper = {
  Status: {
    toString(status, defaultValue = '', masking = false) {
      if (isUndefinedOrNull(status)) return defaultValue
      switch (status) {
        case 'DORMANCY':
          return masking ? '-' : '휴면 회원'
        case 'WITHDRAWAL':
          return '탈퇴 회원'
        default:
          return defaultValue
      }
    },
  },
}

export function checkEmpty(char) {
  return !checkNotEmpty(char)
}

export function isUndefinedOrNull(char) {
  return char === null || char === undefined || char === 'null'
}

export const getTraderLabel = (memberType, memberStatus, memberEmail) => {
  switch (memberType) {
    case 'DSP_ACCOUNT':
      return DspAccountHelper.Status.toString(memberStatus, memberEmail)
    case 'AGENCY_ACCOUNT':
      return '카카오광고 통합에이전시'
    case 'SYSTEM':
    case 'INHOUSE_ACCOUNT':
      return '(주)카카오'
    default:
      return ''
  }
}

export function checkNotEmpty(char) {
  return !isUndefinedOrNull(char) && char !== ''
}

export function convertToCommaSeparatedString(number) {
  if (checkNotEmpty(number)) {
    const numberArray = String(number).split('.')
    const positive = numberArray[0].replace(/(\d)(?=(\d\d\d)+$)/g, '$1,')
    const decimal = numberArray.length > 1 ? `.${numberArray[1]}` : ''
    return `${positive}${decimal}`
  }
  return '0'
}

export const getFreeCashHistoryInfoValue = v => {
  if (checkEmpty(v)) return

  const {
    description,
    amount,
    endDt,
    status,
    issueMemberKakaoEmail,
    createDt,
    issueMemberStatus,
  } = v

  return {
    description: description || '',
    member: DspAccountHelper.Status.toString(
      issueMemberStatus,
      issueMemberKakaoEmail
    ),
    type: status,
    createDt,
    endDt,
    amount: convertToCommaSeparatedString(amount),
  }
}

const getAmountLabel = (amount, status) => {
  switch (status) {
    case 'RESERVED':
    case 'STANDBY':
      return '-'
    case 'APPROVED':
      return `${convertToCommaSeparatedString(amount)}원`
    case 'REJECTED':
      return '0원'
    default:
      return null
  }
}

export const getWithdrawHistoryInfoValue = v => {
  if (checkEmpty(v)) return null

  const {
    requestDt,
    requestAmount,
    amount,
    memberType,
    memberEmail,
    memberStatus,
    status,
    resultDt,
    rejectReason,
  } = v

  return {
    requestDt,
    amount: getAmountLabel(amount, status),
    requestAmount,
    resultDt,
    trader: getTraderLabel(memberType, memberStatus, memberEmail),
    memberEmail,
    status,
    rejectReason,
  }
}

export function coerceAtLeast(value, minimum) {
  return value < minimum ? minimum : value
}
