import 'assets/css/common_reform.css'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { LOGIN_STATUS } from 'features/login/LoginStatus'
import useLogin from 'features/login/useLogin'
import AppHeader from 'components/AppHeader'
import AppFooter from 'components/AppFooter'
import KakaoIndex from 'components/KakaoIndex'
import KakaoWrap from 'components/KakaoWrap'
import Toast from 'features/dialog/Toast'
import Unauthorized from 'components/error/Unauthorized'
import NotFound from 'components/error/NotFound'
import InternalServerError from 'components/error/InternalServerError'
import ServiceUnavailable from 'components/error/ServiceUnavailable'
import Withdrawal from 'components/notice/Withdrawal'
import KeywordAd from 'components/microApps/KeywordAd'
import MyInfo from 'features/myInfo/MyInfo'
import SettingsLayout from 'features/settings/SettingsLayout'
import Jump from 'features/agency/Jump'
import OtpLogin from 'features/login/OtpLogin'
import NoticeBoardList from 'features/board/NoticeBoardList'
import NoticeBoardView from 'features/board/NoticeBoardView'
import useTiaraTracker from 'utils/hooks/useTiaraTracker'
import useDialogClass from 'features/layout/useDialogClass'
import BrowserAlert from 'components/BrowserAlert'
import CruxAdAccount from 'components/microApps/CruxAdAccount'
import AdAccountListRedirect from 'features/adAccount/AdAccountListRedirect'
import CreateAdAccountRedirect from 'features/adAccount/CreateAdAccountRedirect'

const App = () => {
  useLogin()
  useTiaraTracker()
  useDialogClass()
  const { loginStatus } = useSelector(state => state.dspAccount)
  return (
    ![LOGIN_STATUS.IDLE, LOGIN_STATUS.UNAUTHORIZED].includes(loginStatus) && (
      <>
        <KakaoIndex />
        <KakaoWrap>
          <AppHeader />
          <div id="kakaoMain" style={{ minHeight: '100%' }}>
            <main id="kakaoContent">
              <div id="mFeature" role="navigation" />
              <article id="mArticle">
                <Switch>
                  <Route exact path="/error/401" component={Unauthorized} />
                  <Route
                    exact
                    path="/error/500"
                    component={InternalServerError}
                  />
                  <Route
                    exact
                    path="/error/503"
                    component={ServiceUnavailable}
                  />
                  <Route exact path="/withdrawal" component={Withdrawal} />
                  <Route exact path="/otp" component={OtpLogin} />
                </Switch>
                {loginStatus === LOGIN_STATUS.FAIL && (
                  <Redirect to="/error/500" />
                )}
                {loginStatus === LOGIN_STATUS.SUCCESS && (
                  <>
                    <Route
                      path="/:accountId(\d+)/settings"
                      component={SettingsLayout}
                    />
                    <Switch>
                      <Route path="/:accountId(\d+)/*" component={KeywordAd} />
                    </Switch>
                    <Switch>
                      <Route
                        exact
                        path="/noticeboard"
                        component={NoticeBoardList}
                      />
                      <Route
                        exact
                        path="/noticeboard/:id(\d+)"
                        component={NoticeBoardView}
                      />
                      <Route exact path="/myinfo" component={MyInfo} />
                      <Route path="/adaccount" component={CruxAdAccount} />
                      <Route
                        exact
                        path={['/adAccounts', '/adAccounts/invite']}
                        component={AdAccountListRedirect}
                      />
                      <Route
                        exact
                        path="/adAccounts/new"
                        component={CreateAdAccountRedirect}
                      />
                      <Route
                        exact
                        path="/jump/:accountId(\d+)"
                        component={Jump}
                      />
                      <Route exact path="/" component={AdAccountListRedirect} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </>
                )}
              </article>
            </main>
          </div>
          <AppFooter />
        </KakaoWrap>
        <Toast />
        <BrowserAlert />
      </>
    )
  )
}

export default App
