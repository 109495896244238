import React from 'react'
import PropTypes from 'prop-types'
import Loading from '../loading/Loading'
import NoDataTable from 'components/table/NoDataTable'

const LoadingTable = ({ colSpan }) => (
  <NoDataTable colSpan={colSpan}>
    <Loading />
  </NoDataTable>
)

LoadingTable.propTypes = {
  colSpan: PropTypes.number.isRequired,
}

export default LoadingTable
