import React, { forwardRef, useState } from 'react'
import TextInput from './TextInput'
import PropTypes from 'prop-types'

const CounterTextInput = forwardRef((props, ref) => {
  const { maxLength, onInput, ...rest } = props
  const count = value =>
    maxLength - value.length > 0 ? maxLength - value.length : 0
  const [counter, setCounter] = useState(
    props.value ? count(props.value) : maxLength
  )
  const handleInput = event => {
    const {
      target: { value },
    } = event
    setCounter(count(value))
    if (onInput) {
      onInput(value, event)
    }
  }

  return (
    <TextInput {...rest} maxLength={maxLength} onInput={handleInput} ref={ref}>
      <span className="num_byte">
        <span className="screen_out">작성가능한 총 텍스트 수</span> {counter}
      </span>
    </TextInput>
  )
})

CounterTextInput.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'search',
    'email',
    'url',
    'tel',
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  onInvalid: PropTypes.func,
  validityMessage: PropTypes.shape({
    badInput: PropTypes.string,
    customError: PropTypes.string,
    patternMismatch: PropTypes.string,
    rangeOverflow: PropTypes.string,
    rangeUnderflow: PropTypes.string,
    stepMismatch: PropTypes.string,
    tooLong: PropTypes.string,
    tooShort: PropTypes.string,
    typeMismatch: PropTypes.string,
    valueMissing: PropTypes.string,
  }),
}
CounterTextInput.displayName = 'CounterTextInput'

export default CounterTextInput
