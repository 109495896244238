import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getAdAccount } from 'api/accountAPI'

const Jump = () => {
  const { accountId } = useParams()
  const history = useHistory()
  useEffect(() => {
    ;(async () => {
      try {
        await getAdAccount(accountId)
        history.replace(`/${accountId}/dashboard`)
      } catch (ignore) {
        history.replace(`/adAccounts/invite?adAccountId=${accountId}`)
      }
    })()
  }, [accountId, history])
  return <></>
}

export default Jump
