import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import NoneAnchor from 'components/NoneAnchor'

const TooltipPortal = ({ active, top, left, message }) =>
  active
    ? createPortal(
        <div
          className="tooltip_basic ly_tooltip ly_tooltip_type2"
          style={{
            position: 'absolute',
            left,
            top,
            zIndex: 1001,
          }}>
          <p className="txt_tooltip">{message}</p>
        </div>,
        document.body
      )
    : null

TooltipPortal.propTypes = {
  active: PropTypes.bool.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
}

const Tooltip = ({
  style,
  iconStyle,
  className = 'ico_comm ico_help',
  children,
}) => {
  const [active, setActive] = useState(false)
  const ref = useRef()
  const [offset, setOffset] = useState({
    top: -9999,
    left: -9999,
  })
  const set = () => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setOffset({
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
      })
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', set, { passive: true })
    window.addEventListener('resize', set)
    return () => {
      window.addEventListener('scroll', set)
      window.removeEventListener('resize', set)
    }
  }, [])

  return (
    <NoneAnchor
      ref={ref}
      className="link_g link_help"
      onMouseEnter={() => {
        set()
        setActive(true)
      }}
      style={style}
      onMouseLeave={() => setActive(false)}
      onClick={event => event.preventDefault()}>
      <span className={className} style={iconStyle}>
        도움말
      </span>
      <TooltipPortal
        message={children}
        active={active}
        top={offset.top + 20}
        left={offset.left + 10}
      />
    </NoneAnchor>
  )
}
Tooltip.propTypes = {
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
}
export default Tooltip
