import React, { useEffect, useRef, useState } from 'react'
import Contact from './Contact'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Notification from 'features/myInfo/Notification'
import Modal from 'components/dialog/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import {
  getWithdrawalCheck,
  publishPinNumberForWithdrawal,
  verifyPinNumberForWithdrawal,
  withdrawalDspAccount,
} from 'api/accountAPI'
import Alert from 'components/dialog/Alert'
import TextInput from 'components/form/TextInput'
import Button from 'components/form/Button'
import Checkbox from 'components/form/Checkbox'
import AutoPayCard from './autoPayCard/AutoPayCard'
import { success } from '../../utils/toastUtils'

const {
  REACT_APP_BUSINESS_HOST,
  REACT_APP_BRANDSEARCH_HOST,
  REACT_APP_MOMENT_HOST,
  REACT_APP_PF_HOST,
} = process.env

const DSP_ERROR_CODE = {
  KEYWORDAD: 2210,
  MOMENT: 2201,
  BRANDSEARCH: 2206,
  PF: 2207,
}
const MyInfo = () => {
  const dispatch = useDispatch()
  const dspAccount = useSelector(state => state.dspAccount.current)
  const integrationInfo = useSelector(state => state.dspAccount.integrationInfo)
  const history = useHistory()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const tab = params.get('tab') || 'contact'
  const [agreeWithdrawal, setAgreeWithdrawal] = useState(false)
  const [errorCode, setErrorCode] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [pinNumber, setPinNumber] = useState('')
  const [pinNumberLoading, setPinNumberLoading] = useState(false)
  const [pinNumberValid, setPinNumberValid] = useState(false)
  const intervalRef = useRef(null)
  const [count, setCount] = useState(null)
  const [publishButtonText, setPublishButtonText] = useState('인증번호 발송')

  const startTimer = () => {
    stopTimer()
    setCount(90)
    intervalRef.current = setInterval(timer, 1000)
  }

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const timer = () => {
    setCount(prevState => {
      if (prevState > 0) {
        return prevState - 1
      } else {
        stopTimer()
        return 0
      }
    })
  }

  useEffect(
    () => () => {
      stopTimer()
    },
    []
  )

  const handleAgreeChange = event => {
    const { target } = event
    setAgreeWithdrawal(target.checked)
  }
  const handlePinNumberChange = event => {
    const { target } = event
    const { value } = target
    setPinNumber(value)
  }
  const checkWithdrawal = async () => {
    setErrorCode(null)
    try {
      await getWithdrawalCheck(dspAccount.id)
      setPublishButtonText('인증번호 발송')
      setCount(null)
      stopTimer()
      setErrorCode(null)
      setErrorMessage('')
      setPinNumber('')
      setPinNumberLoading(false)
      setPinNumberValid(false)
      showPinNumberModal()
    } catch (error) {
      const code = error?.response?.data?.errorCode
      const message = error?.response?.data?.message
      if (
        [
          DSP_ERROR_CODE.KEYWORDAD,
          DSP_ERROR_CODE.BRANDSEARCH,
          DSP_ERROR_CODE.MOMENT,
          DSP_ERROR_CODE.PF,
        ].includes(code)
      ) {
        setErrorCode(code)
        dispatch(showModal('withdrawalFailModal'))
      } else {
        console.error(code, message)
        dispatch(showModal('withdrawalFailAlert'))
      }
    }
  }
  const handlePublish = async () => {
    dispatch(showModal('sendPinNumberForWithdrawalAlert'))
    startTimer()
    setPublishButtonText('재발송')
    setPinNumberLoading(true)
    try {
      await publishPinNumberForWithdrawal()
    } catch (error) {
      setPublishButtonText('인증번호 발송')
      setCount(null)
      stopTimer()
      setErrorMessage(
        '인증번호 발송에 실패했습니다. 인증번호 발송을 한번 더 시도하세요.'
      )
    }
    setPinNumberLoading(false)
  }
  const handleVerify = async () => {
    if (pinNumber === '') {
      setErrorMessage('인증번호를 입력하세요.')
      setPinNumberValid(false)
    } else if (count === 0) {
      setErrorMessage(
        '인증번호 입력 제한시간을 초과하였습니다. 재발송을 통해 다시 인증하세요.'
      )
      setPinNumberValid(false)
    } else {
      try {
        await verifyPinNumberForWithdrawal(dspAccount.phone, pinNumber)
        setPinNumberValid(true)
        setErrorMessage('')
        success('휴대폰 번호 인증에 성공하였습니다.')
      } catch (error) {
        setErrorMessage(error.message)
        setPinNumberValid(false)
      }
    }
  }
  const handleOk = async () => {
    try {
      await withdrawalDspAccount(dspAccount.id)
      history.push('/withdrawal')
    } catch (error) {
      dispatch(showModal('withdrawalFailAlert'))
      console.error(error.message)
    }
  }
  const showPinNumberModal = () => {
    dispatch(showModal('withdrawalPinNumberModal'))
  }
  const withdrawalOrigin = () => {
    setAgreeWithdrawal(false)
    dispatch(showModal('withdrawalCheckModal'))
  }

  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        내 정보 본문
      </h2>
      <div className="settingmyinfo_wrap">
        <div className="dsp_tit">
          <div className="tit_dsp">
            <h3 className="tit_subject">내 정보</h3>
            <div className="f_right">
              <div className="single_wrap">
                <button
                  type="button"
                  className="btn_gm gm_line"
                  onClick={() => {
                    integrationInfo &&
                    integrationInfo.redirectStatus === 'FALSE'
                      ? dispatch(showModal('withdrawalIntegration'))
                      : withdrawalOrigin()
                  }}>
                  <span className="inner_g">회원탈퇴</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <strong className="screen_out">내 정보 메뉴 탭</strong>
        <ul className="tab_g">
          <li className={tab === 'contact' ? 'on' : ''}>
            <Link to="/myinfo" className="link_tab">
              계정정보 설정
            </Link>
          </li>
          <li className={tab === 'autopaycard' ? 'on' : ''}>
            <Link to="?tab=autopaycard" className="link_tab">
              자동결제 카드 설정
            </Link>
          </li>
          <li className={tab === 'notification' ? 'on' : ''}>
            <Link to="?tab=notification" className="link_tab">
              알림 설정
            </Link>
          </li>
        </ul>
        {tab === 'contact' && <Contact />}
        {tab === 'autopaycard' && <AutoPayCard />}
        {tab === 'notification' && <Notification />}
      </div>
      <Modal
        id="withdrawalCheckModal"
        title="회원탈퇴"
        className="withdrawal_layer"
        disabledOk={!agreeWithdrawal}
        ok={checkWithdrawal}>
        <form>
          <fieldset>
            <legend className="screen_out">
              회원 탈퇴 전 안내사항 동의 폼
            </legend>
            <p className="subtit_layer">
              회원 탈퇴 전에 안내사항을 반드시 확인해 주세요.
            </p>
            <ul className="list_guide">
              <li>
                탈퇴 즉시 회원 정보 및 소유한 픽셀 &amp; SDK는 모두 삭제되며,
                더이상 카카오광고 서비스를 이용할 수 없습니다.
              </li>
              <li>
                픽셀 &amp; SDK 삭제 시, 해당 픽셀 &amp; SDK를 활용하고 있는
                광고계정에서 더이상 정상적인 광고 집행 및 전환 추적이 불가능
                합니다. 보유한 픽셀 &amp; SDK 리스트는 통합회원 전환 시 확인
                가능합니다.
              </li>
              <li>탈퇴 완료 후 탈퇴에 대한 철회는 불가능합니다.</li>
            </ul>
            <Checkbox checked={agreeWithdrawal} onChange={handleAgreeChange}>
              위 안내사항을 모두 확인하였으며, 이에 동의합니다.
            </Checkbox>
          </fieldset>
        </form>
      </Modal>
      <Modal
        id="withdrawalFailModal"
        title="회원탈퇴"
        className="withdrawal_layer"
        showCancel={false}>
        <p className="subtit_layer">
          아래 사유로 인해 삭제 신청이 불가능 합니다.
        </p>
        <ul className="list_cause">
          <li>
            마스터 또는 멤버인 광고계정이 있습니다.
            <br />
            광고계정을 삭제하거나 나가기해야 탈퇴가 가능합니다.
            {errorCode === DSP_ERROR_CODE.KEYWORDAD && (
              <Link
                to="/adaccount/all"
                onClick={() => {
                  dispatch(hideModal())
                }}
                className="btn_gs">
                <span className="inner_g">광고계정 확인</span>
              </Link>
            )}
            {errorCode === DSP_ERROR_CODE.BRANDSEARCH && (
              <a
                href={`${REACT_APP_BRANDSEARCH_HOST}/adaccount`}
                onClick={() => {
                  dispatch(hideModal())
                }}
                className="btn_gs">
                <span className="inner_g">광고계정 확인</span>
              </a>
            )}
            {errorCode === DSP_ERROR_CODE.MOMENT && (
              <a
                href={`${REACT_APP_MOMENT_HOST}/adaccount/all`}
                onClick={() => {
                  dispatch(hideModal())
                }}
                className="btn_gs">
                <span className="inner_g">광고계정 확인</span>
              </a>
            )}
            {errorCode === DSP_ERROR_CODE.PF && (
              <a
                href={`${REACT_APP_PF_HOST}/profiles`}
                onClick={() => {
                  dispatch(hideModal())
                }}
                className="btn_gs">
                <span className="inner_g">광고계정 확인</span>
              </a>
            )}
          </li>
        </ul>
      </Modal>
      <Modal
        id="withdrawalPinNumberModal"
        title="회원 탈퇴"
        className="withdrawal_layer"
        disabledOk={!pinNumberValid}
        okText="탈퇴"
        ok={handleOk}>
        <p className="subtit_layer">회원 정보 확인</p>
        <p className="desc_layer">
          회원 탈퇴를 위해서 휴대폰 인증 절차가 필요합니다.
        </p>
        <table className="tbl_layer">
          <caption className="hide_caption">회원 정보 표</caption>
          <colgroup>
            <col style={{ width: 161 }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">카카오계정</th>
              <td>
                <div className="inner_cell">{dspAccount.kakaoEmail}</div>
              </td>
            </tr>
            <tr>
              <th scope="row">이름</th>
              <td>
                <div className="inner_cell">{dspAccount.name}</div>
              </td>
            </tr>
            <tr>
              <th scope="row">안내받을 이메일</th>
              <td>
                <div className="inner_cell">{dspAccount.email}</div>
              </td>
            </tr>
            <tr>
              <th scope="row" className="th_phone">
                휴대폰<em className="fc_warn2">(인증필수)</em>
              </th>
              <td>
                {pinNumberValid ? (
                  <div className="inner_cell">
                    <div className="group_inp">
                      <span className="txt_phone">{dspAccount.phone}</span>
                      <div className="single_wrap">
                        <Button className="btn_gm gm_line" disabled={true}>
                          인증 완료
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="inner_cell">
                    <div className="group_inp">
                      <span className="txt_phone">{dspAccount.phone}</span>
                      <div className="single_wrap">
                        <Button
                          className="btn_gm gm_line"
                          onClick={handlePublish}
                          disabled={pinNumberLoading}>
                          {publishButtonText}
                        </Button>
                      </div>
                    </div>
                    <div className="group_inp verifi_inp">
                      <TextInput
                        maxLength={8}
                        value={pinNumber}
                        onChange={handlePinNumberChange}
                        placeholder="인증번호"
                      />
                      <div className="single_wrap">
                        <Button
                          className="btn_gm gm_bl"
                          disabled={count === null}
                          onClick={handleVerify}>
                          인증
                        </Button>
                      </div>
                      {count !== null && (
                        <div className="timer_set">
                          <span className="ico_comm ico_timer">남은시간</span>
                          {Math.trunc(count / 60)}:
                          {count % 60 < 10 ? `0${count % 60}` : count % 60}
                        </div>
                      )}
                    </div>
                    {errorMessage && (
                      <p className="txt_error">{errorMessage}</p>
                    )}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
      <Modal
        id="withdrawalIntegration"
        title="회원탈퇴"
        className="withdrawal_layer"
        showOk={false}
        showCancel={false}>
        <div className="box_kakaobiz">
          <p className="desc_kakaobiz">
            회원탈퇴는 카카오 비즈니스에서 진행하실 수 있습니다.
          </p>
          <a
            href={`${REACT_APP_BUSINESS_HOST}/my-info/`}
            className="btn_gb gb_bl"
            target="_blank"
            rel="noopener noreferrer">
            <span className="inner_g">카카오 비즈니스로 이동</span>
          </a>
        </div>
      </Modal>
      <Alert
        id="sendPinNumberForWithdrawalAlert"
        title="인증번호 발송"
        ok={showPinNumberModal}>
        인증번호를 발송했습니다.
        <br />
        인증번호가 오지 않으면 입력하신 번호가 맞는지
        <br />
        확인하세요.
      </Alert>
      <Alert id="withdrawalFailAlert" title="회원 탈퇴 실패">
        회원탈퇴가 불가능합니다.
      </Alert>
    </>
  )
}

export default MyInfo
