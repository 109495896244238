import { hideToast, showToast } from '../features/dialog/dialogSlice'
import store from '../app/store'

let timer
const startTimer = () => {
  if (timer) {
    clearTimeout(timer)
    timer = undefined
  }
  timer = setTimeout(() => {
    store.dispatch(hideToast())
  }, 5000)
}
export const success = text => {
  if (text) {
    store.dispatch(
      showToast({
        type: 'success',
        text,
      })
    )
    startTimer()
  }
}

export const fail = text => {
  if (text) {
    store.dispatch(
      showToast({
        type: 'fail',
        text,
      })
    )
    startTimer()
  }
}
