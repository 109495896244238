import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

const useDialogClass = () => {
  const { modals } = useSelector(state => state.dialog)
  useLayoutEffect(() => {
    if (modals.length > 0) {
      document.body.classList.add('layer_on')
    } else {
      document.body.classList.remove('layer_on')
    }
    return () => {
      document.body.classList.remove('layer_on')
    }
  }, [modals])
}
export default useDialogClass
