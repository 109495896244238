import { Link } from 'react-router-dom'
import React from 'react'
import Loading from '../../../components/loading/Loading'
import PropTypes from 'prop-types'

export const CardMember = ({ dspId, memberList, isLoading }) => (
  <div className="card_summary card_member">
    <strong className="tit_card">멤버</strong>
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <em className="txt_info">{memberList && memberList.totalElements}명</em>
        <div className="define_adinfo">
          {memberList &&
            memberList.content.map(
              (member, i) =>
                i < 3 && (
                  <dl key={`cardMember|${i}`}>
                    {member.dspAccount.status === 'DORMANCY' ? (
                      <>
                        <dt>-</dt>
                        <dd>휴면회원</dd>
                      </>
                    ) : (
                      <>
                        <dt>{member.dspAccount.name}</dt>
                        <dd>
                          {member.memberType === 'MASTER' && (
                            <span className="ico_comm ico_master">마스터</span>
                          )}
                          {member.dspAccount.id === dspId && (
                            <span className="user_adinfo">(나)</span>
                          )}
                          <span className="data_adinfo">
                            {member.dspAccount.kakaoEmail}
                          </span>
                        </dd>
                      </>
                    )}
                  </dl>
                )
            )}
        </div>
        <Link to={`?tab=member`} className="btn_gs">
          {/* 비활성화시 in_active 클래스 추가 */}
          <span className="inner_g">관리</span>
        </Link>
      </>
    )}
  </div>
)
CardMember.propTypes = {
  dspId: PropTypes.string.isRequired,
  memberList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
