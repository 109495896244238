import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TiaraTracker from '@kakao/tiara-sdk-javascript/src/core/TiaraTracker'

const { REACT_APP_DEPLOY_PHASE } = process.env

const TRACK_SECTIONS = {
  AD: '광고관리',
  REPORT: '보고서',
  TOOLS: '도구',
  SETTINGS: '설정',
  DSP: '공통',
}

const TRACK_PAGES = [
  {
    name: '대시보드',
    pattern: /\/\d+\/dashboard/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_캠페인만들기',
    pattern: /\/\d+\/ads\/campaigns\/new/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_캠페인수정',
    pattern: /\/\d+\/ads\/campaigns\/\d+$/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_광고그룹만들기',
    pattern: /\/\d+\/ads\/campaigns\/\d+\/groups\/new/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_광고그룹수정',
    pattern: /\/\d+\/ads\/campaigns\/\d+\/groups\/\d+$/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_키워드만들기',
    pattern: /\/\d+\/ads\/campaigns\/\d+\/groups\/\d+\/keywords/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_키워드추가',
    pattern: /\/\d+\/ads\/campaigns\/\d+\/groups\/\d+\/addKeywords/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_소재등록_기존소재사용하기',
    pattern: /\/\d+\/ads\/campaigns\/\d+\/groups\/\d+\/creatives$/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_소재등록_편집하여새로등록',
    pattern:
      /\/\d+\/ads\/campaigns\/\d+\/groups\/\d+\/creatives\/save-as\/\d+/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '광고만들기_소재등록_새소재',
    pattern: /\/\d+\/ads\/campaigns\/\d+\/groups\/\d+\/creatives\/new/i,
    section: TRACK_SECTIONS.AD,
  },
  {
    name: '맞춤보고서_목록',
    pattern: /\/\d+\/report$/i,
    section: TRACK_SECTIONS.REPORT,
  },
  {
    name: '맞춤보고서_만들기',
    pattern: /\/\d+\/report\/add/i,
    section: TRACK_SECTIONS.REPORT,
  },
  {
    name: '맞춤보고서_수정',
    pattern: /\/\d+\/report\/\w+\/\d+/i,
    section: TRACK_SECTIONS.REPORT,
  },
  {
    name: '비즈채널관리_목록',
    pattern: /\/\d+\/channels$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '비즈채널관리_등록',
    pattern: /\/\d+\/channels\/add/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '비즈채널관리_상세',
    pattern: /\/\d+\/channels\/\d+/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '심사서류관리_목록',
    pattern: /\/\d+\/documents$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '심사서류관리_등록',
    pattern: /\/\d+\/documents\/add$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '심사서류관리_상세',
    pattern: /\/\d+\/documents\/add\/\d+/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고소재관리_목록',
    pattern: /\/\d+\/creatives$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고소재관리_등록',
    pattern: /\/\d+\/creatives\/add$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고소재관리_편집하여새로등록',
    pattern: /\/\d+\/creatives\/add\/\d+/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고소재관리_상세',
    pattern: /\/\d+\/creatives\/\d+/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '키워드플래너',
    pattern: /\/\d+\/keyword-planner/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '대량관리_다운로드',
    pattern: /\/\d+\/bulks\/download/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '대량관리_업로드',
    pattern: /\/\d+\/bulks\/upload/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '대량관리_광고그룹복사현황',
    pattern: /\/\d+\/bulks\/group-copy/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '대량관리_키워드플래너',
    pattern: /\/\d+\/bulks\/keyword-planner/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '이미지관리',
    pattern: /\/\d+\/images/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '픽셀sdk연동관리_목록',
    pattern: /\/\d+\/tracker$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '픽셀sdk연동관리_상세',
    pattern: /\/\d+\/tracker\/\d+/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '픽셀sdk연동관리_권한추가',
    pattern: /\/\d+\/tracker\/add/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고노출제한_목록',
    pattern: /\/\d+\/restrict-ip$/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고노출제한_등록',
    pattern: /\/\d+\/restrict-ip\/add/i,
    section: TRACK_SECTIONS.TOOLS,
  },
  {
    name: '광고계정관리',
    pattern: /\/\d+\/settings\/adaccount/i,
    section: TRACK_SECTIONS.SETTINGS,
  },
  {
    name: '광고캐시관리',
    pattern: /\/\d+\/settings\/cash/i,
    section: TRACK_SECTIONS.SETTINGS,
  },
  {
    name: '변경이력관리',
    pattern: /\/\d+\/editlog/i,
    section: TRACK_SECTIONS.SETTINGS,
  },
  {
    name: '현금영수증조회_목록',
    pattern: /\/\d+\/cash-receipts$/i,
    section: TRACK_SECTIONS.SETTINGS,
  },
  {
    name: '현금영수증조회_상세',
    pattern: /\/\d+\/cash-receipts\/\d+/i,
    section: TRACK_SECTIONS.SETTINGS,
  },
  {
    name: '공지사항_목록',
    pattern: /\/noticeboard$/i,
    section: TRACK_SECTIONS.DSP,
  },
  {
    name: '공지사항_상세',
    pattern: /\/noticeboard\/\d+/i,
    section: TRACK_SECTIONS.DSP,
  },
  {
    name: '내정보',
    pattern: /\/myinfo/i,
    section: TRACK_SECTIONS.DSP,
  },
  {
    name: '내광고계정',
    pattern: /\/adaccount\/all$/i,
    section: TRACK_SECTIONS.DSP,
  },
  {
    name: '광고계정만들기',
    pattern: /\/adaccount$/i,
    section: TRACK_SECTIONS.DSP,
  },
]

const useTiaraTracker = () => {
  const { pathname, search } = useLocation()
  const config = {
    svcDomain: 'keywordad.kakao.com',
    deployment:
      REACT_APP_DEPLOY_PHASE !== 'production' ? 'dev' : REACT_APP_DEPLOY_PHASE,
    autoClick: true,
  }
  TiaraTracker.getInstance().init(config)
  useEffect(() => {
    if (pathname && pathname !== '/') {
      const page = TRACK_PAGES.find(item => item.pattern.test(pathname))
      if (page) {
        TiaraTracker.getInstance()
          .setSection(page.section)
          .setPage(page.name)
          .trackPage(`${page.name}_화면보기`)
          .track()
      }
    }
  }, [pathname, search])
}

export default useTiaraTracker
