import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeMemberRequestable,
  fetchMemberHistory,
  fetchMemberList,
  fetchPendingMasterByMaster,
  fetchPendingMasterByMember,
  fetchPendingMemberList,
} from 'features/settings/adaccount/memberSlice'
import { fetchAdAccountWithoutStatus } from '../../adAccount/adAccountSlice'
import useScrollTop from 'features/layout/useScrollTop'
import { useHistory, useLocation } from 'react-router-dom'
import LoadingTable from 'components/table/LoadingTable'
import NoDataTable from 'components/table/NoDataTable'
import Pagination from 'components/pagination/Pagination'
import Tooltip from 'components/tooltip/Tooltip'
import Checkbox from 'components/form/Checkbox'
import moment from 'moment'
import cx from 'classnames'
import Modal from 'components/dialog/Modal'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import ModalApproveTransferMasterByMember from './ModalApproveTransferMasterByMember'
import MemberPendingMaster from './MemberPendingMaster'
import MemberJoinInviteStatus from './MemberJoinInviteStatus'
import NumberFormat from 'react-number-format'
import {
  postAdAccountMemberInvite,
  putMemberQuit,
  putMemberRelease,
  putTransferMaster,
} from 'api/accountAPI'
import Confirm from 'components/dialog/Confirm'
import TextInput from 'components/form/TextInput'
import { BANK_NAME } from 'features/settings/cash/CashConstants'
import PropTypes from 'prop-types'
import { fetchWithdrawAccount } from 'features/settings/cash/cashSlice'
import { fail, success } from 'utils/toastUtils'

const getMemberHistoryRowText = (
  event,
  action,
  dspAccountStatus,
  kakaoEmail
) => {
  const email =
    dspAccountStatus === 'DORMANCY'
      ? '휴면회원'
      : dspAccountStatus === 'WITHDRAWAL'
        ? '탈퇴회원'
        : kakaoEmail || ''
  if (event === 'INVITATION' && action === 'APPROVE') {
    return `${email}(이)가 멤버 초대를 수락하였습니다.`
  } else if (event === 'REQUEST' && action === 'APPROVE') {
    return `${email}의 멤버 요청을 수락하였습니다.`
  } else if (event === 'MASTER_CHANGE' && action === 'APPROVE') {
    return `${email}(이)가 마스터 권한 이전 요청을 수락하였습니다.`
  } else if (event === 'MASTER_CHANGE' && action === 'DECLINE') {
    return `${email}(이)가 마스터 권한 이전 요청을 거부하였습니다.`
  } else if (event === 'QUIT') {
    return `${email}(이)가 멤버에서 나가기하였습니다.`
  } else if (event === 'CANCEL') {
    return `${email}(을)를 멤버에서 해제하였습니다.`
  }
}
const initialState = {
  checkboxTransferMasterByMasterForAgreement: false,
}

const Member = ({ adAccountType }) => {
  useScrollTop()
  const h = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const { id: dspId, name: dspName } = useSelector(
    state => state.dspAccount.current
  )
  const adAccount = useSelector(state => state.adAccount.current)
  const { id: adAccountId, master, taxBillEmail } = adAccount
  const { isLoading: refundAccountLoading, info: refundAccount } = useSelector(
    state => state.cash.refundAccount
  )
  const {
    members: { isLoading: membersLoading, pagable: member },
    pendingMaster: { isLoading: pendingMasterLoading, pagable: pendingMaster },
    pendingMasterMember: {
      isLoading: pendingMasterMemberLoading,
      pagable: pendingMasterMember,
    },
    pendingMemberList: {
      isLoading: pendingMemberListLoading,
      pagable: pendingMemberList,
    },
    history: { isLoading: historyLoading, pagable: history },
  } = useSelector(state => state.member)

  const [memberPage, setMemberPage] = useState(
    parseInt(params.get('memberPage'), 10) || 0
  )
  const handleMemberPageChange = number => {
    h.push(`?tab=member&memberPage=${number}`)
    setMemberPage(number)
  }
  const [historyPage, setHistoryPage] = useState(
    parseInt(params.get('historyPage'), 10) || 0
  )
  const handleHistoryPageChange = number => {
    h.push(`?tab=member&historyPage=${number}`)
    setHistoryPage(number)
  }
  const handleMemberRequestable = async isRequestable => {
    try {
      const { payload } = await dispatch(
        changeMemberRequestable({
          adAccountId,
          isRequestable: !isRequestable,
        })
      )
      dispatch(fetchAdAccountWithoutStatus(adAccountId))
      success(
        `멤버 요청 거부가 ${
          payload.memberRequestable ? '해제' : '설정'
        }되었습니다.`
      )
    } catch (error) {
      fail(error.message)
    }
  }
  const showConfirmMemberQuit = () => {
    dispatch(showModal('confirmMemberQuit'))
  }
  const showConfirmMemberRelease = () => {
    dispatch(showModal('confirmMemberRelease'))
  }
  const [
    checkboxTransferMasterByMasterForAgreement,
    setCheckboxTransferMasterByMasterForAgreement,
  ] = useState(initialState.checkboxTransferMasterByMasterForAgreement)
  const showConfirmTransferMasterByMaster = () => {
    if (
      adAccountType === 'BUSINESS' ||
      (adAccountType === 'AGENCY' && !refundAccountLoading && refundAccount)
    ) {
      dispatch(showModal('modalTransferMasterByMasterForAgreement'))
    } else {
      dispatch(showModal('modalTransferMasterByMaster'))
    }
  }
  const handleConfirmMemberQuit = async () => {
    try {
      await putMemberQuit(adAccountId)
      h.push(`/adAccounts`)
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleConfirmMemberRelease = async () => {
    try {
      await putMemberRelease(checkedList[0])
      dispatch(
        fetchMemberList({
          adAccountId: adAccount.id,
          page: 0,
        })
      )
      dispatch(
        fetchMemberHistory({
          adAccountId: adAccount.id,
          page: 0,
        })
      )
      setCheckedList([])
      success(`멤버가 해제되었습니다.`)
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const [errorInviteMemberByEmail, setErrorInviteMemberByEmail] = useState('')
  const handleConfirmInviteMemberByEmail = async () => {
    const trimInviteEmail = inviteEmail.replace(/^\s*|\s*$/g, '')
    if (!trimInviteEmail.length) {
      setErrorInviteMemberByEmail('이메일주소를 입력하세요.')
      return false
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(trimInviteEmail)) {
      setErrorInviteMemberByEmail('이메일주소 형식이 올바르지 않습니다.')
      return false
    }
    try {
      await postAdAccountMemberInvite(adAccountId, {
        email: trimInviteEmail,
      })
      dispatch(
        fetchPendingMemberList({
          adAccountId,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
      dispatch(hideModal())
      setErrorInviteMemberByEmail('')
      success('멤버 초대를 요청했습니다.')
    } catch (error) {
      dispatch(hideModal())
      setErrorInviteMemberByEmail('')
      fail(error.message)
    }
  }
  const [inviteEmail, setInviteEmail] = useState('')
  const handleChange = event => {
    setInviteEmail(event.target.value)
  }

  const [checkedList, setCheckedList] = useState([])
  const handleCheckboxChange = (id, checked) => {
    if (checkedList.length < 1 && checked) {
      setCheckedList([...checkedList, id])
    } else {
      setCheckedList(checkedList.filter(item => item !== id))
    }
  }

  const handleConfirmTransferMasterByMaster = async () => {
    try {
      await putTransferMaster(checkedList[0])
      dispatch(fetchPendingMasterByMaster(adAccountId))
      success('마스터 권한을 이전 요청했습니다.')
    } catch (error) {
      fail(error.message)
    }
  }

  useEffect(() => {
    dispatch(fetchPendingMasterByMaster(adAccountId))
    dispatch(fetchPendingMasterByMember(adAccountId))
    dispatch(fetchWithdrawAccount(adAccountId))
  }, [dispatch, adAccountId])
  useEffect(() => {
    setCheckedList([])
    dispatch(
      fetchMemberList({
        adAccountId,
        page: memberPage,
      })
    )
  }, [dispatch, memberPage, adAccountId])
  useEffect(() => {
    dispatch(
      fetchMemberHistory({
        adAccountId,
        page: historyPage,
      })
    )
  }, [dispatch, historyPage, adAccountId])

  return (
    <>
      {master ? (
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">
              총 멤버{' '}
              <span className="fc_emph">
                <NumberFormat
                  value={member && member.totalElements}
                  displayType={'text'}
                  thousandSeparator={true}
                  defaultValue={0}
                />
              </span>
            </strong>
            <div className="f_left">
              <div className="single_wrap">
                <button
                  className="btn_gm gm_bl"
                  onClick={() => {
                    setInviteEmail('')
                    dispatch(showModal('modalInviteMemberByEmail'))
                  }}>
                  <span className="inner_g">
                    <span className="img_comm ico_add" />
                    초대
                  </span>
                </button>
                <button
                  className={cx('btn_gm gm_line', {
                    in_active: !checkedList.length,
                  })}
                  onClick={showConfirmMemberRelease}>
                  <span className="inner_g">해제</span>
                </button>
                {adAccountType !== 'INDIVIDUAL' && (
                  <button
                    className={cx('btn_gm gm_line', {
                      in_active: !checkedList.length,
                    })}
                    onClick={showConfirmTransferMasterByMaster}>
                    <span className="inner_g">마스터 권한 이전</span>
                  </button>
                )}
              </div>
            </div>
            <div className="f_right">
              <div className="check_member">
                <Checkbox
                  name="memberRequestable"
                  onChange={event => {
                    const { target } = event
                    const { checked } = target
                    event.preventDefault()
                    handleMemberRequestable(checked)
                  }}
                  checked={!adAccount.memberRequestable}>
                  멤버 요청 거부
                </Checkbox>
                <Tooltip>
                  설정시 해당 광고계정에 대해
                  <br />더 이상멤버 요청을 받지 않습니다.
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">멤버 안내 표</caption>
              <colgroup>
                <col width="45px" />
                <col width="387px" />
                <col width="387px" />
                <col width="359px" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" className="th_select align_c" />
                  <th scope="col">
                    <div className="inner_tbl">이름</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">카카오ID</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">권한</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {membersLoading ? (
                  <LoadingTable colSpan={4} />
                ) : (
                  member &&
                  member.content.map((m, i) => (
                    <tr
                      key={`member|${m.id}|${i}`}
                      className={cx({
                        tr_inactive: m.dspAccount.status === 'DORMANCY',
                      })}>
                      <td className="align_c">
                        <div className="inner_tbl">
                          <Checkbox
                            checked={checkedList.includes(m.id)}
                            onCheckedChange={checked =>
                              handleCheckboxChange(m.id, checked)
                            }
                            disabled={
                              m.dspAccount.status === 'DORMANCY' ||
                              m.dspAccount.id === dspId
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {m.dspAccount.status === 'DORMANCY'
                            ? '-'
                            : m.dspAccount.name}
                        </div>
                      </td>
                      <td className="td_state">
                        <div className="inner_tbl">
                          {m.dspAccount.status === 'DORMANCY'
                            ? '휴면회원'
                            : `${m.dspAccount.kakaoEmail}${
                                m.dspAccount.id === dspId ? '(나)' : ''
                              }`}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {m.memberType === 'MASTER' ? '마스터' : '멤버'}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {member.totalElements > 0 && (
            <Pagination
              onChange={handleMemberPageChange}
              totalPages={member.totalPages}
              number={member.number}
            />
          )}
        </div>
      ) : (
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">멤버</strong>
            <div className="f_left">
              <div className="single_wrap">
                <button
                  className="btn_gm gm_line"
                  onClick={showConfirmMemberQuit}>
                  <span className="inner_g">나가기</span>
                </button>
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">멤버 안내 표</caption>
              <colgroup>
                <col width="408px" />
                <col width="409px" />
                <col width="359px" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">이름</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">카카오ID</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">권한</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {membersLoading ? (
                  <LoadingTable colSpan={3} />
                ) : (
                  member &&
                  member.content.map((m, i) => (
                    <tr
                      key={`member|${m.id}|${i}`}
                      className={cx({
                        tr_inactive: m.dspAccount.status === 'DORMANCY',
                      })}>
                      <td>
                        <div className="inner_tbl">
                          {m.dspAccount.status === 'DORMANCY'
                            ? '-'
                            : m.dspAccount.name}
                        </div>
                      </td>
                      <td className="td_state">
                        <div className="inner_tbl">
                          {m.dspAccount.status === 'DORMANCY'
                            ? '휴면회원'
                            : `${m.dspAccount.kakaoEmail}${
                                m.dspAccount.id === dspId ? '(나)' : ''
                              }`}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {m.memberType === 'MASTER' ? '마스터' : '멤버'}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {member.totalElements > 0 && (
            <Pagination
              onChange={handleMemberPageChange}
              totalPages={member.totalPages}
              number={member.number}
            />
          )}
        </div>
      )}
      <MemberPendingMaster
        adAccount={adAccount}
        pendingMaster={pendingMaster}
        pendingMasterLoading={pendingMasterLoading}
        pendingMasterMember={pendingMasterMember}
        pendingMasterMemberLoading={pendingMasterMemberLoading}
      />
      {master && (
        <MemberJoinInviteStatus
          adAccount={adAccount}
          pendingMemberList={pendingMemberList}
          pendingMemberListLoading={pendingMemberListLoading}
        />
      )}
      <div className="ad_managebox">
        <div className="managebox_tit">
          <strong className="tit_box">히스토리</strong>
        </div>
        <div className="tblg2_wrap">
          <table className="tbl_g2">
            <caption className="hide_caption">계정 히스토리 안내 표</caption>
            <colgroup>
              <col width="680px" />
              <col width="498px" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <div className="inner_tbl">내용</div>
                </th>
                <th scope="col">
                  <div className="inner_tbl">업데이트 일시</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {historyLoading ? (
                <LoadingTable colSpan={2} />
              ) : history.content.length > 0 ? (
                history.content.map((item, i) => (
                  <tr key={`memberHistory|${item.id}|${i}`}>
                    <td>
                      <div className="inner_tbl">
                        {getMemberHistoryRowText(
                          item.event,
                          item.action,
                          item.dspAccountStatus,
                          item.kakaoEmail
                        )}
                      </div>
                    </td>
                    <td className="td_state">
                      <div className="inner_tbl">
                        {moment(item.date).format('YYYY.MM.DD HH:mm')}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <NoDataTable colSpan={2}>히스토리 내역이 없습니다.</NoDataTable>
              )}
            </tbody>
          </table>
        </div>
        {history.totalElements > 0 && (
          <Pagination
            onChange={handleHistoryPageChange}
            totalPages={history.totalPages}
            number={history.number}
          />
        )}
      </div>
      <Modal
        id="modalTransferMasterByMasterForAgreement"
        disabledOk={!checkboxTransferMasterByMasterForAgreement}
        ok={() => {
          setCheckboxTransferMasterByMasterForAgreement(false)
          handleConfirmTransferMasterByMaster()
        }}
        close={() => {
          setCheckboxTransferMasterByMasterForAgreement(false)
        }}
        cancel={() => {
          setCheckboxTransferMasterByMasterForAgreement(false)
        }}
        className="masterauth_layer"
        innerClassName="inner_basic_layer1"
        title="마스터 권한 이전 요청"
        okText="확인">
        <strong className="subtit_layer">
          선택하신 멤버에게 마스터 권한을 이전 하시겠습니까?
        </strong>
        <div className="agree_master">
          <div className="tit_agreemaster">
            <Checkbox
              name="marketingAgreement"
              onChange={event => {
                setCheckboxTransferMasterByMasterForAgreement(
                  event.target.checked
                )
              }}
              checked={checkboxTransferMasterByMasterForAgreement}>
              정보 제공 동의
            </Checkbox>
          </div>
          <ul className="list_guide">
            <li>요청받은 멤버가 수락한 시점에 권한 이동이 완료됩니다.</li>
            <li>
              선택한 멤버에게 마스터 권한을 비롯한 광고계정의 소유권 일체가
              이전되어 ‘{dspName}’님은 멤버로 변경됩니다.
            </li>
            <li>
              권한 이동 후 현재 등록된 ‘
              {!refundAccountLoading && refundAccount ? (
                <>
                  환불계좌 (예금주명 : {refundAccount.ownerName}, 은행명 :{' '}
                  {BANK_NAME[refundAccount.bankCode]}){taxBillEmail && <>, </>}
                </>
              ) : (
                ''
              )}
              {taxBillEmail && <>세금계산서 수신 이메일주소({taxBillEmail})</>}’
              정보가 선택하신 멤버에게 제공됩니다. 동의하지 않으실 경우
              권한이전이 불가합니다.
            </li>
          </ul>
        </div>
      </Modal>
      <Modal
        id="modalTransferMasterByMaster"
        ok={handleConfirmTransferMasterByMaster}
        className="masterauth_layer"
        innerClassName="inner_basic_layer3"
        title="마스터 권한 이전 요청"
        okText="확인">
        <strong className="subtit_layer">
          선택하신 멤버에게 마스터 권한을 이전 하시겠습니까?
        </strong>
        <ul className="list_guide">
          <li>요청받은 멤버가 수락한 시점에 권한 이동이 완료됩니다.</li>
          <li>
            선택한 멤버에게 마스터 권한을 비롯한 광고계정의 소유권 일체가
            이전되어 ‘{dspName}’님은 멤버로 변경됩니다.
          </li>
        </ul>
      </Modal>
      <ModalApproveTransferMasterByMember
        adAccount={adAccount}
        adAccountType={adAccountType}
        pendingMasterMember={pendingMasterMember}
        refundAccountLoading={refundAccountLoading}
        refundAccount={refundAccount}
      />
      <Modal
        id="modalInviteMemberByEmail"
        className="masterauth_layer"
        title="멤버 초대"
        keepModal={true}
        ok={handleConfirmInviteMemberByEmail}
        close={() => {
          setErrorInviteMemberByEmail('')
        }}
        cancel={() => {
          setErrorInviteMemberByEmail('')
        }}>
        <table className="tbl_layer">
          <caption className="hide_caption">멤버 초대 입력 표</caption>
          <colgroup>
            <col width={161} />
            <col />
          </colgroup>
          <tbody>
            <tr className="tr_box">
              <th scope="row">카카오계정</th>
              <td>
                <div className="inner_cell">
                  <TextInput
                    className={cx('box_inptxt', {
                      in_error: errorInviteMemberByEmail,
                    })}
                    placeholder="ex)kakaodsp@kakao.com"
                    name="email"
                    value={inviteEmail}
                    onChange={handleChange}
                    required={true}
                  />
                  {errorInviteMemberByEmail && (
                    <p className="txt_error">{errorInviteMemberByEmail}</p>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
      <Confirm
        id="confirmMemberQuit"
        title="멤버 나가기"
        ok={handleConfirmMemberQuit}>
        멤버 나가기를 하시겠습니까?
        <br />
        멤버 나가기를 하시면 광고계정을 운영할수 없습니다.
      </Confirm>
      <Confirm
        id="confirmMemberRelease"
        title="멤버 해제"
        ok={handleConfirmMemberRelease}>
        멤버에서 해제 하시겠습니까?
        <br />
        멤버 해제를 하면 해당 멤버는 광고계정을 운영할 수 없습니다.
      </Confirm>
    </>
  )
}

Member.propTypes = {
  adAccountType: PropTypes.string,
}

export default Member
