import cruxAxios, { SERVICE_TYPE } from './config/cruxAxios'
import { NOTIFICATION_SIZE } from '../features/myInfo/Notification'
import { CARD_ACCOUNT_SIZE } from '../features/myInfo/autoPayCard/autoPayCardAccountSlice'

/**
 * @file CRUX GATEWAY 계정 API
 * @see http://adp-account-dev.devel.kakao.com/swagger.html
 * @see https://wiki.daumkakao.com/x/8OxgHw
 * 기존 계정 API 앞에 다음 /crux-account/ path를 추가한다.
 * 헤더에 loginAccountId와 loginAccountType 대신 loginToken, accessToken을 사용한다.
 */
const { get, post, put } = cruxAxios

export const getDspAccount = dspAccountId =>
  get(`/crux-account/dspAccounts/${dspAccountId}`)

export const searchAdAccounts = (searchKeyword = '', page = 0) =>
  get('/crux-account/adAccountMembers:Page', {
    params: {
      searchKeyword,
      page,
      adAccountSearchType: 'AD_ACCOUNT_NAME',
      adPlatformTypes: SERVICE_TYPE,
      userConfigs: 'ON,OFF',
      adAccountMemberStatuses: 'APPROVED,PENDING_MASTER',
      size: CARD_ACCOUNT_SIZE,
    },
    paramsSerializer: {
      encode: value => encodeURIComponent(value),
    },
  })

export const getAdAccount = adAccountId =>
  get(`/crux-account/adAccounts/${adAccountId}`, {
    headers: {
      adAccountId,
    },
  })

export const getNotificationSettings = (dspAccountId, page = 0) =>
  get(`/crux-account/dspAccounts/${dspAccountId}/notificationSettings:Page`, {
    params: {
      adPlatformTypes: SERVICE_TYPE,
      page,
      size: NOTIFICATION_SIZE,
    },
  })

export const putNotificationSettings = (
  dspAccountId,
  adAccountIds,
  notificationSetting
) => {
  sessionStorage.removeItem('adAccount')
  return put(
    `/crux-account/dspAccounts/${dspAccountId}/adAccounts/${adAccountIds}/notificationSettings`,
    notificationSetting,
    {
      headers: {
        adAccountIds,
      },
    }
  )
}

export const getWithdrawalCheck = dspAccountId =>
  get(`/crux-account/dspAccounts/${dspAccountId}/withdrawal/check`)

export const publishPinNumberForWithdrawal = () =>
  post(`/crux-account/publishPinNumberForWithdrawal`)

export const verifyPinNumberForWithdrawal = (phoneNumber, pinNumber) =>
  post(`/crux-account/verifyPinNumberForWithdrawal`, {
    phoneNumber,
    pinNumber,
  })

export const withdrawalDspAccount = dspAccountId =>
  post(`/crux-account/dspAccounts/${dspAccountId}/withdrawal`)

export const putAdAccountMemberInviteCancel = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/invite/cancel`)

export const putAdAccountMemberJoinApprove = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/join/approve`)

export const putAdAccountMemberJoinReject = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/join/reject`)

export const getBizRightCompleted = adAccountId =>
  get(`/crux-account/adAccounts/${adAccountId}/bizRight`, {
    params: {
      bizRightStatus: 'COMPLETED',
    },
  })

export const getBizRightRequested = adAccountId =>
  get(`/crux-account/adAccounts/${adAccountId}/bizRight`, {
    params: {
      bizRightStatus: 'REQUESTED',
    },
  })

export const putBizRightTransferable = (adAccountId, transferable) =>
  put(
    `/crux-account/adAccounts/${adAccountId}/bizRightTransferable`,
    undefined,
    {
      params: {
        transferBizRight: transferable,
      },
    }
  )

export const putBizRightRelease = bizRightId =>
  put(`/crux-account/bizRights/${bizRightId}/release`)

export const putBizRightApprove = bizRightId =>
  put(`/crux-account/bizRights/${bizRightId}/approve`)

export const putBizRightReject = bizRightId =>
  put(`/crux-account/bizRights/${bizRightId}/reject`)

export const putModifyDelegationStatus = (bizRightId, delegationStatus) =>
  put(`/crux-account/bizRights/${bizRightId}/delegation/modify`, undefined, {
    params: {
      delegationStatus,
    },
  })

export const putAdAccountName = (adAccountId, name) =>
  put(`/crux-account/adAccounts/${adAccountId}/name`, undefined, {
    params: {
      name,
    },
    paramsSerializer: {
      encode: value => encodeURIComponent(value),
    },
  })

export const getMemberList = (adAccountId, page) =>
  get(`/crux-account/adAccounts/${adAccountId}/adAccountMembers:Page`, {
    params: {
      adAccountMemberStatuses: 'APPROVED,PENDING_MASTER',
      size: 10,
      page,
    },
  })

export const getPendingMemberList = (adAccountId, page, memberStatuses) =>
  get(`/crux-account/adAccounts/${adAccountId}/adAccountMembers:Page`, {
    params: {
      adAccountMemberStatuses: memberStatuses,
      size: 10,
      page,
    },
  })

export const getMemberHistory = (adAccountId, page) =>
  get(`/crux-account/adAccounts/${adAccountId}/adAccountMemberHistories`, {
    params: {
      page,
      size: 10,
    },
  })

export const putMemberRequestable = (adAccountId, isRequestable) =>
  put(
    `/crux-account/adAccounts/${adAccountId}/adAccountMember/memberRequestable`,
    undefined,
    {
      params: {
        isRequestable,
      },
    }
  )

export const getPendingMasterByMaster = adAccountId =>
  get(`/crux-account/adAccounts/${adAccountId}/adAccountMembers:Page`, {
    params: {
      adAccountMemberStatuses: 'PENDING_MASTER',
    },
  })

export const getPendingMasterByMember = adAccountId =>
  get(`/crux-account/adAccountMembers:Page`, {
    params: {
      adAccountMemberStatuses: 'PENDING_MASTER',
      adAccountSearchType: 'AD_ACCOUNT_ID',
      adPlatformTypes: SERVICE_TYPE,
      memberTypes: 'MEMBER',
      searchKeyword: adAccountId,
    },
  })

export const putApproveTransferMaster = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/transferMaster/approve`)

export const putRejectTransferMaster = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/transferMaster/reject`)

export const putCancelTransferMaster = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/transferMaster/cancel`)

export const putTransferMaster = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/transferMaster`)

export const putMemberQuit = adAccountId =>
  put(`/crux-account/adAccounts/${adAccountId}/adAccountMember/quit`)

export const putMemberRelease = memberId =>
  put(`/crux-account/adAccountMembers/${memberId}/release`)

export const postAdAccountMemberInvite = (adAccountId, params) =>
  post(`/crux-account/adAccounts/${adAccountId}/adAccountMember/invite`, params)

export const getCheckDeleteAdAccount = adAccountId =>
  get(`/crux-account/adAccounts/${adAccountId}/delete/check`)

export const deleteAdAccount = adAccountId =>
  cruxAxios.delete(`/crux-account/adAccounts/${adAccountId}`)

export const putAdAccountOn = adAccountId =>
  put(`/crux-account/adAccounts/${adAccountId}/on`)

export const putAdAccountOff = adAccountId =>
  put(`/crux-account/adAccounts/${adAccountId}/off`)

export const getDeferredPaymentChangeLogList = ({
  adAccountId,
  from,
  to,
  page,
}) =>
  get(`/crux-account/adAccounts/${adAccountId}/paymentLog`, {
    params: {
      paged: true,
      size: 5,
      page,
      startDate: from,
      endDate: to,
    },
  })

export const putChangeTaxbillEmail = (adAccountId, params) =>
  put(`/crux-account/adAccounts/${adAccountId}/taxBillEmail`, params)

export const getRefundOwnName = adAccountId =>
  get(`/crux-account/adAccounts/${adAccountId}/refundOwnName`)

export const getDelegationHistory = ({ adAccountId, page }) =>
  get(`/crux-account/adAccounts/${adAccountId}/delegationHistory`, {
    params: {
      size: 5,
      page,
    },
  })

export const putKakaoPayAgreement = dspAccountId =>
  put(`/crux-account/dspAccounts/${dspAccountId}/kakaoPayAgreement`)

export const linkedTracks = ({ page, adAccountId, size = 10 }) =>
  post(
    `/bcon/v2/services/keywordad/track-rights/adaccount/${adAccountId}/linked`,
    {},
    {
      params: {
        page,
        size,
      },
    }
  )
