import NumberFormat from 'react-number-format'
import { BANK_NAME } from 'features/settings/cash/CashConstants'
import { Link } from 'react-router-dom'
import React from 'react'
import Loading from '../../../components/loading/Loading'
import PropTypes from 'prop-types'

export const CardCash = ({
  walletLoading,
  refundAccountLoading,
  wallet,
  refundAccount,
  adAccountId,
}) => (
  <div className="card_summary card_cash">
    <strong className="tit_card">
      캐시 잔액
      {wallet && wallet.deferredPayment && (
        <span className="txt_sub">후불</span>
      )}
    </strong>
    {walletLoading || refundAccountLoading ? (
      <Loading />
    ) : (
      <>
        <em className="txt_info">
          <NumberFormat
            value={(wallet && wallet.cash) + (wallet && wallet.freeCash)}
            displayType={'text'}
            thousandSeparator={true}
            defaultValue={0}
          />
          원
        </em>
        <div className="define_adinfo">
          <dl>
            <dt>유상캐시</dt>
            <dd>
              <NumberFormat
                value={wallet && wallet.cash}
                displayType={'text'}
                thousandSeparator={true}
                defaultValue={0}
              />
              원
            </dd>
          </dl>
          <dl>
            <dt>무상캐시</dt>
            <dd>
              <NumberFormat
                value={wallet && wallet.freeCash}
                displayType={'text'}
                thousandSeparator={true}
                defaultValue={0}
              />
              원
            </dd>
          </dl>
          <dl>
            <dt>환불계좌</dt>
            <dd>
              {refundAccount ? (
                <>
                  <span className="txt_bank">
                    {refundAccount.accountNumber}
                  </span>
                  <span className="txt_bank">
                    {BANK_NAME[refundAccount.bankCode]}(
                    {refundAccount.ownerName})
                  </span>
                </>
              ) : (
                '미등록'
              )}
            </dd>
          </dl>
          {wallet && wallet.deferredPayment && (
            <dl>
              <dt>후불한도</dt>
              <dd>
                <NumberFormat
                  value={wallet && wallet.creditLimit}
                  displayType={'text'}
                  thousandSeparator={true}
                  defaultValue={0}
                />
                원
              </dd>
            </dl>
          )}
        </div>
        <Link to={`/${adAccountId}/settings/cash`} className="btn_gs">
          <span className="inner_g">더보기</span>
        </Link>
      </>
    )}
  </div>
)
CardCash.propTypes = {
  walletLoading: PropTypes.bool,
  refundAccountLoading: PropTypes.bool,
  wallet: PropTypes.object,
  refundAccount: PropTypes.object,
  adAccountId: PropTypes.number,
}
