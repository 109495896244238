import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import NoneAnchor from 'components/NoneAnchor'
import PropTypes from 'prop-types'

const UsingPaymentCardListLayer = ({ autopayCardInfos }) => {
  const [isOpen, setOpen] = useState(false)
  const close = () => {
    setOpen(false)
  }
  const toggle = () => {
    setOpen(!isOpen)
  }
  const wrapperRef = useOutsideClick(close)
  return (
    <div className="enter_card" ref={wrapperRef}>
      <NoneAnchor className="link_enter" onClick={toggle}>
        계정 <span className="fw_bold">{autopayCardInfos.length}</span>
      </NoneAnchor>
      <div
        className="layer_choice2"
        style={{ display: isOpen ? 'block' : 'none' }}>
        <strong className="tit_layer">
          광고계정 <span className="txt_num">{autopayCardInfos.length}</span>
        </strong>
        <div className="wrap_list">
          <ul className="list_menu">
            {autopayCardInfos.map(
              ({
                walletId,
                adAccountName,
                autopayCardPriorityType,
                priorityOrder,
              }) => (
                <li key={walletId}>
                  <Link
                    to={`/${walletId}/settings/paymentcard`}
                    className="link_menu">
                    <p className="txt_menu">{adAccountName}</p>
                    <span className="txt_append">
                      {autopayCardPriorityType === 'MASTER'
                        ? '결제카드'
                        : `예비카드${priorityOrder}`}
                    </span>
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
UsingPaymentCardListLayer.propTypes = {
  autopayCardInfos: PropTypes.arrayOf(
    PropTypes.shape({
      walletId: PropTypes.number,
      adAccountName: PropTypes.string,
      autopayCardPriorityType: PropTypes.string,
      priorityOrder: PropTypes.number,
    })
  ),
}
export default UsingPaymentCardListLayer
