import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getAutopayCard,
  getChangeHistory,
  getKakaoPayCards,
  getMyAutopayCard,
  getMyAutoPayCardPayHistory,
  getPayHistory,
} from 'api/billingAPI'

const name = 'card'

export const fetchMyAutopayCard = createAsyncThunk(
  `${name}/fetchMyAutopayCard`,
  async () => {
    const { data } = await getMyAutopayCard()
    return data
  }
)

export const fetchKakaoPayCards = createAsyncThunk(
  `${name}/fetchKakaoPayCards`,
  async ({ adAccountId }, { rejectWithValue }) => {
    try {
      const { data } = await getKakaoPayCards({ adAccountId })
      return data
    } catch (error) {
      const { response } = error
      const { status: errStatus, errorCode, message } = response.data
      return rejectWithValue({
        errStatus,
        errorCode,
        message,
      })
    }
  }
)

export const fetchAutopayCardPayHistory = createAsyncThunk(
  `${name}/fetchAutopayCardPayHistory`,
  async ({ adAccountId, from, to, status, page }, { rejectWithValue }) => {
    try {
      const { data } = await getMyAutoPayCardPayHistory({
        adAccountId,
        from,
        to,
        status,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status: errStatus, errorCode, message } = response.data
      return rejectWithValue({
        errStatus,
        errorCode,
        message,
      })
    }
  }
)

export const fetchPayHistory = createAsyncThunk(
  `${name}/fetchPayHistory`,
  async ({ adAccountId, from, to, status, page }, { rejectWithValue }) => {
    try {
      const { data } = await getPayHistory({
        adAccountId,
        from,
        to,
        status,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status: errStatus, errorCode, message } = response.data
      return rejectWithValue({
        errStatus,
        errorCode,
        message,
      })
    }
  }
)

export const fetchChangeHistory = createAsyncThunk(
  `${name}/fetchChangeHistory`,
  async ({ adAccountId, page }, { rejectWithValue }) => {
    try {
      const { data } = await getChangeHistory({
        adAccountId,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { status: errStatus, errorCode, message } = response.data
      return rejectWithValue({
        errStatus,
        errorCode,
        message,
      })
    }
  }
)

export const fetchAutopayCard = createAsyncThunk(
  `${name}/fetchAutopayCard`,
  async ({ adAccountId }, { rejectWithValue }) => {
    try {
      const { data } = await getAutopayCard({ adAccountId })
      return data
    } catch (error) {
      const { response } = error
      const { status: errStatus, errorCode, message } = response.data
      return rejectWithValue({
        errStatus,
        errorCode,
        message,
      })
    }
  }
)

const paymentCardSlice = createSlice({
  name,

  initialState: {
    myAutopayCard: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    autopayCardPayHistory: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    payHistory: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    changeHistory: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    autopayCard: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    kakaoPayCards: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
  },

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchMyAutopayCard.pending, state => {
      state.myAutopayCard.isLoading = true
    })

    builder.addCase(fetchMyAutopayCard.fulfilled, (state, { payload }) => {
      state.myAutopayCard.isLoading = false
      state.myAutopayCard.pagable = payload
    })

    builder.addCase(fetchKakaoPayCards.pending, state => {
      state.kakaoPayCards.isLoading = true
    })

    builder.addCase(fetchKakaoPayCards.fulfilled, (state, { payload }) => {
      state.kakaoPayCards.isLoading = false
      state.kakaoPayCards.pagable = payload
    })

    builder.addCase(fetchKakaoPayCards.rejected, state => {
      state.kakaoPayCards.isLoading = false
    })

    builder.addCase(fetchAutopayCardPayHistory.pending, state => {
      state.autopayCardPayHistory.isLoading = true
    })

    builder.addCase(
      fetchAutopayCardPayHistory.fulfilled,
      (state, { payload }) => {
        state.autopayCardPayHistory.isLoading = false
        state.autopayCardPayHistory.pagable = payload
      }
    )

    builder.addCase(fetchAutopayCardPayHistory.rejected, state => {
      state.autopayCardPayHistory.isLoading = false
    })

    builder.addCase(fetchPayHistory.pending, state => {
      state.payHistory.isLoading = true
    })

    builder.addCase(fetchPayHistory.fulfilled, (state, { payload }) => {
      state.payHistory.isLoading = false
      state.payHistory.pagable = payload
    })

    builder.addCase(fetchPayHistory.rejected, state => {
      state.payHistory.isLoading = false
    })

    builder.addCase(fetchChangeHistory.pending, state => {
      state.changeHistory.isLoading = true
    })

    builder.addCase(fetchChangeHistory.fulfilled, (state, { payload }) => {
      state.changeHistory.isLoading = false
      state.changeHistory.pagable = payload
    })

    builder.addCase(fetchChangeHistory.rejected, state => {
      state.changeHistory.isLoading = false
    })

    builder.addCase(fetchAutopayCard.pending, state => {
      state.autopayCard.isLoading = true
    })

    builder.addCase(fetchAutopayCard.fulfilled, (state, { payload }) => {
      state.autopayCard.isLoading = false
      state.autopayCard.pagable = payload
    })

    builder.addCase(fetchAutopayCard.rejected, state => {
      state.autopayCard.isLoading = false
    })
  },
})
const { reducer } = paymentCardSlice
export default reducer
