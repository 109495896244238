import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LOGIN_STATUS } from 'features/login/LoginStatus'
import { getIntegrationInfo, login, optLogin } from 'api/loginAPI'
import { getDspAccount } from 'api/accountAPI'
import { fetchAdAccount } from '../adAccount/adAccountSlice'
import { kakaoEmptyDspAccount, kakaoLogin, kakaoRedirect } from './kakaoAccount'

export const dspLogin = createAsyncThunk(
  'dspAccount/dspLogin',
  async (noArgs, { rejectWithValue }) => {
    const { data: integrationInfo } = await getIntegrationInfo().catch(() => ({
      data: undefined,
    }))
    try {
      const isAgreement = !(
        integrationInfo?.redirectStatus === 'DELEGATE_TO_SERVICE' &&
        integrationInfo?.dspAccountId !== null
      )
      if (integrationInfo?.redirectStatus === 'TRUE') {
        kakaoRedirect()
      } else if (!isAgreement) {
        kakaoEmptyDspAccount()
      } else {
        const { data: dspAccount } = await login(isAgreement)
        return {
          dspAccount,
          integrationInfo,
        }
      }
    } catch (error) {
      const status = error?.response?.status
      if (status === 401) {
        sessionStorage.clear()
        kakaoLogin()
      }
      return rejectWithValue(LOGIN_STATUS.FAIL)
    }
  }
)

export const otpLogin = createAsyncThunk(
  'dspAccount/otpLogin',
  async (noArgs, { dispatch }) => {
    const { otp, accountId, adAccountId, serviceType } = JSON.parse(
      sessionStorage.getItem('otp')
    )
    const { data } = await optLogin({
      otp,
      accountId,
      adAccountId,
      serviceType,
    })
    const { payload } = await dispatch(fetchAdAccount(adAccountId))
    dispatch(fetchDspAccount(payload.masterId))
    return data
  }
)

export const fetchDspAccount = createAsyncThunk(
  'dspAccount/fetchDspAccount',
  async dspAccountId => {
    const { data } = await getDspAccount(dspAccountId)
    return data
  }
)

const dspAccountSlice = createSlice({
  name: 'dspAccount',
  initialState: {
    loginStatus: LOGIN_STATUS.IDLE,
    kakaoEmail: '',
    current: undefined,
    currentAdmin: undefined,
    integrationInfo: undefined,
  },
  extraReducers: builder => {
    builder.addCase(dspLogin.pending, state => {
      state.loginStatus = LOGIN_STATUS.IDLE
    })
    builder.addCase(dspLogin.fulfilled, (state, { payload }) => {
      if (payload?.integrationInfo) {
        state.integrationInfo = payload.integrationInfo
      }
      if (payload?.dspAccount) {
        state.current = payload.dspAccount
        state.currentAdmin = undefined
        state.kakaoEmail = payload.dspAccount.kakaoEmail
        state.loginStatus = LOGIN_STATUS.SUCCESS
      }
    })
    builder.addCase(dspLogin.rejected, (state, { payload }) => {
      state.loginStatus = payload
    })

    builder.addCase(otpLogin.pending, state => {
      state.loginStatus = LOGIN_STATUS.IDLE
    })
    builder.addCase(otpLogin.fulfilled, (state, { payload }) => {
      state.currentAdmin = payload
      state.loginStatus = LOGIN_STATUS.SUCCESS
    })
    builder.addCase(otpLogin.rejected, state => {
      sessionStorage.removeItem('otp')
      state.loginStatus = LOGIN_STATUS.UNAUTHORIZED
    })

    builder.addCase(fetchDspAccount.fulfilled, (state, { payload }) => {
      state.current = payload
      state.kakaoEmail = payload.kakaoEmail
    })
  },
})

const { reducer } = dspAccountSlice
export default reducer
