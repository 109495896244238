import React from 'react'
import useLogoLinkDisabled from 'features/layout/useLogoLinkDisabled'
import useGnbHide from 'features/layout/useGnbHide'

const { REACT_APP_BUSINESS_HOST } = process.env
const ServiceUnavailable = () => {
  useLogoLinkDisabled()
  useGnbHide()
  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        알림 본문
      </h2>
      <div className="error_wrap">
        <strong className="tit_error">서비스 점검 중입니다.</strong>
        <p className="txt_error">
          점검시간 : yyyy-MM-dd hh:mm ~ yyyy-MM-dd hh:mm
          <br />
          점검시간 동안 사이트 이용이 제한됩니다.
        </p>
        <div className="page_btn">
          <div className="inner_btn">
            <a href={REACT_APP_BUSINESS_HOST} className="btn_gb gb_bl">
              <span className="inner_g">kakao for business 홈으로 이동</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceUnavailable
