import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  getNotificationSettings,
  putNotificationSettings,
} from 'api/accountAPI'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { cut } from 'utils/stringUtils'
import Checkbox from '../../components/form/Checkbox'
import Button from '../../components/form/Button'
import Alert from '../../components/dialog/Alert'
import Confirm from '../../components/dialog/Confirm'
import { showModal } from '../dialog/dialogSlice'
import Tooltip from 'components/tooltip/Tooltip'
import NoneAnchor from '../../components/NoneAnchor'
import { fail, success } from '../../utils/toastUtils'

const NotificationMembers = ({
  settings,
  checkedMembers,
  handleMemberChange,
}) =>
  settings.map(setting => {
    const { adAccountId, adAccountName } = setting
    return (
      <li key={adAccountId}>
        <Checkbox
          name="checkChoiceSet"
          checked={checkedMembers.includes(adAccountId)}
          onChange={checked => handleMemberChange(checked, adAccountId)}>
          {cut(adAccountName, 28)} ({adAccountId})
        </Checkbox>
      </li>
    )
  })

const initFormValues = {
  kakaoTalk: false,
  balance: false,
  budget: false,
  review: false,
  adminStop: false,
  operation: false,
  cash: false,
  autoPayment: false,
  midnight: false,
}

export const NOTIFICATION_SIZE = 200

const Notification = () => {
  const dispatch = useDispatch()
  const { id: dspAccountId } = useSelector(state => state.dspAccount.current)
  const [isLoading, setLoading] = useState(true)
  const [isSaving, setSaving] = useState(false)
  const [settings, setSettings] = useState([])
  const [page, setPage] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [checkedMembers, setCheckedMembers] = useState([])
  const [formValues, setFormValues] = useState(initFormValues)
  const lastPage = useMemo(
    () => Math.max(Math.ceil(totalElements / NOTIFICATION_SIZE) - 1, 0),
    [totalElements]
  )
  const hasSameSetting = useMemo(
    () =>
      checkedMembers.length === 0
        ? false
        : checkedMembers.length === 1
          ? true
          : settings
              .filter(value => checkedMembers.includes(value.adAccountId))
              .map(value => value.notificationSetting)
              .every(
                (value, index, array) =>
                  value.kakaoTalk === array[0].kakaoTalk &&
                  value.balance === array[0].balance &&
                  value.budget === array[0].budget &&
                  value.review === array[0].review &&
                  value.adminStop === array[0].adminStop &&
                  value.operation === array[0].operation &&
                  value.cash === array[0].cash &&
                  value.autoPayment === array[0].autoPayment &&
                  value.midnight === array[0].midnight
              ),
    [checkedMembers, settings]
  )
  const scrollRef = useRef()
  useEffect(() => {
    ;(async () => {
      setCheckedMembers([])
      setLoading(true)
      const { data } = await getNotificationSettings(dspAccountId, page)
      setSettings(data.content)
      setTotalElements(data.totalElements)
      setLoading(false)
      if (scrollRef.current) scrollRef.current.scrollTo({ top: 0 })
    })()
  }, [dspAccountId, page])

  useEffect(() => {
    if (hasSameSetting) {
      const { notificationSetting } = settings.find(
        value => value.adAccountId === checkedMembers[0]
      )
      const {
        kakaoTalk,
        balance,
        budget,
        review,
        adminStop,
        operation,
        cash,
        autoPayment,
        midnight,
      } = notificationSetting
      setFormValues({
        kakaoTalk,
        balance,
        budget,
        review,
        adminStop,
        operation,
        cash,
        autoPayment,
        midnight,
      })
    } else {
      setFormValues(initFormValues)
    }
  }, [hasSameSetting, checkedMembers, settings])

  const handleChange = event => {
    const { target } = event
    const { name, checked } = target
    setFormValues({
      ...formValues,
      [name]: checked,
    })
    if (name === 'kakaoTalk' && checked === false) {
      setFormValues(initFormValues)
    }
  }
  const handleMemberChange = (checked, adAccountId) => {
    if (checked.target.checked) {
      setCheckedMembers([...checkedMembers, adAccountId])
    } else {
      setCheckedMembers(checkedMembers.filter(value => value !== adAccountId))
    }
  }
  const handleCheckAll = event => {
    const { checked } = event.target
    if (checked) {
      setCheckedMembers(settings.map(value => value.adAccountId))
    } else {
      setCheckedMembers([])
    }
  }
  const handleOk = async () => {
    setSaving(true)
    try {
      await putNotificationSettings(dspAccountId, checkedMembers.join(','), {
        ...formValues,
      })
      setSettings(
        settings.map(value => {
          if (checkedMembers.includes(value.adAccountId)) {
            const {
              kakaoTalk,
              balance,
              budget,
              review,
              adminStop,
              operation,
              cash,
              autoPayment,
              midnight,
            } = formValues
            value.notificationSetting = {
              ...value.notificationSetting,
              kakaoTalk,
              balance,
              budget,
              review,
              adminStop,
              operation,
              cash,
              autoPayment,
              midnight,
            }
          }
          return value
        })
      )
      success('알림 설정이 완료되었습니다.')
    } catch (error) {
      fail(['알림 설정 중 오류가 발생하였습니다.', `(${error.message})`])
    }
    setSaving(false)
  }
  const handleSubmit = async event => {
    event.preventDefault()
    if (checkedMembers.length === 0) {
      dispatch(showModal('notificationAlert'))
    } else if (checkedMembers.length > 1) {
      dispatch(showModal('notificationConfirm'))
    } else {
      await handleOk()
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="adnotify_managebox" style={{ overflow: 'hidden' }}>
          <div className="ad_managebox">
            <div className="managebox_tit">
              <strong className="tit_box">내 광고계정</strong>
            </div>
            <div ref={scrollRef} className="detail_adaccound">
              {isLoading ? (
                <Loading />
              ) : settings.length > 0 ? (
                <>
                  <ul className="list_targetitem">
                    <li>
                      <Checkbox
                        onChange={handleCheckAll}
                        checked={checkedMembers.length === settings.length}>
                        전체선택
                      </Checkbox>
                    </li>
                  </ul>
                  <ul className="list_targetitem targetitem_scroll">
                    <NotificationMembers
                      settings={settings}
                      checkedMembers={checkedMembers}
                      handleMemberChange={handleMemberChange}
                    />
                  </ul>
                  <span className="choice_num">
                    <em className="empty_g">
                      <span className="screen_out">
                        현재 선택한 광고계정 수
                      </span>
                      {checkedMembers.length}
                    </em>
                    /<span className="screen_out">총 광고계정 수</span>
                    {settings.length}
                  </span>
                </>
              ) : (
                <div className="nodata_wrap">
                  <div className="inner_nodata">
                    <p className="txt_nodata">광고계정이 없습니다.</p>
                    <Link to="/adaccount" className="btn_gm gm_bl">
                      <span className="inner_g">광고계정 만들기</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="paging_wrap2">
              <span className="inner_paging">
                <strong className="num_current">
                  <span className="screen_out">현재 광고계정 리스트</span>
                  {page + 1}
                </strong>
                / <span className="screen_out">총 광고계정 리스트 수</span>
                {lastPage + 1}
              </span>
              {page === 0 || isLoading ? (
                <span className="num_paging num_prev">
                  <span className="ico_comm ico_prev">이전</span>
                </span>
              ) : (
                <NoneAnchor
                  className="num_paging num_prev"
                  onClick={() => setPage(page - 1)}>
                  <span className="ico_comm ico_prev">이전</span>
                </NoneAnchor>
              )}
              {page === lastPage || isLoading ? (
                <span className="num_paging num_next">
                  <span className="ico_comm ico_next">다음</span>
                </span>
              ) : (
                <NoneAnchor
                  className="num_paging num_next"
                  onClick={() => setPage(page + 1)}>
                  <span className="ico_comm ico_next">다음</span>
                </NoneAnchor>
              )}
            </div>
          </div>
          <div className="ad_managebox choose_account">
            <div className="managebox_tit">
              <strong className="tit_box">선택된 광고계정 설정</strong>
              {settings.length > 0 &&
                checkedMembers.length > 1 &&
                (hasSameSetting ? (
                  <em className="txt_guide fc_empty">
                    *선택된 광고계정 모두 아래와 같이 알림 설정되어 있습니다.
                  </em>
                ) : (
                  <em className="txt_guide fc_warn2">
                    *선택된 광고계정 알림 설정이 서로 상이하여 초기 설정값으로
                    노출됩니다.
                  </em>
                ))}
            </div>
            <div className="detail_chooseaccount">
              <table className="tbl_g3">
                <caption className="hide_caption">알림 설정 테이블</caption>
                <colgroup>
                  <col style={{ width: 160 }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div className="inner_tbl">알림수단</div>
                    </th>
                    <td>
                      <div className="inner_tbl">
                        <ul className="list_selinp">
                          <li>
                            <Checkbox
                              name="kakaoTalk"
                              onChange={handleChange}
                              checked={formValues.kakaoTalk}
                              disabled={checkedMembers.length === 0}>
                              카카오톡
                              <Tooltip>
                                가입시 등록한 휴대폰번호로 카카오톡으로 알림
                                메시지가 발송됩니다.
                                <br />
                                &apos;카카오 광고&apos; 알림톡을 수신 거부한
                                경우 발송되지 않습니다.
                              </Tooltip>
                            </Checkbox>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="inner_tbl">알림내용</div>
                    </th>
                    <td>
                      <div className="inner_tbl">
                        <ul className="list_selinp">
                          <li>
                            <Checkbox
                              name="balance"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.balance
                              }>
                              광고계정 잔액 부족
                            </Checkbox>
                          </li>
                          <li>
                            <Checkbox
                              name="cash"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={formValues.kakaoTalk && formValues.cash}>
                              광고 캐시 정보
                            </Checkbox>
                          </li>
                          <li>
                            <Checkbox
                              name="operation"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.operation
                              }>
                              광고 운영 정보
                            </Checkbox>
                          </li>
                          <li>
                            <Checkbox
                              name="adminStop"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.adminStop
                              }>
                              관리자 정지
                            </Checkbox>
                          </li>
                          <li>
                            <Checkbox
                              name="review"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.review
                              }>
                              소재 심사 결과
                            </Checkbox>
                          </li>
                          <li>
                            <Checkbox
                              name="autoPayment"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.autoPayment
                              }>
                              자동 결제카드 정보
                            </Checkbox>
                          </li>
                          <li>
                            <Checkbox
                              name="budget"
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.budget
                              }>
                              캠페인 또는 광고그룹 일예산 초과
                            </Checkbox>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="inner_tbl">
                        심야알림
                        <Tooltip>
                          기본적으로 밤 9시 ~ 익일 오전 9시에 발생한 알림은
                          제공하지 않으며
                          <br />
                          심야 알림 허용 설정한 경우에만 수신 가능합니다.
                        </Tooltip>
                      </div>
                    </th>
                    <td>
                      <div className="inner_tbl">
                        <ul className="list_selinp">
                          <li>
                            <Checkbox
                              name="midnight"
                              onChange={handleChange}
                              checked={
                                formValues.kakaoTalk && formValues.midnight
                              }
                              disabled={
                                checkedMembers.length === 0 ||
                                !formValues.kakaoTalk
                              }>
                              심야시간대 알림 허용 (21:00 ~ 09:00)
                            </Checkbox>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul className="list_guide">
                <li>
                  알림 수단 설정과 관계없이 모든 알림 내용은 이메일로
                  전달됩니다.
                </li>
                <li>
                  선택된 알림 수단이 없는 경우, 카카오톡 알림은 전달되지
                  않습니다.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="page_btn">
          <div className="inner_btn">
            <Button type="submit" className="btn_gb gb_bl" disabled={isSaving}>
              저장
            </Button>
          </div>
        </div>
      </form>
      <Alert id="notificationAlert" title="알림 설정">
        선택된 광고계정이 없습니다.
        <br />
        광고계정 선택 후 다시 시도하세요.
      </Alert>
      <Confirm id="notificationConfirm" title="알림 설정" ok={handleOk}>
        선택된 모든 광고계정의 알림 설정이 변경됩니다.
        <br />
        계속 진행하시겠습니까?
      </Confirm>
    </>
  )
}

export default Notification
