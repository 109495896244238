import Select from 'components/form/Select'
import { SELECTBOX_BANKNAME } from './CashConstants'
import React, { useEffect, useState } from 'react'
import Modal from 'components/dialog/Modal'
import TextInput from 'components/form/TextInput'
import Checkbox from 'components/form/Checkbox'
import { postWithdrawAccount } from 'api/billingAPI'
import { getRefundOwnName } from 'api/accountAPI'
import { fetchWithdrawAccount } from 'features/settings/cash/cashSlice'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'components/dialog/Alert'
import PropTypes from 'prop-types'
import { fail, success } from 'utils/toastUtils'

const initialState = {
  withdrawAccount: {
    accountNumber: '',
    bankCode: '02',
    ownerName: '',
  },
  checkboxAgreement: false,
}

const ModalRegisterWithdrawAccount = ({
  dspAccount,
  adAccount,
  fromClicked,
}) => {
  const dispatch = useDispatch()
  const [withdrawAccount, setWithdrawAccount] = useState(
    initialState.withdrawAccount
  )
  const [checkboxAgreement, setCheckboxAgreement] = useState(
    initialState.checkboxAgreement
  )
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false)
  const { info: refundAccount } = useSelector(state => state.cash.refundAccount)

  const onChange = e => {
    const { name, value } = e.target
    setWithdrawAccount({
      ...withdrawAccount,
      [name]: value,
    })
  }

  const handleChange = event => {
    setCheckboxAgreement(event.target.checked)
  }

  const handleWithdrawAccountBankCode = value => {
    setWithdrawAccount({
      ...withdrawAccount,
      bankCode: value,
    })
  }
  const [invalidMessage, setInvalidMessage] = useState('')
  const handleRegisterWithdrawAccount = async () => {
    if (withdrawAccount.accountNumber.replace(/\s/g, '').length === 0) {
      setInvalidMessage('계좌번호를 입력해주세요.')
      dispatch(showModal('alertWithdrawAccountRegister'))
      return false
    }
    if (!/^[\d|-]+$/.test(withdrawAccount.accountNumber)) {
      setInvalidMessage('올바른 계좌번호를 입력해주세요.')
      dispatch(showModal('alertWithdrawAccountRegister'))
      return false
    }
    if (
      adAccount.adAccountType !== 'INDIVIDUAL' &&
      withdrawAccount.ownerName.replace(/\s/g, '').length === 0
    ) {
      setInvalidMessage('예금주를 입력해주세요.')
      dispatch(showModal('alertWithdrawAccountRegister'))
      return false
    }
    setDisabledConfirmButton(true)
    try {
      await postWithdrawAccount(adAccount.id, {
        accountNumber: withdrawAccount.accountNumber,
        bankCode: withdrawAccount.bankCode,
        ownerName:
          adAccount.adAccountType === 'INDIVIDUAL'
            ? ownName && ownName.name
            : withdrawAccount.ownerName,
        businessType: adAccount.adAccountType,
        dspId: 'KEYWORDAD',
        adPlatformType: 'KEYWORDAD',
        memberType: 'DSP_ACCOUNT',
        memberId: dspAccount.id,
      })
      await dispatch(fetchWithdrawAccount(adAccount.id))
      setWithdrawAccount(initialState.withdrawAccount)
      setCheckboxAgreement(false)
      setDisabledConfirmButton(false)
      if (!refundAccount && fromClicked === 'WITHDRAW_BUTTON') {
        dispatch(hideModal('modalRegisterWithdrawAccount'))
        dispatch(showModal('modalWithdraw'))
      } else {
        success('환불계좌가 등록되었습니다.')
        dispatch(hideModal())
      }
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal())
      setDisabledConfirmButton(false)
      setWithdrawAccount(initialState.withdrawAccount)
      setCheckboxAgreement(false)
      fail(message)
    }
  }
  const [ownName, setOwnName] = useState(null)
  useEffect(() => {
    ;(async () => {
      await getRefundOwnName(adAccount.id)
        .then(({ data }) => setOwnName(data))
        .catch(() => {})
    })()
  }, [adAccount.id])
  return (
    <>
      <Modal
        id="modalRegisterWithdrawAccount"
        keepModal={true}
        disabledOk={!checkboxAgreement || disabledConfirmButton}
        ok={async () => {
          await handleRegisterWithdrawAccount()
        }}
        close={() => {
          setWithdrawAccount(initialState.withdrawAccount)
          setCheckboxAgreement(false)
        }}
        cancel={() => {
          setWithdrawAccount(initialState.withdrawAccount)
          setCheckboxAgreement(false)
        }}
        className="refund_layer"
        title="환불계좌 등록"
        okText="저장">
        {!refundAccount && (
          <p className="subtit_layer subtit_refund">
            광고계정 생성 시, 등록한 광고계정에 맞는 계좌로 등록해주세요.
          </p>
        )}
        <ul className="list_append">
          {adAccount.advertiser &&
          adAccount.advertiser.businessRegistrationNumber &&
          adAccount.advertiser.businessRegistrationNumber.charAt(4) === '8' ? (
            <li>법인 사업자 광고계정 : 법인 사업자명 계좌</li>
          ) : adAccount.advertiser &&
            adAccount.advertiser.businessRegistrationNumber &&
            adAccount.advertiser.businessRegistrationNumber.charAt(4) !==
              '8' ? (
            <li>
              개인 사업자 광고계정 : 개인 사업자명 계좌 or 사업자 개인의
              개인명의 계좌
            </li>
          ) : (
            ''
          )}
        </ul>
        <table className="tbl_layer">
          <caption className="hide_caption">은행, 계좌번호, 예금주 표</caption>
          <colgroup>
            <col style={{ width: 161 }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">은행</th>
              <td>
                <div className="inner_cell">
                  <Select
                    options={SELECTBOX_BANKNAME}
                    onChange={handleWithdrawAccountBankCode}
                    selected={withdrawAccount.bankCode}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">계좌번호</th>
              <td>
                <div className="inner_cell">
                  <TextInput
                    name="accountNumber"
                    value={withdrawAccount.accountNumber}
                    onChange={onChange}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">예금주</th>
              <td>
                <div className="inner_cell">
                  {adAccount.adAccountType === 'INDIVIDUAL' ? (
                    ownName && ownName.name
                  ) : (
                    <TextInput
                      name="ownerName"
                      value={withdrawAccount.ownerName}
                      onChange={onChange}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="view_agree">
          <Checkbox
            name="marketingAgreement"
            onChange={handleChange}
            checked={checkboxAgreement}>
            개인정보 수집 및 이용동의 <em className="fc_warn2">(필수)</em>
          </Checkbox>
          <div
            className="box_agree"
            style={{ display: checkboxAgreement ? 'block' : 'none' }}>
            {/* 동의 체크시 display:block으로 전환 */}
            <p className="desc_agree">
              (주)카카오는 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의
              개인정보를 안전하게
              <br />
              취급하는데 최선을 다합니다.
            </p>
            <table className="tbl_layer">
              <caption className="hide_caption">
                개인정보 수집 및 이용동의 표
              </caption>
              <colgroup>
                <col style={{ width: 161 }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">목적</th>
                  <td>
                    <div className="inner_cell">환불처리</div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">항목</th>
                  <td>
                    <div className="inner_cell">은행명, 계좌번호, 예금주명</div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">보유기간</th>
                  <td>
                    <div className="inner_cell">
                      <strong
                        style={{
                          textDecoration: 'underline',
                          fontSize: '17px',
                        }}>
                        광고 계정 삭제 시 지체 없이 파기
                      </strong>
                      <br />
                      (단, 전자상거래법 등 관계 법령에 의해 보유가 허용된 경우,
                      <br />
                      법령에서 정한 기간(5년) 동안 보관)
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="desc_agree">
              서비스 제공을 위한 필요한 최소한의 개인정보이므로 동의를 해 주셔야
              서비스 제공이 가능합니다. 더 자세한 내용에 대해서는{' '}
              <a
                href="https://business.kakao.com/policy/privacy/"
                target="_blank"
                rel="noopener noreferrer"
                className="fc_empty">
                개인정보처리방침
              </a>
              을 참고하시기 바랍니다.
            </p>
          </div>
        </div>
      </Modal>
      <Alert id="alertWithdrawAccountRegister" title="환불 계좌 오류">
        {invalidMessage}
      </Alert>
    </>
  )
}

ModalRegisterWithdrawAccount.propTypes = {
  dspAccount: PropTypes.object,
  adAccount: PropTypes.object,
  fromClicked: PropTypes.string,
}

export default ModalRegisterWithdrawAccount
