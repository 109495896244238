import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { hideGnb, showGnb } from 'features/layout/layoutSlice'

const useGnbHide = (flag = true) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (flag) {
      dispatch(hideGnb())
      return () => {
        dispatch(showGnb())
      }
    }
  })
}
export default useGnbHide
