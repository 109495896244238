import React, { useState } from 'react'
import Modal from 'components/dialog/Modal'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import LoadingTable from 'components/table/LoadingTable'
import NoDataTable from 'components/table/NoDataTable'
import { getTraderLabel } from 'features/settings/cash/CashCommons'
import NumberFormat from 'react-number-format'
import Confirm from 'components/dialog/Confirm'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import { deletePlan } from 'api/billingAPI'
import PropTypes from 'prop-types'
import { fail } from '../../../utils/toastUtils'

const ModalVirtualAccountHistory = ({ adAccount, plans }) => {
  const dispatch = useDispatch()
  const [deleteTxId, setDeleteTxId] = useState(null)
  const handleDelete = txId => {
    setDeleteTxId(txId)
    dispatch(showModal('confirmDeleteVirtualAccount'))
  }
  const fireDeletePlan = async () => {
    try {
      await deletePlan(adAccount.id, deleteTxId)
      dispatch(hideModal('confirmDeleteVirtualAccount'))
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal('confirmDeleteVirtualAccount'))
      fail(message)
    }
  }
  return (
    <>
      <Modal
        id="modalVirtualAccountHistory"
        className="virtual_layer"
        title="가상계좌 발급 이력"
        okText="확인"
        showCancel={false}>
        <p className="subtit_layer">
          현재 미결제금액{' '}
          <em className="emph_g fc_warn2">
            <NumberFormat
              value={plans && plans.remainedBondAmount}
              displayType={'text'}
              thousandSeparator={true}
              defaultValue={0}
            />
            원
          </em>
          중 결제를 위해 가상계좌를 발급한 목록입니다.
          <br />
          새롭게 가상계좌 발급 시 아래 금액
          <em className="emph_g fc_warn2">
            (합계{' '}
            <NumberFormat
              value={plans && plans.planRepayAmount}
              displayType={'text'}
              thousandSeparator={true}
              defaultValue={0}
            />
            원)
          </em>
          은 제외되고 발급하게 됩니다.
        </p>
        <div className="itemtbl_wrap">
          <div className="itemtbl_head">
            <table className="tbl_itemchoice">
              <caption className="hide_caption">
                가상계좌 발급 이력 표 제목
              </caption>
              <colgroup>
                <col style={{ width: 159 }} />
                <col style={{ width: 191 }} />
                <col style={{ width: 131 }} />
                <col style={{ width: 226 }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_cell">발급일시</div>
                  </th>
                  <th scope="col">
                    <div className="inner_cell">가상계좌 정보</div>
                  </th>
                  <th scope="col">
                    <div className="inner_cell">금액</div>
                  </th>
                  <th scope="col">
                    <div className="inner_cell">발급한 사람 (거래자)</div>
                  </th>
                  <th scope="col" className="align_c">
                    <div className="inner_cell">삭제</div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="itemtbl_body">
            <table className="tbl_itemchoice">
              <caption className="hide_caption">
                가상계좌 발급 이력 표 내용
              </caption>
              <colgroup>
                <col style={{ width: 159 }} />
                <col style={{ width: 191 }} />
                <col style={{ width: 131 }} />
                <col style={{ width: 226 }} />
                <col />
              </colgroup>
              <tbody>
                {!plans ? (
                  <LoadingTable colSpan={5} />
                ) : !(
                    plans && plans.repaymentPlanVirtualAccounts.length > 0
                  ) ? (
                  <NoDataTable colSpan={5}>
                    가상계좌 발급 이력이 없습니다.
                  </NoDataTable>
                ) : (
                  plans &&
                  plans.repaymentPlanVirtualAccounts.map(plan => {
                    const {
                      dt,
                      txId,
                      amount,
                      virtualAccount,
                      bankName,
                      memberStatus,
                      memberType,
                      memberEmail,
                    } = plan

                    const virtualAccountIssuer = getTraderLabel(
                      memberType,
                      memberStatus,
                      memberEmail
                    )
                    const virtualAccountText = virtualAccount
                      ? `${bankName} ${virtualAccount}`
                      : '-'

                    const finalVirtualAccountText =
                      memberType === 'DSP_ACCOUNT'
                        ? virtualAccountText
                        : '에이전시에서 확인하세요'

                    return (
                      <tr key={txId}>
                        <td>
                          <div className="inner_cell">
                            {moment(dt).format('YYYY.MM.DD HH:mm')}
                          </div>
                        </td>
                        <td>
                          <div className="inner_cell">
                            {finalVirtualAccountText}
                          </div>
                        </td>
                        <td className="align_r">
                          <div className="inner_cell">{amount}원</div>
                        </td>
                        <td className="td_mail">
                          <div className="inner_cell">
                            {virtualAccountIssuer}
                          </div>
                        </td>
                        <td className="align_c">
                          <div className="inner_cell">
                            {virtualAccount && memberType === 'DSP_ACCOUNT' ? (
                              <button
                                onClick={() => {
                                  handleDelete(txId)
                                }}
                                className="btn_gs">
                                <span className="inner_g">삭제</span>
                              </button>
                            ) : (
                              '-'
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <Confirm
        id="confirmDeleteVirtualAccount"
        title="가상계좌 삭제"
        ok={fireDeletePlan}>
        발급한 가상계좌를 삭제하시겠습니까?
        <br />
        삭제하면 해당 가상계좌는 만료처리됩니다.
      </Confirm>
    </>
  )
}
ModalVirtualAccountHistory.propTypes = {
  adAccount: PropTypes.object,
  plans: PropTypes.object,
}
export default ModalVirtualAccountHistory
