import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import cx from 'classnames'
import AdAccountPage from 'features/settings/adaccount/AdAccount'
import MemberPage from 'features/settings/adaccount/Member'
import BusinessRightPage from 'features/settings/adaccount/BusinessRight'
import TaxPage from 'features/settings/adaccount/Tax'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import {
  deleteAdAccount,
  getCheckDeleteAdAccount,
  linkedTracks,
  putAdAccountMemberJoinApprove,
  putAdAccountMemberJoinReject,
  putAdAccountName,
  putBizRightApprove,
  putBizRightReject,
  putModifyDelegationStatus,
} from 'api/accountAPI'
import { fetchAdAccountWithoutStatus } from 'features/adAccount/adAccountSlice'
import { fail, success } from 'utils/toastUtils'
import Modal from 'components/dialog/Modal'
import CounterTextInput from 'components/form/CounterTextInput'
import Checkbox from 'components/form/Checkbox'
import moment from 'moment'
import NoneAnchor from 'components/NoneAnchor'
import {
  fetchMemberHistory,
  fetchMemberList,
  fetchPendingMemberList,
} from 'features/settings/adaccount/memberSlice'
import {
  fetchBizRightCompleted,
  fetchBizRightRequested,
} from 'features/settings/adaccount/businessRightSlice'
import { fetchPayHistory } from 'features/settings/paymentcard/paymentCardSlice'
import { fmt } from 'components/calendar/calendarUtil'

const AdAccountIndex = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const tabName = params.get('tab') || 'adaccount'
  const [tab, setTab] = useState('adaccount')
  const {
    id,
    name,
    master,
    adAccountType,
    ownerCompany,
    paymentMethod: { deferredPayment },
  } = useSelector(state => state.adAccount.current)
  const _adAccountType =
    adAccountType === 'INDIVIDUAL'
      ? 'INDIVIDUAL'
      : adAccountType === 'BUSINESS' &&
          ownerCompany &&
          ownerCompany.companyType === 'ADVERTISER'
        ? 'BUSINESS'
        : adAccountType === 'BUSINESS' &&
            ownerCompany &&
            ownerCompany.companyType === 'AGENCY'
          ? 'AGENCY'
          : ''
  const [adAccountName, setAdAccountName] = useState(name)
  const handleChange = event => {
    setAdAccountName(event.target.value)
  }
  const [errorChangeAdAccountName, setErrorChangeAdAccountName] = useState('')
  const handleChangeAdAccountName = async () => {
    const trimAdAccountName = adAccountName.replace(/^\s*|\s*$/g, '')
    if (trimAdAccountName.length) {
      try {
        await putAdAccountName(id, trimAdAccountName)
        dispatch(fetchAdAccountWithoutStatus(id))
        dispatch(hideModal())
        setErrorChangeAdAccountName('')
        success('광고계정 이름이 수정되었습니다.')
      } catch (error) {
        const message = error?.response?.data?.message
        fail(message)
        dispatch(hideModal())
        setErrorChangeAdAccountName('')
      }
    } else {
      setErrorChangeAdAccountName('광고계정이름을 입력하세요.')
    }
  }
  const [checkboxAgreement, setCheckboxAgreement] = useState(false)
  const handleCheckboxChange = event => {
    setCheckboxAgreement(event.target.checked)
  }
  const [checkDeleteAdAccount, setCheckDeleteAdAccount] = useState(null)
  const [deleteAdAccountCompleted, setDeleteAdAccountCompleted] = useState(null)
  const [checkLinkedTracks, setCheckLinkedTracks] = useState(false)
  const fetchCheckDeleteAdAccount = async () => {
    try {
      const { data } = await getCheckDeleteAdAccount(id)
      const {
        data: {
          body: { content },
        },
      } = await linkedTracks({
        page: 0,
        adAccountId: id,
      })
      if (content.length > 0) {
        setCheckLinkedTracks(true)
      }
      if (data.deletable && content.length === 0) {
        await fireDeleteAdAccount()
      } else {
        setCheckDeleteAdAccount(data)
        dispatch(hideModal())
        dispatch(showModal('confirmDelAdAccountGuide'))
      }
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal())
      fail(message)
    }
  }
  const fireDeleteAdAccount = async () => {
    try {
      const { data } = await deleteAdAccount(id)
      setDeleteAdAccountCompleted(data)
      dispatch(hideModal())
      dispatch(showModal('modalDelAdAccountCompleted'))
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal())
      fail(message)
    }
  }
  const { pagable: pendingMemberList } = useSelector(
    state => state.member.pendingMemberList
  )
  const noticeBarPendingRequest1 = useRef()
  const noticeBarPendingRequest2 = useRef()
  const handleJoinApprove = async memberId => {
    try {
      await putAdAccountMemberJoinApprove(memberId)
      success('멤버 요청을 수락하였습니다.')
      dispatch(
        fetchMemberList({
          adAccountId: id,
          page: 0,
        })
      )
      dispatch(
        fetchPendingMemberList({
          adAccountId: id,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
      dispatch(
        fetchMemberHistory({
          adAccountId: id,
          page: 0,
        })
      )
      if (
        pendingMemberList.content
          .filter(member => member.status === 'PENDING_REQUEST')
          .slice(0, 2).length === 0
      ) {
        document.dispatchEvent(
          new CustomEvent('removeBedgePendingRequest', { detail: true })
        )
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleJoinReject = async memberId => {
    try {
      await putAdAccountMemberJoinReject(memberId)
      success('멤버 요청을 거부하였습니다.')
      dispatch(
        fetchPendingMemberList({
          adAccountId: id,
          page: 0,
          memberStatuses: 'PENDING_REQUEST,PENDING_INVITE',
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  useEffect(() => {
    if (
      pendingMemberList.content
        .filter(member => member.status === 'PENDING_REQUEST')
        .slice(0, 2).length === 0
    ) {
      document.dispatchEvent(
        new CustomEvent('removeBedgePendingRequest', { detail: true })
      )
    }
  }, [pendingMemberList])

  const { requested } = useSelector(state => state.businessRight)
  const noticeBarBizRightRequest = useRef()
  const handleApproveBizRightTransfer = async () => {
    try {
      await putBizRightApprove(requested.id)
      dispatch(fetchAdAccountWithoutStatus(id))
      dispatch(fetchBizRightCompleted(id))
      dispatch(fetchBizRightRequested(id))
      success('영업권이 설정되었습니다.')
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleRejectBizRightTransfer = async () => {
    try {
      await putBizRightReject(requested.id)
      dispatch(fetchBizRightRequested(id))
      success('영업권 요청을 거부하였습니다.')
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  useEffect(() => {
    if (!requested) {
      document.dispatchEvent(
        new CustomEvent('removeBedgeBizRightRequest', { detail: true })
      )
    }
  }, [requested])

  const { completed } = useSelector(state => state.businessRight)
  const noticeBarDelegationRequest = useRef()
  const handleConfirmModifyDelegationStatus = async delegationStatus => {
    try {
      await putModifyDelegationStatus(completed.id, delegationStatus)
      dispatch(fetchBizRightCompleted(id))
      dispatch(fetchAdAccountWithoutStatus(id))
      if (delegationStatus === 'COMPLETED') {
        success(`세금계산서 위임발행이 설정되었습니다.`)
      } else if (delegationStatus === 'REJECTED') {
        success(`세금계산서 위임발행이 거부되었습니다.`)
      } else if (delegationStatus === 'RELEASED') {
        success(`세금계산서 위임발행이 해제되었습니다.`)
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  useEffect(() => {
    if (completed && completed.delegationStatus !== 'REQUESTED') {
      document.dispatchEvent(
        new CustomEvent('removeBedgeDelegationRequest', { detail: true })
      )
    }
  }, [completed])
  const dspAccount = useSelector(state => state.dspAccount.current)
  const { pagable: payHistory } = useSelector(state => state.card.payHistory)
  const noticeBarPayHistory = useRef()
  useEffect(() => {
    ;(async () => {
      if (tab !== 'member') {
        await dispatch(
          fetchPendingMemberList({
            adAccountId: id,
            page: 0,
            memberStatuses: 'PENDING_REQUEST',
          })
        )
      }
      if (tab !== 'businessright') {
        await dispatch(fetchBizRightRequested(id))
        await dispatch(fetchBizRightCompleted(id))
      }
      if (tab !== 'businessright' || tab !== 'tax') {
        await dispatch(fetchBizRightCompleted(id))
      }
      await dispatch(
        fetchPayHistory({
          adAccountId: id,
          from: moment().add(-6, 'day').format(fmt),
          to: moment().format(fmt),
          status: 'SUCCESS,FAIL',
          page: 0,
        })
      )
    })()
  }, [dispatch, id, tab])

  return (
    <>
      <h2 id="#kakaoBody" className="screen_out">
        광고계정 관리 본문
      </h2>
      <div className="guidemsg_wrap">
        {master &&
          pendingMemberList.content
            .filter(member => member.status === 'PENDING_REQUEST')
            .slice(0, 1)
            .map((item, index) => (
              <div
                className="guide_msg2"
                key={`adaccount_pendingMemberList_${index}`}
                ref={noticeBarPendingRequest1}>
                <strong className="screen_out">광고계정 알림</strong>
                <p className="desc_msg">
                  {item.dspAccount.name}({item.dspAccount.kakaoEmail})님이 멤버
                  요청 중
                </p>
                <button
                  type="button"
                  className="btn_gs gs_bk"
                  onClick={() => handleJoinApprove(item.id)}>
                  <span className="inner_g">수락</span>
                </button>
                <button
                  type="button"
                  className="btn_gs gs_bk"
                  onClick={() => handleJoinReject(item.id)}>
                  <span className="inner_g">거부</span>
                </button>
                <button
                  className="link_close"
                  onClick={() => {
                    noticeBarPendingRequest1.current.style.display = 'none'
                  }}>
                  <span className="ico_comm ico_close">닫기</span>
                </button>
              </div>
            ))}
        {master &&
          pendingMemberList.content
            .filter(member => member.status === 'PENDING_REQUEST')
            .slice(1, 2)
            .map((item, index) => (
              <div
                className="guide_msg2"
                key={`adaccount_pendingMemberList_${index}`}
                ref={noticeBarPendingRequest2}>
                <strong className="screen_out">광고계정 알림</strong>
                <p className="desc_msg">
                  {item.dspAccount.name}({item.dspAccount.kakaoEmail})님이 멤버
                  요청 중
                </p>
                <button
                  type="button"
                  className="btn_gs gs_bk"
                  onClick={() => handleJoinApprove(item.id)}>
                  <span className="inner_g">수락</span>
                </button>
                <button
                  type="button"
                  className="btn_gs gs_bk"
                  onClick={() => handleJoinReject(item.id)}>
                  <span className="inner_g">거부</span>
                </button>
                <button
                  className="link_close"
                  onClick={() => {
                    noticeBarPendingRequest2.current.style.display = 'none'
                  }}>
                  <span className="ico_comm ico_close">닫기</span>
                </button>
              </div>
            ))}
        {master && requested && requested.bizRightStatus === 'REQUESTED' && (
          <div className="guide_msg2" ref={noticeBarBizRightRequest}>
            <strong className="screen_out">광고계정 알림</strong>
            <p className="desc_msg">
              {requested.marketer.agencyCompany.name}에서 영업권 요청 중
            </p>
            <button
              type="button"
              className="btn_gs gs_bk"
              onClick={() => handleApproveBizRightTransfer(requested.id)}>
              <span className="inner_g">수락</span>
            </button>
            <button
              type="button"
              className="btn_gs gs_bk"
              onClick={() => handleRejectBizRightTransfer(requested.id)}>
              <span className="inner_g">거부</span>
            </button>
            <button
              className="link_close"
              onClick={() => {
                noticeBarBizRightRequest.current.style.display = 'none'
              }}>
              <span className="ico_comm ico_close">닫기</span>
            </button>
          </div>
        )}
        {master && completed && completed.delegationStatus === 'REQUESTED' && (
          <div className="guide_msg2" ref={noticeBarDelegationRequest}>
            <strong className="screen_out">광고계정 알림</strong>
            <p className="desc_msg">
              {completed.marketer.agencyCompany.name}에서 세금계산서 위임발행
              요청 중
            </p>
            <button
              type="button"
              className="btn_gs gs_bk"
              onClick={() => handleConfirmModifyDelegationStatus('COMPLETED')}>
              <span className="inner_g">수락</span>
            </button>
            <button
              type="button"
              className="btn_gs gs_bk"
              onClick={() => handleConfirmModifyDelegationStatus('REJECTED')}>
              <span className="inner_g">거부</span>
            </button>
            <button
              className="link_close"
              onClick={() => {
                noticeBarDelegationRequest.current.style.display = 'none'
              }}>
              <span className="ico_comm ico_close">닫기</span>
            </button>
          </div>
        )}
        {!deferredPayment &&
          payHistory.content.length > 0 &&
          payHistory.content[0].status === 'FAIL' &&
          (master ||
            dspAccount.kakaoAccountId ===
              payHistory.content[0].kakaoAccountId) && (
            <div className="guide_msg2" ref={noticeBarPayHistory}>
              <strong className="screen_out">광고계정 알림</strong>
              <p className="desc_msg">
                등록된 카드의 결제가 진행되지 않았습니다. 결제 상태를
                확인하세요.
              </p>
              <Link
                to={`/${id}/settings/paymentcard`}
                type="button"
                className="btn_gs gs_bk">
                <span className="inner_g">확인하기</span>
              </Link>
              <button
                className="link_close"
                onClick={() => {
                  noticeBarPayHistory.current.style.display = 'none'
                }}>
                <span className="ico_comm ico_close">닫기</span>
              </button>
            </div>
          )}
      </div>
      <div className="settingmanage_wrap">
        <div className="dsp_tit">
          <div className="tit_dsp">
            <h3 className="tit_subject">광고계정 관리</h3>
            <strong className="sub_title">{name}</strong>
            <span className="sub_code">{id}</span>
            {master && (
              <div className="f_right">
                <div className="single_wrap">
                  <button
                    type="button"
                    className="btn_gm gm_line"
                    onClick={() => {
                      setAdAccountName(name)
                      dispatch(showModal('changeAdAccountName'))
                    }}>
                    <span className="inner_g">광고계정 이름 수정</span>
                  </button>
                </div>
                <div className="single_wrap">
                  <button
                    type="button"
                    className="btn_gm gm_line"
                    onClick={() => {
                      dispatch(showModal('modalDelAdAccountAgreement'))
                    }}>
                    <span className="inner_g">광고계정 삭제</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <strong className="screen_out">광고계정관리 메뉴 탭</strong>
        <ul className="tab_g">
          <li className={cx({ on: tabName === 'adaccount' })}>
            <Link
              to={`/${id}/settings/adaccount?tab=adaccount`}
              className="link_tab"
              onClick={() => {
                setTab('adaccount')
              }}>
              광고계정 현황
            </Link>
          </li>
          <li className={cx({ on: tabName === 'member' })}>
            <Link
              to="?tab=member"
              className="link_tab"
              onClick={() => {
                setTab('member')
              }}>
              멤버관리
            </Link>
          </li>
          <li className={cx({ on: tabName === 'businessright' })}>
            <Link
              to="?tab=businessright"
              className="link_tab"
              onClick={() => {
                setTab('businessright')
              }}>
              영업권관리
            </Link>
          </li>
          <li className={cx({ on: tabName === 'tax' })}>
            <Link
              to="?tab=tax"
              className="link_tab"
              onClick={() => {
                setTab('tax')
              }}>
              세금계산서
            </Link>
          </li>
        </ul>
        {tabName === 'adaccount' && <AdAccountPage />}
        {tabName === 'member' && <MemberPage adAccountType={_adAccountType} />}
        {tabName === 'businessright' && <BusinessRightPage />}
        {tabName === 'tax' && <TaxPage adAccountType={_adAccountType} />}
      </div>
      <Modal
        id="changeAdAccountName"
        title="광고계정이름 수정"
        className="withdrawal_layer"
        keepModal={true}
        ok={handleChangeAdAccountName}
        cancel={() => {
          setErrorChangeAdAccountName('')
        }}
        close={() => {
          setErrorChangeAdAccountName('')
        }}>
        <table className="tbl_layer">
          <caption className="hide_caption">광고계정명 수정 표</caption>
          <colgroup>
            <col width={161} />
            <col />
          </colgroup>
          <tbody>
            <tr className="tr_box">
              <th scope="row">광고계정 이름</th>
              <td>
                <div className="inner_cell">
                  <CounterTextInput
                    className={cx('box_inptxt', {
                      in_error: errorChangeAdAccountName,
                    })}
                    placeholder="광고계정 이름을 입력하세요."
                    name="name"
                    value={adAccountName}
                    onChange={handleChange}
                    minLength={1}
                    maxLength={20}
                    required={true}
                  />
                  {errorChangeAdAccountName && (
                    <p className="txt_error">{errorChangeAdAccountName}</p>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
      <Modal
        id="modalDelAdAccountAgreement"
        title="광고계정 삭제"
        className="accdel_layer"
        innerClassName="inner_basic_layer1"
        disabledOk={!checkboxAgreement}
        ok={() => {
          fetchCheckDeleteAdAccount()
          setCheckboxAgreement(false)
        }}
        cancel={() => {
          setCheckboxAgreement(false)
        }}
        close={() => {
          setCheckboxAgreement(false)
        }}>
        <p className="subtit_layer">삭제 신청 후 처리 안내</p>
        <ul className="list_guide">
          <li>삭제 신청 후 광고계정은 삭제 신청중 상태로 전환됩니다.</li>
          <li>
            삭제 신청중 상태에서는 광고계정 대시보드/보고서 등에 대한 접근이
            제한됩니다.
          </li>
          <li>삭제 신청중 상태에서는 삭제 신청에 대한 취소가 가능합니다.</li>
          <li>최종 삭제 완료까지 최대 2시간이 소요될 수 있습니다.</li>
          <li>
            삭제 신청 이후, 미결제 금액이 확인된 경우 계정 삭제는 반려되며,
            미결제 금액이 지불 완료된 후에 삭제를 재요청 할 수 있습니다.
          </li>
          <li>
            삭제 신청 시 광고계정에 발급된 가상계좌는 즉시 만료되어 사용이
            불가하며, 삭제 신청을 취소해도 만료된 가상계좌는 복구되지 않습니다.
          </li>
        </ul>
        <p className="subtit_layer">삭제 후 데이터 접근 및 복구 불가 안내</p>
        <ul className="list_guide">
          <li>보고서 지표를 비롯한 모든 데이터에 접근 할 수 없습니다.</li>
          <li>무상캐시는 모두 삭제되며 환불되지 않습니다.</li>
          <li>연결된 모든 영업권이 해제됩니다.</li>
          <li>삭제 취소가 불가합니다.</li>
        </ul>
        <fieldset className="fld_accdel">
          <Checkbox
            name="checkboxDelAdAccountAgreement"
            onChange={handleCheckboxChange}
            checked={checkboxAgreement}>
            위 안내사항을 모두 확인하였으며, 이에 동의합니다.
          </Checkbox>
        </fieldset>
      </Modal>
      <Modal
        id="confirmDelAdAccountGuide"
        title="광고계정 삭제"
        className="accdel_layer"
        showCancel={false}
        innerClassName="inner_basic_layer2"
        ok={() => {
          setCheckDeleteAdAccount(null)
        }}
        close={() => {
          setCheckDeleteAdAccount(null)
        }}>
        <p className="subtit_layer">
          아래 사유로 인해 삭제 신청이 불가능 합니다.
        </p>
        <ul className="list_cause">
          {checkDeleteAdAccount &&
            !checkDeleteAdAccount.reasons.adAccountOff && (
              <li>
                광고계정이 사용자OFF 상태가 아닙니다.
                <br />
                사용자OFF 상태일 경우에만 삭제 신청이 가능합니다.
                <button
                  className="btn_gs"
                  onClick={() => {
                    dispatch(hideModal())
                    setCheckDeleteAdAccount(null)
                    history.push(
                      `/${checkDeleteAdAccount.adAccountId}/dashboard`
                    )
                  }}>
                  <span className="inner_g">사용자OFF하기</span>
                </button>
              </li>
            )}
          {checkDeleteAdAccount &&
            !checkDeleteAdAccount.reasons.adAccountMembersNone && (
              <li>
                광고계정이 하위에 삭제 신청자 본인 외 다른 멤버가 존재합니다.
                <br />
                광고계정 멤버는 신청자 본인만 있어야 합니다.
                <button
                  className="btn_gs"
                  onClick={() => {
                    dispatch(hideModal())
                    setCheckDeleteAdAccount(null)
                    history.push(`?tab=member`)
                  }}>
                  <span className="inner_g">멤버 확인</span>
                </button>
              </li>
            )}
          {checkDeleteAdAccount && !checkDeleteAdAccount.reasons.noBizRight && (
            <li>
              광고계정에 영업권 설정이 되어 있습니다.
              <br />
              영업권 설정을 해제하셔야 합니다.
              <button
                className="btn_gs"
                onClick={() => {
                  dispatch(hideModal())
                  setCheckDeleteAdAccount(null)
                  history.push(`?tab=businessright`)
                }}>
                <span className="inner_g">영업권 확인</span>
              </button>
            </li>
          )}
          {checkDeleteAdAccount &&
            !checkDeleteAdAccount.reasons.noCashBalance && (
              <li>
                광고계정 유상캐시 잔액이 있습니다.
                <br />
                환불 요청 중인 내용이 없어야 합니다.
                <button
                  className="btn_gs"
                  onClick={() => {
                    dispatch(hideModal())
                    setCheckDeleteAdAccount(null)
                    history.push(
                      `/${checkDeleteAdAccount.adAccountId}/settings/cash`
                    )
                  }}>
                  <span className="inner_g">캐시 확인</span>
                </button>
              </li>
            )}
          {
            // TODO 자동 카드결제 붙일 때 봐야 함.
            checkDeleteAdAccount &&
              !checkDeleteAdAccount.reasons.noExpectedPayment && (
                <li>
                  결제 예정 금액이 있습니다.
                  <br />
                  자동 결제카드의 결제 예정 금액이 없어야 합니다.
                  <NoneAnchor className="btn_gs">
                    <span className="inner_g">결제 금액 확인</span>
                  </NoneAnchor>
                </li>
              )
          }
          {checkDeleteAdAccount &&
            !checkDeleteAdAccount.reasons.noRefundRequest && (
              <li>
                환불 요청 중인 내용이 있습니다.
                <br />
                환불 요청 중인 내용이 없어야 합니다.
                <button
                  className="btn_gs"
                  onClick={() => {
                    dispatch(hideModal())
                    setCheckDeleteAdAccount(null)
                    history.push(
                      `/${checkDeleteAdAccount.adAccountId}/settings/cash`
                    )
                  }}>
                  <span className="inner_g">환불내용 확인</span>
                </button>
              </li>
            )}
          {checkDeleteAdAccount && !checkDeleteAdAccount.reasons.noTaxbill && (
            <li>
              발행 예정인 세금계산서가 있습니다.
              <br />
              이번 달 소진 금액이 있거나, 발행완료되지 않은 세금계산서가
              있습니다.
              <button
                className="btn_gs"
                onClick={() => {
                  dispatch(hideModal())
                  setCheckDeleteAdAccount(null)
                  history.push(`?tab=tax`)
                }}>
                <span className="inner_g">세금계산서 확인</span>
              </button>
            </li>
          )}
          {checkDeleteAdAccount && checkLinkedTracks && (
            <li>
              광고계정에 연동된 픽셀 &amp; SDK가 있습니다.
              <br />
              광고계정 연동을 해제 후 삭제 신청이 가능합니다.
              <button
                className="btn_gs"
                onClick={() => {
                  dispatch(hideModal())
                  setCheckDeleteAdAccount(null)
                  history.push(`/${id}/tracker`)
                }}>
                <span className="inner_g">연동 해제하기</span>
              </button>
            </li>
          )}
        </ul>
      </Modal>
      <Modal
        id="modalDelAdAccountCompleted"
        title="광고계정 삭제"
        className="accdel_layer"
        innerClassName="inner_basic_layer3"
        showCancel={false}
        ok={() => {
          setDeleteAdAccountCompleted(null)
          dispatch(hideModal())
          history.push('/adaccount/all')
        }}
        close={() => {
          setDeleteAdAccountCompleted(null)
          dispatch(hideModal())
          history.push('/adaccount/all')
        }}>
        <p className="subtit_layer">광고 계정 삭제 신청이 완료되었습니다.</p>
        <table className="tbl_layer">
          <caption className="hide_caption">광고계정 정보 표</caption>
          <colgroup>
            <col width={161} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">광고계정명</th>
              <td>
                <div className="inner_cell">
                  {deleteAdAccountCompleted &&
                    deleteAdAccountCompleted.adAccountName}
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">광고계정번호</th>
              <td>
                <div className="inner_cell">
                  {deleteAdAccountCompleted &&
                    deleteAdAccountCompleted.adAccountId}
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">삭제 신청 일시</th>
              <td>
                <div className="inner_cell">
                  {moment(
                    deleteAdAccountCompleted &&
                      deleteAdAccountCompleted.deleteRequestDate
                  ).format('YYYY.MM.DD HH:mm:ss')}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ul className="list_guide">
          <li>최종 삭제 완료까지 최대 2시간이 소요될 수 있습니다.</li>
          <li>
            &apos;삭제 신청중&apos; 상태에서는{' '}
            <Link
              to="/adAccounts"
              onClick={() => {
                setDeleteAdAccountCompleted(null)
                dispatch(hideModal())
              }}
              className="link_txt">
              전체 광고 계정
            </Link>{' '}
            페이지를 통해 삭제 신청에 대한 취소가 가능합니다.
          </li>
          <li>단, 삭제 완료 이후에는 취소가 불가능합니다.</li>
        </ul>
      </Modal>
    </>
  )
}

export default AdAccountIndex
