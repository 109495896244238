import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  fetchAutopayCard,
  fetchChangeHistory,
  fetchKakaoPayCards,
  fetchPayHistory,
} from 'features/settings/paymentcard/paymentCardSlice'
import Tooltip from 'components/tooltip/Tooltip'
import Loading from 'components/loading/Loading'
import Modal from 'components/dialog/Modal'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import Alert from 'components/dialog/Alert'
import Confirm from 'components/dialog/Confirm'
import cx from 'classnames'
import NumberFormat from 'react-number-format'
import {
  deleteAutopayCard,
  postAutopayCard,
  postMyAutopayCard,
  putAutopayDailySpendingBudget,
  putPriorityOrderToZero,
} from 'api/billingAPI'
import moment from 'moment'
import DateRangePicker from 'components/calendar/DateRangePicker'
import { CALENDAR_PRESETS, fmt } from 'components/calendar/calendarUtil'
import { SELECTBOX_PAY_HISTORY_STATUS } from 'features/settings/cash/CashConstants'
import Select from 'components/form/Select'
import LoadingTable from 'components/table/LoadingTable'
import NoDataTable from 'components/table/NoDataTable'
import Pagination from 'components/pagination/Pagination'
import NoneAnchor from 'components/NoneAnchor'
import { putKakaoPayAgreement } from 'api/accountAPI'
import { fail, success } from '../../../utils/toastUtils'

const PaymentCardIndex = () => {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const autopayDailySpendingBudgetBox = useRef()
  const dspAccount = useSelector(state => state.dspAccount.current)
  const adAccount = useSelector(state => state.adAccount.current)
  const { id, name, master } = useSelector(state => state.adAccount.current)
  const { isLoading: autopayCardLoading, pagable: autopayCard } = useSelector(
    state => state.card.autopayCard
  )
  const { isLoading: kakaoPayCardsLoading, pagable: kakaoPayCards } =
    useSelector(state => state.card.kakaoPayCards)
  const { isLoading: payHistoryLoading, pagable: payHistory } = useSelector(
    state => state.card.payHistory
  )
  const { isLoading: changeHistoryLoading, pagable: changeHistory } =
    useSelector(state => state.card.changeHistory)

  const [payHistoryPage, setPayHistoryPage] = useState(
    parseInt(params.get('payHistoryPage'), 10) || 0
  )
  const [searchOptionsPayHistory, setSearchOptionsPayHistory] = useState({
    status: 'SUCCESS,FAIL',
    adAccountId: adAccount.id,
    preset: CALENDAR_PRESETS.LAST_7_DAYS,
    to: moment().format(fmt),
    from: moment().add(-6, 'day').format(fmt),
  })
  const handlePayHistoryPageChange = number => {
    history.push(`?payHistoryPage=${number}`)
    setPayHistoryPage(number)
  }
  const handleSearchOptionsDuration = (from, to, preset) => {
    setSearchOptionsPayHistory({
      ...searchOptionsPayHistory,
      preset,
      to,
      from,
    })
  }
  const handleSearchOptionsStatus = value => {
    setSearchOptionsPayHistory({
      ...searchOptionsPayHistory,
      status: value,
    })
  }
  const [changeHistoryPage, setChangeHistoryPage] = useState(
    parseInt(params.get('changeHistoryPage'), 10) || 0
  )
  const handleChangeHistoryPageChange = number => {
    history.push(`?changeHistoryPage=${number}`)
    setChangeHistoryPage(number)
  }

  const [deleteMasterCardByOwnerTitle, setDeleteMasterCardByOwnerTitle] =
    useState('')
  const [
    deleteMasterCardByOwnerExpectedPayment,
    setDeleteMasterCardByOwnerExpectedPayment,
  ] = useState('')
  const [deleteMasterCardByOwnerCardKey, setDeleteMasterCardByOwnerCardKey] =
    useState('')
  const [
    deleteMasterCardByOwnerKakaoAccountId,
    setDeleteMasterCardByOwnerKakaoAccountId,
  ] = useState('')
  const handleDeleteMasterCardByOwner = (
    title,
    expectedPayment,
    cardKey,
    kakaoAccountId
  ) => {
    setDeleteMasterCardByOwnerTitle(title)
    setDeleteMasterCardByOwnerExpectedPayment(expectedPayment)
    setDeleteMasterCardByOwnerCardKey(cardKey)
    setDeleteMasterCardByOwnerKakaoAccountId(kakaoAccountId)
    dispatch(showModal('confirmDeleteMasterCardByOwner'))
  }
  const handleDeleteMasterCardByNoOwner = () => {
    dispatch(showModal('alertDeleteMasterCardByNoOwner'))
  }
  const [deleteCardByOwnerTitle, setDeleteCardByOwnerTitle] = useState('')
  const [deleteCardByOwnerCard, setDeleteCardByOwnerCard] = useState('')
  const [deleteCardByOwnerCardKey, setDeleteCardByOwnerCardKey] = useState('')
  const [deleteCardByOwnerKakaoAccountId, setDeleteCardByOwnerKakaoAccountId] =
    useState('')
  const handleDeleteSpareCardByOwner = (
    title,
    card,
    cardKey,
    kakaoAccountId
  ) => {
    setDeleteCardByOwnerTitle(title)
    setDeleteCardByOwnerCard(card)
    setDeleteCardByOwnerCardKey(cardKey)
    setDeleteCardByOwnerKakaoAccountId(kakaoAccountId)
    dispatch(showModal('confirmDeleteCardByOwner'))
  }
  const deleteAutopayCardMethodOwner = async () => {
    try {
      await deleteAutopayCard(adAccount.id, {
        data: {
          adPlatformType: 'KEYWORDAD',
          autopayCardKey: deleteCardByOwnerCardKey,
          dspId: 'KEYWORDAD',
          ownerKakaoAccountId: deleteCardByOwnerKakaoAccountId,
        },
      })
      await dispatch(fetchAutopayCard({ adAccountId: adAccount.id }))
      await dispatch(
        fetchChangeHistory({
          adAccountId: adAccount.id,
          page: changeHistoryPage,
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const deleteAutopayCardMethod = async () => {
    try {
      await deleteAutopayCard(adAccount.id, {
        data: {
          adPlatformType: 'KEYWORDAD',
          autopayCardKey: deleteMasterCardByOwnerCardKey,
          dspId: 'KEYWORDAD',
          ownerKakaoAccountId: deleteMasterCardByOwnerKakaoAccountId,
        },
      })
      await dispatch(fetchAutopayCard({ adAccountId: adAccount.id }))
      await dispatch(
        fetchChangeHistory({
          adAccountId: adAccount.id,
          page: changeHistoryPage,
        })
      )
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  const [priorityOrderToZeroTitle, setPriorityOrderToZeroTitle] = useState('')
  const [priorityOrderToZeroCardKey, setPriorityOrderToZeroCardKey] =
    useState('')
  const handleClickPriorityOrderToZero = (title, autopayCardKey) => {
    setPriorityOrderToZeroTitle(title)
    setPriorityOrderToZeroCardKey(autopayCardKey)
    dispatch(showModal('confirmPriorityOrderToZero'))
  }

  const handleAddAutopayCard = async ev => {
    ev.preventDefault()
    setCheckedAddAutopayCardKey('')
    setAutopayDailySpendingBudget(0)
    await dispatch(fetchKakaoPayCards({ adAccountId: adAccount.id }))
    kakaoPayCards.length > 0
      ? dispatch(showModal('confirmAddAutopayCard'))
      : dispatch(showModal('alertEmptyMyAutopayCard'))
  }
  const [checkedAddAutopayCardKey, setCheckedAddAutopayCardKey] = useState('')
  const handleChangeRadio = e => {
    const { value } = e.target
    setCheckedAddAutopayCardKey(value)
  }
  const [autopayDailySpendingBudget, setAutopayDailySpendingBudget] =
    useState(0)
  const handleSetAutopayDailySpendingBudget = value => {
    setAutopayDailySpendingBudget(value)
  }
  const handlePostAutopayCard = async () => {
    if (!checkedAddAutopayCardKey) {
      dispatch(showModal('alertNoChoosedAutocard'))
      return false
    }
    if (
      autopayCard.length === 0 &&
      (autopayDailySpendingBudget < 1000 ||
        autopayDailySpendingBudget > 20000000 ||
        autopayDailySpendingBudget % 1000 !== 0)
    ) {
      dispatch(showModal('alertInvalidDailySpendingBudget'))
      return false
    }
    try {
      await postAutopayCard(adAccount.id, {
        adPlatformType: 'KEYWORDAD',
        autopayCardKey: checkedAddAutopayCardKey,
        dailySpendingBudget: autopayDailySpendingBudget,
        dspId: 'KEYWORDAD',
        isMaster: master,
        priorityOrder: autopayCard.length,
      })
      setCheckedAddAutopayCardKey('')
      setAutopayDailySpendingBudget(0)
      await dispatch(fetchAutopayCard({ adAccountId: adAccount.id }))
      await dispatch(
        fetchChangeHistory({
          adAccountId: adAccount.id,
          page: changeHistoryPage,
        })
      )
      dispatch(hideModal())
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal())
      fail(message)
    }
  }
  const getMaskingCardNumber = numberString => {
    if (numberString && numberString.length >= 6) {
      return `${numberString.substring(0, 4)} ${numberString.substring(
        4,
        6
      )}** **** ****`
    }
    return numberString
  }

  const handlePriorityOrderToZero = async () => {
    try {
      await putPriorityOrderToZero(adAccount.id, {
        adPlatformType: 'KEYWORDAD',
        autopayCardKey: priorityOrderToZeroCardKey,
        dspId: 'KEYWORDAD',
      })
      await dispatch(fetchAutopayCard({ adAccountId: adAccount.id }))
      await dispatch(
        fetchChangeHistory({
          adAccountId: adAccount.id,
          page: changeHistoryPage,
        })
      )
      dispatch(hideModal())
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal())
      fail(message)
    }
  }

  const [checkedAddAutopayCard, setCheckedAddAutopayCard] = useState(false)
  const handleAddMyAutopayCard = () => {
    if (kakaoPayCards.length > 0) {
      addMyAutopayCard(false)
    } else {
      setCheckedAddAutopayCard(false)
      dispatch(showModal('confirmAddMyAutopayCard'))
    }
  }
  const addMyAutopayCard = async init => {
    if (init) await putKakaoPayAgreement(dspAccount.id)
    const { data } = await postMyAutopayCard({
      isPopUpRedirectUrl: false,
      redirectUrl: encodeURIComponent(
        `${window.location.href}${
          window.location.href.indexOf('?') > -1 ? '&' : '?'
        }openAddAutoPayCard=true`
      ),
    })
    const { paymentUrl } = data
    window.open(
      paymentUrl,
      '_blank',
      'width=520,height=701,top=0,left=0,menubar=no,status=no,toolbar=no'
    )
  }

  useEffect(() => {
    dispatch(fetchKakaoPayCards({ adAccountId: adAccount.id }))
    dispatch(fetchAutopayCard({ adAccountId: adAccount.id }))
    if (window.location.search.indexOf('openAddAutoPayCard') > 0) {
      setCheckedAddAutopayCardKey('')
      setAutopayDailySpendingBudget(0)
      dispatch(showModal('confirmAddAutopayCard'))
      history.push([location.origin, location.pathname].join(''))
    }
  }, [dispatch, adAccount.id, history, location.origin, location.pathname])
  useEffect(() => {
    dispatch(
      fetchPayHistory({
        adAccountId: searchOptionsPayHistory.adAccountId,
        from: searchOptionsPayHistory.from,
        to: searchOptionsPayHistory.to,
        status: searchOptionsPayHistory.status,
        page: payHistoryPage,
      })
    )
  }, [dispatch, searchOptionsPayHistory, payHistoryPage])
  useEffect(() => {
    dispatch(
      fetchChangeHistory({
        adAccountId: adAccount.id,
        page: changeHistoryPage,
      })
    )
  }, [dispatch, changeHistoryPage, adAccount.id])
  return (
    <>
      <div className="accountpayment_wrap">
        <div className="dsp_tit">
          <div className="tit_dsp">
            <h3 className="tit_subject">결제카드 관리</h3>
            <strong className="sub_title">{name}</strong>
            <span className="sub_code">{id}</span>
          </div>
        </div>
        <ul className="list_append">
          <li>결제카드는 광고계정당 최대 3개까지 등록 가능합니다.</li>
          <li>
            자동결제 카드는 일반 신용카드만 등록할 수 있습니다. (단, 신용카드
            기능이 포함된 &apos;하이브리드&apos;카드는 연결된 은행의 점검 시간에
            영향을 받아 등록을 권하지 않습니다.)
          </li>
          <li>
            결제카드로 자동결제가 실패할 경우 예비카드1&gt;예비카드2 순으로
            결제를 시도합니다.
          </li>
          <li>카카오페이에 등록된 공용 법인카드는 현재 사용할 수 없습니다.</li>
        </ul>
        <div className="info_account">
          <h4 className="screen_out">결제 및 카드 정보</h4>
          <div className="account_paymentinfo">
            <strong className="tit_account">결제정보</strong>
            <em className="tit_cardpayment">카드 자동 결제 예정액</em>
            <span className="txt_cardpayment">
              {autopayCardLoading ? (
                '0'
              ) : autopayCard.length > 0 ? (
                <NumberFormat
                  value={
                    autopayCard.filter(
                      card => card.priorityType === 'MASTER'
                    )[0].expectedPayment
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                />
              ) : (
                '0'
              )}
              원
            </span>
            {!autopayCardLoading &&
              autopayCard.length > 0 &&
              autopayCard.filter(card => card.priorityType === 'MASTER')[0]
                .payRetryCountOver && (
                <span className="txt_error">
                  일 결제 시도 횟수 초과
                  <Tooltip
                    className={'ico_comm ico_fail'}
                    iconStyle={{
                      width: '14px',
                      height: '14px',
                      marginTop: '7px',
                    }}>
                    일 결제 시도 횟수가 초과되면 광고 운영이 정지됩니다.
                  </Tooltip>
                </span>
              )}
            <table className="define_adinfo">
              <caption className="hide_caption">카드 자동 결제 정보 표</caption>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">일 지출한도</th>
                  <td>
                    {autopayCardLoading ? (
                      <span className="fc_emph">0원</span>
                    ) : autopayCard.length > 0 ? (
                      <NoneAnchor
                        className="fc_emph"
                        onClick={ev => {
                          ev.preventDefault()
                          handleSetAutopayDailySpendingBudget(
                            autopayCardLoading
                              ? 0
                              : autopayCard.length > 0
                                ? autopayCard.filter(
                                    card => card.priorityType === 'MASTER'
                                  )[0].dailySpendingBudget
                                : 0
                          )
                          autopayDailySpendingBudgetBox.current.style.display =
                            'block'
                        }}>
                        <NumberFormat
                          value={
                            autopayCard.filter(
                              card => card.priorityType === 'MASTER'
                            )[0].dailySpendingBudget
                          }
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        원
                      </NoneAnchor>
                    ) : (
                      <span className="fc_emph">0원</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">사용 가능액</th>
                  <td>
                    {autopayCardLoading ? (
                      '0'
                    ) : autopayCard.length > 0 ? (
                      <NumberFormat
                        value={
                          autopayCard.filter(
                            card => card.priorityType === 'MASTER'
                          )[0].availableSpendingAmount
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    ) : (
                      '0'
                    )}
                    원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="account_cardinfo">
            <strong className="tit_account">
              등록 카드 <span className="fc_empty">{autopayCard.length}</span>
              <Tooltip>
                광고계정에 등록한 결제카드 리스트입니다.
                <br />이 중 ‘결제카드’는 현재 광고계정에서 발생한 비용을 자동
                결제하는 카드를 의미합니다.
              </Tooltip>
              {autopayCardLoading ? (
                <></>
              ) : (
                <NoneAnchor
                  className="btn_gm gm_line"
                  onClick={handleAddMyAutopayCard}>
                  <span className="inner_g">신규 카드 등록</span>
                </NoneAnchor>
              )}
            </strong>
            {autopayCardLoading ? (
              <div className="nodata_info" style={{ minHeight: '190px' }}>
                <div style={{ paddingTop: '88px' }}>
                  <Loading />
                </div>
              </div>
            ) : !(autopayCard && autopayCard.length > 0) ? (
              <div className="add_card">
                <div className="card_wrap">
                  <div className="inner_card">
                    <span className="ico_comm ico_plus" />
                    <span className="txt_add">
                      카드 추가
                      <Tooltip>
                        결제 카드 또는 예비 카드를
                        <br />
                        결제할 우선 순위에 맞게 등록합니다.
                      </Tooltip>
                    </span>
                  </div>
                </div>
                <NoneAnchor
                  className="link_add"
                  onClick={handleAddAutopayCard}
                />
              </div>
            ) : (
              <>
                {autopayCard.map((item, index) => (
                  <div
                    key={`autopayCardList_${index}`}
                    className={`card_pay card_${item.cardCorpName.toLowerCase()} on_card`}>
                    <div className="inner_card">
                      <strong className="tit_card">
                        <span className="use_card">
                          {item.priorityType === 'MASTER'
                            ? '결제카드'
                            : `예비카드${item.priorityOrder}`}
                        </span>
                      </strong>
                      {item.cardCorpName.toLowerCase() === 'kb' ||
                      item.cardCorpName.toLowerCase() === 'nh' ||
                      item.cardCorpName.toLowerCase() === 'shinhan' ||
                      item.cardCorpName.toLowerCase() === 'hana' ||
                      item.cardCorpName.toLowerCase() === 'bc' ||
                      item.cardCorpName.toLowerCase() === 'lotte' ||
                      item.cardCorpName.toLowerCase() === 'samsung' ||
                      item.cardCorpName.toLowerCase() === 'citi' ||
                      item.cardCorpName.toLowerCase() === 'hyundai' ||
                      item.cardCorpName.toLowerCase() === 'kakaobank' ||
                      item.cardCorpName.toLowerCase() === 'kakaopay' ||
                      item.cardCorpName.toLowerCase() === 'paymoney' ? (
                        <em className="card_logo">
                          <span className="ico_cardlogo">
                            {item.cardCorpDisplayName}
                          </span>
                        </em>
                      ) : (
                        <em className="txt_info">{item.cardCorpDisplayName}</em>
                      )}
                      <dl className="detail_cardnum">
                        <dt>카드번호</dt>
                        <dd>{getMaskingCardNumber(`${item.cardBin}`)}</dd>
                      </dl>
                      <dl className="detail_carduser">
                        <dt>소유자</dt>
                        <dd>
                          {item.kakaoEmail}
                          {item.dspAccountId === dspAccount.id ? ' (나)' : ''}
                        </dd>
                      </dl>
                      {(item.priorityOrder === 1 || item.priorityOrder === 2) &&
                        item.kakaoAccountId === dspAccount.kakaoAccountId && (
                          <div className="enter_card">
                            <NoneAnchor
                              className="link_enter"
                              onClick={() => {
                                const title =
                                  item.priorityType === 'MASTER'
                                    ? '결제카드'
                                    : `예비카드${item.priorityOrder}`
                                const card = `${
                                  item.cardCorpDisplayName
                                } ${item.cardBin.substring(0, 4)}`
                                handleClickPriorityOrderToZero(
                                  `${title} ${card}`,
                                  `${item.autopayCardKey}`
                                )
                              }}>
                              결제카드로 변경
                            </NoneAnchor>
                          </div>
                        )}
                      {
                        // 결제예정금액이 0이하인 경우 또는
                        // 마스터인 경우 또는
                        // 예비카드인 경우 또는
                        // 소유자인 경우
                        (item.expectedPayment <= 0 ||
                          master ||
                          // eslint-disable-next-line no-mixed-operators
                          item.priorityType !== 'MASTER' ||
                          // eslint-disable-next-line no-mixed-operators
                          item.kakaoAccountId ===
                            dspAccount.kakaoAccountId) && (
                          <NoneAnchor
                            className="btn_del"
                            onClick={() => {
                              // 결제카드이고 결제예정금액이 0초과이고 소유자인 경우 -> 결제 후 삭제
                              // 결제카드이고 결제예정금액이 0초과이고 마스터인 경우 -> 새로운 결제카드 등록
                              // 그 외의 경우 -> 일반 삭제
                              item.expectedPayment > 0 &&
                              item.kakaoAccountId === dspAccount.kakaoAccountId
                                ? (function () {
                                    const card = `${
                                      item.cardCorpDisplayName
                                    } ${getMaskingCardNumber(item.cardBin)}`
                                    handleDeleteMasterCardByOwner(
                                      `${card}`,
                                      `${item.expectedPayment}`,
                                      `${item.autopayCardKey}`,
                                      `${item.kakaoAccountId}`
                                    )
                                  })()
                                : item.expectedPayment > 0 && master
                                  ? handleDeleteMasterCardByNoOwner()
                                  : (function () {
                                      const title =
                                        item.priorityType === 'MASTER'
                                          ? '결제카드'
                                          : `예비카드${item.priorityOrder}`
                                      const card = `${
                                        item.cardCorpDisplayName
                                      } ${item.cardBin.substring(0, 4)}`
                                      handleDeleteSpareCardByOwner(
                                        title,
                                        card,
                                        `${item.autopayCardKey}`,
                                        `${item.kakaoAccountId}`
                                      )
                                    })()
                            }}>
                            <span className="ico_comm ico_del">삭제</span>
                          </NoneAnchor>
                        )
                      }
                    </div>
                  </div>
                ))}
                {autopayCard.length < 3 && (
                  <div className="add_card">
                    <div className="card_wrap">
                      <div className="inner_card">
                        <span className="ico_comm ico_plus" />
                        <span className="txt_add">
                          카드 추가
                          <Tooltip>
                            결제 카드 또는 예비 카드를
                            <br />
                            결제할 우선 순위에 맞게 등록합니다.
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                    <NoneAnchor
                      className="link_add"
                      onClick={handleAddAutopayCard}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className="tooltip_budget bottom on"
            style={{
              display: 'none',
              left: '102px',
              top: '236px',
            }}
            ref={autopayDailySpendingBudgetBox}>
            <div className="budget_cheking">
              <span className="box_inptxt inp_num on">
                <span className="num_byte">원</span>
                <span className="inner_inp">
                  <label htmlFor="inpTxt1" className="screen_out">
                    일예산 수정
                  </label>
                  <NumberFormat
                    thousandSeparator={true}
                    className="inp_txt"
                    allowNegative={false}
                    inputMode="numeric"
                    value={autopayDailySpendingBudget}
                    onValueChange={val => {
                      handleSetAutopayDailySpendingBudget(val.value)
                    }}
                  />
                </span>
              </span>
            </div>
            <p className="txt_guide">최소 1천원 이상 설정 가능</p>
            <div className="wrap_btn">
              <NoneAnchor
                className="btn_gm"
                onClick={() => {
                  autopayDailySpendingBudgetBox.current.style.display = 'none'
                }}>
                <span className="inner_g">취소</span>
              </NoneAnchor>
              <button
                className="btn_gm gm_bl"
                onClick={async ev => {
                  ev.preventDefault()
                  if (
                    autopayDailySpendingBudget.length === 0 ||
                    autopayDailySpendingBudget < 1000 ||
                    autopayDailySpendingBudget > 20000000 ||
                    autopayDailySpendingBudget % 1000 !== 0
                  ) {
                    dispatch(showModal('alertInvalidDailySpendingBudget'))
                    return false
                  } else {
                    try {
                      await putAutopayDailySpendingBudget(
                        adAccount.id,
                        autopayDailySpendingBudget
                      )
                      await dispatch(
                        fetchAutopayCard({ adAccountId: adAccount.id })
                      )
                      await dispatch(
                        fetchChangeHistory({
                          adAccountId: adAccount.id,
                          page: changeHistoryPage,
                        })
                      )
                      autopayDailySpendingBudgetBox.current.style.display =
                        'none'
                      success('일 지출한도가 변경되었습니다.')
                    } catch (error) {
                      const message = error?.response?.data?.message
                      fail(message)
                    }
                  }
                }}>
                <span className="inner_g">수정</span>
              </button>
            </div>
            <span className="img_comm ico_arr" />
          </div>
        </div>
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">거래 내역</strong>
            <div className="f_right">
              <div className="single_wrap">
                <Select
                  options={SELECTBOX_PAY_HISTORY_STATUS}
                  onChange={handleSearchOptionsStatus}
                  selected={searchOptionsPayHistory.status}
                />
              </div>
              <div className="single_wrap">
                <DateRangePicker
                  preset={searchOptionsPayHistory.preset}
                  start={searchOptionsPayHistory.from}
                  end={searchOptionsPayHistory.to}
                  onChange={handleSearchOptionsDuration}
                  min={moment().subtract(5, 'year').format(fmt)}
                  max={moment().format(fmt)}
                />
              </div>
            </div>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">거래내역 표</caption>
              <colgroup>
                <col width="220px" />
                <col width="220px" />
                <col />
                <col width="185px" />
                <col width="185px" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">거래일시</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">카드 유형</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">카드 정보</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">결제 금액</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">결제 상태</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {payHistoryLoading ? (
                  <LoadingTable colSpan={5} />
                ) : !(payHistory && payHistory.content.length > 0) ? (
                  <NoDataTable colSpan={5}>거래 내역이 없습니다.</NoDataTable>
                ) : (
                  payHistory.content.map((item, index) => (
                    <tr key={`payHistory_${index}`}>
                      <td>
                        <div className="inner_tbl">
                          {moment(item.approvedDt).format('YYYY.MM.DD HH:mm')}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {item.priorityType === 'MASTER'
                            ? '결제카드'
                            : item.priorityType === 'SPARE_1'
                              ? '예비카드1'
                              : '예비카드2'}
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          <span className="limit_line">
                            {item.cardName} ({item.cardNumber})
                          </span>
                        </div>
                      </td>
                      <td className="align_r">
                        <div className="inner_tbl">
                          <NumberFormat
                            value={item.amount}
                            displayType={'text'}
                            thousandSeparator={true}
                            defaultValue={0}
                          />
                          원
                        </div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {item.status === 'SUCCESS' ? (
                            <span>결제 성공</span>
                          ) : (
                            <span className="fc_warn">
                              결제 실패
                              <Tooltip
                                className={'ico_comm ico_fail'}
                                iconStyle={{
                                  width: '14px',
                                  height: '14px',
                                  marginTop: '4px',
                                }}>
                                {item.errorMessage}
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {payHistory.totalElements > 0 && (
            <Pagination
              onChange={handlePayHistoryPageChange}
              totalPages={payHistory.totalPages}
              number={payHistory.number}
            />
          )}
        </div>
        {/* 변경이력 */}
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">변경 이력</strong>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">변경이력 정보 표</caption>
              <colgroup>
                <col />
                <col width="185px" />
              </colgroup>
              <thead>
                <tr className="hide_head">
                  {/* 표 타이틀 숨김 : hide_head 클래스 추가 */}
                  <th scope="col">내용</th>
                  <th scope="col">날짜/시간</th>
                </tr>
              </thead>
              <tbody>
                {changeHistoryLoading ? (
                  <LoadingTable colSpan={2} />
                ) : !(changeHistory && changeHistory.content.length > 0) ? (
                  <NoDataTable colSpan={2}>변경 이력이 없습니다.</NoDataTable>
                ) : (
                  changeHistory.content.map((item, index) => (
                    <tr key={`changeHistory_${index}`}>
                      <td>
                        <div className="inner_tbl">{item.content}</div>
                      </td>
                      <td>
                        <div className="inner_tbl">
                          {moment(item.createDt).format('YYYY.MM.DD HH:mm')}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {changeHistory.totalElements > 0 && (
            <Pagination
              onChange={handleChangeHistoryPageChange}
              totalPages={changeHistory.totalPages}
              number={changeHistory.number}
            />
          )}
        </div>
        {/* //변경이력 */}
      </div>
      <Modal
        id="confirmAddAutopayCard"
        className="cardregist_layer"
        innerClassName="inner_basic_layer1"
        title={
          autopayCard.length > 0 &&
          autopayCard.filter(item => item.priorityOrder !== undefined)
            .length === 1
            ? '예비카드1 추가'
            : autopayCard.length > 0 &&
                autopayCard.filter(item => item.priorityOrder !== undefined)
                  .length === 2
              ? '예비카드2 추가'
              : '결제카드 추가'
        }
        okText="확인"
        ok={() => {
          autopayCard.length > 0 &&
          kakaoPayCards.filter(
            item => item.registeredPriorityOrder === undefined
          ).length === 0
            ? history.push(`/myinfo?tab=autopaycard`)
            : handlePostAutopayCard()
        }}
        keepModal={true}>
        <div className="wrap_tbl">
          <div className="inner_wrap">
            <table className="tbl_layer">
              <caption className="hide_caption">카드 추가 표</caption>
              <colgroup>
                <col width={159} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">등록 카드 리스트</th>
                  <td>
                    <div className="inner_cell">
                      <ul className="list_cash">
                        {kakaoPayCardsLoading ? (
                          <></>
                        ) : !(kakaoPayCards && kakaoPayCards.length > 0) ? (
                          <></>
                        ) : (
                          kakaoPayCards.map((item, idx) => (
                            <li key={`kakaoaycard${idx}`}>
                              <span
                                className={cx('box_radioinp', {
                                  in_active:
                                    item.registeredPriorityOrder !== undefined,
                                })}>
                                <input
                                  type="radio"
                                  name="addCard"
                                  id={`addCard${idx}`}
                                  className="inp_radio"
                                  onChange={handleChangeRadio}
                                  value={item.autopayCardKey}
                                />
                                <label
                                  htmlFor={`addCard${idx}`}
                                  className="lab_radio">
                                  <span className="img_comm ico_radio" />
                                  {item.cardCorpDisplayName}{' '}
                                  {getMaskingCardNumber(`${item.cardBin}`)}{' '}
                                  {item.registeredPriorityOrder !== undefined &&
                                    (item.registeredPriorityOrder === 0
                                      ? '(결제카드)'
                                      : item.registeredPriorityOrder === 1
                                        ? '(예비카드1)'
                                        : '(예비카드2)')}
                                </label>
                              </span>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
                {autopayCard.length === 0 && (
                  <tr className="tr_box">
                    <th scope="row">
                      일 지출한도
                      <Tooltip>
                        1일(00시~24시)동안 자동결제 카드에서
                        <br />
                        결제 가능한 최대 한도 금액을 설정할 수 있습니다.
                      </Tooltip>
                    </th>
                    <td>
                      <div className="inner_cell">
                        <span className="box_inptxt box_cash">
                          {/* 입력시 on / 비활성화시 in_active 클래스 부여 */}
                          <span className="inner_inp">
                            <label
                              htmlFor="inpaccountName"
                              className="screen_out">
                              일 지출한도 직접 입력
                            </label>
                            <NumberFormat
                              thousandSeparator={true}
                              className="inp_txt"
                              allowNegative={false}
                              inputMode="numeric"
                              defaultValue={0}
                              onValueChange={val => {
                                handleSetAutopayDailySpendingBudget(val.value)
                              }}
                            />
                            <span className="txt_won">원</span>
                          </span>
                        </span>
                        <p className="txt_append">
                          최소 1,000원 이상 설정 가능합니다.
                        </p>
                        {/* 2021-05-10 수정 */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              {!kakaoPayCardsLoading &&
              kakaoPayCards.length <=
                (!autopayCardLoading &&
                  autopayCard.length > 0 &&
                  autopayCard.filter(item => item.priorityOrder !== undefined)
                    .length) ? (
                <ul className="list_notice list_default">
                  <li>모든 카드가 이미 등록되어 있습니다.</li>
                  <li>
                    카드 추가 등록은 결제카드 관리 오른쪽 &apos;신규 카드
                    등록&apos;을 클릭하거나, [확인]을 를릭하면 [내 정보]로
                    이동하여 카드 추가가 가능합니다.
                  </li>
                </ul>
              ) : !autopayCardLoading && autopayCard.length === 1 ? (
                <ul className="list_notice">
                  <li>결제카드 결제가 실패한 경우 예비카드1로 결제됩니다.</li>
                  <li>일 지출한도는 결제카드에 설정된 한도와 동일합니다.</li>
                  <li>
                    카드 추가 등록은 결제카드관리 오른쪽 &apos;신규 카드
                    등록&apos;을 통해 가능합니다.
                  </li>
                </ul>
              ) : !autopayCardLoading && autopayCard.length === 2 ? (
                <ul className="list_notice">
                  <li>
                    결제카드와 예비카드1 결제가 모두 실패할 경우 예비카드2로
                    결제됩니다.
                  </li>
                  <li>일 지출한도는 결제카드에 설정된 한도와 동일합니다.</li>
                  <li>
                    카드 추가 등록은 결제카드관리 오른쪽 &apos;신규 카드
                    등록&apos;을 통해 가능합니다.
                  </li>
                </ul>
              ) : (
                <ul className="list_notice">
                  <li>
                    카드 추가 등록은 결제카드관리 오른쪽 &apos;신규 카드
                    등록&apos;을 통해 가능합니다.
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        id="confirmPriorityOrderToZero"
        className="cardregist_layer"
        title="결제카드로 변경"
        okText="변경"
        ok={handlePriorityOrderToZero}>
        <div className="box_vertical min_vertical">
          <div className="inner_box">
            <strong className="tit_center">
              {priorityOrderToZeroTitle}을(를) 결제카드로 변경하시겠습니까?
            </strong>
            <p className="txt_center">
              결제카드로 변경 시 현재 결제카드의 결제 예정액을 포함하여
              <br />
              변경 시점부터 광고비가 결제됩니다.
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        id="confirmDeleteMasterCardByOwner"
        className="cardregist_layer"
        title="결제카드 삭제"
        okText="즉시결제 후 삭제"
        ok={deleteAutopayCardMethod}>
        <div className="wrap_tbl">
          <div className="inner_wrap">
            <p className="subtit_layer">
              결제 예정금액을 결제하시면 카드 삭제가 완료됩니다.
            </p>
            <table className="tbl_layer">
              <caption className="hide_caption">결제 예정금액 정보 표</caption>
              <colgroup>
                <col width="159" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">결제카드</th>
                  <td>
                    <div className="inner_cell">
                      {deleteMasterCardByOwnerTitle}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">결제 예정 금액</th>
                  <td>
                    <div className="inner_cell">
                      <NumberFormat
                        value={deleteMasterCardByOwnerExpectedPayment}
                        displayType={'text'}
                        thousandSeparator={true}
                        defaultValue={0}
                      />
                      원
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <Confirm
        id="confirmDeleteCardByOwner"
        title={`${deleteCardByOwnerTitle} 삭제`}
        okText="확인"
        ok={deleteAutopayCardMethodOwner}>
        <p className="txt_layer">
          {deleteCardByOwnerCard}을(를) 삭제하시겠습니까?
        </p>
      </Confirm>
      <Alert
        id="alertDeleteMasterCardByNoOwner"
        title="결제카드 삭제"
        okText="결제카드 등록">
        <p className="txt_layer">
          결제카드 삭제를 위해서는
          <br />
          새로운 결제카드를 등록해주세요.
        </p>
      </Alert>
      <Alert
        id="alertEmptyMyAutopayCard"
        title="자동결제 카드 등록"
        okText="확인"
        ok={() => {
          history.push(`/myinfo?tab=autopaycard`)
        }}>
        <p className="txt_layer">
          등록한 자동결제 카드가 없습니다.
          <br /> 카드 추가로 자동결제 카드를 등록하세요.
        </p>
      </Alert>
      <Alert id="alertNoChoosedAutocard" title="카드 미선택">
        카드를 선택하세요.
      </Alert>
      <Alert id="alertInvalidDailySpendingBudget" title="일 지출한도">
        일 지출한도는 1,000원 이상 2천만원 이하의
        <br />
        1,000원 단위 숫자로만 입력 가능합니다.
      </Alert>
      <Modal
        id="confirmAddMyAutopayCard"
        className="cardinfo_layer"
        innerClassName="inner_basic_layer1"
        title="자동결제 카드 등록"
        okText="확인"
        disabledOk={!checkedAddAutopayCard}
        ok={() => {
          addMyAutopayCard(true)
        }}>
        <strong className="subtit_layer">
          자동결제를 위한 카드를 불러오거나, 새로 등록하기 위해 아래 동의가
          필요합니다.
        </strong>
        <div className="agree_master">
          <div className="tit_agreemaster">
            <span className="box_checkinp">
              <input
                type="checkbox"
                name="inpCheckSet2"
                id="checkSet1"
                className="inp_check"
                checked={checkedAddAutopayCard}
                onChange={ev => {
                  setCheckedAddAutopayCard(ev.target.checked)
                }}
              />
              <label htmlFor="checkSet1" className="lab_check">
                <span className="img_comm ico_check" />
                결제조건을 확인하고 결제 진행에 동의합니다.
              </label>
            </span>
          </div>
          <ul className="list_guide">
            <li>결제 예정금액이 50만 원에 도달하면 결제됩니다.</li>
            <li>
              결제 예정금액이 50만 원에 도달하지 않은 경우 매일 자정 00시 00분에
              결제됩니다.
            </li>
            <li>
              결제 예정금액이 있는 경우 카드를 삭제하려고 시도할 때
              결제예정금액을 결제해야 삭제가 가능합니다.
            </li>
          </ul>
        </div>
      </Modal>
    </>
  )
}

export default PaymentCardIndex
