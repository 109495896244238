import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../features/dialog/Dialog'

const Modal = ({ children, ...rest }) => (
  <Dialog type="modal" {...rest}>
    {children}
  </Dialog>
)

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  ok: PropTypes.func,
  showOk: PropTypes.bool,
  disabledOk: PropTypes.bool,
  okText: PropTypes.string,
  cancel: PropTypes.func,
  showCancel: PropTypes.bool,
  cancelText: PropTypes.string,
  close: PropTypes.func,
  keepModal: PropTypes.bool,
}

export default Modal
