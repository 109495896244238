/* eslint-disable */
import React from 'react'
import NumberFormat from 'react-number-format'
import Loading from 'components/loading/Loading'
import { Link } from 'react-router-dom'

export const CardCardPayment = ({
  autopayCardLoading,
  autopayCard,
  wallet,
  dspAccount,
  adAccount,
}) => {
  const mastercard =
    autopayCard.length > 0
      ? autopayCard.filter(card => card.priorityType === 'MASTER')[0]
      : null
  const getMaskingCardNumber = numberString => {
    if (numberString && numberString.length >= 6) {
      return `${numberString.substring(0, 4)} ${numberString.substring(
        4,
        6
      )}** **** ****`
    }
    return numberString
  }
  return (
    <div
      className={
        wallet && wallet.deferredPayment
          ? `card_summary card_payment in_active`
          : `card_summary card_payment`
      }>
      <strong className="tit_card">카드 결제 예정액</strong>
      {wallet && wallet.deferredPayment ? (
        <em className="txt_info">
          후불 계정은
          <br />
          자동결제카드를
          <br />
          지원하지 않습니다.
        </em>
      ) : autopayCardLoading ? (
        <Loading />
      ) : autopayCard.length > 0 ? (
        <>
          <em className="txt_info">
            <NumberFormat
              value={mastercard.expectedPayment}
              displayType={'text'}
              thousandSeparator={true}
            />
            원
          </em>
          <div className="define_adinfo">
            <dl>
              <dt>카드번호</dt>
              <dd>
                <span className="txt_limit">
                  {mastercard.cardCorpDisplayName}{' '}
                  {getMaskingCardNumber(`${mastercard.cardBin}`)}
                </span>
              </dd>
            </dl>
            <dl>
              <dt>소유자</dt>
              <dd>
                {mastercard.kakaoEmail}
                {mastercard.kakaoEmail === dspAccount.kakaoEmail ? ` (나)` : ''}
              </dd>
            </dl>
            <dl>
              <dt>일 지출한도</dt>
              <dd>
                <NumberFormat
                  value={mastercard.dailySpendingBudget}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                원
              </dd>
            </dl>
            <Link
              to={`/${adAccount.id}/settings/paymentcard`}
              className="btn_gs">
              {/* 비활성화시 in_active 클래스 추가 */}
              <span className="inner_g">더보기</span>
            </Link>
          </div>
        </>
      ) : (
        <em className="txt_info">미등록</em>
      )}
    </div>
  )
}
