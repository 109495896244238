import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLayoutClass, removeLayoutClass } from 'features/layout/layoutSlice'
import {
  fetchAdAccountWithoutStatus,
  modifyAdAccountOnOff,
} from 'features/adAccount/adAccountSlice'

const KakaoWrap = ({ children }) => {
  const dispatch = useDispatch()
  const { classNames } = useSelector(state => state.layout)
  const adAccountId = useSelector(state => state.adAccount.current)?.id
  const [scrolled, setScrolled] = useState(false)
  const className = useMemo(() => {
    const baseClass = scrolled ? ['scrolling'] : []
    return baseClass.concat(classNames).join(' ')
  }, [scrolled, classNames])

  const handleScroll = () => {
    setScrolled(window.scrollY > 0)
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  })

  const handleAddLayoutClass = useCallback(
    ({ detail }) => {
      dispatch(addLayoutClass(detail))
    },
    [dispatch]
  )

  useEffect(() => {
    document.addEventListener('addLayoutClass', handleAddLayoutClass)
    return () => {
      document.removeEventListener('addLayoutClass', handleAddLayoutClass)
    }
  }, [handleAddLayoutClass])

  const handleRemoveLayoutClass = useCallback(
    ({ detail }) => {
      dispatch(removeLayoutClass(detail))
    },
    [dispatch]
  )

  useEffect(() => {
    document.addEventListener('removeLayoutClass', handleRemoveLayoutClass)
    return () => {
      document.removeEventListener('removeLayoutClass', handleRemoveLayoutClass)
    }
  }, [handleRemoveLayoutClass])

  const handleAdAccountOnOff = useCallback(
    ({ detail }) => {
      if (adAccountId && ['ON', 'OFF'].includes(detail)) {
        dispatch(
          modifyAdAccountOnOff({
            adAccountId,
            state: detail,
          })
        )
      }
    },
    [dispatch, adAccountId]
  )

  useEffect(() => {
    document.addEventListener('adAccountOnOff', handleAdAccountOnOff)
    return () => {
      document.removeEventListener('adAccountOnOff', handleAdAccountOnOff)
    }
  }, [handleAdAccountOnOff])

  const handleAdAccountSync = useCallback(() => {
    dispatch(fetchAdAccountWithoutStatus(adAccountId))
  }, [dispatch, adAccountId])

  useEffect(() => {
    document.addEventListener('adAccountSync', handleAdAccountSync)
    return () => {
      document.removeEventListener('adAccountSync', handleAdAccountSync)
    }
  }, [handleAdAccountSync])

  return (
    <div id="kakaoWrap" className={className}>
      {children}
    </div>
  )
}

KakaoWrap.propTypes = {
  children: PropTypes.node,
}
export default KakaoWrap
