import React, { useState } from 'react'
import Modal from '../../../components/dialog/Modal'
import { postBondRepay } from 'api/billingAPI'
import { hideModal } from 'features/dialog/dialogSlice'
import { useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format'
import Tooltip from '../../../components/tooltip/Tooltip'
import { fetchBondList, fetchWallet } from 'features/settings/cash/cashSlice'
import { fetchAdAccountWithoutStatus } from 'features/adAccount/adAccountSlice'
import { fail } from 'utils/toastUtils'
import PropTypes from 'prop-types'

const ModalRequestRePayment = ({ dspAccount, adAccount, bondRepay }) => {
  const dispatch = useDispatch()
  const initBondRepay = {
    nextDueDt: bondRepay && bondRepay?.nextDueDt,
    remainedBondAmount: bondRepay && bondRepay?.remainedBondAmount,
    planRepayAmount: bondRepay && bondRepay?.planRepayAmount,
    dueDtRemainedBondAmount: bondRepay && bondRepay?.dueDtRemainedBondAmount,
    maxPayableAmount: bondRepay && bondRepay?.maxPayableAmount,
    paymentAmount: bondRepay && bondRepay?.paymentAmount,
  }
  const [bondRepays, setBondRepays] = useState(initBondRepay)

  const handleRequestRePayment = async () => {
    try {
      const { data } = await postBondRepay(adAccount?.id, {
        adPlatformType: 'KEYWORDAD',
        dspId: 'KEYWORDAD',
        memberType: 'DSP_ACCOUNT',
        memberId: dspAccount?.id,
        businessType: adAccount?.adAccountType,
        plans: [
          {
            walletId: adAccount?.id,
            repayAmount: bondRepays.paymentAmount,
          },
        ],
        redirectUrl: [
          window.location.protocol,
          '//',
          window.location.host,
          '/popup.html',
        ].join(''),
        popUpRedirectUrl: true,
      })
      if (data.paymentUrl) {
        window.open(
          data.paymentUrl,
          '_blank',
          'width=520,height=701,top=0,left=0,menubar=no,status=no,toolbar=no'
        )
        window.anonymousCallback = () => {
          dispatch(fetchWallet(adAccount?.id))
          dispatch(fetchAdAccountWithoutStatus(adAccount?.id))
          dispatch(
            fetchBondList({
              adAccountId: adAccount?.id,
              repaymentStatus: '',
              page: 0,
            })
          )
          dispatch(hideModal('modalRequestRePayment'))
        }
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  return (
    <>
      <Modal
        id="modalRequestRePayment"
        className="payment_layer"
        title="결제"
        disabledOk={
          bondRepays &&
          (bondRepays.paymentAmount.length === 0 ||
            (bondRepays && bondRepays.paymentAmount - 0 < 1))
        }
        ok={handleRequestRePayment}
        cancel={() => {
          setBondRepays(initBondRepay)
        }}
        close={() => {
          setBondRepays(initBondRepay)
        }}>
        <p className="subtit_layer">
          결제 가능 내역
          <span className="date_repay">
            (다음 결제기한일 : {bondRepay && bondRepay?.nextDueDt})
          </span>
        </p>
        <table className="tbl_layer">
          <caption className="hide_caption">결제 가능 금액 표</caption>
          <colgroup>
            <col style={{ width: 280 }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                미결제 총 금액
                <Tooltip>
                  광고계정 기준으로 다음 결제기한일 기준의 미결제금액과 이후의
                  결제기한일의 미결제금액의 총합입니다.
                </Tooltip>
              </th>
              <td className="align_r">
                <NumberFormat
                  value={bondRepay && bondRepay?.remainedBondAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  defaultValue={0}
                />
                원
              </td>
            </tr>
            <tr>
              <th scope="row">
                다음 결제기한일 기준 미결제 금액
                <Tooltip>
                  다음 결제기한일 기준 미결제금액과 기한 이전의 연체금액의
                  총합입니다.
                </Tooltip>
              </th>
              <td className="align_r">
                <NumberFormat
                  value={bondRepay && bondRepay?.dueDtRemainedBondAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  defaultValue={0}
                />
                원
              </td>
            </tr>
            <tr>
              <th scope="row">
                기발급 가상계좌 입금대기 금액
                <Tooltip>
                  가상계좌를 이미 발급하고 입금하지 않은 금액의 총합입니다.
                </Tooltip>
              </th>
              <td className="align_r">
                <NumberFormat
                  value={bondRepay && bondRepay?.planRepayAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  defaultValue={0}
                />
                원
              </td>
            </tr>
            <tr>
              <th scope="row">
                최대 결제 가능 금액
                <Tooltip>
                  결제 금액에 입력할 수 있는 최대 금액입니다. 전체 미결제
                  금액에서 기발급 가상계좌 입금대기 금액을 차감한 금액과 10억원
                  중 작은 금액입니다.
                </Tooltip>
              </th>
              <td className="align_r">
                <NumberFormat
                  value={bondRepay && bondRepay?.maxPayableAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  defaultValue={0}
                />
                원
              </td>
            </tr>
          </tbody>
        </table>
        <div className="payment_wrap">
          <table className="tbl_layer">
            <caption className="hide_caption">결제 금액 선택 표</caption>
            <colgroup>
              <col style={{ width: 161 }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <div className="inner_cell">결제금액</div>
                </th>
                <td>
                  <div className="inner_cell">
                    <span className="box_inptxt inp_num ">
                      {/* 입력시 on / 비활성화시 in_active 클래스 부여 */}
                      {/* 금액 input 인 경우 inp_num 클래스 추가 */}
                      <span className="num_byte">원</span>
                      <span className="inner_inp">
                        <NumberFormat
                          thousandSeparator={true}
                          className="inp_txt"
                          allowNegative={false}
                          decimalSeparator={false}
                          inputMode="numeric"
                          defaultValue={bondRepays.paymentAmount}
                          onValueChange={val => {
                            setBondRepays({
                              ...bondRepays,
                              paymentAmount: val.value,
                            })
                          }}
                        />
                      </span>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="desc_guide">
            결제 금액이 1,000원 미만인 경우 신용카드 결제만 가능합니다.
          </p>
        </div>
      </Modal>
    </>
  )
}
ModalRequestRePayment.propTypes = {
  dspAccount: PropTypes.object.isRequired,
  adAccount: PropTypes.object.isRequired,
  bondRepay: PropTypes.object.isRequired,
}
export default ModalRequestRePayment
