import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { dspLogin, otpLogin } from 'features/login/dspAccountSlice'

const useLogin = () => {
  const dispatch = useDispatch()
  const { otp, accountId, adAccountId, serviceType } = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )
  if (otp && accountId && adAccountId && serviceType) {
    sessionStorage.setItem(
      'otp',
      JSON.stringify({
        otp,
        accountId,
        adAccountId,
        serviceType,
      })
    )
  }
  useEffect(() => {
    if (sessionStorage.getItem('otp')) {
      dispatch(otpLogin())
    } else {
      dispatch(dspLogin())
    }
  }, [dispatch])
}

export default useLogin
