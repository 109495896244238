import Select from 'components/form/Select'
import { SELECTBOX_BANKNAME } from './CashConstants'
import React, { useEffect, useState } from 'react'
import Modal from 'components/dialog/Modal'
import TextInput from 'components/form/TextInput'
import Checkbox from 'components/form/Checkbox'
import { deleteWithdrawAccount, putWithdrawAccount } from 'api/billingAPI'
import { fetchWithdrawAccount } from 'features/settings/cash/cashSlice'
import { hideModal, showModal } from 'features/dialog/dialogSlice'
import { useDispatch } from 'react-redux'
import { getRefundOwnName } from 'api/accountAPI'
import Alert from 'components/dialog/Alert'
import PropTypes from 'prop-types'
import { fail, success } from 'utils/toastUtils'

const ModalModifyWithdrawAccount = ({
  dspAccount,
  adAccount,
  refundAccount,
  withdrawList,
}) => {
  const dispatch = useDispatch()
  const [ownName, setOwnName] = useState(null)
  const withdrawAccount = {
    accountNumber: refundAccount && refundAccount.accountNumber,
    bankCode: refundAccount && refundAccount.bankCode,
    ownerName:
      adAccount.adAccountType === 'INDIVIDUAL'
        ? ownName && ownName.name
        : refundAccount && refundAccount.ownerName,
  }
  const [modifyWithdrawAccount, setModifyWithdrawAccount] =
    useState(withdrawAccount)
  const [checkboxAgreement, setCheckboxAgreement] = useState(false)
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false)

  const onChange = e => {
    const { name, value } = e.target
    setModifyWithdrawAccount({
      ...modifyWithdrawAccount,
      [name]: value,
    })
  }

  const handleChange = event => {
    setCheckboxAgreement(event.target.checked)
  }

  const handleDeleteWithdrawAccount = async () => {
    try {
      await deleteWithdrawAccount(adAccount.id, {
        data: {
          memberId: dspAccount.id,
          memberType: 'DSP_ACCOUNT',
        },
      })
      await dispatch(fetchWithdrawAccount(adAccount.id))
      success('환불계좌가 삭제되었습니다.')
      dispatch(hideModal('modalModifyWithdrawAccount'))
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal('modalModifyWithdrawAccount'))
      fail(message)
    }
  }

  const handleWithdrawAccountBankCode = value => {
    setModifyWithdrawAccount({
      ...modifyWithdrawAccount,
      bankCode: value,
    })
  }
  const [invalidMessage, setInvalidMessage] = useState('')
  const handleModifyWithdrawAccount = async () => {
    if (modifyWithdrawAccount.accountNumber.replace(/\s/g, '').length === 0) {
      setInvalidMessage('계좌번호를 입력해주세요.')
      dispatch(showModal('alertWithdrawAccount'))
      return false
    }
    if (!/^[\d|-]+$/.test(modifyWithdrawAccount.accountNumber)) {
      setInvalidMessage('올바른 계좌번호를 입력해주세요.')
      dispatch(showModal('alertWithdrawAccount'))
      return false
    }
    if (
      adAccount.adAccountType !== 'INDIVIDUAL' &&
      modifyWithdrawAccount.ownerName.replace(/\s/g, '').length === 0
    ) {
      setInvalidMessage('예금주를 입력해주세요.')
      dispatch(showModal('alertWithdrawAccount'))
      return false
    }
    setDisabledConfirmButton(true)
    try {
      await putWithdrawAccount(adAccount.id, {
        accountNumber: modifyWithdrawAccount.accountNumber,
        bankCode: modifyWithdrawAccount.bankCode,
        ownerName:
          adAccount.adAccountType === 'INDIVIDUAL'
            ? ownName && ownName.name
            : modifyWithdrawAccount.ownerName,
        businessType: adAccount.adAccountType,
        dspId: 'KEYWORDAD',
        adPlatformType: 'KEYWORDAD',
        memberType: 'DSP_ACCOUNT',
        memberId: dspAccount.id,
      })
      dispatch(fetchWithdrawAccount(adAccount.id))
      setCheckboxAgreement(false)
      setDisabledConfirmButton(false)
      success('환불계좌가 수정되었습니다.')
      dispatch(hideModal())
    } catch (error) {
      const message = error?.response?.data?.message
      dispatch(hideModal())
      setDisabledConfirmButton(false)
      setCheckboxAgreement(false)
      fail(message)
    }
  }
  useEffect(() => {
    ;(async () => {
      await getRefundOwnName(adAccount.id)
        .then(({ data }) => setOwnName(data))
        .catch(() => {})
    })()
  }, [adAccount.id])
  return (
    <>
      <Modal
        id="modalModifyWithdrawAccount"
        keepModal={true}
        disabledOk={!checkboxAgreement || disabledConfirmButton}
        ok={async () => {
          await handleModifyWithdrawAccount()
        }}
        close={() => {
          setModifyWithdrawAccount(withdrawAccount)
          setCheckboxAgreement(false)
        }}
        cancel={() => {
          setModifyWithdrawAccount(withdrawAccount)
          setCheckboxAgreement(false)
        }}
        className="refund_layer"
        title="환불계좌 수정"
        okText="저장">
        <div className="layer_subtit">
          <p className="subtit_layer subtit_refund">
            광고계정 생성 시, 등록한 광고계정에 맞는 계좌로 등록해주세요.
          </p>
          {withdrawList &&
            withdrawList.content.length === 0 &&
            !withdrawList.content.some(v =>
              ['STANDBY', 'RESERVED'].includes(v.status)
            ) && (
              <button
                type="button"
                className="btn_gs"
                onClick={handleDeleteWithdrawAccount}>
                <span className="inner_g">환불계좌 삭제</span>
              </button>
            )}
        </div>
        <ul className="list_append">
          {adAccount.advertiser &&
          adAccount.advertiser.businessRegistrationNumber &&
          adAccount.advertiser.businessRegistrationNumber.charAt(4) === '8' ? (
            <li>법인 사업자 광고계정 : 법인 사업자명 계좌</li>
          ) : adAccount.advertiser &&
            adAccount.advertiser.businessRegistrationNumber &&
            adAccount.advertiser.businessRegistrationNumber.charAt(4) !==
              '8' ? (
            <li>
              개인 사업자 광고계정 : 개인 사업자명 계좌 or 사업자 개인의
              개인명의 계좌
            </li>
          ) : (
            ''
          )}
        </ul>
        <table className="tbl_layer">
          <caption className="hide_caption">은행, 계좌번호, 예금주 표</caption>
          <colgroup>
            <col style={{ width: 161 }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">은행</th>
              <td>
                <div className="inner_cell">
                  <Select
                    options={SELECTBOX_BANKNAME}
                    onChange={handleWithdrawAccountBankCode}
                    selected={modifyWithdrawAccount.bankCode}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">계좌번호</th>
              <td>
                <div className="inner_cell">
                  <TextInput
                    name="accountNumber"
                    value={modifyWithdrawAccount.accountNumber}
                    onChange={onChange}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">예금주</th>
              <td>
                <div className="inner_cell">
                  {adAccount.adAccountType === 'INDIVIDUAL' ? (
                    ownName && ownName.name
                  ) : (
                    <TextInput
                      name="ownerName"
                      value={modifyWithdrawAccount.ownerName}
                      onChange={onChange}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="view_agree">
          <Checkbox
            name="marketingAgreement"
            onChange={handleChange}
            checked={checkboxAgreement}>
            개인정보 수집 및 이용동의 <em className="fc_warn2">(필수)</em>
          </Checkbox>
          <div
            className="box_agree"
            style={{ display: checkboxAgreement ? 'block' : 'none' }}>
            {/* 동의 체크시 display:block으로 전환 */}
            <p className="desc_agree">
              (주)카카오는 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의
              개인정보를 안전하게
              <br />
              취급하는데 최선을 다합니다.
            </p>
            <table className="tbl_layer">
              <caption className="hide_caption">
                개인정보 수집 및 이용동의 표
              </caption>
              <colgroup>
                <col style={{ width: 161 }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">목적</th>
                  <td>
                    <div className="inner_cell">환불처리</div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">항목</th>
                  <td>
                    <div className="inner_cell">은행명, 계좌번호, 예금주명</div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">보유기간</th>
                  <td>
                    <div className="inner_cell">
                      <strong
                        style={{
                          textDecoration: 'underline',
                          fontSize: '17px',
                        }}>
                        광고 계정 삭제 시 지체 없이 파기
                      </strong>
                      <br />
                      (단, 전자상거래법 등 관계 법령에 의해 보유가 허용된 경우,
                      <br />
                      법령에서 정한 기간(5년) 동안 보관)
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="desc_agree">
              서비스 제공을 위한 필요한 최소한의 개인정보이므로 동의를 해 주셔야
              서비스 제공이 가능합니다. 더 자세한 내용에 대해서는{' '}
              <a
                href="https://business.kakao.com/policy/privacy/"
                target="_blank"
                rel="noopener noreferrer"
                className="fc_empty">
                개인정보처리방침
              </a>
              을 참고하시기 바랍니다.
            </p>
          </div>
        </div>
      </Modal>
      <Alert id="alertWithdrawAccount" title="환불 계좌 오류">
        {invalidMessage}
      </Alert>
    </>
  )
}

ModalModifyWithdrawAccount.propTypes = {
  dspAccount: PropTypes.object,
  adAccount: PropTypes.object,
  refundAccount: PropTypes.object,
  withdrawList: PropTypes.object,
}

export default ModalModifyWithdrawAccount
