import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTaxList } from '../../../api/billingAPI'
import { getDelegationHistory } from '../../../api/accountAPI'

const name = 'tax'

export const fetchTaxList = createAsyncThunk(
  `${name}/fetchTaxList`,
  async (
    { adAccountId, from, to, issueStatuses, page },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getTaxList({
        adAccountId,
        from,
        to,
        issueStatuses,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { errorCode, message } = response.data
      return rejectWithValue({
        errorCode,
        message,
      })
    }
  }
)

export const fetchDelegationHistory = createAsyncThunk(
  `${name}/fetchDelegationHistory`,
  async ({ adAccountId, page }, { rejectWithValue }) => {
    try {
      const { data } = await getDelegationHistory({
        adAccountId,
        page,
      })
      return data
    } catch (error) {
      const { response } = error
      const { errorCode, message } = response.data
      return rejectWithValue({
        errorCode,
        message,
      })
    }
  }
)

const taxSlice = createSlice({
  name,

  initialState: {
    taxList: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
    delegationHistory: {
      pagable: {
        content: [],
      },
      isLoading: false,
    },
  },

  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchTaxList.pending, state => {
      state.taxList.isLoading = true
    })

    builder.addCase(fetchTaxList.fulfilled, (state, { payload }) => {
      state.taxList.isLoading = false
      state.taxList.pagable = payload
    })

    builder.addCase(fetchTaxList.rejected, state => {
      state.taxList.isLoading = false
    })

    builder.addCase(fetchDelegationHistory.pending, state => {
      state.delegationHistory.isLoading = true
    })

    builder.addCase(fetchDelegationHistory.fulfilled, (state, { payload }) => {
      state.delegationHistory.isLoading = false
      state.delegationHistory.pagable = payload
    })

    builder.addCase(fetchDelegationHistory.rejected, state => {
      state.delegationHistory.isLoading = false
    })
  },
})
const { reducer } = taxSlice
export default reducer
