import React, { useState } from 'react'
import { createPortal } from 'react-dom'

const BrowserAlert = () => {
  const [visible, setVisible] = useState(true)
  const isChrome = navigator.userAgent.includes('Chrome')
  return (
    visible &&
    !isChrome &&
    createPortal(
      <div className="layer_alert" style={{ position: 'fixed' }}>
        <p className="txt_alert">
          이 사이트는 <span className="link_browser">Chrome</span> 브라우저에
          최적화되어있어, 현재 브라우저에서는 정상적으로 동작하지 않을 수
          있습니다.
        </p>
        <button className="link_close" onClick={() => setVisible(false)}>
          <span className="ico_comm ico_close">닫기</span>
        </button>
      </div>,
      document.body
    )
  )
}
export default BrowserAlert
