import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeBizRightTransferable,
  fetchBizRightCompleted,
  fetchBizRightRequested,
} from 'features/settings/adaccount/businessRightSlice'
import Checkbox from '../../../components/form/Checkbox'
import useScrollTop from 'features/layout/useScrollTop'
import { fetchAdAccountWithoutStatus } from 'features/adAccount/adAccountSlice'
import {
  putBizRightApprove,
  putBizRightReject,
  putBizRightRelease,
  putModifyDelegationStatus,
} from 'api/accountAPI'
import { showModal } from 'features/dialog/dialogSlice'
import Confirm from '../../../components/dialog/Confirm'
import { fail, success } from 'utils/toastUtils'

const BusinessRight = () => {
  useScrollTop()
  const dispatch = useDispatch()
  const adAccount = useSelector(state => state.adAccount.current)
  const { id, master, transferBizRight } = adAccount
  const { completed, requested } = useSelector(state => state.businessRight)
  const handleBizRightTransferable = async transferable => {
    try {
      const { payload } = await dispatch(
        changeBizRightTransferable({
          adAccountId: id,
          transferable: !transferable,
        })
      )
      dispatch(fetchAdAccountWithoutStatus(id))
      success(
        `영업권 요청 거부가 ${
          payload.transferBizRight ? '해제' : '설정'
        }되었습니다.`
      )
    } catch (error) {
      fail(error.message)
    }
  }
  const handleRejectBizRightTransfer = async () => {
    try {
      await putBizRightReject(requested.id)
      dispatch(fetchBizRightRequested(id))
      success('영업권 요청을 거부하였습니다.')
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleApproveBizRightTransfer = async () => {
    try {
      await putBizRightApprove(requested.id)
      dispatch(fetchAdAccountWithoutStatus(id))
      dispatch(fetchBizRightCompleted(id))
      dispatch(fetchBizRightRequested(id))
      success('영업권이 설정되었습니다.')
      if (requested.delegationStatus === 'REQUESTED') {
        dispatch(showModal('confirmDelegation'))
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleShowReleaseBizRight = () => {
    dispatch(showModal('confirmReleaseBizRight'))
  }
  const handleConfirmReleaseBizRight = async () => {
    try {
      await putBizRightRelease(completed.id)
      dispatch(fetchAdAccountWithoutStatus(id))
      dispatch(fetchBizRightCompleted(id))
      dispatch(fetchBizRightRequested(id))
      success('영업권이 해제되었습니다.')
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }
  const handleConfirmModifyDelegationStatus = async delegationStatus => {
    try {
      await putModifyDelegationStatus(completed.id, delegationStatus)
      dispatch(fetchBizRightCompleted(adAccount.id))
      dispatch(fetchAdAccountWithoutStatus(adAccount.id))
      if (delegationStatus === 'COMPLETED') {
        success(`세금계산서 위임발행이 설정되었습니다.`)
      } else if (delegationStatus === 'REJECTED') {
        success(`세금계산서 위임발행이 거부되었습니다.`)
      } else if (delegationStatus === 'RELEASED') {
        success(`세금계산서 위임발행이 해제되었습니다.`)
      }
    } catch (error) {
      const message = error?.response?.data?.message
      fail(message)
    }
  }

  useEffect(() => {
    ;(async () => {
      await dispatch(fetchBizRightCompleted(id))
      await dispatch(fetchBizRightRequested(id))
    })()
  }, [dispatch, id])
  return (
    <>
      <div className="ad_managebox">
        <div className="managebox_tit">
          <strong className="tit_box">영업권</strong>
        </div>
        <div className="tblg2_wrap">
          <table className="tbl_g2">
            <caption className="hide_caption">영업권 안내 표</caption>
            {master ? (
              <>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="inner_tbl">대행사</div>
                    </th>
                    <th scope="col">
                      <div className="inner_tbl">영업권 요청 설정</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="inner_tbl">
                        {completed ? (
                          <>
                            {completed.marketer.agencyCompany.name}
                            <div className="wrap_btn after_item">
                              <button
                                type="button"
                                className="btn_gs"
                                onClick={handleShowReleaseBizRight}>
                                <span className="inner_g">영업권 해제</span>
                              </button>
                            </div>
                          </>
                        ) : (
                          '직접운영'
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="inner_tbl">
                        <Checkbox
                          onChange={event => {
                            const { target } = event
                            const { checked } = target
                            event.preventDefault()
                            handleBizRightTransferable(checked)
                          }}
                          checked={!transferBizRight}>
                          영업권 요청 거부
                        </Checkbox>
                        <p className="desc_info">
                          설정 시 해당 광고계정에 대하여 더이상 영업권 요청을
                          받지 않습니다.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                <colgroup>
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="inner_tbl">대행사</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="inner_tbl">
                        {completed
                          ? completed.marketer.agencyCompany.name
                          : '직접운영'}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
      {master && requested && (
        <div className="ad_managebox">
          <div className="managebox_tit">
            <strong className="tit_box">영업권 요청 현황</strong>
          </div>
          <div className="tblg2_wrap">
            <table className="tbl_g2">
              <caption className="hide_caption">영업권 안내 표</caption>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="inner_tbl">대행사</div>
                  </th>
                  <th scope="col">
                    <div className="inner_tbl">영업권 요청 설정</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="inner_tbl">
                      {requested.marketer.agencyCompany.name}
                    </div>
                  </td>
                  <td>
                    <div className="inner_tbl">
                      <div className="wrap_btn">
                        <button
                          className="btn_gs"
                          onClick={handleRejectBizRightTransfer}>
                          <span className="inner_g">거부</span>
                        </button>
                        <button
                          className="btn_gs"
                          onClick={handleApproveBizRightTransfer}>
                          <span className="inner_g">수락</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <Confirm
        id="confirmDelegation"
        className="entrustset_layer"
        okText="수락"
        cancelText="거부"
        marginLeft={-428.5}
        ok={async () => {
          await handleConfirmModifyDelegationStatus('COMPLETED')
        }}
        cancel={async () => {
          await handleConfirmModifyDelegationStatus('REJECTED')
        }}>
        <strong className="tit_layer">
          {completed && completed.marketer.agencyCompany.name}(대행사)으로
          영업권이 설정되었습니다.
          <br /> 세금계산서 위임발행도 함께 설정 하시겠습니까?
        </strong>
        <p className="txt_layer">
          세금계산서 위임발행 설정 후에는 세금계산서가{' '}
          {completed && completed.marketer.agencyCompany.name}(대행사)으로
          발송됩니다.
        </p>
        <div className="box_entrustset">
          <em className="tit_list">주의사항</em>
          <ul className="list_guide">
            <li>
              광고용역거래에서 광고대행사가 대행 역할(주선·중개)만을 수행하는
              경우에는 부가가치세법 시행령 제69조에 따라 해당 용역거래와 관련된
              세금계산서의 [공급자] 는 카카오가 되고 [공급받는 자] 는 광고주가
              되어야 합니다.
            </li>
            <li>
              광고용역거래에서 광고대행사가 대행 역할(주선·중개)만을 수행하는
              경우가 아닌, 광고주로부터 포괄적 위임을 받아 자기 계산과 책임하에
              광고용역을 광고주에게 제공하는 것임을 광고대행사 및 광고주가
              확인해 주는 경우에 한하여 [공급받는 자] 를 광고대행사로 세금계산서
              위임발행을 하고 있습니다.
            </li>
            <li>
              세금계산서 위임발행은 대행사와 광고주 간의 문자 승인의 절차를
              거쳐서 진행하므로, 세금계산서 위임발행으로 인한 문제의 귀책사유는
              이를 신청한 대행사와 승인한 광고주에 있음을 알려드리며, 승인 전
              해당 대행사의 계약관계를 재고하여, 승인해 주시기 바랍니다.
            </li>
            <li>
              위임발행에 대한 판단 착오시, 「부가가치세법」 제60조[가산세]에
              따라, 공급가액의 3%의 가산세를 부담합니다.
              <br /> 이 때의 가산세는, 약관 상 &apos;회원의 의무&apos;를 위반한
              것으로 보아, 손해를 배상하여야 합니다.
            </li>
          </ul>
        </div>
      </Confirm>
      <Confirm
        id="confirmReleaseBizRight"
        title="영업권 해제"
        ok={handleConfirmReleaseBizRight}>
        {completed && completed.marketer.agencyCompany.name} 대행사 영업권을
        해제하시겠습니까?
      </Confirm>
    </>
  )
}
export default BusinessRight
