import Calendar from 'components/calendar/Calendar'
import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import Icon from 'components/Icon'
import {
  CalendarContext,
  DateRangePickerContext,
} from 'components/calendar/CalendarContext'
import {
  CALENDAR_PRESETS,
  fmt,
  getDuration,
  getPresetRange,
  getVisibleMonths,
} from 'components/calendar/calendarUtil'
import HelpTooltip from 'components/tooltip/HelpTooltip'
import NoneAnchor from 'components/NoneAnchor'

const DoubleMonthLayer = () => {
  const { min, max, close, reset, onChange } = useContext(CalendarContext)
  const {
    start,
    setStart,
    end,
    setEnd,
    leftMonth,
    setLeftMonth,
    rightMonth,
    setRightMonth,
    preset,
    setPreset,
    presetExcludes,
    presetTooltip,
    movedRef,
    durationRef,
  } = useContext(DateRangePickerContext)
  const minMonth = min ? min.clone().startOf('month') : null
  const maxMonth = max ? max.clone().startOf('month') : null
  const [disabled, setDisabled] = useState(start === null || end === null)
  useEffect(() => {
    setDisabled(start === null || end === null)
  }, [start, end])
  const prev = () => {
    setLeftMonth(leftMonth.clone().subtract(1, 'months'))
    setRightMonth(rightMonth.clone().subtract(1, 'months'))
  }
  const next = () => {
    setLeftMonth(leftMonth.clone().add(1, 'months'))
    setRightMonth(rightMonth.clone().add(1, 'months'))
  }
  const handlePresetClick = selected => {
    const { start: presetStart, end: presetEnd } = getPresetRange(
      selected,
      min,
      max
    )
    if (
      selected === CALENDAR_PRESETS.CUSTOM ||
      (presetStart.isSameOrAfter(min, 'days') &&
        presetEnd.isSameOrBefore(max, 'days'))
    ) {
      setPreset(selected)
      if (presetStart && presetEnd) {
        setStart(presetStart)
        setEnd(presetEnd)
        const { left, right } = getVisibleMonths(presetEnd)
        setLeftMonth(left)
        setRightMonth(right)
        durationRef.current = getDuration(selected, presetStart, presetEnd)
      }
    }
  }
  const handleCancel = () => {
    close()
    reset()
  }
  const handleSubmit = () => {
    movedRef.current = false
    const result = onChange(start.format(fmt), end.format(fmt), preset)
    if (result !== false) {
      close()
    }
  }
  return (
    <div className="calendar_layer">
      <div className="inner_calendar_layer">
        <div className="layer_head">
          <strong className="screen_out">날짜 선택 달력</strong>
        </div>
        <div className="layer_body">
          <em className="screen_out">기간 선택</em>
          <ul className="list_period">
            {Object.keys(CALENDAR_PRESETS)
              .filter(key => !presetExcludes.includes(CALENDAR_PRESETS[key]))
              .map(key => (
                <li
                  key={key}
                  className={classNames({
                    on: preset === CALENDAR_PRESETS[key],
                  })}>
                  <NoneAnchor
                    className="link_period"
                    onClick={() => {
                      handlePresetClick(CALENDAR_PRESETS[key])
                    }}>
                    {CALENDAR_PRESETS[key].text}
                    {presetTooltip &&
                      presetTooltip.preset === CALENDAR_PRESETS[key] && (
                        <HelpTooltip>{presetTooltip.text}</HelpTooltip>
                      )}
                  </NoneAnchor>
                </li>
              ))}
          </ul>
          <div className="date_calendar">
            <div className="datecalendar_wrap">
              <div className="inner_datecalendar">
                <div className="box_datecalendar">
                  <strong className="setinfo_calendar">
                    <span className="txt_setdate">
                      {end === null
                        ? start.format(fmt)
                        : `${start.format(fmt)} ~ ${end.format(fmt)}`}
                    </span>
                  </strong>
                  <div className="setdetail_calendar">
                    <Calendar type="range" month={leftMonth} />
                    <Calendar type="range" month={rightMonth} />
                    <button
                      type="button"
                      className={classNames('btn_move btn_prev', {
                        in_active:
                          minMonth &&
                          leftMonth.isSameOrBefore(minMonth, 'days'),
                      })}
                      disabled={
                        minMonth && leftMonth.isSameOrBefore(minMonth, 'days')
                      }
                      onClick={prev}>
                      <Icon name="arr">이전</Icon>
                    </button>
                    <button
                      type="button"
                      className={classNames('btn_move btn_next', {
                        in_active:
                          maxMonth &&
                          rightMonth.isSameOrAfter(maxMonth, 'days'),
                      })}
                      disabled={
                        maxMonth && rightMonth.isSameOrAfter(maxMonth, 'days')
                      }
                      onClick={next}>
                      <Icon name="arr">다음</Icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_wrap">
              <button
                type="button"
                className={classNames('btn_gm gm_bl', {
                  in_active: disabled,
                })}
                onClick={handleSubmit}
                disabled={disabled}>
                <span className="inner_g">적용</span>
              </button>
              <NoneAnchor className="btn_gm" onClick={handleCancel}>
                <span className="inner_g">취소</span>
              </NoneAnchor>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DoubleMonthLayer
