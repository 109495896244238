import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AccessEnvironment from './header/AccessEnvironment'
import { fetchGnbMenu } from 'features/gnb/gnbSlice'
import ServiceMenu from 'features/gnb/ServiceMenu'
import Notice from 'features/gnb/Notice'
import MyMenu from '../features/gnb/MyMenu'
import { fetchGnbNoticeList } from '../features/board/boardSlice'

const AppHeader = () => {
  const dispatch = useDispatch()
  const { home } = useSelector(state => state.gnb)
  const { current, currentAdmin } = useSelector(state => state.dspAccount)
  const { disabledLogoLink } = useSelector(state => state.layout)

  useEffect(() => {
    dispatch(fetchGnbMenu())
    dispatch(fetchGnbNoticeList())
  }, [dispatch])

  return (
    <header id="kakaoHead" className="kakao_head biz_gnb">
      <AccessEnvironment />
      <h1>
        {disabledLogoLink || home === undefined ? (
          <span id="kakaoLogo">
            <span className="ir_pm">Kakao business</span>
          </span>
        ) : (
          <a href={home?.link?.href} id="kakaoLogo">
            <span className="ir_pm">Kakao business</span>
          </a>
        )}
      </h1>
      <ServiceMenu />
      <div className="gnb_relate">
        <h2 className="screen_out">서비스 관련 메뉴</h2>
        <ul className="list_relate">
          <Notice />
          <li>
            <a
              href="https://cs.kakao.com/helps?service=186"
              target="_blank"
              rel="noopener noreferrer"
              className="link_gnb">
              고객센터
            </a>
          </li>
          {current !== undefined && currentAdmin === undefined && <MyMenu />}
        </ul>
      </div>
    </header>
  )
}

export default AppHeader
